import React, { useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { useForm } from "react-hook-form";
import { OrderCardBase } from "./OrderCardBase";
import { useOrderCard } from "./OrderCardContext";
import InputField from "../../components/InputField";
import { OrderCardInvoiceSection as OrderCardInvoiceSectionType } from "../../types/orderCardInvoiceSection";
import { InputAdornment } from "@mui/material";

type FormData = {
  engLabel: string | null;
  estimatedWeight: number | null;
  hudLabel: string | null;
  vinNumber: string | null;
};

const getDefaultValues = (
  orderCard: OrderCardInvoiceSectionType | null
): FormData => {
  return {
    engLabel: orderCard?.cardData.engLabel ?? null,
    estimatedWeight: orderCard?.cardData.estimatedWeight ?? null,
    hudLabel: orderCard?.cardData.hudLabel ?? null,
    vinNumber: orderCard?.cardData.vinNumber ?? null,
  };
};

interface OrderCardInvoiceSectionProps {
  orderCard: OrderCardInvoiceSectionType;
  expanded: boolean;
  groupIndex: number;
}

export const OrderCardInvoiceSection: React.FC<
  OrderCardInvoiceSectionProps
> = ({ orderCard, expanded, groupIndex }) => {
  const { saveOrderCard, setEditingOrderCard } = useOrderCard();

  const disabled = orderCard.isLocked;

  const { register, reset, getValues } = useForm<FormData>({
    defaultValues: getDefaultValues(orderCard),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const handleBlurField = () => {
    const values = getValues();
    saveOrderCard(orderCard.orderCardGuid, values);
    setEditingOrderCard(null);
  };

  const handleEditingField = () => {
    setEditingOrderCard(orderCard);
  };

  const handleClearClick = () => {
    const defaultValues = getDefaultValues(null);
    reset(defaultValues);

    saveOrderCard(orderCard.orderCardGuid, defaultValues);
  };

  useEffect(() => {
    reset(getDefaultValues(orderCard), { keepDirtyValues: true });
  }, [orderCard, reset]);

  return (
    <OrderCardBase
      disabled={disabled}
      orderCard={orderCard}
      onClearClick={handleClearClick}
      expanded={expanded}
      groupIndex={groupIndex}
    >
      <Grid container spacing={2} component="form">
        <Grid xs={12} sm={12} md={6}>
          <InputField
            {...register("vinNumber", {
              onBlur: handleBlurField,
              valueAsNumber: true,
            })}
            label="VIN"
            onFocus={handleEditingField}
            required
            fullWidth
            disabled
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <InputField
            {...register("estimatedWeight", {
              onBlur: handleBlurField,
              valueAsNumber: true,
            })}
            label="Estimated Weight"
            onFocus={handleEditingField}
            placeholder="Enter Estimated Weight"
            required
            fullWidth
            endAdornment={<InputAdornment position="end">LBS</InputAdornment>}
            disabled={disabled}
            type="number"
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <InputField
            {...register("hudLabel", {
              onBlur: handleBlurField,
            })}
            label="HUD Label (HUD Only)"
            onFocus={handleEditingField}
            placeholder="Enter HUD Label"
            fullWidth
            disabled={disabled}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <InputField
            {...register("engLabel", {
              onBlur: handleBlurField,
            })}
            label="Engineering Label (MOD Only)"
            onFocus={handleEditingField}
            placeholder="Enter Engineering Label"
            fullWidth
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </OrderCardBase>
  );
};
