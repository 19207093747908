import {
  Avatar,
  AvatarProps,
  Button,
  Stack,
  SxProps,
  Theme,
  styled,
} from "@mui/material";
import React from "react";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

type AvatarUploadProps = {
  alt: AvatarProps["alt"];
  src: AvatarProps["src"];
  variant: AvatarProps["variant"];
  onSelectFile: (file: File) => void;
  onDeleteFile?: () => void;
  readOnly?: boolean;
  avatarSx?: SxProps<Theme>;
  deletable?: boolean;
};

export const AvatarUpload: React.FC<AvatarUploadProps> = ({
  alt,
  src,
  variant,
  onSelectFile,
  readOnly,
  avatarSx,
  deletable = false,
  onDeleteFile,
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }

    onSelectFile(file);
  };

  return (
    <Stack alignItems="center">
      <Avatar
        alt={alt}
        src={src}
        sx={{ width: 250, height: 250, ...avatarSx }}
        variant={variant}
      />
      {!readOnly && (
        <Button
          component="label"
          role={undefined}
          color="secondary"
          variant="contained"
          tabIndex={-1}
          sx={{ marginTop: 2, width: "100%" }}
        >
          Upload
          <VisuallyHiddenInput
            type="file"
            accept="image/*"
            onChange={handleInputChange}
          />
        </Button>
      )}
      {deletable ? (
        <Button
          color="secondary"
          variant="contained"
          sx={{ marginTop: 1 , width:'100%'}}
          onClick={onDeleteFile}
        >
          Remove
        </Button>
      ) : null}
    </Stack>
  );
};
