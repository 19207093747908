import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ConfirmModal } from "./ConfirmModal";
import { useGlobalToastNotificationContext } from "../context/GlobalToastNotificationContext";
import { useApiRequest } from "../hooks/useApiRequest";
import { Option } from "../types/option";

type DeleteOptionModalProps = {
  option: Option;
  onClose: () => void;
  onDeleteSuccess: () => void;
};

export const DeleteOptionModal: React.FC<DeleteOptionModalProps> = ({
  option,
  onClose,
  onDeleteSuccess,
}) => {
  const { showErrorToast } = useGlobalToastNotificationContext();
  const [deleteErrorMessage, setDeleteErrorMessage] = useState<String | null>(null);

  const {
    request: deleteOptionRequest,
    loading: deleteOptionLoading,
    errorMessage: deleteOptionErrorMessage,
    response: deleteOptionResponse,
  } = useApiRequest(false);

  useEffect(() => {
    if (deleteOptionErrorMessage) {
      showErrorToast({ message: deleteOptionErrorMessage });
    }
  }, [deleteOptionErrorMessage, showErrorToast]);

  useEffect(() => {
    if (deleteOptionResponse) {
      if (deleteOptionResponse.data.result.error) {
        setDeleteErrorMessage(deleteOptionResponse.data.result.error);
      } else {
        onDeleteSuccess();
      }
    }
  }, [deleteOptionResponse, onDeleteSuccess]);

  const handleDeleteClick = () => {
    deleteOptionRequest(`/company/options/${option.optionGuid}/delete`, {
      method: "DELETE",
    });
  };

  return (
    <>
      {deleteErrorMessage ? (
        <ConfirmModal
          isOpen={true}
          heading={
            `Unable to delete this selection option.`
          }
          body={
            <Typography variant="body2" color="grey.500">
              {deleteErrorMessage}
            </Typography>
          }
          confirmButtonText="Ok"
          confirmOnly={true}
          onConfirm={onClose}
          onClose={onClose}
        />
      ) : (
        <ConfirmModal
          isOpen={true}
          heading={
            `Delete option "${option.optionName}"`
          }
          body={
            <Typography variant="body2" color="grey.500">
              This cannot be undone. Delete it?
            </Typography>
          }
          confirmButtonText="Delete"
          isDestructive={true}
          onConfirm={handleDeleteClick}
          onClose={onClose}
          confirmDisabled={deleteOptionLoading}
        />        
      )}
    </>
  );
};
