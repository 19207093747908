import React, { ForwardedRef, useMemo } from "react";
import {
  Autocomplete,
  AutocompleteProps,
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  OutlinedInput,
} from "@mui/material";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

export type RegularOption<T extends string> = {
  value: T;
  label: T;
};

export type GroupedOption<T extends string> = RegularOption<T> & {
  groupValue: string;
  groupLabel: string;
};

export type AutocompleteOption<T extends string> =
  | RegularOption<T>
  | GroupedOption<T>;

type AutocompleteFieldProps<
  Option extends AutocompleteOption<string>,
  DisableClearable extends boolean | undefined
> = Omit<
  AutocompleteProps<Option, false, DisableClearable, false>,
  "renderInput"
> & {
  options: Array<Option>;
  label?: string;
  placeholder?: string;
  required?: boolean;
  helperText?: string;
  error?: boolean;
  onOptionChange?: (value: Option, isNew: boolean) => void;
  allowNewOption?: boolean;
};

const AutocompleteFieldInner = <
  Option extends AutocompleteOption<string>,
  DisableClearable extends boolean | undefined = false
>(
  {
    options,
    label,
    placeholder,
    helperText,
    required,
    error,
    onOptionChange,
    allowNewOption,
    ...props
  }: AutocompleteFieldProps<Option, DisableClearable>,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const [inputValue, setInputValue] = React.useState("");
  const optionNames: string[] = useMemo(
    () => options.map((o) => o.label.toString().toLowerCase()) as string[],
    [options]
  );
  let optionsWithCreateOption: Option[] = [];
  if (
    allowNewOption &&
    inputValue !== "" &&
    !optionNames.includes(inputValue.toLowerCase())
  ) {
    const createOption = {
      value: inputValue,
      label: `Create "${inputValue}"`,
    } as Option;
    optionsWithCreateOption = [createOption, ...options];
  } else {
    optionsWithCreateOption = options;
  }

  return (
    <FormControl fullWidth size="small">
      <FormLabel component="legend" required={required}>
        {label}
      </FormLabel>
      <Autocomplete
        {...props}
        fullWidth
        options={optionsWithCreateOption}
        groupBy={(option) => ("groupValue" in option ? option.groupValue : "")}
        getOptionLabel={(option) => option.label.toString()}
        renderInput={({
          InputProps,
          inputProps,
          InputLabelProps,
          ...params
        }) => (
          <OutlinedInput
            {...params}
            {...InputProps}
            inputProps={inputProps}
            size="small"
            placeholder={placeholder}
            onChange={(e) => {
              inputProps.onChange?.(e as React.ChangeEvent<HTMLInputElement>);
              setInputValue(e.target.value);
            }}
            inputRef={ref}
          />
        )}
        isOptionEqualToValue={(option, value) => {
          return option.value === value.value;
        }}
        renderGroup={(params) => {
          const option = options.find(
            (o) => "groupValue" in o && o.groupValue === params.group
          );

          if (!option) {
            return params.children;
          }

          return (
            <li key={params.key}>
              <Box
                sx={{
                  position: "sticky",
                  top: "-8px",
                  padding: "8px 14px",
                  color: "grey.500",
                  backgroundColor: "grey.200",
                  fontSize: "12px",
                  fontWeight: "500",
                  zIndex: "modal",
                }}
              >
                {"groupLabel" in option ? option.groupLabel : ""}
              </Box>
              <ul style={{ padding: 0 }}>{params.children}</ul>
            </li>
          );
        }}
        renderOption={(props, option, { selected }) => {
          return (
            <Box
              component="li"
              {...props}
              sx={{
                paddingX: "14px !important",
                display: "flex",
                justifyContent: "space-between !important",
                alignItems: "center",
                width: "100%",
              }}
            >
              <span>{option.label}</span>
              {selected ? <CheckOutlinedIcon color="primary" /> : null}
            </Box>
          );
        }}
        autoHighlight
        autoSelect
        {...props}
        onChange={(e, value, reason, details) => {
          if (props.onChange) {
            props.onChange(e, value, reason, details);
          }

          if (value && onOptionChange) {
            onOptionChange(value, value.label.startsWith('Create "'));
            setInputValue("");
          }
        }}
      />
      {helperText ? (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

export const AutocompleteField = React.forwardRef(AutocompleteFieldInner) as <
  Option extends AutocompleteOption<string>,
  DisableClearable extends boolean | undefined = false
>(
  props: AutocompleteFieldProps<Option, DisableClearable> & {
    ref?: ForwardedRef<HTMLInputElement>;
  }
) => ReturnType<typeof AutocompleteFieldInner>;
