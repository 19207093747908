import { IconButton } from "@mui/material";
import { useSortable } from "@dnd-kit/sortable";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

interface DraggableIndicatorProps {
  dragId: string | number;
  children?: React.ReactNode;
}

export const DraggableIndicator = ({
  dragId,
  children,
}: DraggableIndicatorProps) => {
  const { attributes, listeners, setActivatorNodeRef, isDragging } =
    useSortable({
      id: dragId,
    });

  return (
    <>
      <IconButton
        ref={setActivatorNodeRef}
        {...listeners}
        {...attributes}
        disableRipple
        sx={{ cursor: isDragging ? "grabbing" : "grab" }}
      >
        <DragIndicatorIcon />
      </IconButton>
      {children}
    </>
  );
};
