import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import { useSession } from "../hooks/useSession";
import { InfoOutlined } from "@mui/icons-material";
import { Popover } from "./Popover";

export const ImpersonationBanner: React.FC = () => {
  const { user, imposterTenant, imposterLogout } = useSession();
  const handleImpersonateLogout = () => {
    imposterLogout();
  };
  return (
    imposterTenant && (
      <AppBar
        position="static"
        enableColorOnDark
        sx={{ backgroundColor: "#7B61FF", displayPrint: "none" }}
      >
        <Toolbar>
          <Button
            variant="text"
            color="inherit"
            onClick={handleImpersonateLogout}
            sx={{
              "&:hover": {
                backgroundColor: "inherit",
                boxShadow: "none",
              },
            }}
          >
            <ArrowBackIcon />
            Stop Impersonating
          </Button>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              backgroundColor: "white",
              marginTop: 3,
              marginBottom: 3,
              marginLeft: 2,
              marginRight: 2,
            }}
          />

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {imposterTenant && user && (
              <Typography variant="body1">
                {`You are logged into ${imposterTenant?.tenantName} as
            ${user?.userFirstName} ${user?.userLastName}`}
              </Typography>
            )}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Popover
              button={
                <IconButton>
                  <InfoOutlined sx={{ color: "white" }} />
                </IconButton>
              }
              body={
                <Box>
                  <Typography variant="body1" fontWeight={600}>
                    Impersonation Mode
                  </Typography>
                  <Typography variant="body1">
                    When impersonating a member, your actions are considered
                    official and will reflect as if the actual user performed
                    them.
                  </Typography>
                </Box>
              }
            />
          </Box>
        </Toolbar>
      </AppBar>
    )
  );
};
