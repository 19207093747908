import { OrderCardError } from "./orderCard";
import { OrderCardStatus, orderCardTypes } from "./orderCardTypes";

export type OrderCardInvoiceFloorInsulation = {
  orderCardGuid: string;
  orderCardType: (typeof orderCardTypes)["invoiceFloorInsulation"];
  categoryName: string;
  groupName: string;
  subgroupName: string;
  isRequired: boolean;
  isApproved: boolean;
  isHidden: undefined;
  isLocked: boolean;
  orderCardStatus: OrderCardStatus;
  sortOrder: number;
  errors: OrderCardError[];
  updatedTimestamp: number;
  types: any[];
  materials: any[];
  cardData: {
    material: InvoiceFloorInsulationMaterials | null;
    rValue: number | null;
    thickness: number | null;
    type: InvoiceFloorInsulationTypes | null;
  };
};

export const invoiceFloorInsulationMaterials = [
  "Fiberglass",
  "Mineral Wood",
  "Plastic Fiber",
  "Natural Fiber",
  "Open Cell",
  "Closed Cell",
  "Cellulose",
] as const;

export type InvoiceFloorInsulationMaterials =
  (typeof invoiceFloorInsulationMaterials)[number];

export const invoiceFloorInsulationTypes = [
  "Rolled",
  "Battens",
  "Polyurethane Spray Foam",
  "Loose Filled",
  "Reflective",
] as const;

export type InvoiceFloorInsulationTypes =
  (typeof invoiceFloorInsulationTypes)[number];

export const invoiceFloorInsulationMaterialsOptions =
  invoiceFloorInsulationMaterials.map((m) => ({
    label: m,
    value: m,
  }));

export const invoiceFloorInsulationTypesOptions =
  invoiceFloorInsulationTypes.map((m) => ({
    label: m,
    value: m,
  }));
