import React from "react";
import { Checkbox, FormControlLabel, SwitchProps } from "@mui/material";

type CheckboxFieldProps = SwitchProps & {
  label: string;
  labelPlacement?: "start" | "end" | "top" | "bottom";
};

const CheckboxField = React.forwardRef<HTMLInputElement, CheckboxFieldProps>(
  ({ label, labelPlacement = "end", ...props }, ref) => {
    return (
      <FormControlLabel
        control={<Checkbox {...props} inputRef={ref} />}
        label={label}
        labelPlacement={labelPlacement}
      />
    );
  }
);

export default CheckboxField;
