import React from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { FormSection } from "../../components/FormSection";
import InputField from "../../components/InputField";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormTrigger,
} from "react-hook-form";
import SelectField from "../../components/SelectField";
import { getModelConstructionTypeDisplayName } from "../../types/model";
import { isValidNumber } from "../../utils/validation";
import { DatePicker } from "../../components/DatePicker";
import { FormData } from "./FormData";
import { InputAdornment } from "@mui/material";
import { useSession } from "../../hooks/useSession";

interface HomeDetailsFormSectionProps {
  errors: FieldErrors<FormData>;
  register: UseFormRegister<FormData>;
  control: Control<FormData>;
  trigger: UseFormTrigger<FormData>;
  numBedrooms: number;
  numBathrooms: number;
  numHalfBathrooms: number;
  numTotalRooms: number;
  seriesName: string;
}

export const HomeDetailsFormSection: React.FC<HomeDetailsFormSectionProps> = ({
  errors,
  register,
  control,
  trigger,
  numBedrooms,
  numBathrooms,
  numHalfBathrooms,
  numTotalRooms,
  seriesName,
}) => {
  const { user } = useSession();
  return (
    <>
      <FormSection heading="Home Details">
        <Grid xs={12} md={6}>
          <InputField
            {...register("modelName", {
              required: {
                value: true,
                message: "Please enter a value.",
              },
            })}
            fullWidth
            required
            label="Model Name"
            placeholder="Enter model name"
            error={!!errors.modelName}
            helperText={errors.modelName?.message}
            disabled={user?.isRetailUser}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <InputField
            {...register("modelNumber", {
              required: {
                value: true,
                message: "Please enter a value.",
              },
            })}
            fullWidth
            required
            label="Model Number"
            placeholder="Enter model number"
            error={!!errors.modelNumber}
            helperText={errors.modelNumber?.message}
            disabled={user?.isRetailUser}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <Controller
            name="startDate"
            control={control}
            rules={{
              required: "Enter Start Date",
              validate: (startDate, fields) => {
                if (!startDate) {
                  return "Start Date is required";
                }
                if (startDate && !fields.endDate) {
                  return true;
                }
                if (fields.endDate && startDate > fields.endDate) {
                  return "Start Date must be before End Date";
                }
                return true;
              },
            }}
            render={({ field }) => (
              <DatePicker
                label="Start Date"
                fullWidth
                {...field}
                required
                error={!!errors.startDate}
                helperText={errors.startDate?.message}
                onChange={(e) => {
                  field.onChange(e);
                  trigger("endDate");
                }}
                disabled={user?.isRetailUser}
              />
            )}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <Controller
            name="endDate"
            control={control}
            rules={{
              validate: (endDateValue, fields) => {
                if (
                  endDateValue &&
                  fields.startDate &&
                  fields.startDate > endDateValue
                ) {
                  return "End Date must be after Start Date";
                }
                return true;
              },
            }}
            render={({ field }) => (
              <DatePicker
                label="End Date"
                required
                fullWidth
                allowNoDate
                {...field}
                error={!!errors.endDate}
                helperText={errors.endDate?.message}
                onChange={(e) => {
                  field.onChange(e);
                  trigger("startDate");
                }}
                disabled={user?.isRetailUser}
              />
            )}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <InputField
            fullWidth
            readOnly
            disabled
            name="series"
            label="Series"
            value={seriesName}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <Controller
            control={control}
            name="modelConstructionType"
            rules={{ required: "Please select a value." }}
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                label="Construction Type"
                placeholder="Enter construction type"
                required
                fullWidth
                options={[
                  {
                    label: getModelConstructionTypeDisplayName("m"),
                    value: "m",
                  },
                  {
                    label: getModelConstructionTypeDisplayName("d"),
                    value: "d",
                  },
                ]}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                disabled={user?.isRetailUser}
              />
            )}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <InputField
            {...register("modelBasePrice", {
              valueAsNumber: true,
              required: {
                value: true,
                message: "Please enter a value.",
              },
              validate: {
                isNumber: (value) => {
                  return isValidNumber(value) || "Please enter a number.";
                },
              },
            })}
            type="number"
            startAdornment={
              <InputAdornment position="start">$</InputAdornment>
            }
            intOnly={true}
            fullWidth
            required
            label="Base Price"
            placeholder="Enter base price"
            error={!!errors.modelBasePrice}
            helperText={errors.modelBasePrice?.message}
            disabled={user?.isRetailUser}
          />
        </Grid>
        <Grid md={6} display={{ xs: "none", md: "initial" }} />
        <Grid xs={12} md={3}>
          <InputField
            fullWidth
            readOnly
            disabled
            name="bedrooms"
            label="Bedrooms"
            value={numBedrooms}
          />
        </Grid>
        <Grid xs={12} md={3}>
          <InputField
            fullWidth
            readOnly
            disabled
            name="bathrooms"
            label="Bathrooms"
            value={numBathrooms}
          />
        </Grid>
        <Grid xs={12} md={3}>
          <InputField
            fullWidth
            readOnly
            disabled
            name="halfBathrooms"
            label="Half Bathrooms"
            value={numHalfBathrooms}
          />
        </Grid>
        <Grid xs={12} md={3}>
          <InputField
            fullWidth
            readOnly
            disabled
            name="totalRooms"
            label="Total Rooms"
            value={numTotalRooms}
          />
        </Grid>
      </FormSection>
    </>
  );
};
