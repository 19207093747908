import React from "react";
import { Typography } from "@mui/material";
import { ConfirmModal } from "./ConfirmModal";

interface AttributeSelectionDiscontinueModalProps {
  attributeSelectionName: string;
  isOpen: boolean;
  onDiscontinue: () => void;
  onCancel: () => void;
}

export const AttributeSelectionDiscontinueModal: React.FC<
  AttributeSelectionDiscontinueModalProps
> = ({
  attributeSelectionName,
  isOpen,
  onDiscontinue,
  onCancel,
}) => {
  return (
    <ConfirmModal
      isOpen={!!isOpen}
      heading={`Discontinue ${attributeSelectionName}?`}
      body={
        <>
          <Typography variant="body2" color="grey.500">
            If you discontinue this attribute selection, the end date will be
            set to today and all linked selection options that use this
            attribute will no longer have this selection available.
          </Typography>
          <br />
          <Typography variant="body2" color="grey.500">
            Any quotes in progress that use this selection will require a
            different selection.
          </Typography>
        </>
      }
      confirmButtonText="Discontinue"
      onConfirm={onDiscontinue}
      onClose={onCancel}
    />
  );
};
