import { Nullable } from "../utils/nullable";

export type Section = {
  sectionName: "A" | "B" | "C";
  sectionLength: number;
  sectionHitchLength: number;
  sectionWidth: number;
  sectionHeight: number;
  sectionLivingSpace: number;
  sectionUnheatedSpace: number;
  sectionWeight: number;
};

type SectionName = Section["sectionName"];

export const sectionNames: SectionName[] = ["A", "B", "C"];

export const getTotalSpace = (section: Nullable<Section>) => {
  return (
    (section.sectionLivingSpace || 0) + (section.sectionUnheatedSpace || 0)
  );
};

export const getUnitWidth = (sections: Nullable<Section>[]): number => {
  const unitWidth = sections.reduce(
    (sum, section) => sum + (section.sectionWidth || 0),
    0
  );
  return unitWidth;
};

export const getUnitLength = (sections: Nullable<Section>[]): number => {
  const unitLength = sections.reduce(
    (currentMax, section) => Math.max(currentMax, section.sectionLength || 0),
    0
  );
  return unitLength;
};

export const getHitchLength = (sections: Nullable<Section>[]): number => {
  const hitchLength = sections.reduce(
    (currentMax, section) =>
      Math.max(currentMax, section.sectionHitchLength || 0),
    0
  );
  return hitchLength;
};

export const getUnitHeight = (sections: Nullable<Section>[]): number => {
  const unitHeight = sections.reduce(
    (currentMax, section) => Math.max(currentMax, section.sectionHeight || 0),
    0
  );
  return unitHeight;
};

export const getLivingSpace = (sections: Nullable<Section>[]): number => {
  const livingSpace = sections.reduce(
    (sum, section) => sum + (section.sectionLivingSpace || 0),
    0
  );
  return livingSpace;
};

export const getNonHeatedSpace = (sections: Nullable<Section>[]): number => {
  const nonHeatedSpace = sections.reduce(
    (sum, section) => sum + (section.sectionUnheatedSpace || 0),
    0
  );
  return nonHeatedSpace;
};

export const getGrossWeight = (sections: Nullable<Section>[]): number => {
  const grossWeight = sections.reduce(
    (sum, section) => sum + (section.sectionWeight || 0),
    0
  );
  return grossWeight;
};
