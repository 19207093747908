import React from "react";
import { Stack, Typography } from "@mui/material";

interface OrderTabSummarySectionProps {
  icon: React.ReactNode;
  title: string;
  children: React.ReactNode;
}

export const OrderTabSummarySection: React.FC<OrderTabSummarySectionProps> = ({
  icon,
  title,
  children,
}) => {
  return (
    <Stack padding={3}>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        marginBottom={2}
      >
        {icon}
        <Typography
          fontSize="11px"
          fontWeight={500}
          marginLeft={0.5}
          textTransform="uppercase"
        >
          {title}
        </Typography>
      </Stack>
      {children}
    </Stack>
  );
};
