import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Divider } from "@mui/material";

type MenuItemDivider = {
  divider: true;
};

type MenuItemText = {
  divider?: false;
  label: string;
  onClick: () => void;
};

export type MenuItemProp = MenuItemText | MenuItemDivider;

interface MenuButtonProps {
  children: React.ReactNode;
  menuItems: MenuItemProp[];
  disabled?: boolean;
}

export const MenuButton: React.FC<MenuButtonProps> = ({
  children,
  menuItems,
  disabled,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        disabled={disabled}
      >
        {children}
      </Button>
      <Menu
        id="menu-button"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {menuItems.map((item, index) => {
          if (item.divider) {
            return <Divider key={index} sx={{ my: 0.5 }} />;
          }
          return (
            <MenuItem
              key={index}
              onClick={() => {
                item.onClick();
                handleClose();
              }}
            >
              {item.label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
