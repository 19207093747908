import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Session } from "../types/session";
import { useApiRequest } from "../hooks/useApiRequest";
import { Tenant } from "../types/tenant";
import { User, UserSession } from "../types/user";

export const SessionContext = createContext<Session>({
  isSessionLoaded: false,
  isLoggedIn: false,
  isImpersonating: false,
  user: null,
  imposterTenant: null,
  impersonate: () => {},
  imposterLogout: () => {},
  login: () => {},
  logout: () => {},
  updateUserSession: () => {},
  isCurrentUser: () => false,
});

export const session = {
  getUser(): UserSession | null {
    const userString = localStorage.getItem("user");
    // TODO: Validate the parsed userString is of the type 'UserSession'
    const user: UserSession | null = userString ? JSON.parse(userString) : null;
    return user;
  },
};

export const SessionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isSessionLoaded, setIsSessionLoaded] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState<UserSession | null>(() => session.getUser()); // TODO: Validate accessing local storage is okay here.
  const [imposterTenant, setImposterTenant] = useState<Tenant | null>(null);
  const [isImpersonating, setIsImpersonating] = useState(false);

  const { request: impersonateLoginRequest, data: imposterUserResponse } =
    useApiRequest<UserSession>();
  const { request: impersonateLogoutRequest, data: imposterLogoutResponse } =
    useApiRequest<UserSession>();

  useEffect(() => {
    const parsedUser = session.getUser();
    setUser(parsedUser);
    setIsLoggedIn(!!parsedUser);
    setIsSessionLoaded(true);

    // Impersonation
    const imposterTenantString = localStorage.getItem("imposterTenant");
    const parsedImposterTenant = imposterTenantString
      ? JSON.parse(imposterTenantString)
      : null;
    setIsImpersonating(parsedUser?.isImposter ?? false);
    setImposterTenant(parsedImposterTenant);
  }, []);

  useEffect(() => {
    // Handle Impersonation Login
    if (imposterUserResponse) {
      localStorage.setItem("user", JSON.stringify(imposterUserResponse));
      if (imposterUserResponse.authentication) {
        localStorage.setItem(
          "access_token",
          imposterUserResponse.authentication
        );
      }
      setUser(imposterUserResponse);

      setIsImpersonating(true);
      window.location.href = `/company/${imposterUserResponse.tenantGuid}/quotes`;
    }
  }, [imposterUserResponse, imposterTenant]);

  useEffect(() => {
    // Handle Impersonation Logout
    if (imposterLogoutResponse) {
      localStorage.setItem("user", JSON.stringify(imposterLogoutResponse));
      if (imposterLogoutResponse.authentication) {
        localStorage.setItem(
          "access_token",
          imposterLogoutResponse.authentication
        );
      }
      localStorage.removeItem("imposterTenant");
      setUser(imposterLogoutResponse);
      setIsImpersonating(false);
      setImposterTenant(null);
      window.location.href = `/manage/tenants`;
    }
  }, [imposterLogoutResponse]);

  const login = useCallback((user: UserSession) => {
    localStorage.setItem("user", JSON.stringify(user));
    if (user.authentication) {
      localStorage.setItem("access_token", user.authentication);
    }
    setUser(user);
    setIsLoggedIn(true);
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem("user");
    localStorage.removeItem("access_token");
    localStorage.removeItem("imposterTenant");
    setUser(null);
    setIsLoggedIn(false);
    setIsImpersonating(false);
  }, []);

  const impersonate = useCallback(
    (tenant: Tenant) => {
      impersonateLoginRequest("/users/imposter/make", {
        method: "POST",
        data: { tenantGuid: tenant.tenantGuid },
      });
      localStorage.setItem("imposterTenant", JSON.stringify(tenant));
    },
    [impersonateLoginRequest]
  );

  const imposterLogout = useCallback(() => {
    impersonateLogoutRequest("/users/imposter/logout", {
      method: "GET",
    });
  }, [impersonateLogoutRequest]);

  const updateUserSession = useCallback((updatedUser: User) => {
    setUser((prevUser) => {
      if (!prevUser) {
        return prevUser;
      }

      const newUser = {
        ...prevUser,
        ...updatedUser,
      };

      localStorage.setItem("user", JSON.stringify(newUser));

      return newUser;
    });
  }, []);

  const isCurrentUser = useCallback(
    (u: User) => {
      // If the logged in user is currently being impostered
      // then we must compare userEmail's instead of userGuid's.
      // This is because the the userGuid property of the logged
      // in user will be the userGuid of the sysadmin who is impostering them.
      if (user?.isImposter) {
        return user?.userEmail === u.userEmail;
      }

      return u.userGuid === user?.userGuid;
    },
    [user]
  );

  const value = useMemo(
    () => ({
      isSessionLoaded,
      isLoggedIn,
      isImpersonating,
      user,
      imposterTenant,
      impersonate,
      imposterLogout,
      login,
      logout,
      updateUserSession,
      isCurrentUser,
    }),
    [
      impersonate,
      imposterLogout,
      imposterTenant,
      isImpersonating,
      isLoggedIn,
      isSessionLoaded,
      login,
      logout,
      updateUserSession,
      user,
      isCurrentUser,
    ]
  );

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
};
