import { useEffect } from "react";

interface MetaDataOptions {
  title?: string;
}

export const usePageMetadata = ({ title }: MetaDataOptions) => {
  useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);
};
