import React from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { Retailer } from "../../types/tenant";
import { FormState, UseFormRegister } from "react-hook-form";
import { FormSection } from "../../components/FormSection";
import InputField from "../../components/InputField";
import { FormData } from "./FormData";

interface CompanyInfoTabHomeDetailsProps {
  retailer: Retailer | null;
  // form props
  formState: FormState<FormData>;
  register: UseFormRegister<FormData>;
}

export const CompanyInfoTabHomeDetails: React.FC<
  CompanyInfoTabHomeDetailsProps
> = ({ retailer, formState, register }) => {
  return (
    <FormSection heading="Home Details">
      <Grid xs={12} md={6}>
        <InputField
          {...register(
            "tenantName",
            retailer?.canEdit
              ? { required: "Please enter a value." }
              : { required: false }
          )}
          required={retailer?.canEdit}
          disabled={!retailer?.canEdit}
          fullWidth
          label="Legal Name"
          placeholder="Enter Legal Name"
          error={!!formState.errors.tenantName}
          helperText={formState.errors.tenantName?.message}
        />
      </Grid>
      <Grid xs={12} md={6}>
        <InputField
          {...register("retailerFriendlyName")}
          disabled={!retailer?.canEdit}
          fullWidth
          label="Friendly Name"
          placeholder="Enter Friendly Name"
          error={!!formState.errors.retailerFriendlyName}
          helperText={formState.errors.retailerFriendlyName?.message}
        />
      </Grid>
      <Grid xs={12} md={6}>
        <InputField
          {...register("retailerEinTin")}
          disabled={!retailer?.canEdit}
          fullWidth
          label="EIN / TIN"
          placeholder="Enter EIN / TIN"
          error={!!formState.errors.retailerEinTin}
          helperText={formState.errors.retailerEinTin?.message}
        />
      </Grid>
    </FormSection>
  );
};
