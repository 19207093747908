import { Container, ContainerProps } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { TopBar } from "./TopBar";
import { useEffect } from "react";
import { useSession } from "../hooks/useSession";
import { Breadcrumbs } from "./Breadcrumbs";
import { ImpersonationBanner } from "./ImpersonationBanner";
import { routes } from "../routes";

type MainLayoutBaseProps = {
  containerProps?: ContainerProps;
  children?: React.ReactNode;
};

export const MainLayoutBase: React.FC<MainLayoutBaseProps> = ({
  children,
  containerProps,
}) => {
  const { isSessionLoaded, isLoggedIn, isImpersonating, user } = useSession();
  const navigate = useNavigate();

  // Redirect to login if not logged in
  useEffect(() => {
    if (isSessionLoaded && isLoggedIn === false) {
      navigate(routes.login.path);
    }
  }, [isSessionLoaded, isLoggedIn, navigate]);

  // Wait to render any content until the session is loaded.
  if (!isSessionLoaded) {
    return null;
  }

  return (
    <>
      {isSessionLoaded && isImpersonating && <ImpersonationBanner />}
      <TopBar buyerMode={!!user?.isBuyerMode} />
      <Container
        maxWidth={false}
        sx={{ paddingTop: 2, paddingLeft: 5, paddingRight: 5 }}
        {...containerProps}
      >
        {children}
        <Outlet />
      </Container>
    </>
  );
};

type MainLayoutProps = {
  containerProps?: ContainerProps;
  children?: React.ReactNode;
};

export const MainLayout: React.FC<MainLayoutProps> = ({
  children,
  ...props
}) => {
  return (
    <MainLayoutBase {...props}>
      <Breadcrumbs />
      {children}
    </MainLayoutBase>
  );
};
