import React from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { Retailer } from "../../types/tenant";
import { FormState, UseFormRegister } from "react-hook-form";
import { FormSection } from "../../components/FormSection";
import InputField from "../../components/InputField";
import { FormData } from "./FormData";

interface CompanyInfoTabContactProps {
  retailer: Retailer | null;
  // form props
  formState: FormState<FormData>;
  register: UseFormRegister<FormData>;
}

export const CompanyInfoTabContact: React.FC<CompanyInfoTabContactProps> = ({
  retailer,
  formState,
  register,
}) => {
  return (
    <FormSection heading="Contact">
      <Grid xs={12} md={6}>
        <InputField
          {...register("tenantPhoneNumber")}
          disabled={!retailer?.canEdit}
          fullWidth
          label="Business phone"
          placeholder="Enter Business phone"
          error={!!formState.errors.tenantPhoneNumber}
          helperText={formState.errors.tenantPhoneNumber?.message}
        />
      </Grid>
      <Grid xs={12} md={6}>
        <InputField
          {...register("tenantWebsite")}
          disabled={!retailer?.canEdit}
          fullWidth
          label="Website"
          placeholder="Enter Website"
          error={!!formState.errors.tenantWebsite}
          helperText={formState.errors.tenantWebsite?.message}
        />
      </Grid>
    </FormSection>
  );
};
