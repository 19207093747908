import { Avatar } from "@mui/material";
import ImagesearchRollerOutlinedIcon from "@mui/icons-material/ImagesearchRollerOutlined";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import { Option } from "../types/option";
import { getAttributeSelectionImageUrl } from "../types/attributeSelection";

interface LogicTextSelectionOptionAttributeProps {
  option?: Option;
  logicAttributeselectionGuid: string;
}

export const LogicTextSelectionOptionAttribute: React.FC<
  LogicTextSelectionOptionAttributeProps
> = ({ option, logicAttributeselectionGuid }) => {
  const attribute = option?.attributes.find((a) => {
    return a.attributeselections.some(
      (ats) => ats.attributeselectionGuid === logicAttributeselectionGuid
    );
  });

  const attributeSelection = (attribute?.attributeselections ?? []).find(
    (ats) => ats.attributeselectionGuid === logicAttributeselectionGuid
  );

  const attributeSelectionImgUrl =
    getAttributeSelectionImageUrl(attributeSelection);

  return (
    <>
      <ChecklistRtlIcon color="primary" sx={{ marginRight: 1 }} />
      {option?.optionName}
      <ImagesearchRollerOutlinedIcon color="primary" sx={{ marginX: 1 }} />
      {attribute?.attributeName}
      {attribute?.attributeIncludeImages ? (
        <Avatar
          src={attributeSelectionImgUrl}
          sx={{
            width: 16,
            height: 16,
            marginX: 1,
          }}
        />
      ) : (
        " "
      )}
      {attributeSelection?.attributeselectionName}
    </>
  );
};
