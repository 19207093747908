import React, { ForwardedRef, useMemo, useRef } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  SelectProps,
  FormLabel,
  Typography,
  FormHelperText,
  Box,
  Chip,
  ListItemText,
  Stack,
  ListSubheader,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

export type RegularOption<T extends string | number> = {
  value: T;
  label: string;
  error?: string;
};

export type GroupedOption<T extends string | number> = RegularOption<T> & {
  groupValue: string;
  groupLabel: string;
};

export type Option<T extends string | number> =
  | RegularOption<T>
  | GroupedOption<T>;

type MultiSelectFieldProps<T extends string | number> = SelectProps<
  Option<T>["value"][]
> & {
  label: string;
  options: Array<Option<T>>;
  helperText?: string;
  disabled?: boolean;
  onDelete?: (option: Option<T>, index: number) => void;
};

const MultiSelectFieldInner = <T extends string | number>(
  {
    label,
    options,
    helperText,
    disabled,
    onDelete,
    ...props
  }: MultiSelectFieldProps<T>,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleDeleteOption = (option: Option<T>, index: number) => {
    // Focus the select before deleting the option to ensure that
    // the user can click away afterwards and blur the field.
    inputRef.current?.focus();
    onDelete?.(option, index);
  };

  const groupIndexes = useMemo(() => {
    let indexes: Record<string | number, string | number> = {};

    options.forEach((option) => {
      // If this option's group hasn't been accounted for then this is the first option in the group..
      if ("groupValue" in option && indexes[option.groupValue] == null) {
        indexes[option.groupValue] = option.value;
      }
    });

    return indexes;
  }, [options]);

  return (
    <FormControl fullWidth size="small">
      <FormLabel component="legend" required={props.required}>
        {label}
      </FormLabel>
      <Select
        inputRef={(e) => {
          ref = e;
          inputRef.current = e;
        }}
        displayEmpty={!!props.placeholder}
        error={props.error}
        disabled={disabled}
        multiple
        renderValue={(selectedValue) => {
          // Render placeholder if nothing is selected
          if (!props.value || props.value.length === 0) {
            return (
              <Typography sx={{ opacity: 0.42 }}>
                {props.placeholder}
              </Typography>
            );
          }

          // Otherwise render the selected options.
          const selectedOptions = options.filter((option) =>
            selectedValue.includes(option.value)
          );

          return (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selectedOptions.map((option, index) => (
                <Chip
                  key={option.value}
                  label={
                    <Stack direction="row" spacing={1}>
                      <Typography>{option.label}</Typography>
                      {option.error && (
                        <Typography color="error" fontWeight={500}>
                          {option.error}
                        </Typography>
                      )}
                    </Stack>
                  }
                  size="small"
                  deleteIcon={!disabled ? <ClearIcon /> : undefined}
                  onMouseDown={(event) => event.stopPropagation()}
                  onDelete={
                    !disabled
                      ? () => handleDeleteOption(option, index)
                      : undefined
                  }
                />
              ))}
            </Box>
          );
        }}
        {...props}
      >
        {options.map((option, index) => {
          const isSelected = props.value
            ? props.value.includes(option.value)
            : false;

          const elements = [];

          if (
            "groupValue" in option &&
            groupIndexes[option.groupValue] === option.value
          ) {
            elements.push(
              <ListSubheader
                key={`${index}-group`}
                sx={{
                  padding: "8px 14px",
                  color: "grey.500",
                  backgroundColor: "grey.200",
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "normal",
                }}
              >
                {option.groupLabel}
              </ListSubheader>
            );
          }

          elements.push(
            <MenuItem key={index} value={option.value}>
              <ListItemText
                primary={
                  <Stack direction="row" spacing={1}>
                    <Typography>{option.label}</Typography>
                    {option.error && (
                      <Typography color="error" fontWeight={500}>
                        {option.error}
                      </Typography>
                    )}
                  </Stack>
                }
              />
              {isSelected ? <CheckIcon color="primary" /> : null}
            </MenuItem>
          );

          return elements;
        })}
      </Select>
      {helperText ? (
        <FormHelperText error={props.error}>{helperText}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

const MultiSelectField = React.forwardRef(MultiSelectFieldInner) as <
  T extends string | number
>(
  props: MultiSelectFieldProps<T> & { ref?: ForwardedRef<HTMLInputElement> }
) => ReturnType<typeof MultiSelectFieldInner>;

export default MultiSelectField;
