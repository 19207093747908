import React, { useEffect } from "react";
import { Modal } from "../../components/Modal";
import InputField from "../../components/InputField";
import { Alert, Box, Button, Grid, Stack, InputAdornment } from "@mui/material";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Freight } from "../../types/freight";
import SelectField from "../../components/SelectField";
import { stateAbbrSelectOptions } from "../../types/states";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

type FormData = {
  freightGuid?: string;
  freightCity: string;
  freightState: string;
  freightMileage: string;
  freightPrice14: string;
  freightPrice16: string;
};

type NewEditModalProps = {
  isOpen: boolean;
  onSaveSuccessful: () => void;
  onClose: () => void;
  freight?: Freight | null;
  isNew: boolean;
};

const getDefaultValues = (freight?: Freight | null): FormData => {
  return {
    freightCity: freight?.freightCity || "",
    freightState: freight?.freightState || "",
    freightMileage: freight?.freightMileage || "",
    freightPrice14: freight?.freightPrice14 || "",
    freightPrice16: freight?.freightPrice16 || "",
  };
};

export const NewEditModal: React.FC<NewEditModalProps> = ({
  isOpen,
  onSaveSuccessful,
  onClose,
  freight,
  isNew,
}) => {
  const { data, loading, request, status, error } =
    useApiRequest<Freight>(true);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: getDefaultValues(freight),
  });

  const onSubmit: SubmitHandler<FormData> = (data: FormData) => {
    if (isNew) {
      request("/company/freights/add", {
        method: "POST",
        data,
      });
    } else {
      request(`/company/freights/${freight?.freightGuid}/edit`, {
        method: "POST",
        data,
      });
    }
  };

  useEffect(() => {
    if (data && status === "ok" && loading === false) {
      onSaveSuccessful();
    }
  }, [data, onSaveSuccessful, status, loading]);

  return (
    <Modal heading="New Freight Rate" isOpen={isOpen} onClose={onClose}>
      <Box sx={{ maxWidth: 600 }}>
        {error && (
          <Alert severity="error" sx={{ marginBottom: 2 }}>
            Something went wrong. Please try again.
          </Alert>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={10}>
              <InputField
                label="City"
                placeholder="Enter City"
                fullWidth
                {...register("freightCity", { required: "Enter City" })}
                error={!!errors.freightCity}
                helperText={errors.freightCity ? "Enter City" : ""}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Controller
                name="freightState"
                control={control}
                rules={{ required: "Enter State" }}
                render={({ field }) => (
                  <SelectField
                    label="State"
                    placeholder="Select"
                    options={stateAbbrSelectOptions}
                    error={!!errors.freightState}
                    helperText={errors.freightState ? "Select State" : ""}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                label="Route Mileage"
                placeholder="Enter Mileage"
                fullWidth
                type="number"
                {...register("freightMileage", { required: "Enter Mileage" })}
                error={!!errors.freightMileage}
                helperText={errors.freightMileage ? "Enter Mileage" : ""}
                endAdornment={<span>/Miles</span>}
                intOnly={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField
                label="14' Price"
                placeholder="Enter Price"
                fullWidth
                type="number"
                intOnly={true}
                {...register("freightPrice14", { required: "Enter Price" })}
                error={!!errors.freightPrice14}
                helperText={errors.freightPrice14 ? "Enter Price" : ""}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                endAdornment={<span>/Floor</span>}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField
                label="16' Price"
                placeholder="Enter Price"
                fullWidth
                type="number"
                intOnly={true}
                {...register("freightPrice16", { required: "Enter Price" })}
                error={!!errors.freightPrice16}
                helperText={errors.freightPrice16 ? "Enter Price" : ""}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                endAdornment={<span>/Floor</span>}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="end">
              <Stack spacing={1} direction="row">
                <Button variant="outlined" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading}
                >
                  Save
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};
