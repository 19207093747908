import React from "react";
import { InputAdornment } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { FormSection } from "../../components/FormSection";
import InputField from "../../components/InputField";

interface HomeSizeFormSectionProps {
  numUnitWidth: number;
  numUnitLength: number;
  numHitchLength: number;
  numUnitHeight: number;
}

export const HomeSizeFormSection: React.FC<HomeSizeFormSectionProps> = ({
  numUnitWidth,
  numUnitLength,
  numHitchLength,
  numUnitHeight,
}) => {
  return (
    <FormSection heading="Home Size">
      <Grid xs={12} md={3}>
        <InputField
          fullWidth
          readOnly
          disabled
          name="unitWidth"
          label="Unit Width"
          value={numUnitWidth}
          endAdornment={<InputAdornment position="end">FT</InputAdornment>}
        />
      </Grid>
      <Grid xs={12} md={3}>
        <InputField
          fullWidth
          readOnly
          disabled
          name="unitLength"
          label="Unit Length"
          value={numUnitLength}
          endAdornment={<InputAdornment position="end">FT</InputAdornment>}
        />
      </Grid>
      <Grid xs={12} md={3}>
        <InputField
          fullWidth
          readOnly
          disabled
          name="hitchLength"
          label="Hitch Length"
          value={numHitchLength}
          endAdornment={<InputAdornment position="end">FT</InputAdornment>}
        />
      </Grid>
      <Grid xs={12} md={3}>
        <InputField
          fullWidth
          readOnly
          disabled
          name="unitHeight"
          label="Unit Height"
          value={numUnitHeight}
          endAdornment={<InputAdornment position="end">FT</InputAdornment>}
        />
      </Grid>
    </FormSection>
  );
};
