import { Box } from "@mui/material";
import React from "react";

type HtmlDivElementProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

interface TabPanelProps
  extends Pick<HtmlDivElementProps, "id" | "aria-labelledby" | "children"> {
  index: number;
  value: number;
  tabsAriaLabel: string;
}

export const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  tabsAriaLabel,
  ...props
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...tabPanelA11yProps(tabsAriaLabel, index)}
      {...props}
    >
      {value === index && <Box sx={{ paddingY: 3 }}>{children}</Box>}
    </div>
  );
};

export function tabA11yProps(tabsAriaLabel: string, index: number) {
  return {
    id: `${tabsAriaLabel}-tab-${index}`,
    "aria-controls": `${tabsAriaLabel}-tabpanel-${index}`,
  };
}

function tabPanelA11yProps(tabsAriaLabel: string, index: number) {
  return {
    id: `${tabsAriaLabel}-tabpanel-${index}`,
    "aria-labelledby": `${tabsAriaLabel}-tab-${index}`,
  };
}
