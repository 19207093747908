export type InvoiceDisclosure = {
  invoiceDisclosureGuid: string;
  disclosureType: "c" | "t" | "v";
  disclosureText: string;
  isActive: boolean;
  sortOrder: number;
};

export type InvoiceDisclosureType = InvoiceDisclosure["disclosureType"];

export const getInvoiceDisclosureTypeDisplayName = (
  disclosureType: InvoiceDisclosureType
): string => {
  const displayName: Record<InvoiceDisclosureType, string> = {
    c: "Certification",
    t: "Truth in Lending Practices",
    v: "Volume Incentive Program",
  };
  return displayName[disclosureType];
};
