import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";

export interface Breadcrumb {
  label: string;
  href?: string;
}

interface BreadcrumbsContextType {
  breadcrumbs: Breadcrumb[];
  setBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void;
  stickyTo: number | undefined;
  setStickyTo: (value?: number) => void;
}

export const BreadcrumbsContext = createContext<
  BreadcrumbsContextType | undefined
>(undefined);

export const useBreadcrumbs = (stickyTo?: number) => {
  const context = useContext(BreadcrumbsContext);
  if (!context) {
    throw new Error("BreadcrumbsProvider is required");
  }

  useEffect(() => {
    context.setStickyTo(stickyTo);
  }, [stickyTo, context]);

  return context;
};

export const BreadcrumbsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
  const [stickyTo, setStickyTo] = useState<number | undefined>(undefined);

  return (
    <BreadcrumbsContext.Provider
      value={{ breadcrumbs, setBreadcrumbs, stickyTo, setStickyTo }}
    >
      {children}
    </BreadcrumbsContext.Provider>
  );
};
