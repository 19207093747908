import { useSortable } from "@dnd-kit/sortable";
import { Box, BoxProps } from "@mui/material";
import { CSS } from "@dnd-kit/utilities";
import { CSSProperties } from "react";

interface DraggableBoxProps extends BoxProps {
  dragId: string | number;
  children: React.ReactElement;
}

export const DraggableBox: React.FC<DraggableBoxProps> = ({
  dragId,
  children,
  style,
  className = "",
  ...props
}) => {
  const { setNodeRef, transform, transition, isDragging } = useSortable({
    id: dragId,
    transition: null,
  });

  const boxStyle: CSSProperties = {
    ...style,
    transform: CSS.Translate.toString(transform),
    transition,
    zIndex: isDragging ? 1 : 0,
    cursor: isDragging ? "grabbing" : undefined,
  };

  const classNames: string = isDragging
    ? `DraggableBox--dragging ${className}`
    : `${className}`;

  return (
    <Box {...props} ref={setNodeRef} style={boxStyle} className={classNames}>
      {children}
    </Box>
  );
};
