import React, { useMemo } from "react";
import { Card, Divider, Stack, Typography } from "@mui/material";
import ReceiptIcon from "@mui/icons-material/Receipt";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import {
  getOrderCard,
  getOrderStatusDisplayName,
  getOrderVinNumberSuffix,
  Order,
} from "../../types/order";
import { orderCardTypes } from "../../types/orderCardTypes";
import { formatAsDate } from "../../utils/date";
import { formatAsCurrency } from "../../utils/number";
import { OrderTabSummarySection } from "./OrderTabSummarySection";
import {
  getModelBuildCodeDisplayName,
  getModelNumBathrooms,
  getModelNumBedrooms,
} from "../../types/model";
import { getSoldAsSummaryDisplayName } from "../../types/orderCardBusinessLogistics";
import { getUserFullName } from "../../types/user";

interface OrderTabSummaryProps {
  order: Order | null;
}

export const OrderTabSummary: React.FC<OrderTabSummaryProps> = ({ order }) => {
  const orderCardBusinessLogistics = getOrderCard(
    order,
    orderCardTypes.businessLogistics
  );

  const orderCardBusinessHome = getOrderCard(
    order,
    orderCardTypes.businessHome
  );

  const orderCardFinancialPricing = getOrderCard(
    order,
    orderCardTypes.financialPricing
  );

  const orderVinNumberSuffix = order ? getOrderVinNumberSuffix(order) : "";
  const orderVinNumber = orderCardBusinessLogistics?.cardData.vinNumber
    ? `${orderCardBusinessLogistics?.cardData.vinNumber}${orderVinNumberSuffix}`
    : null;

  const orderNumBedrooms = useMemo(() => {
    return order?.model ? getModelNumBedrooms(order.model) : null;
  }, [order?.model]);

  const orderNumBathrooms = useMemo(() => {
    return order?.model ? getModelNumBathrooms(order.model) : null;
  }, [order?.model]);

  const orderRetailer = orderCardBusinessLogistics?.retailers.find(
    (retailer) =>
      retailer.tenantGuid ===
      orderCardBusinessLogistics?.cardData.retailerTenantGuid
  );

  return (
    <Card variant="outlined">
      <Stack divider={<Divider flexItem sx={{ borderColor: "grey.200" }} />}>
        {order && order.isTemplate ? (
          <OrderTabSummarySection
            icon={<ReceiptIcon sx={{ fontSize: "16px" }} />}
            title="Template"
          >
            <Typography fontSize="14px" fontWeight={600}>
              {order.templateName}
            </Typography>
            <Typography fontSize="12px" fontWeight={500} color="#505050">
              {getUserFullName(order.assigneeUser)}
            </Typography>
          </OrderTabSummarySection>
        ) : order && !order.isTemplate ? (
          <OrderTabSummarySection
            icon={<ReceiptIcon sx={{ fontSize: "16px" }} />}
            title={order ? getOrderStatusDisplayName(order.orderStatus) : ""}
          >
            <Typography fontSize="14px" fontWeight={600}>
              ID {orderCardBusinessLogistics?.cardData.orderIdentifier}
            </Typography>
            <Typography
              fontSize="12px"
              fontWeight={500}
              color="#505050"
              marginBottom={1}
            >
              VIN {orderVinNumber}
            </Typography>
            <Typography fontSize="12px" fontWeight={500}>
              Created {formatAsDate(order?.createdTimestamp)}
            </Typography>
            <Typography fontSize="12px" fontWeight={500}>
              Confirmed {formatAsDate(order?.confirmedTimestamp)}
            </Typography>
          </OrderTabSummarySection>
        ) : null}

        <OrderTabSummarySection
          icon={<HomeOutlinedIcon sx={{ fontSize: "16px" }} />}
          title={"HOME"}
        >
          <Typography fontSize="14px" fontWeight={600}>
            {orderCardBusinessHome?.cardData.modelNumber}
          </Typography>
          <Typography
            fontSize="12px"
            fontWeight={500}
            color="#505050"
            marginBottom={1}
          >
            {orderCardBusinessHome?.cardData.seriesName}
          </Typography>

          {orderCardBusinessHome?.cardData != null ? (
            <Typography fontSize="12px" fontWeight={500}>
              {getModelBuildCodeDisplayName(
                orderCardBusinessHome.cardData.construction
              )}{" "}
              Code
            </Typography>
          ) : null}

          <Typography fontSize="12px" fontWeight={500}>
            {orderCardBusinessHome?.cardData.livingSpace ?? 0}{" "}
            <i>
              ({orderNumBedrooms} bed, {orderNumBathrooms} bath)
            </i>
          </Typography>

          <Typography fontSize="12px" fontWeight={500}>
            L{" "}
            {(orderCardBusinessHome?.cardData.length ?? 0) +
              (orderCardBusinessHome?.cardData.hitch ?? 0)}
            ' x W {orderCardBusinessHome?.cardData.width ?? 0}'{" "}
            <i>({orderCardBusinessHome?.cardData.length ?? 0}' w/o hitch)</i>
          </Typography>
        </OrderTabSummarySection>

        {orderCardBusinessLogistics?.cardData.soldAs !== "y" ? (
          <OrderTabSummarySection
            icon={<SavingsOutlinedIcon sx={{ fontSize: "16px" }} />}
            title={"RETAILER"}
          >
            <Typography fontSize="12px" fontWeight={500}>
              {orderCardBusinessLogistics
                ? getSoldAsSummaryDisplayName(
                    orderCardBusinessLogistics.cardData.soldAs
                  )
                : null}
            </Typography>
            <Typography
              fontSize="12px"
              fontWeight={500}
              color="#505050"
              marginBottom={1}
            >
              {orderRetailer?.tenantName}
            </Typography>
            <Typography fontSize="12px" fontWeight={500}>
              {orderCardBusinessLogistics?.cardData.retailSalesRep}
              {orderCardBusinessLogistics?.cardData.retailSalesRep ? (
                <Typography
                  component="span"
                  fontSize="12px"
                  fontWeight={500}
                  color="#505050"
                  fontStyle="italic"
                >
                  (sales rep)
                </Typography>
              ) : null}
            </Typography>
            <Typography fontSize="12px" fontWeight={500}>
              {orderRetailer &&
              orderRetailer.tenantCity &&
              orderRetailer.tenantState
                ? `${orderRetailer.tenantCity}, ${orderRetailer.tenantState}`
                : null}
            </Typography>
          </OrderTabSummarySection>
        ) : null}
        <OrderTabSummarySection
          icon={<SellOutlinedIcon sx={{ fontSize: "16px" }} />}
          title={"PRICE"}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize="12px" fontWeight={500}>
              Base Total
            </Typography>
            <Typography fontSize="12px" fontWeight={500}>
              {formatAsCurrency(
                orderCardFinancialPricing?.cardData.baseTotal ?? 0
              )}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize="12px" fontWeight={500}>
              Option Total
            </Typography>
            <Typography fontSize="12px" fontWeight={500}>
              {formatAsCurrency(
                orderCardFinancialPricing?.cardData.optionsTotal ?? 0
              )}
            </Typography>
          </Stack>

          <Divider sx={{ borderColor: "grey.400", marginY: 1 }} />

          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize="12px" fontWeight={500}>
              Base + Options
            </Typography>
            <Typography fontSize="12px" fontWeight={600}>
              {formatAsCurrency(
                (orderCardFinancialPricing?.cardData.baseTotal ?? 0) +
                  (orderCardFinancialPricing?.cardData.optionsTotal ?? 0)
              )}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize="12px" fontWeight={500}>
              Material Surcharge
            </Typography>
            <Typography fontSize="12px" fontWeight={500}>
              {formatAsCurrency(order?.materialSurcharge ?? 0)}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize="12px" fontWeight={500}>
              Fees Total
            </Typography>
            <Typography fontSize="12px" fontWeight={500}>
              {formatAsCurrency(order?.feesTotal ?? 0)}
            </Typography>
          </Stack>

          <Divider
            sx={{ borderColor: "grey.400", marginTop: 1, marginBottom: "3px" }}
          />
          <Divider sx={{ borderColor: "grey.400", marginBottom: 1 }} />

          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize="18px" fontWeight={600}>
              Grand Total
            </Typography>
            <Typography fontSize="18px" fontWeight={600}>
              {formatAsCurrency(
                orderCardFinancialPricing?.cardData.grandTotal ?? 0
              )}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize="11px" fontWeight={500} color="#505050">
              Priced{" "}
              {formatAsDate(
                orderCardFinancialPricing?.cardData.priceDateTimestamp
              )}
            </Typography>
          </Stack>
        </OrderTabSummarySection>
      </Stack>
    </Card>
  );
};
