import { useMemo } from "react";
import { Table } from "../../components/Table";
import {
  Order,
  getOrderQuotePhaseDisplayName,
  getOrderStatusDisplayName,
  canAddChangeOrder,
  canDeleteOrder,
} from "../../types/order";
import { GridColDef } from "@mui/x-data-grid";
import { Avatar, Container, Stack, Typography } from "@mui/material";
import { OrderPhaseChip } from "./OrderPhaseChip";
import { formatAsCurrency } from "../../utils/number";
import { formatAsDate } from "../../utils/date";
import {
  MoreMenuButton,
  MoreMenuButtonProps,
} from "../../components/MoreMenuButton";
import { getUserFullName, getUserInitials } from "../../types/user";
import { DateTime } from "luxon";
import { getTenantInitials, getTenantPhotoUrlSrc } from "../../types/tenant";
import { FillContainer } from "../../components/FillContainer";
import { useSession } from "../../hooks/useSession";

type OrderListViewProps = {
  orders: Order[];
  handleOrderEditClick: (orderGuid: string) => void;
  handleNewChangeOrderClick: (orderGuid: string) => void;
  handleDeleteOrderClick: (orderGuid: string) => void;
};

export const OrderListView: React.FC<OrderListViewProps> = ({
  orders,
  handleOrderEditClick,
  handleNewChangeOrderClick,
  handleDeleteOrderClick,
}) => {
  const { user } = useSession();

  const columns: GridColDef<Order>[] = useMemo(
    () => [
      {
        field: "orderStatus",
        flex: 1,
        minWidth: 290,
        maxWidth: 390,
        headerName: "Status",
        renderCell: (params) => (
          <Stack
            direction="row"
            justifyItems="center"
            spacing={1}
            sx={{ mt: 2 }}
          >
            <Typography>
              {getOrderStatusDisplayName(params.value.orderStatus)}
            </Typography>
            {params.row.orderStatus === "q" ? (
              <OrderPhaseChip quotePhase={params.row.quotePhase} />
            ) : null}
          </Stack>
        ),
        valueGetter: (_, row) => row,
        sortComparator: (a, b) => {
          const getStatusPhaseDate = (order: Order) => {
            const status = getOrderStatusDisplayName(order.orderStatus).slice(
              0,
              2
            );
            const phase = getOrderQuotePhaseDisplayName(order.quotePhase)[0];
            const dateCreated = DateTime.fromSeconds(
              order.createdTimestamp
            ).toFormat("yyyyMMddhhmmss");
            return `${status}${phase}${dateCreated}`;
          };
          const aString = getStatusPhaseDate(a);
          const bString = getStatusPhaseDate(b);
          return aString.localeCompare(bString);
        },
      },
      {
        field: "orderIdentifier",
        flex: 1,
        minWidth: 150,
        maxWidth: 200,
        headerName: "ID",
        valueFormatter: (value) => `ID ${value}`,
      },
      {
        field: "calculatedVinNumber",
        flex: 1,
        minWidth: 150,
        maxWidth: 200,
        headerName: "VIN",
        valueFormatter: (value) => value ?? "",
      },
      {
        field: "basePrice",
        headerName: "Base + Options",
        width: 150,
        valueFormatter: (value, order) =>
          order.orderStatus !== "a" ? formatAsCurrency(value) : "",
      },
      {
        field: "createdTimestamp",
        width: 120,
        headerName: "Date Created",
        valueFormatter: (value) => formatAsDate(value),
      },
      {
        field: "model.modelName",
        flex: 1,
        headerName: "Model",
        minWidth: 150,
        maxWidth: 300,
        valueGetter: (_, order) => order.model.modelName,
      },
      {
        field: "retailer.tenantName",
        flex: 1,
        headerName: "Retailer",
        minWidth: 200,
        renderCell: (params) =>
          params.row.retailer ? (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
              height="100%"
              width="100%"
            >
              <Avatar
                sx={{ mr: 2 }}
                src={getTenantPhotoUrlSrc(params.row.retailer)}
              >
                {getTenantInitials(params.row.retailer) || ""}
              </Avatar>

              <Typography>{params.row?.retailer?.tenantName || ""}</Typography>
            </Stack>
          ) : null,
        valueGetter: (_, order) => order.retailer,
        sortComparator: (a, b) => {
          if (!a) return 1;
          if (!b) return -1;
          return a?.tenantName.localeCompare(b?.tenantName);
        },
      },
      {
        field: "assigneeUser",
        flex: 1,
        minWidth: 200,
        headerName: "Assignee",
        renderCell: (params) => (
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            height="100%"
            width="100%"
          >
            <Avatar
              sx={{ mr: 2 }}
              src={
                `${process.env.REACT_APP_API_BASE_URL}/${params.row?.assigneeUser?.userPhotoUrl}` ??
                undefined
              }
            >
              {getUserInitials(params.row?.assigneeUser) || ""}
            </Avatar>
            <Typography>
              {getUserFullName(params.row?.assigneeUser) || ""}
            </Typography>
          </Stack>
        ),
        sortComparator: (a, b) => {
          return (
            (a?.userFirstName + a?.userLastName).localeCompare(
              b?.userFirstName + b?.userLastName
            ) || 0
          );
        },
      },
      {
        field: "actions",
        headerName: "",
        width: 10,
        align: "right",
        sortable: false,
        renderCell: ({ row }) => {
          const items: MoreMenuButtonProps["menuItems"] = [
            {
              label: "Edit",
              onClick: () => handleOrderEditClick(row.orderGuid),
            },
          ];

          if (canAddChangeOrder(row) && !user?.isRetailUser) {
            items.push({
              label: "New Sales Change Order",
              onClick: () => handleNewChangeOrderClick(row.orderGuid),
            });
          }

          if (user && canDeleteOrder(row, user)) {
            items.push({
              label: "Delete",
              onClick: () => handleDeleteOrderClick(row.orderGuid),
            });
          }

          return <MoreMenuButton menuItems={items} />;
        },
      },
    ],
    [
      handleDeleteOrderClick,
      handleNewChangeOrderClick,
      handleOrderEditClick,
      user,
    ]
  );

  return (
    <Container maxWidth={false}>
      <FillContainer>
        <Table
          onRowClick={(row) => handleOrderEditClick(row.row.orderGuid)}
          columns={columns}
          rows={orders}
          rowSelection={false}
          disableColumnFilter
          disableColumnMenu
          disableColumnResize
          disableRowSelectionOnClick
          getRowId={(row) => row.orderGuid}
          initialState={{
            sorting: {
              sortModel: [{ field: "orderStatus", sort: "asc" }],
            },
          }}
        />
      </FillContainer>
    </Container>
  );
};
