import { Model } from "./model";

export type Series = {
  seriesGuid: string;
  seriesName: string;
  models: Model[];
  isActive: boolean;
};

export const getSeriesName = (series: Series[], seriesGuid: string): string => {
  const foundSeries = series.find((s) => s.seriesGuid === seriesGuid);
  return foundSeries?.seriesName ?? "";
};
