import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Search } from "@mui/icons-material";
import { Switch } from "../../components/Switch";
import {
  Box,
  Button,
  InputAdornment,
  OutlinedInput,
  Stack,
} from "@mui/material";
import { User } from "../../types/user";
import { useApiRequest } from "../../hooks/useApiRequest";
import { useSession } from "../../hooks/useSession";
import { GridColDef } from "@mui/x-data-grid";
import { Table } from "../../components/Table";
import { NewEditModal } from "./NewEditModal";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { PageHeader } from "../../components/PageHeader";
import { MoreMenuButton } from "../../components/MoreMenuButton";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import { routes } from "../../routes";

const ManageSysAdminsRoute: React.FC = () => {
  const [isEditing, setIsEditing] = useState(false);
  const { user } = useSession();
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [searchText, setSearchText] = useState("");
  const { setBreadcrumbs } = useBreadcrumbs();
  const { setGlobalToastNotification } = useGlobalToastNotificationContext();

  usePageMetadata({ title: `Manage ${routes.manageSystemAdmins.label}` });

  const { request: userRequest, data: users } = useApiRequest<User[]>(false);
  const {
    request: inviteRequest,
    status: sendInviteStatus,
    loading: sendInviteLoading,
    error: sendInviteError,
  } = useApiRequest();

  const {
    request: activationToggleRequest,
    status: activationToggleStatus,
    loading: activateLoading,
  } = useApiRequest();

  const refreshUsers = useCallback(() => {
    userRequest("/users", {
      method: "GET",
    });
  }, [userRequest]);

  useEffect(() => {
    if (sendInviteLoading === false && sendInviteStatus === "ok") {
      setGlobalToastNotification({
        severity: "success",
        message: "Invite Sent!",
        title: "Success",
      });
    } else if (sendInviteError != null) {
      setGlobalToastNotification({
        severity: "error",
        message: "Something went wrong. Please try again..",
        title: "Error",
      });
    }
  }, [
    sendInviteStatus,
    sendInviteLoading,
    setGlobalToastNotification,
    sendInviteError,
  ]);

  // Fetch users on mount
  useEffect(() => {
    refreshUsers();
  }, [refreshUsers]);

  useEffect(() => {
    setBreadcrumbs([{ label: routes.manageSystemAdmins.label }]);
  }, [setBreadcrumbs]);

  useEffect(() => {
    if (activateLoading === false && activationToggleStatus === "ok")
      refreshUsers();
  }, [activationToggleStatus, refreshUsers, activateLoading]);

  const handleEditClick = (user: User) => () => {
    setSelectedUser(user);
    setIsEditing(true);
  };

  const handleNewButtonClick = () => {
    setSelectedUser(null);
    setIsEditing(true);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value.toLowerCase());
  };

  const handleInviteRequest = useCallback(
    (user: User) => () => {
      inviteRequest("/users/invite", {
        method: "POST",
        data: {
          userGuid: user.userGuid,
        },
      });
    },
    [inviteRequest]
  );

  const filteredUsers = useMemo(() => {
    if (!users) return [];
    return users.filter(
      (user) =>
        user.userFirstName.toLowerCase().includes(searchText) ||
        user.userLastName.toLowerCase().includes(searchText) ||
        user.userEmail.toLowerCase().includes(searchText)
    );
  }, [users, searchText]);

  const handleSave = useCallback(() => {
    refreshUsers();
    setIsEditing(false);
  }, [refreshUsers]);

  const handleActiveToggle = useCallback(
    (user: User) => () => {
      activationToggleRequest(`/users/${user.userGuid}/activate`, {
        method: "POST",
        data: {
          isActive: !user.isActive,
        },
      });
    },
    [activationToggleRequest]
  );

  const columns: GridColDef<User>[] = useMemo(
    () => [
      {
        field: "userFirstName",
        flex: 1,
        headerName: "Name",
        renderCell: ({ row }) => {
          return `${row.userFirstName} ${row.userLastName}`;
        },
      },
      {
        field: "userEmail",
        headerName: "Email",
        flex: 1,
      },
      {
        field: "userPhoneNumber",
        headerName: "Phone",
        flex: 1,
      },
      {
        field: "isActive",
        headerName: "Active",
        renderCell: (params) => (
          <Box sx={{ paddingTop: 2, paddingLeft: 1 }}>
            <Switch
              disabled={params.row.userGuid === user?.userGuid}
              checked={params.row.isActive}
              onChange={handleActiveToggle(params.row)}
            />
          </Box>
        ),
      },
      {
        field: "actions",
        headerName: "",
        sortable: false,
        renderCell: ({ row }) => {
          return (
            <MoreMenuButton
              menuItems={[
                {
                  label: "Edit",
                  onClick: handleEditClick(row),
                },
                {
                  label: "Invite",
                  disabled: row.userGuid === user?.userGuid,
                  onClick: handleInviteRequest(row),
                },
              ]}
            />
          );
        },
      },
    ],
    [handleActiveToggle, handleInviteRequest, user?.userGuid]
  );

  return (
    <>
      <Box>
        <PageHeader
          title="Super Admins"
          actions={
            <Stack spacing={2}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNewButtonClick}
                >
                  + Super Admin
                </Button>
              </Box>
              <OutlinedInput
                placeholder="Search"
                onChange={handleSearchChange}
                size="small"
                color="primary"
                sx={{ backgroundColor: "white" }}
                startAdornment={
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                }
              />
            </Stack>
          }
        />

        {users && (
          <Table
            rows={filteredUsers}
            columns={columns}
            rowSelection={false}
            hideFooter
            disableColumnFilter
            disableColumnMenu
            disableColumnResize
            disableRowSelectionOnClick
            getRowId={(row) => row.userGuid}
          />
        )}
      </Box>
      {isEditing && (
        <NewEditModal
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          onSaveSuccessful={handleSave}
          user={selectedUser}
          isNew={!selectedUser}
          isCurrentUser={selectedUser?.userGuid === user?.userGuid}
        />
      )}
    </>
  );
};

export default ManageSysAdminsRoute;
