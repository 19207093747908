export type StateFee = {
  statefeeGuid: string;
  statefeeState: string;
  statefeeType: "m" | "d";
  statefeeAmount: number;
  statefeeUom: "f" | "u";
  isActive: boolean;
};

export type StateFeeType = StateFee["statefeeType"];

export type StateFeeUom = StateFee["statefeeUom"];

export const getStateFeeTypeDisplayName = (
  stateFeeType: StateFeeType
): string => {
  const displayNames: Record<StateFeeType, string> = {
    m: "Manufactured",
    d: "Modular",
  };

  return displayNames[stateFeeType] || stateFeeType;
};

export const getStateFeeUomDisplayName = (statefeeUom: StateFeeUom): string => {
  const displayNames: Record<StateFeeUom, string> = {
    f: "Floor",
    u: "Unit",
  };

  return displayNames[statefeeUom] || statefeeUom;
};
