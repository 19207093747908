import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { Search } from "@mui/icons-material";
import { generatePath, useNavigate } from "react-router-dom";
import { EmptyState } from "../../components/EmptyState";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import { routes } from "../../routes";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Logic, getLogicThenRequireHideAsDisplayName } from "../../types/logic";
import { MoreMenuButton } from "../../components/MoreMenuButton";
import {
  Grid,
  Stack,
  Button,
  OutlinedInput,
  InputAdornment,
  Box,
  Chip,
} from "@mui/material";
import { Table } from "../../components/Table";
import { Switch } from "../../components/Switch";
import { useSidebarLayoutManageData } from "../../components/SidebarLayoutManageData";
import { Category } from "../../types/category";
import { Option } from "../../types/option";
import { LogicTextSelectionOption } from "../../components/LogicTextSelectionOption";
import { LogicTextSelectionOptionAttribute } from "../../components/LogicTextSelectionOptionAttribute";
import { LogicTextSubgroup } from "../../components/LogicTextSubgroup";
import { DeleteConditionalLogicModal } from "./DeleteConditionalLogicModal";

const ManageConditionalLogicRoute: React.FC = () => {
  const navigate = useNavigate();
  const { refreshSidebar } = useSidebarLayoutManageData();
  const { setBreadcrumbs } = useBreadcrumbs();
  const [searchText, setSearchText] = useState("");
  const [deletingLogicGuid, setDeletingLogicGuid] = useState<string | null>(
    null
  );

  const {
    data: logics,
    loading: logicsLoading,
    request: logicsRequest,
  } = useApiRequest<Logic[]>(false);

  const {
    request: activationToggleRequest,
    status: activationToggleStatus,
    loading: activateLoading,
  } = useApiRequest();

  const { data: categoriesData, request: categoriesRequest } =
    useApiRequest<Category[]>(false);

  useEffect(() => {
    categoriesRequest("/company/options/categories", { method: "GET" });
  }, [categoriesRequest]);

  usePageMetadata({
    title: `Manage ${routes.manageDataConditionalLogic.label}`,
  });

  useEffect(() => {
    setBreadcrumbs([{ label: routes.manageDataConditionalLogic.label }]);
  }, [setBreadcrumbs]);

  const refreshLogics = useCallback(() => {
    logicsRequest("/company/logics", { method: "GET" });
  }, [logicsRequest]);

  useEffect(() => {
    refreshLogics();
  }, [refreshLogics]);

  const handleActiveToggle = useCallback(
    (logic: Logic) => () => {
      activationToggleRequest(`/company/logics2/${logic.logicGuid}/edit`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: { isActive: !logic.isActive },
      });
    },
    [activationToggleRequest]
  );

  useEffect(() => {
    if (activateLoading === false && activationToggleStatus === "ok") {
      refreshLogics();
      refreshSidebar();
    }
  }, [activateLoading, activationToggleStatus, refreshLogics, refreshSidebar]);

  const handleNewButtonClick = () => {
    navigate(routes.manageDataConditionalLogicAdd.path);
  };

  const handleEditClick = useCallback(
    (logic: Logic) => () => {
      navigate(
        generatePath(routes.manageDataConditionalLogicEdit.path, {
          logicGuid: logic.logicGuid,
        })
      );
    },
    [navigate]
  );

  const handleDeleteClick = useCallback(
    (logic: Logic) => () => {
      setDeletingLogicGuid(logic.logicGuid);
    },
    []
  );

  const handleDeleteLogicClose = () => {
    setDeletingLogicGuid(null);
  };

  const handleDeleteLogicSuccess = () => {
    handleDeleteLogicClose();
    refreshLogics();
  };

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value.toLowerCase());
    },
    []
  );

  const columns: GridColDef<Logic>[] = useMemo(
    () => [
      {
        field: "if",
        headerName: "If",
        flex: 1,
        minWidth: 300,
        renderCell: (params) => {
          return (
            <Stack direction="row" alignItems="center">
              {params.row.ifType === "a" ? (
                <LogicTextSelectionOptionAttribute
                  option={findOption(
                    categoriesData ?? [],
                    params.row.ifOptionGuid
                  )}
                  logicAttributeselectionGuid={
                    params.row.ifAttributeselectionGuid ?? ""
                  }
                />
              ) : params.row.ifType === "o" ? (
                <LogicTextSelectionOption
                  optionName={params.row.ifOptionName ?? ""}
                />
              ) : null}{" "}
              is selected
            </Stack>
          );
        },
      },
      {
        field: "thenRequireHide",
        headerName: "Then",
        width: 100,
        valueGetter: (_, row) => {
          return getLogicThenRequireHideAsDisplayName(row.thenRequireHide);
        },
      },
      {
        field: "this",
        headerName: "This",
        flex: 1,
        minWidth: 300,
        renderCell: (params) => {
          return (
            <Stack direction="row" alignItems="center">
              {params.row.thisType === "a" ? (
                <LogicTextSelectionOptionAttribute
                  option={findOption(
                    categoriesData ?? [],
                    params.row.thisOptionGuid
                  )}
                  logicAttributeselectionGuid={
                    params.row.thisAttributeselectionGuid ?? ""
                  }
                />
              ) : params.row.thisType === "o" ? (
                <LogicTextSelectionOption
                  optionName={params.row.thisOptionName ?? ""}
                />
              ) : params.row.thisType === "s" ? (
                <LogicTextSubgroup
                  subgroupName={params.row.thisSubgroupName ?? ""}
                />
              ) : null}
              {params.row.thisTargets.length > 1
                ? `, +${params.row.thisTargets.length - 1}`
                : ""}{" "}
              {params.row.logicError ? (
                <Chip
                  label={params.row.logicError.shortDesc}
                  size="small"
                  color="error"
                  sx={{ fontSize: "12px", marginLeft: "auto" }}
                />
              ) : null}
            </Stack>
          );
        },
      },
      {
        field: "isActive",
        headerName: "Active",
        headerAlign: "right",
        align: "right",
        width: 65,
        renderCell: (params) => (
          <Box sx={{ paddingTop: 2, paddingLeft: 1 }}>
            <Switch
              sx={{ marginLeft: "auto" }}
              checked={params.row.isActive}
              onChange={handleActiveToggle(params.row)}
            />
          </Box>
        ),
      },
      {
        field: "actions",
        headerName: "",
        width: 10,
        align: "right",
        sortable: false,
        renderCell: ({ row }) => {
          return (
            <MoreMenuButton
              menuItems={[
                {
                  label: "Edit",
                  onClick: handleEditClick(row),
                },
                {
                  label: "Delete",
                  onClick: handleDeleteClick(row),
                },
              ]}
            />
          );
        },
      },
    ],
    [categoriesData, handleActiveToggle, handleEditClick, handleDeleteClick]
  );

  const filteredLogics = useMemo(() => {
    if (!logics) {
      return [];
    }

    if (searchText.length === 0) {
      return logics;
    }

    const filtered = logics.filter((logic) => {
      const searchValues = [
        logic.ifOptionName,
        logic.ifAttributeselectionName,
        logic.thisOptionName,
        logic.thisAttributeselectionName,
        logic.thisSubgroupName,
        getLogicThenRequireHideAsDisplayName(logic.thenRequireHide),
      ];

      const matchesFilter = searchValues.some(
        (v) => v && v.toLowerCase().includes(searchText)
      );

      return matchesFilter;
    });

    return filtered;
  }, [logics, searchText]);

  const deletingLogic = useMemo(() => {
    return logics?.find((o) => o.logicGuid === deletingLogicGuid);
  }, [deletingLogicGuid, logics]);

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent={"right"}
        marginBottom={2}
      >
        <Grid item />
        <Grid item>
          <Stack spacing={2} direction={"row-reverse"}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNewButtonClick}
            >
              + New Conditional Logic
            </Button>
            <OutlinedInput
              placeholder="Search"
              onChange={handleSearchChange}
              size="small"
              color="primary"
              sx={{ backgroundColor: "white", height: 37 }}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
            />
          </Stack>
        </Grid>
      </Grid>
      {logics && logics.length === 0 && !logicsLoading && (
        <EmptyState
          heading="No Conditional Logic"
          body="Add a conditional logic statement to restrict or require selection options when specific conditions are met within a quote"
        />
      )}
      <Box>
        {filteredLogics && filteredLogics.length > 0 && (
          <Table
            columns={columns}
            rows={filteredLogics}
            rowSelection={false}
            disableColumnFilter
            disableColumnMenu
            disableColumnResize
            disableRowSelectionOnClick
            getRowId={(row) => row.logicGuid}
          />
        )}
      </Box>

      {deletingLogic && (
        <DeleteConditionalLogicModal
          logic={deletingLogic}
          onDeleteSuccess={handleDeleteLogicSuccess}
          onClose={handleDeleteLogicClose}
        />
      )}
    </>
  );
};

const findOption = (
  categories: Category[],
  optionGuid: string
): Option | undefined => {
  let option: Option | undefined;

  (categories ?? []).forEach((category) => {
    if (option) {
      return;
    }
    category.groups.forEach((group) => {
      if (option) {
        return;
      }
      group.subgroups.forEach((subgroup) => {
        if (option) {
          return;
        }
        subgroup.options.forEach((o) => {
          if (o.optionGuid !== optionGuid) {
            return;
          }
          option = o;
          return;
        });
      });
    });
  });

  return option;
};

export default ManageConditionalLogicRoute;
