import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import axios from "../axiosInstance";
import { useState, useCallback, useMemo } from "react";
import { routes } from "../routes";
import { useSession } from "./useSession";
import { Entity, getApiErrorMessage } from "../utils/error";

type ApiRequestOptions<T> = {
  /**
   * Called when the API request is successful.
   *
   * @param data The responses data
   */
  onSuccess?: (data: T) => void;
};

type ApiRequestReturnType<T> = {
  data: T | null;
  loading: boolean;
  error: AxiosError | null;
  errorMessage: string | null;
  request: (
    url: string,
    options?: AxiosRequestConfig,
    apiRequestOptions?: ApiRequestOptions<T>
  ) => void;
  response: AxiosResponse | null;
  status: string | null;
};

export const useApiRequest = <T>(
  clearOnRequest = true,
  entity?: Entity
): ApiRequestReturnType<T> => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError | null>(null);
  const [data, setData] = useState<any>(null);
  const [status, setStatus] = useState<string | null>(null);
  const [response, setResponse] = useState<any>(null);
  const { logout } = useSession();

  const request = useCallback(
    async (
      url: string,
      options?: AxiosRequestConfig,
      apiRequestOptions?: ApiRequestOptions<T>
    ) => {
      setLoading(true);
      setError(null);
      setStatus(null);

      if (clearOnRequest) {
        setData(null);
      }

      try {
        const response = await axios(url, options);
        const data = response.data;
        const status = data?.status;

        const responseData = data?.result || data;
        setResponse(response);
        setData(responseData);
        setStatus(status);
        apiRequestOptions?.onSuccess?.(responseData);
      } catch (err) {
        const axiosError = err as AxiosError;

        // Logout user if unauthorized
        if (
          axiosError.response?.status === 401 &&
          window.location.pathname !== routes.login.path &&
          !axiosError.request.responseURL.includes("verify")
        ) {
          logout();
          window.location.href = routes.login.path;
        }

        setError(axiosError);
      } finally {
        setLoading(false);
      }
    },
    [clearOnRequest, logout]
  );

  const errorMessage = useMemo(
    () => (error ? getApiErrorMessage(error, entity) : null),
    [error, entity]
  );

  return {
    loading,
    error,
    errorMessage,
    data,
    request,
    response,
    status,
  };
};
