import { usePageMetadata } from "../../hooks/usePageMetadata";

export default function NotFound() {
  usePageMetadata({ title: "Not Found" });

  return (
    <div>
      <h1>404 - Not Found</h1>
    </div>
  );
}
