import React, { useEffect, useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import {
  Question,
  type OrderCardAdditionalBuyerQuestions as OrderCardAdditionalBuyerQuestionsType,
} from "../../types/orderCardAdditionalBuyerQuestions";
import { OrderCardActionButton } from "./OrderCardActionButton";
import { OrderCardBase } from "./OrderCardBase";
import { useApiRequest } from "../../hooks/useApiRequest";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";
import { Box, Grid, Stack } from "@mui/material";
import InputField from "../../components/InputField";
import { useOrderCard } from "./OrderCardContext";
import { NewQuestionModal } from "./NewQuestionModal";
import { MoreMenuButton } from "../../components/MoreMenuButton";
import { ConfirmModal } from "../../components/ConfirmModal";
import { useSession } from "../../hooks/useSession";
import { UserRole, UserRoles } from "../../types/userRole";

interface OrderCardAdditionalBuyerQuestionsProps {
  orderCard: OrderCardAdditionalBuyerQuestionsType;
}
type FormData = {
  questions: Question[];
};

const getDefaultValues = (
  orderCard: OrderCardAdditionalBuyerQuestionsType | null
): FormData => {
  return {
    questions: orderCard?.cardData.questions ?? [],
  };
};

export const OrderCardAdditionalBuyerQuestions: React.FC<
  OrderCardAdditionalBuyerQuestionsProps
> = ({ orderCard }) => {
  const { request: deleteRequest, errorMessage } =
    useApiRequest<OrderCardAdditionalBuyerQuestionsType>();
  const { user } = useSession();
  const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false);
  const [editingQuestion, setEditingQuestion] = useState<Question | null>(null);
  const [editingQuestionIndex, setEditingQuestionIndex] = useState<
    number | null
  >(null);
  const [deletingQuestionIndex, setDeletingQuestionIndex] = useState<
    number | null
  >();
  const [isPendingDelete, setIsPendingDelete] = useState(false);

  const { showErrorToast } = useGlobalToastNotificationContext();
  const { saveOrderCard } = useOrderCard();

  const { getValues, register, formState, control } = useForm<FormData>({
    defaultValues: getDefaultValues(orderCard),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "questions",
  });

  const handleBlurField = () => {
    const cardData = getValues();
    saveOrderCard(orderCard.orderCardGuid, cardData);
  };

  const handleAddQuestionClick = () => {
    setIsQuestionModalOpen(true);
  };

  const handleDeleteConfirm = () => {
    setIsPendingDelete(false);
    deleteRequest(
      `ordercards/${orderCard.orderCardGuid}/qa/${deletingQuestionIndex}/delete`,
      {
        method: "DELETE",
      },
      {
        onSuccess: () => {
          if (deletingQuestionIndex != null) {
            remove(deletingQuestionIndex);
          }
          setDeletingQuestionIndex(null);
        },
      }
    );
  };

  const handleQuestionAddedEdited = (question: Question) => {
    const form = getValues();
    setIsQuestionModalOpen(false);
    setEditingQuestion(null);
    setEditingQuestionIndex(null);

    if (editingQuestionIndex !== null) {
      update(editingQuestionIndex, question);
    } else {
      saveOrderCard(orderCard.orderCardGuid, {
        questions: [...form.questions, question],
      });
      append(question);
    }
  };

  const handleEditClick = (index: number) => {
    const form = getValues();
    setIsQuestionModalOpen(true);
    setEditingQuestion(form.questions[index]);
    setEditingQuestionIndex(index);
  };

  const handleDeleteClick = (index: number) => {
    const form = getValues();

    if (form.questions[index].alwaysInclude) {
      setIsPendingDelete(true);
      setDeletingQuestionIndex(index);
    } else {
      saveOrderCard(orderCard.orderCardGuid, {
        questions: form.questions.filter(
          (q) => q.text !== form.questions[index].text
        ),
      });
      remove(index);
    }
  };

  const handleClosedQuestionModal = () => {
    setIsQuestionModalOpen(false);
    setEditingQuestion(null);
    setEditingQuestionIndex(null);
  };

  const isAtleastAdvancedUser = useMemo((): boolean => {
    if (user?.userRole) {
      const a: UserRoles[] = [
        UserRole.OWNER,
        UserRole.SYSADMIN,
        UserRole.ADMIN,
        UserRole.ADVANCEDUSER,
      ];

      return a.includes(user?.userRole);
    }
    return false;
  }, [user]);

  useEffect(() => {
    if (errorMessage) {
      showErrorToast({ message: errorMessage });
    }
  }, [errorMessage, showErrorToast]);

  return (
    <>
      <OrderCardBase
        disabled={false}
        orderCard={orderCard}
        expanded={true}
        groupIndex={0}
        action={
          <OrderCardActionButton onClick={handleAddQuestionClick}>
            Add Question
          </OrderCardActionButton>
        }
      >
        <Grid container spacing={2}>
          {fields.map((question, index) => (
            <Grid xs={12} sm={12} md={12} item key={question.id}>
              <Box sx={{ marginTop: 5 }}>
                <Stack direction="row">
                  <InputField
                    {...register(`questions.${index}.answer`, {
                      onBlur: handleBlurField,
                    })}
                    label={question.text}
                    fullWidth
                    multiline
                    minRows={3}
                    error={!!formState.errors.questions?.[index]?.answer}
                    helperText={
                      formState.errors.questions?.[index]?.answer?.message
                    }
                  />
                  <Box>
                    {isAtleastAdvancedUser && (
                      <MoreMenuButton
                        menuItems={[
                          {
                            label: "Edit",
                            onClick: () => handleEditClick(index),
                          },
                          {
                            label: "Delete",
                            onClick: () => handleDeleteClick(index),
                          },
                        ]}
                      />
                    )}
                  </Box>
                </Stack>
              </Box>
            </Grid>
          ))}
        </Grid>
      </OrderCardBase>
      {isQuestionModalOpen && (
        <NewQuestionModal
          orderCardGuid={orderCard.orderCardGuid}
          question={editingQuestion}
          isOpen={isQuestionModalOpen}
          onClose={handleClosedQuestionModal}
          onSaveSuccessful={handleQuestionAddedEdited}
        />
      )}
      {isPendingDelete && (
        <ConfirmModal
          isDestructive
          isOpen={true}
          heading={"Delete this question"}
          body={
            "Deleting this question will also delete it from future Prospects. It does not affect past Prospects."
          }
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          onClose={() => setIsPendingDelete(false)}
          onConfirm={handleDeleteConfirm}
        />
      )}
    </>
  );
};
