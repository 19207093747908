import { useEffect, useMemo } from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import {
  OrderCardFinancialFinancingPaymentTerms,
  getOrderCardFinancingPaymentTermsDisplayName,
  type OrderCardFinancialFinancing as OrderCardFinancialFinancingType,
} from "../../types/orderCardFinancialFinancing";
import { OrderCardBase } from "./OrderCardBase";
import { useOrderCard } from "./OrderCardContext";
import InputField from "../../components/InputField";
import { Controller, useForm } from "react-hook-form";
import SelectField from "../../components/SelectField";
import { getOrderCardError } from "../../types/orderCard";

type FormData = {
  poNumber: string;
  paymentTerms: OrderCardFinancialFinancingPaymentTerms | "";
  floorPlanLenderGuid: string;
  fiApprovedBy: string;
  fiApprovalNumber: string;
};

const getDefaultValues = (
  orderCard: OrderCardFinancialFinancingType | null
): FormData => {
  return {
    poNumber: orderCard?.cardData.poNumber ?? "",
    paymentTerms: orderCard?.cardData.paymentTerms ?? "",
    floorPlanLenderGuid: orderCard?.cardData.floorPlanLenderGuid ?? "",
    fiApprovedBy: orderCard?.cardData.fiApprovedBy ?? "",
    fiApprovalNumber: orderCard?.cardData.fiApprovalNumber ?? "",
  };
};

const paymentTermsOptions: Array<{
  label: string;
  value: Exclude<FormData["paymentTerms"], null>;
}> = [
  { label: getOrderCardFinancingPaymentTermsDisplayName("COD"), value: "COD" },
  {
    label: getOrderCardFinancingPaymentTermsDisplayName("Financed"),
    value: "Financed",
  },
];

interface OrderCardFinancialFinancingProps {
  orderCard: OrderCardFinancialFinancingType;
  expanded: boolean;
  groupIndex: number;
}

export const OrderCardFinancialFinancing: React.FC<
  OrderCardFinancialFinancingProps
> = ({ orderCard, expanded, groupIndex }) => {
  const { saveOrderCard, setEditingOrderCard } = useOrderCard();

  const disabled = orderCard.isLocked;

  const {
    control,
    register,
    reset,
    formState,
    getValues,
    setError,
    clearErrors,
  } = useForm<FormData>({
    defaultValues: getDefaultValues(orderCard),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const floorPlanLenderOptions = useMemo(() => {
    const invalidVendor = getOrderCardError(orderCard, "effv")?.vendor;

    const vendors = invalidVendor
      ? [invalidVendor, ...orderCard.vendors]
      : orderCard.vendors;

    return vendors.map((v) => ({
      label: v.vendorName,
      value: v.vendorGuid,
    }));
  }, [orderCard]);

  useEffect(() => {
    reset(getDefaultValues(orderCard), { keepDirtyValues: true });
  }, [orderCard, reset]);

  useEffect(() => {
    const invalidVendor = getOrderCardError(orderCard, "effv")?.vendor;

    if (invalidVendor) {
      setError("floorPlanLenderGuid", { type: "manual", message: "Inactive" });
    } else {
      clearErrors("floorPlanLenderGuid");
    }
  }, [clearErrors, disabled, orderCard, setError]);

  const getOrderCardData = (
    formData: FormData
  ): OrderCardFinancialFinancingType["cardData"] => {
    const cardData = {
      poNumber: formData.poNumber || null,
      paymentTerms: formData.paymentTerms || null,
      floorPlanLenderGuid: formData.floorPlanLenderGuid || null,
      fiApprovedBy: formData.fiApprovedBy || null,
      fiApprovalNumber: formData.fiApprovalNumber || null,
    };
    return cardData;
  };

  const handleBlurField = () => {
    const values = getValues();
    const cardData = getOrderCardData(values);
    saveOrderCard(orderCard.orderCardGuid, cardData);
    setEditingOrderCard(null);
  };

  const handleEditingField = () => {
    setEditingOrderCard(orderCard);
  };

  const handleClearClick = () => {
    const defaultValues = getDefaultValues(null);
    reset(defaultValues);
    const cardData = getOrderCardData(defaultValues);
    saveOrderCard(orderCard.orderCardGuid, cardData);
  };

  return (
    <OrderCardBase
      disabled={disabled}
      orderCard={orderCard}
      onClearClick={handleClearClick}
      expanded={expanded}
      groupIndex={groupIndex}
    >
      <Grid container spacing={2} component="form">
        <Grid xs={12} sm={12} md={6}>
          <InputField
            {...register("poNumber", { onBlur: handleBlurField })}
            label="Purchase Order #"
            placeholder="Enter Purchase Order #"
            onFocus={handleEditingField}
            fullWidth
            disabled={disabled}
            sx={{ marginBottom: 2 }}
            error={!!formState.errors.poNumber}
            helperText={formState.errors.poNumber?.message}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <Controller
            control={control}
            name="paymentTerms"
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                label="Payment Terms"
                placeholder="Enter Payment Terms"
                onFocus={handleEditingField}
                fullWidth
                disabled={disabled}
                options={paymentTermsOptions}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                onBlur={() => {
                  handleBlurField();
                  field.onBlur();
                }}
              />
            )}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <Controller
            control={control}
            name="floorPlanLenderGuid"
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                label="Floor Plan Lender"
                placeholder="Select Floor Plan Lender"
                onFocus={handleEditingField}
                fullWidth
                options={floorPlanLenderOptions}
                disabled={disabled}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                value={field.value ?? ""}
                onBlur={() => {
                  handleBlurField();
                  field.onBlur();
                }}
              />
            )}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <InputField
            {...register("fiApprovedBy", { onBlur: handleBlurField })}
            label="FI Approved By"
            placeholder="Enter FI Approved By"
            onFocus={handleEditingField}
            fullWidth
            disabled={disabled}
            sx={{ marginBottom: 2 }}
            error={!!formState.errors.fiApprovedBy}
            helperText={formState.errors.fiApprovedBy?.message}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <InputField
            {...register("fiApprovalNumber", { onBlur: handleBlurField })}
            label="FI Approval #"
            placeholder="Enter FI Approval #"
            onFocus={handleEditingField}
            fullWidth
            disabled={disabled}
            sx={{ marginBottom: 2 }}
            error={!!formState.errors.fiApprovalNumber}
            helperText={formState.errors.fiApprovalNumber?.message}
          />
        </Grid>
      </Grid>
    </OrderCardBase>
  );
};
