import { useEffect } from "react";
import { Alert, Box, Button } from "@mui/material";
import { Modal } from "../../components/Modal";
import InputField from "../../components/InputField";
import { useApiRequest } from "../../hooks/useApiRequest";
import { useForm } from "react-hook-form";
import { Order } from "../../types/order";

type EditTemplateNameModalProps = {
  order: Order;
  isOpen: boolean;
  onSaveSuccessful: (order: Order) => void;
  onClose: () => void;
};

type FormData = {
  templateName: string;
};

const getDefaultValues = (order: Order): FormData => {
  return {
    templateName: order?.templateName ?? "",
  };
};

export const EditTemplateNameModal = ({
  order,
  isOpen,
  onSaveSuccessful,
  onClose,
}: EditTemplateNameModalProps) => {
  const {
    data: saveTemplateData,
    loading: saveTemplateLoading,
    request: saveTemplateRequest,
    status: saveTemplateStatus,
    errorMessage: saveTemplateErrorMessage,
  } = useApiRequest<Order>(true);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isValid },
  } = useForm<FormData>({
    defaultValues: getDefaultValues(order),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  useEffect(() => {
    if (order) {
      const defaultValues = getDefaultValues(order);
      reset(defaultValues);
    }
  }, [reset, order]);

  const onSubmit = (data: FormData) => {
    saveTemplateRequest(`orders/${order.orderGuid}/edit`, {
      method: "POST",
      data: data,
    });
  };

  useEffect(() => {
    if (
      saveTemplateData &&
      saveTemplateStatus === "ok" &&
      saveTemplateLoading === false
    ) {
      onSaveSuccessful(saveTemplateData);
    }
  }, [
    onSaveSuccessful,
    saveTemplateData,
    saveTemplateLoading,
    saveTemplateStatus,
  ]);

  return (
    <Modal heading={`Edit Template Name`} isOpen={isOpen} onClose={onClose}>
      {saveTemplateErrorMessage && (
        <Alert severity="error" sx={{ marginBottom: 2 }}>
          {saveTemplateErrorMessage}
        </Alert>
      )}
      <Box
        component="form"
        sx={{ width: { xs: "100%", sm: "350px" } }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <InputField
          {...register("templateName", {
            required: { value: true, message: "Please enter name." },
          })}
          label="Template Name"
          required
          fullWidth
          error={!!errors.templateName}
          helperText={errors.templateName?.message}
          onFocus={(e) => e.target.select()}
        />
        <Box sx={{ mt: 2, display: "flex", justifyContent: "end" }}>
          <Button variant="outlined" onClick={onClose} sx={{ marginRight: 2 }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={!isValid || saveTemplateLoading}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
