import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { DropzoneRef } from "react-dropzone";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import { routes } from "../../routes";
import { useApiRequest } from "../../hooks/useApiRequest";
import { PageHeader } from "../../components/PageHeader";
import {
  Order,
  OrderStatus,
  OrderQuotePhase,
  getOrderStatusDisplayName,
  getOrderQuotePhaseDisplayName,
  getOrderCard,
  transformOrder,
  canAddChangeOrder,
  canDeleteOrder,
} from "../../types/order";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { ArrowLeft, AttachFile, Compress, Expand } from "@mui/icons-material";
import {
  MoreMenuButton,
  MoreMenuButtonProps,
} from "../../components/MoreMenuButton";
import { InfoPanel, InfoPanelProps } from "../../components/InfoPanel";
import { TabPanel, tabA11yProps } from "../../components/TabPanel";
import {
  getUserFullName,
  getUserInitials,
  getUserPhotoUrlSrc,
} from "../../types/user";
import { MenuButton, MenuItemProp } from "../../components/MenuButton";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";
import { OrderCardList } from "./OrderCardList";
import { OrderCard } from "../../types/orderCard";
import { orderCardTypes } from "../../types/orderCardTypes";
import {
  isBusinessBuyerOrderCardVisible,
  OrderCardBusinessBuyer,
} from "../../types/orderCardBusinessBuyer";
import { OrderCardSalesChangeOrder } from "../../types/orderCardSalesChangeOrder";
import { OrderCardEditResponse } from "./orderCardEditResponse";
import { OrderCardNavMenu } from "./OrderCardNavMenu";
import { OrderTabAttachments } from "./OrderTabAttachments";
import { OrderTabSummary } from "./OrderTabSummary";
import { EditTemplateNameModal } from "./EditTemplateNameModal";
import { DocumentModal } from "./DocumentModal";
import { useSession } from "../../hooks/useSession";
import { DeleteOrderModal } from "../../components/DeleteOrderModal";
import { OrderNotes } from "./OrderNotes";

const tabs = {
  summary: 0,
  attachments: 1,
  comments: 2,
} as const;
type TabValue = (typeof tabs)[keyof typeof tabs];
const tabsAriaLabel = "quote-tabs";

const QuotesAndOrdersDetailRoute: React.FC = () => {
  const { user } = useSession();
  const { tenantGuid, orderGuid } = useParams();
  const { setBreadcrumbs } = useBreadcrumbs(70);
  const { showErrorToast } = useGlobalToastNotificationContext();
  const { state } = useLocation();
  const [tabValue, setTabValue] = useState<TabValue>(tabs.summary);
  const [order, setOrder] = useState<Order | null>(null);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [showDocumentDownloadModal, setShowDocumentDownloadModal] =
    useState(false);
  const [boldedOrderCardGuid, setBoldedOrderCardGuid] = useState<string>();
  const [jumpToCardGuid, setJumpToCardGuid] = useState<string | null>(
    state?.jumpToCardGuid ?? null
  );
  const [isEditingTemplateName, setIsEditingTemplateName] = useState(false);
  const [isDeletingOrder, setIsDeletingOrder] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSummaryExpanded, setIsSummaryExpanded] = useState<boolean>(() => {
    const savedState = localStorage.getItem("summaryExpanded");
    return savedState ? JSON.parse(savedState) : true;
  });

  const backTo = useMemo(
    () => new URLSearchParams(window.location.search).get("backTo"),
    []
  );

  const dropzoneRef = useRef<DropzoneRef>(null);
  const navigate = useNavigate();

  const {
    data: orderData,
    request: orderRequest,
    errorMessage: orderErrorMessage,
  } = useApiRequest<Order>();

  const {
    data: saveAsOptions,
    loading: saveAsOptionsLoading,
    request: saveAsOptionsRequest,
  } = useApiRequest<Order["saveAsOptions"]>();

  const {
    data: duplicateOrderData,
    loading: duplicateOrderLoading,
    request: duplicateOrderRequest,
    errorMessage: duplicateOrderErrorMessage,
  } = useApiRequest<Order>();

  const {
    data: addChangeOrderData,
    request: addChangeOrderRequest,
    loading: addChangeOrderLoading,
    status: addChangeOrderStatus,
    errorMessage: addChangeOrderErrorMessage,
  } = useApiRequest<OrderCardSalesChangeOrder>(false);

  const {
    data: updateOrder,
    status: updateOrderStatus,
    loading: updateOrderLoading,
    request: updateOrderRequest,
    errorMessage: updateOrderErrorMessage,
  } = useApiRequest<Order>();

  // TODO: Implement convert prospect
  // const {
  //   data: convertPropspectData,
  //   request: convertProspectRequest,
  //   loading: convertProspectLoading,
  //   errorMessage: convertProspectErrorMessage,
  // } = useApiRequest<Prospect>();

  const orderName = order
    ? order.isTemplate
      ? order.templateName ?? ""
      : `ID ${order?.orderIdentifier}`
    : "";

  usePageMetadata({
    title: `${routes.quotesAndOrders.label} ${orderName}`,
  });

  useEffect(() => {
    // Clear any history state that way it does not persist on page refresh
    window.history.replaceState({}, "");
  }, []);

  useEffect(() => {
    if (user?.isBuyerMode) {
      setBreadcrumbs([]);
    } else if (order?.isTemplate) {
      setBreadcrumbs([
        {
          label: routes.manageDataTemplates.label,
          href: routes.manageDataTemplates.path,
        },
        { label: order.templateName ?? "" },
      ]);
    } else if (order?.isTemplate === false) {
      setBreadcrumbs([
        {
          label: routes.quotesAndOrders.label,
          href: tenantGuid
            ? generatePath(routes.quotesAndOrders.path, {
                tenantGuid: tenantGuid,
              })
            : undefined,
        },
        { label: order?.orderIdentifier ? `ID ${order?.orderIdentifier}` : "" },
      ]);
    }
  }, [
    order?.isTemplate,
    order?.orderIdentifier,
    order?.templateName,
    setBreadcrumbs,
    tenantGuid,
    user?.isBuyerMode,
  ]);

  const refreshOrder = useCallback(() => {
    if (orderGuid) {
      setIsLoading(true);
      orderRequest(`/orders/${orderGuid}`, { method: "GET" });
    }
  }, [orderGuid, orderRequest]);

  const refreshSaveAsOptions = useCallback(() => {
    if (orderGuid) {
      saveAsOptionsRequest(`/orders/${orderGuid}/saveasoptions`, {
        method: "GET",
      });
    }
  }, [orderGuid, saveAsOptionsRequest]);

  // TODO: Implement convert prospect
  // useEffect(() => {
  //   if (convertProspectErrorMessage) {
  //     showErrorToast({ message: convertProspectErrorMessage });
  //   }
  // }, [convertProspectErrorMessage, showErrorToast]);

  // useEffect(() => {
  //   if (convertPropspectData) {
  //     refreshOrder();
  //   }
  // }, [convertPropspectData, refreshOrder]);

  useEffect(() => {
    refreshOrder();
    refreshSaveAsOptions();
  }, [refreshOrder, refreshSaveAsOptions]);

  useEffect(() => {
    if (orderData) {
      const transformedOrder = transformOrder(orderData);
      setIsLoading(false);
      setOrder(transformedOrder);
    }
  }, [orderData]);

  useEffect(() => {
    if (updateOrder?.orderGuid && updateOrderStatus === "ok") {
      refreshOrder();
    }
  }, [refreshOrder, updateOrder, updateOrderStatus]);

  useEffect(() => {
    if (updateOrderErrorMessage) {
      showErrorToast({ message: updateOrderErrorMessage });
    }
  }, [updateOrderErrorMessage, showErrorToast]);

  useEffect(() => {
    if (duplicateOrderData?.orderGuid) {
      navigate(
        generatePath(routes.quotesAndOrdersDetail.path, {
          tenantGuid: tenantGuid ?? "",
          orderGuid: duplicateOrderData.orderGuid,
        })
      );
    }
  }, [duplicateOrderData, navigate, tenantGuid]);

  useEffect(() => {
    if (!!duplicateOrderErrorMessage) {
      showErrorToast({
        message: duplicateOrderErrorMessage,
      });
    }
  }, [duplicateOrderErrorMessage, showErrorToast]);

  useEffect(() => {
    if (orderErrorMessage) {
      showErrorToast({ message: orderErrorMessage });
    }
  }, [orderErrorMessage, showErrorToast]);

  const handleAddSalesChangeOrder = useCallback(
    (orderCard: OrderCardSalesChangeOrder) => {
      setOrder((prevOrder) => {
        if (!prevOrder) {
          return prevOrder;
        }

        const orderCards: OrderCard[] = [
          ...(prevOrder.orderCards ?? []),
          orderCard,
        ];

        const updatedOrder: Order = {
          ...prevOrder,
          orderCards,
        };
        const transformedOrder = transformOrder(updatedOrder);
        return transformedOrder;
      });
      setJumpToCardGuid(orderCard.orderCardGuid);
      refreshOrder();
    },
    [refreshOrder]
  );

  useEffect(() => {
    if (
      addChangeOrderData &&
      addChangeOrderStatus === "ok" &&
      addChangeOrderLoading === false
    ) {
      handleAddSalesChangeOrder(addChangeOrderData);
    }
  }, [
    addChangeOrderData,
    addChangeOrderLoading,
    addChangeOrderStatus,
    handleAddSalesChangeOrder,
  ]);

  useEffect(() => {
    if (addChangeOrderErrorMessage) {
      showErrorToast({ message: addChangeOrderErrorMessage });
    }
  }, [addChangeOrderErrorMessage, showErrorToast]);

  const handleSaveQuotePhaseMenuItemClick = useCallback(
    (quotePhase: OrderQuotePhase) => {
      updateOrderRequest(
        `/orders/${orderGuid}/edit`,
        {
          method: "POST",
          data: { quotePhase, orderStatus: order?.orderStatus },
        },
        {
          onSuccess: () => {
            refreshSaveAsOptions();
          },
        }
      );
    },
    [updateOrderRequest, orderGuid, order?.orderStatus, refreshSaveAsOptions]
  );

  const handleSaveOrderStatusMenuItemClick = useCallback(
    (orderStatus: OrderStatus) => {
      updateOrderRequest(
        `/orders/${orderGuid}/edit`,
        {
          method: "POST",
          data: { orderStatus: orderStatus },
        },
        {
          onSuccess: () => {
            refreshSaveAsOptions();
          },
        }
      );
    },
    [orderGuid, refreshSaveAsOptions, updateOrderRequest]
  );

  const handleTabChange = (_: React.SyntheticEvent, newTabValue: TabValue) => {
    setTabValue(newTabValue);
  };

  const handleOrderCardEdited = useCallback(
    (orderCardEditResponse: OrderCardEditResponse<OrderCard>) => {
      setOrder((prevOrder) => {
        if (!prevOrder) {
          return prevOrder;
        }

        const newOrderCards = [
          orderCardEditResponse.orderCard,
          ...orderCardEditResponse.relatedOrderCards,
        ];

        const updatedOrderCards = (prevOrder.orderCards ?? []).map(
          (orderCard) => {
            const newOrderCard = newOrderCards.find(
              (newOrderCard) =>
                newOrderCard.orderCardGuid === orderCard.orderCardGuid
            );
            return newOrderCard ? newOrderCard : orderCard;
          }
        );

        const missingOrderCards = newOrderCards.filter((newOrderCard) =>
          (prevOrder.orderCards ?? []).every(
            (o) => o.orderCardGuid !== newOrderCard.orderCardGuid
          )
        );

        const orderCards: OrderCard[] = [
          ...updatedOrderCards,
          ...missingOrderCards,
        ].sort((a, b) => a.sortOrder - b.sortOrder);

        const logicAffected = orderCardEditResponse.logicAffected
          ? orderCardEditResponse.logicAffected
          : prevOrder.logicAffected;

        const updatedOrder: Order = {
          ...prevOrder,
          orderCards,
          logicAffected,
        };

        // Refresh Save As Options if the order card is required or is:
        // - Custom Option
        // - Custom Subgroup
        // - Change Order
        if (
          orderCardEditResponse.orderCard.isRequired ||
          ["cs", "so", "co"].includes(
            orderCardEditResponse.orderCard.orderCardType
          )
        ) {
          refreshSaveAsOptions();
        }

        const transformedOrder = transformOrder(updatedOrder);
        return transformedOrder;
      });
    },
    [refreshSaveAsOptions]
  );

  const handleCurrentCardInViewChange = useCallback((orderCard: OrderCard) => {
    setBoldedOrderCardGuid(orderCard.orderCardGuid);
  }, []);

  const handleCurrentEditingOrderCardChange = useCallback(
    (orderCard: OrderCard | null) => {
      setBoldedOrderCardGuid((prevBoldedOrderCardGuid) => {
        return orderCard ? orderCard.orderCardGuid : prevBoldedOrderCardGuid;
      });
    },
    []
  );

  const handleLinkClicked = useCallback((orderCardGuid: string) => {
    setJumpToCardGuid(orderCardGuid);

    setTimeout(() => {
      setJumpToCardGuid(null);
    }, 100);
  }, []);

  const handleAttachFile = () => {
    if (tabValue === tabs.attachments) {
      dropzoneRef.current?.open();
    } else {
      // We need to open the attachments tab and wait long enough
      // for the tab to render before opening the file selector.
      setTabValue(tabs.attachments);
      setTimeout(() => {
        dropzoneRef.current?.open();
      }, 50);
    }
  };

  const handleDuplicateOrder = useCallback(() => {
    duplicateOrderRequest(`/orders/${orderGuid}/duplicate`, { method: "GET" });
  }, [duplicateOrderRequest, orderGuid]);

  const handleNewSalesChangeOrder = useCallback(() => {
    addChangeOrderRequest(`/orders/${orderGuid}/changeorders/add`, {
      method: "GET",
    });
  }, [addChangeOrderRequest, orderGuid]);

  const handleDeleteOrder = useCallback(() => {
    setIsDeletingOrder(true);
  }, []);

  const handleSaveTemplateClick = () => {
    navigate(routes.manageDataTemplates.path);
  };

  const handleEditTemplateName = () => {
    setIsEditingTemplateName(true);
  };

  const handleEditTemplateNameSuccess = (updatedOrder: Order) => {
    setIsEditingTemplateName(false);
    setOrder((prevOrder) => {
      if (!prevOrder) {
        return prevOrder;
      }
      const transformedOrder = transformOrder({
        ...prevOrder,
        templateName: updatedOrder.templateName,
      });
      return transformedOrder;
    });
  };

  const handleEditTemplateNameClose = () => {
    setIsEditingTemplateName(false);
  };

  const handleDeleteOrderClose = () => {
    setIsDeletingOrder(false);
  };

  const handleDeleteOrderSuccess = () => {
    navigate(
      generatePath(routes.quotesAndOrders.path, {
        tenantGuid: tenantGuid ?? "",
      })
    );
  };

  const handleConvertToProspectClick = () => {
    alert("Not Implemented");
    // TODO: Implement convert prospect
    // convertProspectRequest(
    //   `retailer/${user?.tenantGuid}/prospects/${order?.orderGuid}/convert`,
    //   {
    //     method: "POST",
    //   }
    // );
  };

  const handleNavigateBack = () => {
    if (user?.isBuyerMode) {
      return navigate(routes.buyerMode.path);
    } else {
      navigate(`${routes.quotesAndOrders.path}?view=${backTo ?? "kanban"}`);
    }
  };

  const handleSummaryExpandToggle = () => {
    setIsSummaryExpanded((prev) => !prev);
    localStorage.setItem("summaryExpanded", JSON.stringify(!isSummaryExpanded));
  };

  const isProspectValid = (cardData: OrderCardBusinessBuyer["cardData"]) => {
    return (
      cardData.firstName !== "" &&
      cardData.firstName != null &&
      cardData.lastName !== "" &&
      cardData.lastName != null
    );
  };

  const orderCards = useMemo(() => {
    const cards = order?.orderCards?.filter((orderCard) => {
      if (orderCard.isHidden) {
        return false;
      }

      if (
        order.orderStatus !== "p" &&
        orderCard.orderCardType === orderCardTypes.businessBuyer
      ) {
        const businessLogisticsCard = getOrderCard(
          order,
          orderCardTypes.businessLogistics
        );
        return (
          businessLogisticsCard != null &&
          isBusinessBuyerOrderCardVisible(businessLogisticsCard)
        );
      } else if (orderCard.orderCardType === orderCardTypes.subgroupOption) {
        if (orderCard.cardData.customOptionSelected) {
          return true;
        }

        // Check to see if this order card if affected by conditional logic and needs to be hidden.
        const isHidden = (order.logicAffected?.hideSubgroups ?? []).includes(
          orderCard.subgroupGuid
        );
        return !isHidden;
      } else {
        return true;
      }
    });

    return cards ?? [];
  }, [order]);

  const saveAsMenuItems = useMemo(() => {
    const saveAsMenuItemsQuotePhase: MenuItemProp[] = (
      saveAsOptions?.quotePhases ?? []
    ).map((quotePhase) => {
      return {
        label: `Quote: ${
          user?.isRetailUser
            ? quotePhase === "qpn"
              ? "Submit for Review"
              : getOrderQuotePhaseDisplayName(quotePhase)
            : getOrderQuotePhaseDisplayName(quotePhase)
        }`,
        onClick: () => handleSaveQuotePhaseMenuItemClick(quotePhase),
      };
    });

    const divider: MenuItemProp = { divider: true };

    const saveAsMenuItemsOrderStatus: MenuItemProp[] = (
      saveAsOptions?.orderStatuses ?? []
    ).reduce((acc, orderStatus) => {
      if (user?.isRetailUser && orderStatus === "i") {
        return acc;
      }
      const menuItem: MenuItemProp = {
        label: getOrderStatusDisplayName(orderStatus),
        onClick: () => handleSaveOrderStatusMenuItemClick(orderStatus),
      };
      return [...acc, menuItem];
    }, [] as MenuItemProp[]);

    return [
      ...saveAsMenuItemsQuotePhase,
      // Only show divider and statuses if there are statuses to show
      ...(saveAsMenuItemsOrderStatus.length > 0 &&
      saveAsMenuItemsQuotePhase.length > 0
        ? [divider]
        : []),
      ...saveAsMenuItemsOrderStatus,
    ];
  }, [
    handleSaveOrderStatusMenuItemClick,
    handleSaveQuotePhaseMenuItemClick,
    saveAsOptions?.orderStatuses,
    saveAsOptions?.quotePhases,
    user,
  ]);

  const infoPanels: InfoPanelProps["panels"] = useMemo(() => {
    const panels: InfoPanelProps["panels"] = [
      {
        title: "Sales Rep",
        html: (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing="4px"
          >
            {order?.assigneeUser && (
              <>
                <Avatar
                  src={getUserPhotoUrlSrc(order.assigneeUser)}
                  sx={{
                    height: 20,
                    width: 20,
                    fontSize: "10px",
                  }}
                >
                  {getUserInitials(order.assigneeUser)}
                </Avatar>
                <Typography
                  variant="body2"
                  color="text.primary"
                  fontWeight={500}
                >
                  {getUserFullName(order.assigneeUser)}
                </Typography>
              </>
            )}
          </Stack>
        ),
      },
      {
        title: "Status",
        text: order
          ? order.isTemplate
            ? "Template"
            : user?.isRetailUser && ["Scheduled For Production","In Production"].includes(getOrderStatusDisplayName(order.orderStatus)) ? "Order" : getOrderStatusDisplayName(order.orderStatus)
          : "",
      },
    ];

    if (order && !order.isTemplate && order.orderStatus === "q") {
      panels.push({
        title: "Phase",
        html: order ? (
          <Chip
            label={getOrderQuotePhaseDisplayName(order.quotePhase)}
            color="default"
            size="small"
          />
        ) : undefined,
      });
    }

    return panels;
  }, [order,user?.isRetailUser]);

  const menuItems = useMemo(() => {
    const items: MoreMenuButtonProps["menuItems"] = [
      {
        label: "Duplicate",
        disabled: duplicateOrderLoading,
        onClick: handleDuplicateOrder,
      },
    ];

    if (order && canAddChangeOrder(order) && !user?.isRetailUser) {
      items.push({
        label: "New Sales Change Order",
        disabled: addChangeOrderLoading,
        onClick: handleNewSalesChangeOrder,
      });
    }

    if (user?.isRetailUser && ["p", "q"].includes(order?.orderStatus ?? "")) {
      items.push({
        label: "Archive",
        onClick: () => handleSaveOrderStatusMenuItemClick("a"),
      });
    }

    if (user && order && canDeleteOrder(order, user)) {
      items.push({
        label: "Delete",
        onClick: handleDeleteOrder,
      });
    }

    return items;
  }, [
    addChangeOrderLoading,
    duplicateOrderLoading,
    handleDeleteOrder,
    handleDuplicateOrder,
    handleNewSalesChangeOrder,
    handleSaveOrderStatusMenuItemClick,
    order,
    user,
  ]);

  return (
    <>
      <Box
        sx={{
          position: "sticky",
          top: 64,
          backgroundColor: "grey.100",
          zIndex: 1000,
        }}
      >
        <PageHeader
          title={
            order?.isTemplate ? (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing="4px"
              >
                <Typography variant="h5" fontWeight="bold">
                  {order.templateName}
                </Typography>
                {!order.isLocked ? (
                  <IconButton
                    aria-label="edit"
                    color="primary"
                    sx={{ padding: 0 }}
                    onClick={handleEditTemplateName}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                ) : null}
              </Stack>
            ) : (
              order?.orderIdentifier
            )
          }
          actions={
            <Stack spacing={4} direction="row">
              {order?.orderStatus === "p" ? null : (
                <Stack direction="row" spacing={1}>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ padding: 1, minWidth: "initial" }}
                    onClick={() => setExpanded(true)}
                  >
                    <Expand />
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ padding: 1, minWidth: "initial" }}
                    onClick={() => setExpanded(false)}
                  >
                    <Compress />
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setShowDocumentDownloadModal(true)}
                    sx={{ padding: 1, minWidth: "initial" }}
                  >
                    <InsertDriveFileOutlinedIcon />
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      paddingLeft: 1.5,
                      paddingRight: 1.5,
                      minWidth: "initial",
                    }}
                    onClick={handleAttachFile}
                  >
                    <Stack direction={"row"}>
                      <AttachFile
                        aria-label="Attach"
                        sx={{ transform: "rotate(45deg)" }}
                      />
                      {order?.attachments?.length ?? null}
                    </Stack>
                  </Button>
                  <MoreMenuButton
                    component={Button}
                    componentProps={{
                      variant: "contained",
                      color: "secondary",
                    }}
                    menuItems={menuItems}
                  />
                </Stack>
              )}
              <Stack direction="row" spacing={1}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleNavigateBack}
                >
                  Exit
                </Button>
                {!order ? null : order.isTemplate ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveTemplateClick}
                  >
                    Save Template
                  </Button>
                ) : order.orderStatus === "p" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      updateOrderLoading ||
                      saveAsOptionsLoading ||
                      !(
                        orderCards[0]?.cardData &&
                        isProspectValid(
                          orderCards[0]
                            .cardData as OrderCardBusinessBuyer["cardData"]
                        )
                      )
                    }
                    onClick={handleConvertToProspectClick}
                  >
                    Convert to Prospect
                  </Button>
                ) : (
                  <MenuButton
                    disabled={updateOrderLoading || saveAsOptionsLoading}
                    menuItems={saveAsMenuItems}
                  >
                    Save As
                  </MenuButton>
                )}
              </Stack>
            </Stack>
          }
        />
        <InfoPanel panels={infoPanels} />
        <Divider />
      </Box>
      <Grid container spacing={3} paddingTop={3} paddingBottom={"38%"}>
        {order && order.orderStatus !== "p" && (
          <Grid width={{ xs: "100%", sm: "339px" }}>
            {order && (
              <OrderCardNavMenu
                order={order}
                orderCards={orderCards}
                boldedOrderCardGuid={boldedOrderCardGuid}
                onLinkClicked={handleLinkClicked}
              />
            )}
          </Grid>
        )}

        <Grid xs>
          {order ? (
            <OrderCardList
              order={order}
              orderCards={orderCards}
              onOrderCardEdited={handleOrderCardEdited}
              onCurrentCardInViewChange={handleCurrentCardInViewChange}
              onCurrentEditingOrderCardChange={
                handleCurrentEditingOrderCardChange
              }
              onAddSalesChangeOrder={handleAddSalesChangeOrder}
              expanded={expanded}
              refreshOrder={refreshOrder}
              jumpToCardGuid={jumpToCardGuid}
              isLoading={isLoading}
            />
          ) : null}
        </Grid>

        {order?.orderStatus !== "p" && (
          <Grid width={isSummaryExpanded ? { sm: "378px" } : 0}>
            <Box
              onClick={handleSummaryExpandToggle}
              sx={{
                position: "fixed",
                top: 214,
                marginLeft: "-5px",
                height: "100%",
                width: "5px",
                backgroundColor: "lightgrey",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "primary.main",
                },
                zIndex: 99,
              }}
            >
              <Box>
                <ArrowLeft
                  sx={{
                    backgroundColor: "lightgrey",
                    "&:hover": {
                      backgroundColor: "primary.main",
                      color: "white",
                    },
                    borderRadius: "50%",
                    position: "absolute",
                    top: 140,
                    left: -10,
                    transform: isSummaryExpanded ? "rotate(180deg)" : null,
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                position: "sticky",
                top: 220,
                paddingLeft: 2,
              }}
            >
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label={tabsAriaLabel}
              >
                <Tab
                  label="Summary"
                  {...tabA11yProps(tabsAriaLabel, tabs.summary)}
                />
                <Tab
                  label="Attachments"
                  {...tabA11yProps(tabsAriaLabel, tabs.attachments)}
                />
                <Tab
                  label="Comments"
                  {...tabA11yProps(tabsAriaLabel, tabs.comments)}
                />
              </Tabs>
              <Box
                sx={{
                  maxHeight: "calc(100vh - 260px)",
                  overflowY: "auto",
                }}
              >
                <TabPanel
                  value={tabValue}
                  index={tabs.summary}
                  tabsAriaLabel={tabsAriaLabel}
                >
                  <OrderTabSummary order={order} />
                </TabPanel>
                <TabPanel
                  value={tabValue}
                  index={tabs.attachments}
                  tabsAriaLabel={tabsAriaLabel}
                >
                  <OrderTabAttachments
                    order={order}
                    dropzoneRef={dropzoneRef}
                    onAttachmentsChanged={refreshOrder}
                  />
                </TabPanel>
                <TabPanel
                  value={tabValue}
                  index={tabs.comments}
                  tabsAriaLabel={tabsAriaLabel}
                >
                  <OrderNotes order={order} />
                </TabPanel>
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
      {order && isEditingTemplateName ? (
        <EditTemplateNameModal
          order={order}
          isOpen={isEditingTemplateName}
          onSaveSuccessful={handleEditTemplateNameSuccess}
          onClose={handleEditTemplateNameClose}
        />
      ) : null}
      {order && showDocumentDownloadModal && (
        <DocumentModal
          order={order}
          onClose={() => setShowDocumentDownloadModal(false)}
        />
      )}
      {order && isDeletingOrder && (
        <DeleteOrderModal
          order={order}
          onDeleteSuccess={handleDeleteOrderSuccess}
          onClose={handleDeleteOrderClose}
        />
      )}
    </>
  );
};

export default QuotesAndOrdersDetailRoute;
