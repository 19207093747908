import React, { useEffect } from "react";
import { Alert, Box, Button, Stack } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { Modal } from "../../components/Modal";
import InputField from "../../components/InputField";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Category } from "../../types/category";

type FormData = {
  categoryName: string;
};

type NewEditCategoryModalProps = {
  isOpen: boolean;
  onSaveSuccessful: () => void;
  onClose: () => void;
  category?: Category;
  isNew: boolean;
};

const getDefaultValues = (category?: Category): FormData => {
  return {
    categoryName: category?.categoryName ?? "",
  };
};

export const NewEditCategoryModal: React.FC<NewEditCategoryModalProps> = ({
  isOpen,
  onSaveSuccessful,
  onClose,
  category,
  isNew,
}) => {
  const {
    data: saveCategoryData,
    loading: saveCategoryLoading,
    request: saveCategoryRequest,
    status: saveCategoryStatus,
    error: saveCategoryError,
  } = useApiRequest<Category>(true);

  const { register, handleSubmit, formState } = useForm<FormData>({
    defaultValues: getDefaultValues(category),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<FormData> = (data: FormData) => {
    if (isNew) {
      saveCategoryRequest("/company/options/categories/add", {
        method: "POST",
        data,
      });
    } else {
      saveCategoryRequest(
        `/company/options/categories/${category?.categoryGuid}/edit`,
        {
          method: "POST",
          data,
        }
      );
    }
  };

  useEffect(() => {
    if (
      saveCategoryData &&
      saveCategoryStatus === "ok" &&
      saveCategoryLoading === false
    ) {
      onSaveSuccessful();
    }
  }, [
    saveCategoryData,
    onSaveSuccessful,
    saveCategoryStatus,
    saveCategoryLoading,
  ]);

  return (
    <Modal
      heading={isNew ? "New Category" : "Edit Category"}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box sx={{ width: { xs: "100%", sm: "371px" } }}>
        {saveCategoryError && (
          <Alert severity="error" sx={{ marginBottom: 2 }}>
            {saveCategoryError.response?.status === 409
              ? "A category already exists with this name."
              : "Something went wrong. Please try again."}
          </Alert>
        )}
        <Stack component="form" onSubmit={handleSubmit(onSubmit)} spacing={3}>
          <InputField
            label="Category Name"
            placeholder="Enter name"
            fullWidth
            required
            {...register("categoryName", { required: "Enter name" })}
            error={!!formState.errors.categoryName}
            helperText={formState.errors.categoryName?.message}
          />
          <Stack direction="row" justifyContent="flex-end">
            <Button
              variant="outlined"
              onClick={onClose}
              sx={{ marginRight: 2 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={saveCategoryLoading}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};
