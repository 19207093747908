import React, { useMemo } from "react";
import { Box, Button } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { Modal } from "../../components/Modal";
import { AutocompleteField } from "../../components/AutocompleteField";
import { Model } from "../../types/model";

interface ChangeModelModalProps {
  currentModelNumber: Model["modelNumber"];
  models: Model[];
  onSaveSuccessful: (modelGuid: Model["modelGuid"]) => void;
  onClose: () => void;
}

type FormData = {
  modelGuid: Model["modelGuid"] | null;
};

const getDefaultValues = (): FormData => {
  return {
    modelGuid: null,
  };
};

export const ChangeModelModal: React.FC<ChangeModelModalProps> = ({
  currentModelNumber,
  models,
  onSaveSuccessful,
  onClose,
}) => {
  const { handleSubmit, formState, control } = useForm<FormData>({
    defaultValues: getDefaultValues(),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const modelSelectionOptions = useMemo(() => {
    return models.reduce((acc, model) => {
      if (model.modelNumber !== currentModelNumber) {
        acc.push({
          value: model.modelGuid,
          label: model.modelNumber,
        });
      }
      return acc;
    }, [] as { value: string; label: string }[]);
  }, [models, currentModelNumber]);

  const onSubmit = (data: FormData) => {
    if (data.modelGuid) {
      onSaveSuccessful(data.modelGuid);
    }
  };

  return (
    <Modal heading={"Change Model"} isOpen={true} onClose={onClose}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ width: { xs: "100%", sm: "552px" } }}
      >
        <Controller
          control={control}
          name="modelGuid"
          rules={{ required: "Please select a value." }}
          render={({ field }) => {
            const selectedValue = modelSelectionOptions.find(
              (s) => s.value === field.value
            );

            return (
              <AutocompleteField
                label="Model"
                {...field}
                options={modelSelectionOptions}
                value={selectedValue ?? null}
                placeholder="Select Model Option"
                onChange={(_, value) => {
                  field.onChange(value?.value ?? null);
                }}
              />
            );
          }}
        />
        <Box sx={{ mt: 2, display: "flex", justifyContent: "end" }}>
          <Button variant="outlined" onClick={onClose} sx={{ marginRight: 2 }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={!formState.isValid}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
