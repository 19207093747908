import { useEffect, useRef } from 'react';

function useEventListener<TDocumentEventKey extends keyof DocumentEventMap>(
  element: Document | null | undefined,
  eventType: TDocumentEventKey,
  listener: (this: Document, evt: DocumentEventMap[TDocumentEventKey]) => void
): void;

function useEventListener<THtmlElementEventKey extends keyof HTMLElementEventMap>(
  element: HTMLElement | null | undefined,
  eventType: THtmlElementEventKey,
  listener: (this: HTMLElement, evt: HTMLElementEventMap[THtmlElementEventKey]) => void
): void;

function useEventListener<TWindowEvent extends keyof WindowEventMap>(
  element: Window | null | undefined,
  eventType: TWindowEvent,
  listener: (this: Window, evt: WindowEventMap[TWindowEvent]) => void
): void;

function useEventListener(
  element: Document | HTMLElement | Window | null | undefined,
  eventType: string,
  listener: (evt: Event) => void
): void;

function useEventListener<
  TDocumentEventKey extends keyof DocumentEventMap,
  THtmlElementEventKey extends keyof HTMLElementEventMap,
  TWindowEventEventKey extends keyof WindowEventMap
>(
  element: Document | HTMLElement | Window | null | undefined,
  eventType: TDocumentEventKey | THtmlElementEventKey | TWindowEventEventKey | string,
  listener: (
    this: typeof element,
    evt:
      | DocumentEventMap[TDocumentEventKey]
      | HTMLElementEventMap[THtmlElementEventKey]
      | WindowEventMap[TWindowEventEventKey]
      | Event
  ) => void
): void {
  const listenerRef = useRef(listener);

  useEffect(() => {
    listenerRef.current = listener;
  }, [listener]);

  useEffect(() => {
    if (!element) {
      return undefined;
    }

    const eventListener: typeof listenerRef.current = evt => listenerRef.current.call(element, evt);

    element.addEventListener(eventType, eventListener);

    return () => {
      element.removeEventListener(eventType, eventListener);
    };
  }, [eventType, element]);
}

export default useEventListener;
