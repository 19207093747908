export const recursiveObjectFilter = (
  obj: any,
  search: string,
  excludeGuid: boolean = true
): any => {
  if (Array.isArray(obj)) {
    for (const item of obj) {
      const result = recursiveObjectFilter(item, search, excludeGuid);
      if (result) {
        return result;
      }
    }
  } else if (typeof obj === "object" && obj !== null) {
    for (const key in obj) {
      if (!excludeGuid || !key.endsWith("Guid")) {
        const result = recursiveObjectFilter(obj[key], search, excludeGuid);
        if (result) {
          return result;
        }
      }
    }
  } else if (obj?.toString().toLowerCase().includes(search.toLowerCase())) {
    return true;
  }
  return false;
};
