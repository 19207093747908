import { Box, CircularProgress, Stack, Typography } from "@mui/material";

export const LoadingOverlay = () => (
  <Box
    sx={{
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0)",
      opacity: 0.5,
      width: "100%",
      height: "100%",
      zIndex: 999999,
    }}
  >
    <Stack
      spacing={1}
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100%", width: "100%" }}
    >
      <CircularProgress color="secondary" size={24} />
      <Typography variant="h6" color="secondary">
        Loading...
      </Typography>
    </Stack>
  </Box>
);
