import { OrderCardError } from "./orderCard";
import { OrderCardStatus, orderCardTypes } from "./orderCardTypes";

export type OrderCardInvoiceSpecifications = {
  orderCardGuid: string;
  orderCardType: (typeof orderCardTypes)["invoiceSpecifications"];
  categoryName: string;
  groupName: string;
  subgroupName: string;
  isRequired: boolean;
  isApproved: boolean;
  isHidden: undefined;
  isLocked: boolean;
  orderCardStatus: OrderCardStatus;
  sortOrder: number;
  errors: OrderCardError[];
  updatedTimestamp: number;
  humidities: InvoiceSpecificationsHumidities[];
  windZones: InvoiceSpecificationsWindZones[];
  roofLoads: InvoiceSpecificationsRoofLoads[];
  cardData: {
    certifiedBy: string | null;
    customRoofLoad: number | null;
    dateOfMfgTimestamp: number | null;
    humidity: InvoiceSpecificationsHumidities | null;
    meetsExposure: boolean;
    roofLoad: InvoiceSpecificationsRoofLoads | null;
    shuttersIncluded: boolean;
    siteConstruction: boolean;
    windZone: InvoiceSpecificationsWindZones | null;
  };
};

export const invoiceSpecificationsHumidities = [
  "Humid",
  "Fringe Humid",
  "Non Humid",
] as const;

export type InvoiceSpecificationsHumidities =
  (typeof invoiceSpecificationsHumidities)[number];

export const invoiceSpecificationsWindZones = [
  "Wind Zone 1",
  "Wind Zone 2",
  "Wind Zone 3",
] as const;

export type InvoiceSpecificationsWindZones =
  (typeof invoiceSpecificationsWindZones)[number];

export const invoiceSpecificationsRoofLoads = [
  "North - 40 PSF",
  "Middle - 30 PSF",
  "South - 20 PSF",
  "Other",
] as const;

export type InvoiceSpecificationsRoofLoads =
  (typeof invoiceSpecificationsRoofLoads)[number];

export const invoiceSpecificationsHumiditiesOptions =
  invoiceSpecificationsHumidities.map((m) => ({
    label: m,
    value: m,
  }));

export const invoiceSpecificationsWindZonesOptions =
  invoiceSpecificationsWindZones.map((m) => ({
    label: m,
    value: m,
  }));

export const invoiceSpecificationsRoofLoadsOptions =
  invoiceSpecificationsRoofLoads.map((m) => ({
    label: m,
    value: m,
  }));

export const isInvoiceSpecificationsRoofLoadsCustom = (
  roofLoad: InvoiceSpecificationsRoofLoads
): boolean => {
  return roofLoad === "Other";
};
