import React, { useEffect } from "react";
import { Alert, Box, Button, Stack } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { Modal } from "../../components/Modal";
import InputField from "../../components/InputField";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Group } from "../../types/group";
import { Category } from "../../types/category";

type FormData = {
  groupName: string;
};

type NewEditGroupModalProps = {
  isOpen: boolean;
  onSaveSuccessful: () => void;
  onClose: () => void;
  category: Category;
  group?: Group;
  isNew: boolean;
};

const getDefaultValues = (group?: Group): FormData => {
  return {
    groupName: group?.groupName ?? "",
  };
};

export const NewEditGroupModal: React.FC<NewEditGroupModalProps> = ({
  isOpen,
  onSaveSuccessful,
  onClose,
  category,
  group,
  isNew,
}) => {
  const {
    data: saveGroupData,
    loading: saveGroupLoading,
    request: saveGroupRequest,
    status: saveGroupStatus,
    error: saveGroupError,
  } = useApiRequest<Group>(true);

  const { register, handleSubmit, formState } = useForm<FormData>({
    defaultValues: getDefaultValues(group),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<FormData> = (data: FormData) => {
    if (isNew) {
      saveGroupRequest(
        `/company/options/categories/${category?.categoryGuid}/groups/add`,
        {
          method: "POST",
          data,
        }
      );
    } else {
      saveGroupRequest(`/company/options/groups/${group?.groupGuid}/edit`, {
        method: "POST",
        data,
      });
    }
  };

  useEffect(() => {
    if (
      saveGroupData &&
      saveGroupStatus === "ok" &&
      saveGroupLoading === false
    ) {
      onSaveSuccessful();
    }
  }, [saveGroupData, onSaveSuccessful, saveGroupStatus, saveGroupLoading]);

  return (
    <Modal
      heading={isNew ? "New Group" : "Edit Group"}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box sx={{ width: { xs: "100%", sm: "371px" } }}>
        {saveGroupError && (
          <Alert severity="error" sx={{ marginBottom: 2 }}>
            {saveGroupError.response?.status === 409
              ? "A group already exists with this name."
              : "Something went wrong. Please try again."}
          </Alert>
        )}
        <Stack component="form" onSubmit={handleSubmit(onSubmit)} spacing={3}>
          <InputField
            label="Group Name"
            placeholder="Enter name"
            fullWidth
            required
            {...register("groupName", { required: "Enter name" })}
            error={!!formState.errors.groupName}
            helperText={formState.errors.groupName?.message}
          />
          <Stack direction="row" justifyContent="flex-end">
            <Button
              variant="outlined"
              onClick={onClose}
              sx={{ marginRight: 2 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={saveGroupLoading}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};
