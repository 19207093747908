import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Button,
  Typography,
  Stack,
  Alert,
  FormLabel,
} from "@mui/material";
import { Modal } from "../../components/Modal";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  AutocompleteField,
  GroupedOption,
} from "../../components/AutocompleteField";
import {
  Order,
  OrderSoldAsType,
  getSoldAsTypeDisplayName,
} from "../../types/order";
import { Series } from "../../types/series";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Retailer } from "../../types/tenant";
import SwitchField from "../../components/SwitchField";
import { useSession } from "../../hooks/useSession";

type FormData = {
  modelGuid: string | null;
  retailerGuid: string | null;
  manufacturerGuid: string | null;
  soldAsType: OrderSoldAsType;
  fromTemplate: boolean;
  templateGuid?: string | null;
};

const getDefaultValues = (): FormData => {
  return {
    modelGuid: null,
    retailerGuid: null,
    manufacturerGuid: null,
    soldAsType: "r",
    fromTemplate: false,
    templateGuid: null,
  };
};

type NewModalProps = {
  isOpen: boolean;
  onSaveSuccessful: (order: Order) => void;
  onClose: () => void;
};

const NewQuoteModal: React.FC<NewModalProps> = ({
  isOpen,
  onSaveSuccessful,
  onClose,
}) => {
  const { user } = useSession();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    data: seriesData,
    loading: seriesLoading,
    request: seriesRequest,
  } = useApiRequest<Series[]>();

  const {
    data: retailersData,
    loading: retailersLoading,
    request: retailersRequest,
  } = useApiRequest<Retailer[]>();

  const {
    data: manufacturerData,
    loading: manufacturerLoading,
    request: manufacturerRequest,
  } = useApiRequest<Retailer[]>();

  const {
    data: orderData,
    loading: orderLoading,
    request: orderRequest,
    status: orderStatus,
    error: orderError,
  } = useApiRequest<Order>();

  const {
    data: templateData,
    loading: templateLoading,
    request: templateRequest,
    errorMessage: templateErrorMessage,
  } = useApiRequest<Order[]>();

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
    watch,
    setValue,
  } = useForm<FormData>({
    defaultValues: getDefaultValues(),
  });

  const soldAsType = watch("soldAsType");
  const fromTemplate = watch("fromTemplate");
  const templateGuid = watch("templateGuid");
  const manufacturerGuid = watch("manufacturerGuid");

  useEffect(() => {
    seriesRequest(
      user?.isRetailUser
        ? `retailer/${manufacturerGuid}/series`
        : "/quotes/series",
      {
        method: "GET",
      }
    );
  }, [seriesRequest, user?.isRetailUser, manufacturerGuid]);

  useEffect(() => {
    if (user?.isRetailUser) {
      return;
    }
    retailersRequest("/quotes/retailers", {
      method: "GET",
    });
  }, [retailersRequest, user?.isRetailUser]);

  useEffect(() => {
    manufacturerRequest("/retailer/manufacturers", {
      method: "GET",
    });
  }, [manufacturerRequest]);

  useEffect(() => {
    if (user?.isRetailUser && !manufacturerGuid) {
      return;
    }
    templateRequest(
      user?.isRetailUser
        ? `retailer/${manufacturerGuid}/templates`
        : "/company/templates",
      {
        method: "GET",
      }
    );
  }, [templateRequest, user?.isRetailUser, manufacturerGuid]);

  const templateModelGuid = useMemo(() => {
    if (!templateGuid || !templateData) return null;
    const template = templateData.find((t) => t.orderGuid === templateGuid);
    return template?.model.modelGuid ?? null;
  }, [templateGuid, templateData]);

  const templateRetailerGuid = useMemo(() => {
    if (!templateGuid || !templateData) return null;
    const template = templateData.find((t) => t.orderGuid === templateGuid);
    return template?.retailer?.tenantGuid ?? null;
  }, [templateGuid, templateData]);

  useEffect(() => {
    if (fromTemplate && templateGuid) {
      setValue("modelGuid", templateModelGuid);

      if (soldAsType === "r" || soldAsType === "s") {
        setValue("retailerGuid", templateRetailerGuid);
      }
    }
  });

  type GroupedOptionSelectionOption = GroupedOption<string> & {};

  const modelSelectionOptions = useMemo(() => {
    const options: Array<GroupedOptionSelectionOption> = [];

    seriesData?.forEach((series) => {
      series.models.forEach((model) => {
        options.push({
          label: `Model ${model.modelNumber}`,
          value: model.modelGuid,
          groupValue: series.seriesGuid,
          groupLabel: series.seriesName,
        });
      });
    });

    return options;
  }, [seriesData]);

  const retailerSelectionOptions = useMemo(() => {
    if (!retailersData) return [];
    return retailersData.map((retailer) => ({
      label: `${retailer.tenantName}`,
      citystate:
        retailer.tenantCity && retailer.tenantState
          ? `${retailer.tenantCity}, ${retailer.tenantState}`
          : "",
      value: retailer.tenantGuid,
    }));
  }, [retailersData]);

  const manufacturerSelectionOptions = useMemo(() => {
    if (!manufacturerData) return [];
    return manufacturerData.map((mfg) => ({
      label: `${mfg.tenantName}`,
      citystate:
        mfg.tenantCity && mfg.tenantState
          ? `${mfg.tenantCity}, ${mfg.tenantState}`
          : "",
      value: mfg.tenantGuid,
    }));
  }, [manufacturerData]);

  const templateSelectionOptions = useMemo(() => {
    if (!templateData) return [];
    return templateData.map((template) => ({
      label: template.templateName ?? "",
      value: template.orderGuid,
    }));
  }, [templateData]);

  const showRetailer = useMemo(() => {
    return !user?.isRetailUser && ["r", "s"].includes(soldAsType);
  }, [soldAsType, user?.isRetailUser]);

  const onSubmit: SubmitHandler<FormData> = (data: FormData) => {
    const cleanData = {
      ...data,
      retailerGuid: showRetailer ? data.retailerGuid : null,
    };

    orderRequest(
      user?.isRetailUser
        ? `/retailer/${manufacturerGuid}/orders/add`
        : "/orders/add",
      {
        method: "POST",
        data: cleanData,
      }
    );
  };

  useEffect(() => {
    if (orderError) {
      setErrorMessage(
        "An error occurred while saving quote. Please try again."
      );
    }
  }, [orderError]);

  useEffect(() => {
    if (orderData && orderStatus === "ok") {
      setErrorMessage(null);
      onSaveSuccessful(orderData);
    }
  }, [orderStatus, onSaveSuccessful, orderData]);

  return (
    <Modal heading={"New Quote"} isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ width: 300 }}>
          {errorMessage && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {errorMessage}
            </Alert>
          )}
          {templateErrorMessage && (
            <Alert severity="error" sx={{ mb: 2 }}>
              An error occurred while fetching templates. Please try again.
            </Alert>
          )}
          {user?.isRetailUser && (
            <Box sx={{ mb: 2 }}>
              <Controller
                control={control}
                name="manufacturerGuid"
                rules={{ required: "Please select a value." }}
                render={({ field }) => {
                  const selectedValue = manufacturerSelectionOptions.find(
                    (s) => s.value === field.value
                  );

                  return (
                    <AutocompleteField
                      label="Manufacturer"
                      {...field}
                      disabled={manufacturerLoading}
                      options={manufacturerSelectionOptions}
                      value={selectedValue ?? null}
                      placeholder="Select Manufacturer"
                      onChange={(_, value) => {
                        field.onChange(value?.value ?? null);
                      }}
                      renderOption={(props, option) => (
                        <Box p={1} component="li" {...props}>
                          <Stack
                            spacing={1}
                            direction="row"
                            alignContent="center"
                          >
                            <Typography variant="body1">
                              {option.label}
                            </Typography>
                            <Typography variant="body2" color="gray" pt={0.3}>
                              {option.citystate}
                            </Typography>
                          </Stack>
                        </Box>
                      )}
                    />
                  );
                }}
              />
            </Box>
          )}
          {(user?.isRetailUser && manufacturerGuid == null) ||
            (templateSelectionOptions.length > 0 && (
              <Controller
                name="fromTemplate"
                control={control}
                render={({ field }) => (
                  <SwitchField
                    {...field}
                    disabled={user?.isRetailUser && manufacturerGuid == null}
                    label="From Template"
                    checked={field.value}
                    sx={{ mb: 3 }}
                  />
                )}
              />
            ))}
          {fromTemplate && (
            <Box sx={{ mb: 2 }}>
              <Controller
                control={control}
                name="templateGuid"
                rules={{ required: "Please select a value." }}
                render={({ field }) => {
                  const selectedValue = templateSelectionOptions.find(
                    (s) => s.value === field.value
                  );

                  return (
                    <AutocompleteField
                      label="Template"
                      {...field}
                      disabled={templateLoading || !!templateErrorMessage}
                      options={templateSelectionOptions}
                      value={selectedValue ?? null}
                      placeholder="Select Template"
                      onChange={(_, value) => {
                        field.onChange(value?.value ?? null);
                      }}
                    />
                  );
                }}
              />
            </Box>
          )}
          <Controller
            control={control}
            name="modelGuid"
            rules={{ required: "Please select a value." }}
            render={({ field }) => {
              const selectedValue = modelSelectionOptions.find(
                (s) => s.value === field.value
              );

              return (
                <AutocompleteField
                  label="Model"
                  {...field}
                  disabled={
                    seriesLoading ||
                    fromTemplate ||
                    (user?.isRetailUser && manufacturerGuid == null)
                  }
                  options={modelSelectionOptions}
                  value={selectedValue ?? null}
                  placeholder="Select Model Option"
                  onChange={(_, value) => {
                    field.onChange(value?.value ?? null);
                  }}
                />
              );
            }}
          />
          <Box sx={{ my: 2 }}>
            <Controller
              name="soldAsType"
              control={control}
              render={({ field }) => (
                <>
                  <FormLabel component="legend">Sold As</FormLabel>
                  <ToggleButtonGroup
                    fullWidth
                    value={field.value}
                    onChange={(_, value) => {
                      if (value == null) return;
                      return value != null && field.onChange(value);
                    }}
                    exclusive
                  >
                    <ToggleButton value="r" sx={{ fontSize: 12 }}>
                      {getSoldAsTypeDisplayName("r")}
                    </ToggleButton>
                    <ToggleButton value="s" sx={{ fontSize: 12 }}>
                      {getSoldAsTypeDisplayName("s")}
                    </ToggleButton>
                    {!user?.isRetailUser && (
                      <ToggleButton value="y" sx={{ fontSize: 12 }}>
                        {getSoldAsTypeDisplayName("y")}
                      </ToggleButton>
                    )}
                  </ToggleButtonGroup>
                </>
              )}
            />
          </Box>
          {showRetailer && (
            <Box sx={{ my: 2 }}>
              <Controller
                control={control}
                name="retailerGuid"
                rules={{ required: "Please select a value." }}
                render={({ field }) => {
                  const selectedValue = retailerSelectionOptions.find(
                    (s) => s.value === field.value
                  );

                  return (
                    <AutocompleteField
                      label="Retailer"
                      {...field}
                      disabled={retailersLoading}
                      options={retailerSelectionOptions}
                      value={selectedValue ?? null}
                      placeholder="Select Retailer"
                      onChange={(_, value) => {
                        field.onChange(value?.value ?? null);
                      }}
                      renderOption={(props, option) => (
                        <Box p={1} component="li" {...props}>
                          <Stack
                            spacing={1}
                            direction="row"
                            alignContent="center"
                          >
                            <Typography variant="body1">
                              {option.label}
                            </Typography>
                            <Typography variant="body2" color="gray" pt={0.3}>
                              {option.citystate}
                            </Typography>
                          </Stack>
                        </Box>
                      )}
                    />
                  );
                }}
              />
            </Box>
          )}
          <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="outlined"
              onClick={onClose}
              fullWidth
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isDirty || !isValid || orderLoading}
              fullWidth
              sx={{ ml: 1 }}
            >
              Create
            </Button>
          </Box>
        </Box>
      </form>
    </Modal>
  );
};

export default NewQuoteModal;
