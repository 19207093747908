import { OrderCardError } from "./orderCard";
import { OrderCardStatus, orderCardTypes } from "./orderCardTypes";

export type OrderCardInvoiceHeatingCoolingDucts = {
  orderCardGuid: string;
  orderCardType: (typeof orderCardTypes)["invoiceHeatingCoolingDucts"];
  categoryName: string;
  groupName: string;
  subgroupName: string;
  isRequired: boolean;
  isApproved: boolean;
  isHidden: undefined;
  isLocked: boolean;
  orderCardStatus: OrderCardStatus;
  sortOrder: number;
  errors: OrderCardError[];
  updatedTimestamp: number;
  cooling: InvoiceHeatingCoolingDuctsCooling[];
  heating: InvoiceHeatingCoolingDuctsHeating[];
  cardData: {
    adCeiling: number | null;
    adFloor: number | null;
    adOutside: number | null;
    cooling: InvoiceHeatingCoolingDuctsCooling | null;
    ductMax: number | null;
    heating: InvoiceHeatingCoolingDuctsHeating | null;
    minOutdoorTemp: number | null;
    winterDesignTemp: number | null;
  };
};

export const invoiceHeatingCoolingDuctsCooling = [
  "A/C Provided",
  "A/C Not Provided",
] as const;

export type InvoiceHeatingCoolingDuctsCooling =
  (typeof invoiceHeatingCoolingDuctsCooling)[number];

export const invoiceHeatingCoolingDuctsHeating = [
  "U/O Value Zone 1",
  "U/O Value Zone 2",
  "U/O Value Zone 3",
] as const;

export type InvoiceHeatingCoolingDuctsHeating =
  (typeof invoiceHeatingCoolingDuctsHeating)[number];

export const invoiceHeatingCoolingDuctsCoolingOptions =
  invoiceHeatingCoolingDuctsCooling.map((m) => ({
    label: m,
    value: m,
  }));

export const invoiceHeatingCoolingDuctsHeatingOptions =
  invoiceHeatingCoolingDuctsHeating.map((m) => ({
    label: m,
    value: m,
  }));
