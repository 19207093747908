import { Tenant } from "./tenant";
import { Model } from "./model";
import { User } from "./user";
import { Attachment } from "./attachment";
import { OrderCard } from "./orderCard";
import { MenuItem } from "./menuItem";
import { LogicAffected } from "./logicAffected";
import { OrderCardBusinessBuyer } from "./orderCardBusinessBuyer";
import { OrderCardBusinessHome } from "./orderCardBusinessHome";
import { OrderCardBusinessLogistics } from "./orderCardBusinessLogistics";
import { OrderCardSubgroupOption } from "./orderCardSubgroupOption";
import { OrderCardCustomSubgroup } from "./orderCardCustomSubgroup";
import { OrderCardFinancialPricing } from "./orderCardFinancialPricing";
import { OrderCardFinancialFinancing } from "./orderCardFinancialFinancing";
import { OrderCardFinancialFees } from "./orderCardFinancialFees";
import { OrderCardType, orderCardTypes } from "./orderCardTypes";
import { OrderCardInvoiceDates } from "./orderCardInvoiceDates";
import { OrderCardHeatUValues } from "./orderCardHeatUValues";
import { OrderCardAppliance } from "./orderCardAppliance";
import { OrderCardInvoiceSection } from "./orderCardInvoiceSection";
import { OrderCardInvoiceSpecifications } from "./orderCardInvoiceSpecifications";
import { OrderCardInvoiceHeatingCoolingDucts } from "./orderCardInvoiceHeatingCoolingDucts";
import { OrderCardInvoiceCeilingInsulation } from "./orderCardInvoiceCeilingInsulation";
import { OrderCardInvoiceExteriorWallInsulation } from "./orderCardInvoiceExteriorWallInsulation";
import { OrderCardInvoiceFloorInsulation } from "./orderCardInvoiceFloorInsulation";
import { getOrderCardAddAppliance } from "./orderCardAddAppliance";
import { getOrderCardAddSalesChangeOrder } from "./orderCardAddSalesChangeOrder";
import { UserRole, UserRoles } from "./userRole";

export type Order = {
  orderGuid: string;
  orderIdentifier: string;
  soldAsType: "r" | "s" | "y";
  orderStatus: "p" | "q" | "o" | "f" | "i" | "c" | "v" | "s" | "a";
  quotePhase: "qpd" | "qpp" | "qpn" | "qpo";
  basePrice: number;
  calculatedVinNumber: string;
  retailer: Tenant | null;
  model: Model;
  assigneeUser: User;
  createdTimestamp: number;
  confirmedTimestamp: number | null;
  invoiceNumber: number | null;
  isLocked: boolean;
  isSold: boolean;
  errors: null;
  attachments: Attachment[] | null;
  orderCards: OrderCard[] | null;
  saveAsOptions: {
    quotePhases: OrderQuotePhase[];
    orderStatuses: OrderStatus[];
  };
  optionsPrice: number | null;
  logicAffected: LogicAffected | null;
  isTemplate: boolean;
  templateName: string | null;
  updatedTimestamp: number;
  materialSurcharge: number;
  feesTotal: number;
  buyerLastName: string | null;
  invoiceTotal: number;
  buyerPrice: number;
  createdByText: string;
  mfgTenantName: string;
};

export type Inventory = Order;
export type Prospect = Order;

export type OrderSoldAsType = Order["soldAsType"];
export type OrderStatus = Order["orderStatus"];
export type OrderQuotePhase = Order["quotePhase"];

export const getSoldAsTypeDisplayName = (type: OrderSoldAsType): string => {
  const displayName: Record<OrderSoldAsType, string> = {
    r: "Retailer",
    s: "Stock",
    y: "Yard",
  };
  return displayName[type];
};

export const getOrderStatusDisplayName = (orderStatus: OrderStatus): string => {
  const displayName: Record<OrderStatus, string> = {
    p: "Prospects",
    q: "Quote",
    o: "Order",
    f: "Scheduled For Production",
    i: "In Production",
    c: "Complete",
    v: "Invoiced",
    s: "Shipped",
    a: "Archived",
  };
  return displayName[orderStatus];
};

export const getOrderQuotePhaseDisplayName = (
  quotePhase: OrderQuotePhase
): string => {
  const displayName: Record<OrderQuotePhase, string> = {
    qpd: "Draft",
    qpp: "Pending Retail",
    qpn: "Pending Mfg",
    qpo: "Order Ready",
  };
  return displayName[quotePhase];
};

export const getOrderStatusColumnsDisplayNameList = (): string[] => {
  return [
    getOrderStatusDisplayName("q"),
    getOrderStatusDisplayName("o"),
    getOrderStatusDisplayName("f"),
    getOrderStatusDisplayName("i"),
    getOrderStatusDisplayName("c"),
    getOrderStatusDisplayName("s"),
    getOrderStatusDisplayName("a"),
  ];
};

export const getOrderQoutePhaseMenuItems = (): MenuItem[] => {
  return [
    { label: getOrderQuotePhaseDisplayName("qpd"), value: "qpd" },
    { label: getOrderQuotePhaseDisplayName("qpp"), value: "qpp" },
    { label: getOrderQuotePhaseDisplayName("qpn"), value: "qpn" },
    { label: getOrderQuotePhaseDisplayName("qpo"), value: "qpo" },
  ];
};

export const getOrderPrice = (order: Order) => {
  return (order?.basePrice ?? 0) + (order?.optionsPrice ?? 0);
};

export const getOrderVinNumberSuffix = (order: Order): "A&B" | "A&B&C" | "" => {
  const numModelSections = order?.model.sections.length ?? 0;
  const vinNumberSuffix =
    numModelSections === 2 ? "A&B" : numModelSections === 3 ? "A&B&C" : "";

  return vinNumberSuffix;
};

export const canAddChangeOrder = (order: Pick<Order, "orderStatus">) => {
  const requiredOrderStatuses: OrderStatus[] = ["o", "f", "i"];

  return requiredOrderStatuses.includes(order.orderStatus);
};

export function getOrderCard(
  order: Order | null,
  orderCardType: (typeof orderCardTypes)["businessLogistics"]
): OrderCardBusinessLogistics | undefined;
export function getOrderCard(
  order: Order | null,
  orderCardType: (typeof orderCardTypes)["businessBuyer"]
): OrderCardBusinessBuyer | undefined;
export function getOrderCard(
  order: Order | null,
  orderCardType: (typeof orderCardTypes)["businessHome"]
): OrderCardBusinessHome | undefined;
export function getOrderCard(
  order: Order | null,
  orderCardType: (typeof orderCardTypes)["subgroupOption"]
): OrderCardSubgroupOption | undefined;
export function getOrderCard(
  order: Order | null,
  orderCardType: (typeof orderCardTypes)["customSubgroup"]
): OrderCardCustomSubgroup | undefined;
export function getOrderCard(
  order: Order | null,
  orderCardType: (typeof orderCardTypes)["financialPricing"]
): OrderCardFinancialPricing | undefined;
export function getOrderCard(
  order: Order | null,
  orderCardType: (typeof orderCardTypes)["financialFinancing"]
): OrderCardFinancialFinancing | undefined;
export function getOrderCard(
  order: Order | null,
  orderCardType: (typeof orderCardTypes)["financialFees"]
): OrderCardFinancialFees | undefined;
export function getOrderCard(
  order: Order | null,
  orderCardType: (typeof orderCardTypes)["invoiceDates"]
): OrderCardInvoiceDates | undefined;
export function getOrderCard(
  order: Order | null,
  orderCardType: (typeof orderCardTypes)["invoiceSection"]
): OrderCardInvoiceSection | undefined;
export function getOrderCard(
  order: Order | null,
  orderCardType: (typeof orderCardTypes)["invoiceAppliances"]
): OrderCardAppliance | undefined;
export function getOrderCard(
  order: Order | null,
  orderCardType: (typeof orderCardTypes)["heatUValue"]
): OrderCardHeatUValues | undefined;
export function getOrderCard(
  order: Order | null,
  orderCardType: (typeof orderCardTypes)["invoiceSpecifications"]
): OrderCardInvoiceSpecifications | undefined;
export function getOrderCard(
  order: Order | null,
  orderCardType: (typeof orderCardTypes)["invoiceHeatingCoolingDucts"]
): OrderCardInvoiceHeatingCoolingDucts | undefined;
export function getOrderCard(
  order: Order | null,
  orderCardType: (typeof orderCardTypes)["invoiceFloorInsulation"]
): OrderCardInvoiceFloorInsulation | undefined;
export function getOrderCard(
  order: Order | null,
  orderCardType: (typeof orderCardTypes)["invoiceExteriorWallInsulation"]
): OrderCardInvoiceExteriorWallInsulation | undefined;
export function getOrderCard(
  order: Order | null,
  orderCardType: (typeof orderCardTypes)["invoiceCeilingInsulation"]
): OrderCardInvoiceCeilingInsulation | undefined;

export function getOrderCard(
  order: Order | null,
  orderCardType: OrderCardType
) {
  return order?.orderCards?.find((orderCard) => {
    return orderCard.orderCardType === orderCardType;
  });
}

export function transformOrder(order: Order): Order {
  const updatedOrderCards = transformOrderCards(order);

  const updatedOrder: Order = {
    ...order,
    orderCards: updatedOrderCards,
  };
  return updatedOrder;
}

export function transformOrderCards(order: Order): OrderCard[] | null {
  if (!order.orderCards) {
    return null;
  }

  // Make sure cards are sorted
  let updatedOrderCards: OrderCard[] = [...order.orderCards].sort(
    (a, b) => a.sortOrder - b.sortOrder
  );

  // Check to see if we should add the "Add Appliances" Order Card.
  const isAddApplianceOrderCardVisible = order.orderStatus === "i";

  if (isAddApplianceOrderCardVisible) {
    const isAlreadyAdded = updatedOrderCards.some(
      (oc) =>
        oc.orderCardType === orderCardTypes.addAppliance ||
        oc.orderCardType === orderCardTypes.invoiceAppliances
    );
    if (!isAlreadyAdded) {
      const orderCardAddAppliance = getOrderCardAddAppliance();

      const firstChangeOrderCardIndex = updatedOrderCards.findIndex(
        (oc) =>
          oc.orderCardType === orderCardTypes.addSalesChangeOrder ||
          oc.orderCardType === orderCardTypes.salesChangeOrder
      );

      // If we have a change order card then this needs to be before it.
      if (firstChangeOrderCardIndex !== -1) {
        updatedOrderCards = [
          ...updatedOrderCards.slice(0, firstChangeOrderCardIndex),
          orderCardAddAppliance,
          ...updatedOrderCards.slice(firstChangeOrderCardIndex),
        ];
      } else {
        updatedOrderCards.push(orderCardAddAppliance);
      }
    }
  }

  // Check to see if we should add the "Add Sales Change Order" Order Card.
  const canAddSalesChangeOrder = canAddChangeOrder(order);
  if (canAddSalesChangeOrder) {
    const isAlreadyAdded = updatedOrderCards?.some(
      (oc) =>
        oc.orderCardType === orderCardTypes.addSalesChangeOrder ||
        oc.orderCardType === orderCardTypes.salesChangeOrder
    );
    if (!isAlreadyAdded) {
      const orderCardAddSalesChangeOrder = getOrderCardAddSalesChangeOrder();
      updatedOrderCards.push(orderCardAddSalesChangeOrder);
    }
  }

  updatedOrderCards = updatedOrderCards.map((oc, index) => ({
    ...oc,
    sortOrder: index,
  }));

  return updatedOrderCards;
}

export const canDeleteOrder = (order: Order, user: Pick<User, "userRole">) => {
  const rolesThatCanDelete: UserRoles[] = [UserRole.ADMIN, UserRole.OWNER];
  const statusesThatCanDelete: OrderStatus[] = ["q", "a", "p"];

  return (
    rolesThatCanDelete.includes(user.userRole) &&
    statusesThatCanDelete.includes(order.orderStatus) &&
    order.confirmedTimestamp === null
  );
};
