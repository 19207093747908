import { RetailerInventoryView } from "../RetailerInventoryView";
import { useSession } from "../../hooks/useSession";

export default function RetailerInventory() {
  const { user } = useSession();

  return user?.tenantGuid ? (
    <RetailerInventoryView retailerGuid={user?.tenantGuid} />
  ) : null;
}
