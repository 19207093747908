export enum VendorType {
  CommonCarrier = "Common Carrier",
  FloorPlanLender = "Floor Plan Lender",
  Furniture = "Furniture",
  ServiceTrimOutSub = "Service / Trim-Out Sub",
  SupplierAppliances = "Supplier - Appliances",
  SupplierInventory = "Supplier - Inventory",
  TransportationUnitHauler = "Transportation (Unit Hauler)",
}

export type Vendor = {
  vendorGuid: string;
  vendorName: string;
  vendorIdentifier: string;
  isActive: boolean;
  vendorType: VendorType;
  vendorAddress: string;
  vendorCity: string;
  vendorState: string;
  vendorZip: string;
};

export const vendorTypes = Object.values(VendorType);

export const vendorTypeOptions = Object.values(VendorType).map((value) => ({
  label: value,
  value: value,
}));
