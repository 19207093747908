import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import { routes } from "../../routes";
import { useForm } from "react-hook-form";
import { PageHeader } from "../../components/PageHeader";
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Option } from "../../types/option";
import { FormData, FormOptionPricing } from "./FormData";
import { useApiRequest } from "../../hooks/useApiRequest";
import {
  generatePath,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
import { Subgroup } from "../../types/subgroup";
import { OptionDetailsFormSection } from "./OptionDetailsFormSection";
import { DateTime } from "luxon";
import { OptionPricingFormSection } from "./OptionPricingFormSection";
import { NewEditAttributeModal } from "./AddEditAttributeModal";
import { Attribute } from "../../types/attribute";
import { AttributeRemoveConfirmModal } from "./AttributeRemoveConfirmModal";
import { AttributeCard } from "./AttributeCard";
import { NewEditAttributeSelectionModal } from "../ManageAttributesDetailRoute/NewEditAttributeSelectionModal";
import { AttributeSelection } from "../../types/attributeSelection";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";
import { DraggableList } from "../../components/DraggableList";
import { updateSortOrder } from "../../utils/array";
import { OptionLogicsModal } from "./OptionLogicsModal";
import { AttributeSelectionDiscontinueModal } from "../../components/AttributeSelectionDiscontinueModal";

const defaultPricingOptions: FormOptionPricing[] = [
  {
    type: "a",
    price: null,
    startDate: DateTime.local(),
    endDate: null,
  },
  {
    type: "sw",
    price: null,
    startDate: DateTime.local(),
    endDate: null,
  },
  {
    type: "dw",
    price: null,
    startDate: DateTime.local(),
    endDate: null,
  },
  {
    type: "tw",
    price: null,
    startDate: DateTime.local(),
    endDate: null,
  },
  {
    type: "m",
    price: null,
    startDate: DateTime.local(),
    endDate: null,
  },
];

const getDefaultValues = (option: Option | null): FormData => {
  return {
    optionName: option?.optionName || "",
    optionRooms: option?.optionRooms || [],
    series: option?.series || [],
    optionIsUpgrade: option?.optionIsUpgrade || false,
    optionMinimum: option?.optionMinimum || null,
    optionMaximum: option?.optionMaximum || null,
    optionDisclaimer: option?.optionDisclaimer || "",
    optionPricedPer: option?.optionPricedPer || "",
    optionPricedFor: option?.optionPricedFor || "a",
    optionPricing:
      option?.optionPricing.map((pricing) => ({
        ...pricing,
        startDate: DateTime.fromSeconds(pricing.startDate),
        endDate: pricing.endDate ? DateTime.fromSeconds(pricing.endDate) : null,
      })) || defaultPricingOptions.slice(0, 1),
    startDate: option?.startDate
      ? DateTime.fromSeconds(option?.startDate)
      : null,
    endDate: option?.endDate ? DateTime.fromSeconds(option?.endDate) : null,
    uniqueAttributesPerRoom: option?.uniqueAttributesPerRoom ?? false,
  };
};

const ManageSelectionOptionsAddEditRoute: React.FC = () => {
  const [attributes, setAttributes] = useState<Attribute[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [expanded, setExpanded] = useState<boolean>(true);
  const [expandedAttributes, setExpandedAttributes] = useState<
    Record<string, boolean>
  >({});
  const [editingAttribute, setEditingAttribute] = useState<Attribute | null>(
    null
  );
  const [attributeToRemove, setAttributeToRemove] = useState<Attribute | null>(
    null
  );
  const [
    editingAttributeSelectionAttributeGuid,
    setEditingAttributeSelectionAttributeGuid,
  ] = useState<string | null>(null);
  const [
    editingAttributeSelectionSelectionGuid,
    setEditingAttributeSelectionSelectionGuid,
  ] = useState<string>();
  const [
    discontinuingAttributeSelectionSelection,
    setDiscontinuingAttributeSelection,
  ] = useState<AttributeSelection | null>(null);
  const [isLogicModalOpen, setIsLogicModalOpen] = useState(false);

  const { setBreadcrumbs } = useBreadcrumbs();
  const { subgroupGuid, optionGuid } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const isAddRoute = useMemo(
    () => location.pathname.endsWith("/add"),
    [location.pathname]
  );

  const { showSuccessToast, showErrorToast } =
    useGlobalToastNotificationContext();

  const { data: subgroupData, request: subgroupRequest } =
    useApiRequest<Subgroup>(false);

  const {
    data: optionData,
    request: optionRequest,
    loading: optionLoading,
    status: optionStatus,
  } = useApiRequest<Option>(false);

  const {
    data: optionCreateData,
    request: optionCreateUpdateRequest,
    loading: optionCreateUpdateLoading,
    status: optionCreateUpdateStatus,
  } = useApiRequest<Option>(false);

  const {
    loading: removeAttributeLoading,
    status: removeAttributeStatus,
    request: removeAttributeRequest,
  } = useApiRequest<Attribute>(false);

  const {
    data: attributeSelectionDiscontinueData,
    loading: attributeSelectionDiscontinueLoading,
    status: attributeSelectionDiscontinueStatus,
    error: attributeSelectionDiscontinueError,
    request: attributeSelectionDiscontinueRequest,
  } = useApiRequest<AttributeSelection>(false);

  useEffect(() => {
    if (optionData) {
      setAttributes(optionData.attributes);
    }
  }, [optionData]);

  const { request: updateSortOrderRequest } = useApiRequest<[]>(false);

  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    trigger,
    formState: { isDirty, isSubmitted, isValid, errors },
    watch,
  } = useForm<FormData>({
    defaultValues: getDefaultValues(optionData),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const optionName = watch("optionName");
  const optionRooms = watch("optionRooms");
  const optionPricedPer = watch("optionPricedPer");
  usePageMetadata({
    title: optionGuid ? "Edit Option" : "Add Option",
  });
  const series = watch("series");

  const refreshOption = useCallback(() => {
    if (optionGuid) {
      optionRequest(`company/options/${optionGuid}`, {
        method: "GET",
      });
    }
  }, [optionGuid, optionRequest]);

  // Request list of subgroups
  useEffect(() => {
    if (!subgroupGuid) {
      return;
    }
    subgroupRequest(`/company/options/subgroups/${subgroupGuid}`, {
      method: "GET",
    });
  }, [subgroupGuid, subgroupRequest]);

  // Load option data if editing
  useEffect(() => {
    refreshOption();
  }, [refreshOption]);

  // Set breadcrumbs
  useEffect(() => {
    if (!subgroupData) {
      return;
    }
    setBreadcrumbs([
      {
        label: routes.manageDataSelectionOptionsSubGroup.label,
        href: generatePath(routes.manageDataSelectionOptionsSubGroup.path, {
          subgroupGuid: subgroupData.subgroupGuid,
        }),
      },
      {
        label: subgroupData?.subgroupName || "",
        href: generatePath(routes.manageDataSelectionOptionsSubGroup.path, {
          subgroupGuid: subgroupData.subgroupGuid,
        }),
      },
      {
        label: optionData?.optionName ? `Edit Option` : "Add Option",
      },
    ]);
  }, [setBreadcrumbs, optionData, subgroupData]);

  // Set default values once we load the option data
  useEffect(() => {
    if (optionData && optionStatus === "ok" && optionLoading === false) {
      const defaultValues = getDefaultValues(optionData);
      reset(defaultValues, { keepDirtyValues: true });
    }
  }, [optionLoading, optionStatus, optionData, reset]);

  // If we are editing an attribute selection, update the attribute and selection
  // to the latest data
  const editingAttributeSelectionAttribute: Attribute | undefined =
    useMemo(() => {
      const attribute = optionData?.attributes?.find(
        (a) => a.attributeGuid === editingAttributeSelectionAttributeGuid
      );
      return attribute;
    }, [editingAttributeSelectionAttributeGuid, optionData?.attributes]);

  const editingAttributeSelectionSelection: AttributeSelection | undefined =
    useMemo(() => {
      const attributeSelection =
        editingAttributeSelectionAttribute?.attributeselections.find(
          (s) =>
            s.attributeselectionGuid === editingAttributeSelectionSelectionGuid
        );
      return attributeSelection;
    }, [
      editingAttributeSelectionAttribute?.attributeselections,
      editingAttributeSelectionSelectionGuid,
    ]);

  useEffect(() => {
    if (
      attributeSelectionDiscontinueData &&
      attributeSelectionDiscontinueStatus === "ok" &&
      attributeSelectionDiscontinueLoading === false
    ) {
      refreshOption();
    } else if (attributeSelectionDiscontinueError) {
      showErrorToast({ message: "Something went wrong. Please try again.." });
    }
  }, [
    attributeSelectionDiscontinueData,
    attributeSelectionDiscontinueError,
    attributeSelectionDiscontinueLoading,
    attributeSelectionDiscontinueStatus,
    refreshOption,
    showErrorToast,
  ]);

  // Redirect to the edit route if the option was created successfully
  useEffect(() => {
    if (optionCreateUpdateStatus === "ok" && optionCreateData && isSubmitted) {
      const defaultValues = getDefaultValues(optionData);
      reset(defaultValues, { keepDirtyValues: false });

      // If this is a new option, redirect to the edit page
      // otherwise redirect to the subgroup page
      if (isAddRoute) {
        navigate(
          generatePath(routes.manageDataSelectionOptionsEdit.path, {
            subgroupGuid: subgroupGuid!,
            optionGuid: optionCreateData.optionGuid,
          })
        );
      } else {
        navigate(
          generatePath(routes.manageDataSelectionOptionsSubGroup.path, {
            subgroupGuid: subgroupGuid!,
          })
        );
      }
    }
  }, [
    optionCreateData,
    optionCreateUpdateStatus,
    navigate,
    subgroupGuid,
    reset,
    optionData,
    isAddRoute,
    isSubmitted,
  ]);

  // Refresh the option data if we remove an attribute
  useEffect(() => {
    if (!removeAttributeLoading && removeAttributeStatus === "ok") {
      refreshOption();
    }
  }, [removeAttributeLoading, removeAttributeStatus, refreshOption]);

  const onSubmit = (data: FormData) => {
    const optionPricing = data.optionPricing.map((price) => ({
      ...price,
      startDate: Math.floor(price.startDate.toSeconds()),
      endDate: price.endDate ? Math.floor(price.endDate?.toSeconds()) : null,
    }));

    if (optionGuid) {
      optionCreateUpdateRequest(`company/options/${optionGuid}/edit`, {
        method: "POST",
        data: {
          ...data,
          optionPricing: JSON.stringify(optionPricing),
        },
      });
    } else {
      optionCreateUpdateRequest(
        `/company/options/subgroups/${subgroupGuid}/options/add`,
        {
          method: "POST",
          data: {
            ...data,
            optionPricing: JSON.stringify(optionPricing),
          },
        }
      );
    }
  };

  const handleCancelClick = () => {
    navigate(
      generatePath(routes.manageDataSelectionOptionsSubGroup.path, {
        subgroupGuid: subgroupGuid!,
      })
    );
  };

  const removeAttribute = () => {
    if (optionData) {
      removeAttributeRequest(
        `company/options/${optionData?.optionGuid}/removeattribute`,
        {
          method: "POST",
          data: { attributeGuid: attributeToRemove?.attributeGuid },
        }
      );
      setAttributeToRemove(null);
    }
  };
  const onPhotoChange = useCallback(() => {
    refreshOption();
  }, [refreshOption]);

  const handleSaveAttributeSelection = useCallback(
    (attributeSelection: AttributeSelection) => {
      setEditingAttributeSelectionSelectionGuid(
        attributeSelection.attributeselectionGuid
      );
      showSuccessToast({ message: "Attribute Selection Saved" });
      refreshOption();
    },
    [refreshOption, showSuccessToast]
  );

  const handleCloseAttributeSelectionModal = useCallback(() => {
    setEditingAttributeSelectionAttributeGuid(null);
    setEditingAttributeSelectionSelectionGuid(void 0);
  }, []);

  const handleDiscontinueAttributeSelection = useCallback(
    (attribute: Attribute, attributeSelection: AttributeSelection) => {
      setDiscontinuingAttributeSelection(attributeSelection);
    },
    []
  );

  const handleDiscontinueAttributeConfirmed = () => {
    attributeSelectionDiscontinueRequest(
      "company/attributeselections/discontinue",
      {
        method: "POST",
        data: {
          attributeselectionGuid:
            discontinuingAttributeSelectionSelection?.attributeselectionGuid,
        },
      }
    );
    setDiscontinuingAttributeSelection(null);
  };
  const handleEditAttributeClick = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleDragEnd = (sortedAttributes: Attribute[]) => {
    setAttributes((prevAttribute) => {
      const updatedAttributes = updateSortOrder(
        [...sortedAttributes, ...prevAttribute],
        (a, b) => a.attributeGuid === b.attributeGuid
      );

      updateSortOrderRequest("company/attributes/sortorder/update", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: updatedAttributes.map((c) => ({
          attributeGuid: c.attributeGuid,
          sortOrder: c.sortOrder,
        })),
      });

      return updatedAttributes;
    });
  };

  const toggleAttributeExpansion = (attributeGuid: string) => {
    setExpandedAttributes((prev) => ({
      ...prev,
      [attributeGuid]: !prev[attributeGuid],
    }));
  };

  const toggleExpandCollapseAll = () => {
    const newExpandedState = !expanded;
    setExpanded(newExpandedState);

    const newExpandedAttributes = attributes.reduce((acc, attribute) => {
      acc[attribute.attributeGuid] = newExpandedState;
      return acc;
    }, {} as Record<string, boolean>);

    setExpandedAttributes(newExpandedAttributes);
  };

  const handleLogicStatementsClick = () => {
    setIsLogicModalOpen(true);
  };

  const handleLogicStatementsModalClose = () => {
    setIsLogicModalOpen(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageHeader
          title={
            <>
              <Typography variant="h5" fontWeight="bold">
                {optionName}
              </Typography>
              {optionData && optionData.logics.length > 0 ? (
                <Button
                  size="small"
                  disableRipple
                  sx={{
                    backgroundColor: "#F4F3FF",
                    borderRadius: 50,
                    padding: "2px 8px",
                    height: "24px",
                    marginLeft: 2,
                  }}
                  endIcon={<InfoOutlinedIcon />}
                  onClick={handleLogicStatementsClick}
                >
                  {optionData.logics.length} Logic Statements
                </Button>
              ) : null}
            </>
          }
          actions={
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!isValid || !isDirty || optionCreateUpdateLoading}
              >
                {optionGuid ? "Save" : "Create"}
              </Button>
            </Stack>
          }
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <Stack spacing={2} direction="column">
              <Card variant="outlined">
                <CardContent>
                  <OptionDetailsFormSection
                    control={control}
                    register={register}
                    trigger={trigger}
                    setValue={setValue}
                    errors={errors}
                    optionRooms={optionRooms}
                    subgroupSeries={subgroupData?.series || []}
                    selectedSeries={series || []}
                  />
                </CardContent>
              </Card>
              <Card variant="outlined">
                <CardContent>
                  <OptionPricingFormSection
                    control={control}
                    register={register}
                    trigger={trigger}
                    setValue={setValue}
                    errors={errors}
                    optionPricedPer={optionPricedPer}
                  />
                </CardContent>
              </Card>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card variant="outlined">
              <CardContent
                component={Stack}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                Option Attributes
                <Stack direction="row" spacing={1}>
                  {attributes.length > 0 && (
                    <>
                      <Button onClick={toggleExpandCollapseAll}>
                        {expanded ? "Collapse" : "Expand"} All
                      </Button>
                      <Divider
                        orientation="vertical"
                        flexItem
                        variant="middle"
                      />
                    </>
                  )}
                  <Button
                    disabled={!optionGuid}
                    onClick={handleEditAttributeClick}
                  >
                    Add Attribute
                  </Button>
                </Stack>
              </CardContent>
            </Card>
            <DraggableList
              items={attributes || []}
              getItemDragId={(attribute: Attribute) => attribute.attributeGuid}
              onDragEnd={handleDragEnd}
            >
              {attributes
                .sort((a: Attribute, b: Attribute) => a.sortOrder - b.sortOrder)
                .map((attribute) => (
                  <AttributeCard
                    key={attribute.attributeGuid}
                    attribute={attribute}
                    expanded={
                      expandedAttributes[attribute.attributeGuid] ?? expanded
                    }
                    onToggleExpand={() =>
                      toggleAttributeExpansion(attribute.attributeGuid)
                    }
                    onAttributeSelectionAdd={(attribute) => {
                      setEditingAttributeSelectionAttributeGuid(
                        attribute.attributeGuid
                      );
                      setEditingAttributeSelectionSelectionGuid(void 0);
                    }}
                    onAttributeSelectionEdit={(attribute, selection) => {
                      setEditingAttributeSelectionAttributeGuid(
                        attribute.attributeGuid
                      );
                      setEditingAttributeSelectionSelectionGuid(
                        selection.attributeselectionGuid
                      );
                    }}
                    onAttributeEdit={() => {
                      setIsEditing(true);
                      setEditingAttribute(attribute);
                    }}
                    onRemove={() => {
                      setAttributeToRemove(attribute);
                    }}
                    onAttributeSelectionDiscontinue={(attribute, selection) => {
                      handleDiscontinueAttributeSelection(attribute, selection);
                    }}
                  />
                ))}
            </DraggableList>
          </Grid>
        </Grid>
      </form>
      {isEditing && (
        <NewEditAttributeModal
          isOpen={true}
          onSaveSuccessful={() => {
            setIsEditing(false);
            setEditingAttribute(null);
            refreshOption();
          }}
          onClose={() => {
            setIsEditing(false);
            setEditingAttribute(null);
          }}
          attribute={editingAttribute}
          option={optionData}
          isNew={editingAttribute === null}
        />
      )}
      {attributeToRemove && (
        <AttributeRemoveConfirmModal
          attributeName={attributeToRemove.attributeName}
          isOpen={!!attributeToRemove}
          onCancel={() => setAttributeToRemove(null)}
          onRemove={removeAttribute}
        />
      )}
      {editingAttributeSelectionAttribute && (
        <NewEditAttributeSelectionModal
          isNew={editingAttributeSelectionSelection === void 0}
          isOpen={!!editingAttributeSelectionAttribute}
          attribute={editingAttributeSelectionAttribute}
          attributeSelection={editingAttributeSelectionSelection}
          onSaveSuccessful={handleSaveAttributeSelection}
          onClose={handleCloseAttributeSelectionModal}
          onPhotoChange={onPhotoChange}
        />
      )}
      {discontinuingAttributeSelectionSelection && (
        <AttributeSelectionDiscontinueModal
          isOpen={!!discontinuingAttributeSelectionSelection}
          attributeSelectionName={
            discontinuingAttributeSelectionSelection?.attributeselectionName ||
            ""
          }
          onDiscontinue={handleDiscontinueAttributeConfirmed}
          onCancel={() => {
            setDiscontinuingAttributeSelection(null);
          }}
        />
      )}
      {optionData ? (
        <OptionLogicsModal
          isOpen={isLogicModalOpen}
          option={optionData}
          onClose={handleLogicStatementsModalClose}
        />
      ) : null}
    </>
  );
};
export default ManageSelectionOptionsAddEditRoute;
