import { isEqual } from "lodash";
import { OrderCardAppliance } from "./orderCardAppliance";
import { OrderCardBusinessBuyer } from "./orderCardBusinessBuyer";
import {
  OrderCardBusinessHome,
  OrderCardModelError,
} from "./orderCardBusinessHome";
import { OrderCardBusinessLogistics } from "./orderCardBusinessLogistics";
import {
  OrderCardCustomSubgroup,
  OrderCardInvalidGroupError,
  OrderCardInvalidRoomsError,
} from "./orderCardCustomSubgroup";
import {
  OrderCardFinancialFees,
  OrderCardFinancialFeesFreightError,
} from "./orderCardFinancialFees";
import {
  OrderCardFinancialFinancing,
  OrderCardFinancialFinancingInvalidVendor,
} from "./orderCardFinancialFinancing";
import { OrderCardFinancialPricing } from "./orderCardFinancialPricing";
import { OrderCardHeatUValues } from "./orderCardHeatUValues";
import { OrderCardInvoiceCeilingInsulation } from "./orderCardInvoiceCeilingInsulation";
import { OrderCardInvoiceDates } from "./orderCardInvoiceDates";
import { OrderCardInvoiceSection } from "./orderCardInvoiceSection";
import { OrderCardInvoiceSpecifications } from "./orderCardInvoiceSpecifications";
import { OrderCardInvoiceHeatingCoolingDucts } from "./orderCardInvoiceHeatingCoolingDucts";
import { OrderCardInvoiceExteriorWallInsulation } from "./orderCardInvoiceExteriorWallInsulation";
import { OrderCardInvoiceFloorInsulation } from "./orderCardInvoiceFloorInsulation";
import {
  OrderCardSOAttributeExpired,
  OrderCardSOAttributeRemoved,
  OrderCardSOInvalidOption,
  OrderCardSOInvalidRooms,
  OrderCardSOInvalidSubgroupError,
  OrderCardSubgroupOption,
  OrderCardSubgroupOptionInvalidSeriesError,
  OrderCardSOMinQuantity,
  OrderCardSOMaxQuantity,
} from "./orderCardSubgroupOption";
import { OrderCardApplianceVendorError } from "./orderCardAppliance";
import { OrderCardAddAppliance } from "./orderCardAddAppliance";
import {
  OrderCardChangeOrderInvalidRoomsError,
  OrderCardChangeOrderDuplicateError,
  OrderCardSalesChangeOrder,
} from "./orderCardSalesChangeOrder";
import { OrderCardAddSalesChangeOrder } from "./orderCardAddSalesChangeOrder";
import { OrderCardAdditionalBuyerQuestions } from "./orderCardAdditionalBuyerQuestions";

export type OrderCard =
  | OrderCardBusinessLogistics
  | OrderCardBusinessBuyer
  | OrderCardBusinessHome
  | OrderCardFinancialFinancing
  | OrderCardFinancialFees
  | OrderCardFinancialPricing
  | OrderCardSubgroupOption
  | OrderCardCustomSubgroup
  | OrderCardInvoiceDates
  | OrderCardInvoiceSection
  | OrderCardHeatUValues
  | OrderCardAppliance
  | OrderCardInvoiceSpecifications
  | OrderCardInvoiceHeatingCoolingDucts
  | OrderCardInvoiceFloorInsulation
  | OrderCardInvoiceExteriorWallInsulation
  | OrderCardInvoiceCeilingInsulation
  | OrderCardSalesChangeOrder
  | OrderCardAddAppliance
  | OrderCardAddSalesChangeOrder
  | OrderCardAdditionalBuyerQuestions;

type OrderCardErrorCode =
  | "eblr"
  | "eblv"
  | "ebhm"
  | "ecsg"
  | "ecsr"
  | "esog"
  | "esos"
  | "esoo"
  | "esoar"
  | "esoae"
  | "esor"
  | "eiav"
  | "ecor"
  | "ecod"
  | "esoos"
  | "esmq"
  | "esxq"
  | "effv"
  | "efef"
  | null;

export type OrderCardError<T = any> = {
  type: string;
  data: T;
};

export function getOrderCardError(
  orderCard: OrderCard,
  errorCode: "eblr"
): null;
export function getOrderCardError(
  orderCard: OrderCard,
  errorCode: "eblv"
): null;
export function getOrderCardError(
  orderCard: OrderCard,
  errorCode: "ebhm"
): OrderCardModelError["data"] | null;
export function getOrderCardError(
  orderCard: OrderCard,
  errorCode: "ecsg"
): OrderCardInvalidGroupError["data"] | null;
export function getOrderCardError(
  orderCard: OrderCard,
  errorCode: "ecsr"
): OrderCardInvalidRoomsError["data"] | null;
export function getOrderCardError(
  orderCard: OrderCard,
  errorCode: "esog"
): OrderCardInvalidGroupError["data"] | null;
export function getOrderCardError(
  orderCard: OrderCard,
  errorCode: "esos"
): OrderCardSOInvalidSubgroupError["data"] | null;
export function getOrderCardError(
  orderCard: OrderCard,
  errorCode: "esoo"
): OrderCardSOInvalidOption["data"] | null;
export function getOrderCardError(
  orderCard: OrderCard,
  errorCode: "esoar"
): OrderCardSOAttributeRemoved["data"] | null;
export function getOrderCardError(
  orderCard: OrderCard,
  errorCode: "esoae"
): OrderCardSOAttributeExpired["data"] | null;
export function getOrderCardError(
  orderCard: OrderCard,
  errorCode: "esor"
): OrderCardSOInvalidRooms["data"] | null;
export function getOrderCardError(
  orderCard: OrderCard,
  errorCode: "eiav"
): OrderCardApplianceVendorError["data"] | null;
export function getOrderCardError(
  orderCard: OrderCard,
  errorCode: "ecor"
): OrderCardChangeOrderInvalidRoomsError["data"] | null;
export function getOrderCardError(
  orderCard: OrderCard,
  errorCode: "ecod"
): OrderCardChangeOrderDuplicateError["data"] | null;
export function getOrderCardError(
  orderCard: OrderCard,
  errorCode: "esoos"
): OrderCardSubgroupOptionInvalidSeriesError["data"] | null;
export function getOrderCardError(
  orderCard: OrderCard,
  errorCode: "esmq"
): OrderCardSOMinQuantity["data"] | null;
export function getOrderCardError(
  orderCard: OrderCard,
  errorCode: "esxq"
): OrderCardSOMaxQuantity["data"] | null;
export function getOrderCardError(
  orderCard: OrderCard,
  errorCode: "effv"
): OrderCardFinancialFinancingInvalidVendor["data"] | null;
export function getOrderCardError(
  orderCard: OrderCard,
  errorCode: "efef"
): OrderCardFinancialFeesFreightError["data"] | null;
export function getOrderCardError(orderCard: OrderCard, errorCode: null): null;

export function getOrderCardError(
  orderCard: OrderCard,
  errorCode: OrderCardErrorCode
): OrderCardError["data"] | null {
  return (
    orderCard.errors.find((error) => error.type === errorCode)?.data ?? null
  );
}

// Compares the keys found in primary OrderCard to the keys found in secondary OrderCard
// Note that we iterate over the keys found in the primary OrderCard so if secondary
// has extra keys, they will be ignored
export const areOrderCardsEqual = <T extends object>(
  primary: T,
  secondary: T
) => {
  const cardDataKeys = Object.keys(primary);
  return cardDataKeys.every((key) =>
    isEqual(
      secondary[key as keyof typeof secondary],
      primary[key as keyof typeof primary]
    )
  );
};
