import React from "react";
import { Box, Button, Grid } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { Modal } from "../../components/Modal";
import InputField from "../../components/InputField";
import SelectField from "../../components/SelectField";
import { stateSelectOptions } from "../../types/states";

type FormData = {
  firstName: string | null;
  lastName: string | null;
  address1: string | null;
  address2: string | null;
  lotNumber: string | null;
  postalCode: string | null;
  city: string | null;
  state: string | null;
  county: string | null;
  homePhone: string | null;
  mobilePhone: string | null;
  email: string | null;
};

interface InventoryTabBuyerDetailsModalProps {
  onSaveSuccessful: (data: FormData) => void;
  onClose: () => void;
}

const getDefaultValues = (): FormData => {
  return {
    firstName: null,
    lastName: null,
    address1: null,
    address2: null,
    lotNumber: null,
    postalCode: null,
    city: null,
    state: "",
    county: null,
    homePhone: null,
    mobilePhone: null,
    email: null,
  };
};

export const InventoryTabBuyerDetailsModal: React.FC<
  InventoryTabBuyerDetailsModalProps
> = ({ onSaveSuccessful, onClose }) => {
  const { handleSubmit, register, formState, control } = useForm<FormData>({
    defaultValues: getDefaultValues(),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const onSubmit = (data: FormData) => {
    onSaveSuccessful(data);
  };

  return (
    <Modal heading={"Buyer Information"} isOpen={true} onClose={onClose}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ width: { xs: "100%", sm: "752px" } }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <InputField
              {...register("firstName")}
              label="First Name"
              placeholder="Enter First Name"
              fullWidth
              error={!!formState.errors.firstName}
              helperText={formState.errors.firstName?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              {...register("lastName")}
              label="Last Name"
              placeholder="Enter Last Name"
              fullWidth
              error={!!formState.errors.lastName}
              helperText={formState.errors.lastName?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              {...register("address1")}
              label="Address (Build Site)"
              placeholder="Enter Address 1"
              fullWidth
              error={!!formState.errors.address1}
              helperText={formState.errors.address1?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              {...register("address2")}
              label="Address 2 (Build Site)"
              placeholder="Enter Address 2"
              fullWidth
              error={!!formState.errors.address2}
              helperText={formState.errors.address2?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              {...register("lotNumber")}
              label="Lot Number (Build Site)"
              placeholder="Enter Lot Number"
              fullWidth
              error={!!formState.errors.lotNumber}
              helperText={formState.errors.lotNumber?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              {...register("postalCode")}
              label="Postal Code (Build Site)"
              placeholder="Enter Postal Code"
              fullWidth
              error={!!formState.errors.postalCode}
              helperText={formState.errors.postalCode?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              {...register("city")}
              label="City (Build Site)"
              placeholder="Enter City"
              fullWidth
              error={!!formState.errors.city}
              helperText={formState.errors.city?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="state"
              render={({ field }) => (
                <SelectField
                  {...field}
                  label="State (Build Site)"
                  placeholder="Enter State (Build Site)"
                  fullWidth
                  options={stateSelectOptions}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              {...register("county")}
              label="County"
              placeholder="Enter County"
              fullWidth
              error={!!formState.errors.county}
              helperText={formState.errors.county?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              {...register("homePhone")}
              label="Home Phone"
              placeholder="Enter Home Phone"
              fullWidth
              error={!!formState.errors.homePhone}
              helperText={formState.errors.homePhone?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              {...register("mobilePhone")}
              label="Mobile Phone"
              placeholder="Enter Mobile Phone"
              fullWidth
              error={!!formState.errors.mobilePhone}
              helperText={formState.errors.mobilePhone?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              {...register("email")}
              label="Email"
              placeholder="Enter Email"
              fullWidth
              error={!!formState.errors.email}
              helperText={formState.errors.email?.message}
            />
          </Grid>
        </Grid>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "end" }}>
          <Button variant="outlined" onClick={onClose} sx={{ marginRight: 2 }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={!formState.isValid}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
