import { AttributeSelection } from "./attributeSelection";

export type Attribute = {
  attributeGuid: string;
  attributeName: string;
  sortOrder: number;
  attributePresentAs: "d" | "s";
  attributeIncludeImages: boolean;
  attributeSelectIsRequired: boolean;
  optionNames: OptionName[];
  attributeselections: AttributeSelection[];
};

export type AttributePresentAs = Attribute['attributePresentAs'];

export type OptionName = {
  optionName: string;
  optionPath: string;
  optionGuid: string;
};

export const getAttributePresentAsDisplayName = (attributePresentAs: AttributePresentAs): string => {
    const displayNames: Record<AttributePresentAs, string> = {
      d: "Dropdown",
      s: "Swatch",
    };

    return displayNames[attributePresentAs] || attributePresentAs;
  };

export const attributeNameMaxLength = 20;
