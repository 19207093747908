import React, { useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { Controller, useForm } from "react-hook-form";
import { OrderCardBase } from "./OrderCardBase";
import { useOrderCard } from "./OrderCardContext";
import InputField from "../../components/InputField";
import {
  InvoiceHeatingCoolingDuctsCooling,
  InvoiceHeatingCoolingDuctsHeating,
  invoiceHeatingCoolingDuctsCoolingOptions,
  invoiceHeatingCoolingDuctsHeatingOptions,
  OrderCardInvoiceHeatingCoolingDucts as OrderCardInvoiceHeatingCoolingDuctsType,
} from "../../types/orderCardInvoiceHeatingCoolingDucts";
import SelectField from "../../components/SelectField";
import { InputAdornment } from "@mui/material";

type FormData = {
  adCeiling: number | null;
  adFloor: number | null;
  adOutside: number | null;
  cooling: InvoiceHeatingCoolingDuctsCooling | null;
  ductMax: number | null;
  heating: InvoiceHeatingCoolingDuctsHeating | null;
  minOutdoorTemp: number | null;
  winterDesignTemp: number | null;
};

const getDefaultValues = (
  orderCard: OrderCardInvoiceHeatingCoolingDuctsType | null
): FormData => {
  return {
    adCeiling: orderCard?.cardData.adCeiling ?? null,
    adFloor: orderCard?.cardData.adFloor ?? null,
    adOutside: orderCard?.cardData.adOutside ?? null,
    cooling: orderCard?.cardData.cooling ?? null,
    ductMax: orderCard?.cardData.ductMax ?? null,
    heating: orderCard?.cardData.heating ?? null,
    minOutdoorTemp: orderCard?.cardData.minOutdoorTemp ?? null,
    winterDesignTemp: orderCard?.cardData.winterDesignTemp ?? null,
  };
};

interface OrderCardInvoiceHeatingCoolingDuctsProps {
  orderCard: OrderCardInvoiceHeatingCoolingDuctsType;
  expanded: boolean;
  groupIndex: number;
}

export const OrderCardInvoiceHeatingCoolingDucts: React.FC<
  OrderCardInvoiceHeatingCoolingDuctsProps
> = ({ orderCard, expanded, groupIndex }) => {
  const { saveOrderCard, setEditingOrderCard } = useOrderCard();

  const disabled = orderCard.isLocked;

  const { control, register, reset, getValues } = useForm<FormData>({
    defaultValues: getDefaultValues(orderCard),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const handleBlurField = () => {
    const values = getValues();
    saveOrderCard(orderCard.orderCardGuid, values);
    setEditingOrderCard(null);
  };

  const handleEditingField = () => {
    setEditingOrderCard(orderCard);
  };

  const handleClearClick = () => {
    const defaultValues = getDefaultValues(null);
    reset(defaultValues);

    saveOrderCard(orderCard.orderCardGuid, defaultValues);
  };

  useEffect(() => {
    reset(getDefaultValues(orderCard), { keepDirtyValues: true });
  }, [orderCard, reset]);

  return (
    <OrderCardBase
      disabled={disabled}
      orderCard={orderCard}
      onClearClick={handleClearClick}
      expanded={expanded}
      groupIndex={groupIndex}
    >
      <Grid container spacing={2} component="form">
        <Grid xs={12} sm={12} md={6}>
          <Controller
            control={control}
            name="heating"
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                label="Heating"
                placeholder="Select Heating"
                onFocus={handleEditingField}
                required
                fullWidth
                disabled={disabled}
                value={field.value ?? ""}
                options={invoiceHeatingCoolingDuctsHeatingOptions}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                onBlur={() => {
                  handleBlurField();
                  field.onBlur();
                }}
              />
            )}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <Controller
            control={control}
            name="cooling"
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                label="Cooling"
                placeholder="Select Cooling"
                onFocus={handleEditingField}
                required
                fullWidth
                disabled={disabled}
                value={field.value ?? ""}
                options={invoiceHeatingCoolingDuctsCoolingOptions}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                onBlur={() => {
                  handleBlurField();
                  field.onBlur();
                }}
              />
            )}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <InputField
            {...register("minOutdoorTemp", {
              onBlur: handleBlurField,
              valueAsNumber: true,
            })}
            label="Minimum Outdoor Temp"
            onFocus={handleEditingField}
            placeholder="Enter Minimum Outdoor Temp"
            required
            fullWidth
            disabled={disabled}
            type="number"
            endAdornment={<InputAdornment position="end">°F</InputAdornment>}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <InputField
            {...register("winterDesignTemp", {
              onBlur: handleBlurField,
              valueAsNumber: true,
            })}
            label="Winter Design Temp"
            onFocus={handleEditingField}
            placeholder="Enter Winter Design Temp"
            required
            fullWidth
            disabled={disabled}
            type="number"
            endAdornment={<InputAdornment position="end">°F</InputAdornment>}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <InputField
            {...register("ductMax", {
              onBlur: handleBlurField,
              valueAsNumber: true,
            })}
            label="Duct Max BTU Rated Capacity"
            onFocus={handleEditingField}
            placeholder="Enter Duct Max BTU Rated Capacity"
            required
            fullWidth
            disabled={disabled}
            type="number"
            endAdornment={<InputAdornment position="end">BTUs</InputAdornment>}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <InputField
            {...register("adFloor", {
              onBlur: handleBlurField,
              valueAsNumber: true,
            })}
            label="Air Ducts in Floor"
            onFocus={handleEditingField}
            placeholder="Enter Air Ducts in Floor"
            fullWidth
            disabled={disabled}
            type="number"
            endAdornment={<InputAdornment position="end">SQ FT</InputAdornment>}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <InputField
            {...register("adCeiling", {
              onBlur: handleBlurField,
              valueAsNumber: true,
            })}
            label="Air Ducts in Ceiling"
            onFocus={handleEditingField}
            placeholder="Enter Air Ducts in Ceiling"
            fullWidth
            disabled={disabled}
            type="number"
            endAdornment={<InputAdornment position="end">SQ FT</InputAdornment>}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <InputField
            {...register("adOutside", {
              onBlur: handleBlurField,
              valueAsNumber: true,
            })}
            label="Air Ducts Outside Home"
            onFocus={handleEditingField}
            placeholder="Enter Air Ducts Outside Home"
            fullWidth
            disabled={disabled}
            type="number"
            endAdornment={<InputAdornment position="end">SQ FT</InputAdornment>}
          />
        </Grid>
      </Grid>
    </OrderCardBase>
  );
};
