import { Category, findOption, findSubgroup } from "../types/category";
import { LogicThisTarget } from "../types/logic";
import { LogicTextSelectionOption } from "./LogicTextSelectionOption";
import { LogicTextSelectionOptionAttribute } from "./LogicTextSelectionOptionAttribute";
import { LogicTextSubgroup } from "./LogicTextSubgroup";

interface LogicThisTargetStatementProps {
  categories: Category[];
  thisTarget: LogicThisTarget;
}

export const LogicThisTargetStatement: React.FC<
  LogicThisTargetStatementProps
> = ({ categories, thisTarget }) => {
  switch (thisTarget.thisType) {
    case "a": {
      const option = findOption(categories, thisTarget.thisOptionGuid ?? "");
      return (
        <LogicTextSelectionOptionAttribute
          option={option}
          logicAttributeselectionGuid={
            thisTarget.thisAttributeselectionGuid ?? ""
          }
        />
      );
    }
    case "o": {
      const option = findOption(categories, thisTarget.thisOptionGuid ?? "");
      return <LogicTextSelectionOption optionName={option?.optionName ?? ""} />;
    }
    case "s": {
      const subgroup = findSubgroup(
        categories,
        thisTarget.thisSubgroupGuid ?? ""
      );
      return <LogicTextSubgroup subgroupName={subgroup?.subgroupName ?? ""} />;
    }
    default: {
      // Make sure typescript reports an error if we don't handle all thisTarget.thisType values
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _exhaustiveCheck: never = thisTarget.thisType;
      return null;
    }
  }
};
