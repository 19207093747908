import {
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React from "react";
import { MoreMenuButton } from "../../components/MoreMenuButton";
import { SavedReport } from "../../types/report";
import { formatAsDate } from "../../utils/date";

interface ReportingRightSidebarTabSavedReportsProps {
  savedReports: SavedReport[];
  selectedReportGuid?: string;
  onViewReport: (reportGuid: string) => void;
  onDeleteReport: (reportGuid: string) => void;
}

export const ReportingRightSidebarTabSavedReports: React.FC<
  ReportingRightSidebarTabSavedReportsProps
> = ({ savedReports, selectedReportGuid, onViewReport, onDeleteReport }) => {
  const handleViewReportClick = (reportGuid: string | null) => {
    if (reportGuid) {
      onViewReport(reportGuid);
    }
  };

  const handleDeleteReportClick = (reportGuid: string | null) => {
    if (reportGuid) {
      onDeleteReport(reportGuid);
    }
  };

  return (
    <Box padding={0} sx={{ overflowY: "auto" }}>
      <List>
        {savedReports.map((report, reportIndex) => {
          return (
            <React.Fragment key={report.reportGuid}>
              {reportIndex !== savedReports.length ? (
                <Divider variant="fullWidth" component="li" />
              ) : null}
              <ListItemButton
                key={report.reportGuid}
                disableRipple
                selected={report.reportGuid === selectedReportGuid}
                sx={{ borderRadius: 0 }}
                onClick={() => handleViewReportClick(report.reportGuid)}
              >
                <ListItemText
                  primary={report.reportName}
                  secondary={`${report.createdByUserName} • ${formatAsDate(
                    report.createdTimestamp
                  )}`}
                  sx={{
                    ".MuiListItemText-primary": {
                      fontWeight: "600",
                      color: "grey.900",
                    },
                    ".MuiListItemText-secondary": {
                      fontWeight: "500",
                      color: "grey.400",
                      fontSize: "12px",
                    },
                  }}
                />
                {report.canEditOrDelete ? (
                  <MoreMenuButton
                    menuItems={[
                      {
                        label: "Delete",
                        onClick: () =>
                          handleDeleteReportClick(report.reportGuid),
                      },
                    ]}
                  />
                ) : null}
              </ListItemButton>
            </React.Fragment>
          );
        })}
      </List>
    </Box>
  );
};
