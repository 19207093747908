import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import { routes } from "../../routes";
import { useApiRequest } from "../../hooks/useApiRequest";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Divider,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { Search } from "@mui/icons-material";
import { GridColDef } from "@mui/x-data-grid";
import { MoreMenuButton } from "../../components/MoreMenuButton";
import {
  Attribute,
  getAttributePresentAsDisplayName,
} from "../../types/attribute";
import { useParams } from "react-router-dom";
import { PageHeader } from "../../components/PageHeader";
import { InfoPanel } from "../../components/InfoPanel";
import { AttributeSelection, getAttributeSelectionImageUrl } from "../../types/attributeSelection";
import { formatAsDate } from "../../utils/date";
import { formatAsCurrency } from "../../utils/number";
import { TableDraggable } from "../../components/TableDraggable";
import { NewEditAttributeSelectionModal } from "./NewEditAttributeSelectionModal";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";
import { NewEditAttributeModal } from "../ManageAttributesRoute/NewEditAttributeModal";
import { AttributeSelectionDiscontinueModal } from "../../components/AttributeSelectionDiscontinueModal";
import { updateSortOrder } from "../../utils/array";

const ManageAttributesDetailRoute: React.FC = () => {
  const { attributeGuid } = useParams();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { showErrorToast } = useGlobalToastNotificationContext();
  const [attribute, setAttribute] = useState<Attribute | null>(null);
  const [isEditingAttribute, setIsEditingAttribute] = useState(false);
  const [
    discontinueAttributeSelectionGuid,
    setDiscontinueAttributeSelectionGuid,
  ] = useState<string | null>(null);
  const [isEditingAttributeSelection, setIsEditingAttributeSelection] =
    useState(false);
  const [editingAttributeSelectionGuid, setEditingAttributeSelectionGuid] =
    useState<string | null>(null);
  const [searchText, setSearchText] = useState("");

  const { data: attributeData, request: attributeRequest } =
    useApiRequest<Attribute>(false);

  const {
    data: attributeSelectionDiscontinueData,
    loading: attributeSelectionDiscontinueLoading,
    status: attributeSelectionDiscontinueStatus,
    error: attributeSelectionDiscontinueError,
    request: attributeSelectionDiscontinueRequest,
  } = useApiRequest<AttributeSelection>(false);

  const { request: updateSortOrderRequest } = useApiRequest<[]>(false);

  const editingAttributeSelection = attribute?.attributeselections.find(
    (s) => s.attributeselectionGuid === editingAttributeSelectionGuid
  );

  const discontinueAttributeSelection = attribute?.attributeselections.find(
    (a) => a.attributeselectionGuid === discontinueAttributeSelectionGuid
  );

  usePageMetadata({ title: `Manage ${attribute?.attributeName}` });

  useEffect(() => {
    setBreadcrumbs([
      {
        label: routes.manageDataAttributes.label,
        href: routes.manageDataAttributes.path,
      },
      { label: attribute?.attributeName ?? "" },
    ]);
  }, [attribute?.attributeName, setBreadcrumbs]);

  const refreshAttribute = useCallback(() => {
    if (!attributeGuid) {
      return;
    }

    attributeRequest(`/company/attributes/${attributeGuid}`, {
      method: "GET",
    });
  }, [attributeGuid, attributeRequest]);

  useEffect(() => {
    refreshAttribute();
  }, [refreshAttribute]);

  useEffect(() => {
    setAttribute(attributeData);
  }, [attributeData]);

  const handleNewAttributeSelectionClick = () => {
    setEditingAttributeSelectionGuid(null);
    setIsEditingAttributeSelection(true);
  };

  const handleEditAttributeSelectionClick = useCallback(
    (attributeSelection: AttributeSelection) => () => {
      setEditingAttributeSelectionGuid(
        attributeSelection.attributeselectionGuid
      );
      setIsEditingAttributeSelection(true);
    },
    []
  );

  const handleDiscontinueAttributeSelectionClick = useCallback(
    (attributeSelection: AttributeSelection) => async () => {
      setDiscontinueAttributeSelectionGuid(
        attributeSelection.attributeselectionGuid
      );
    },
    []
  );

  const handleDiscontinueAttributeConfirmed = () => {
    attributeSelectionDiscontinueRequest(
      "company/attributeselections/discontinue",
      {
        method: "POST",
        data: { attributeselectionGuid: discontinueAttributeSelectionGuid },
      }
    );
    setDiscontinueAttributeSelectionGuid(null);
  };

  const handleDiscontinueAttributeCancel = () => {
    setDiscontinueAttributeSelectionGuid(null);
  };

  useEffect(() => {
    if (
      attributeSelectionDiscontinueData &&
      attributeSelectionDiscontinueStatus === "ok" &&
      attributeSelectionDiscontinueLoading === false
    ) {
      refreshAttribute();
    } else if (attributeSelectionDiscontinueError) {
      showErrorToast({ message: "Something went wrong. Please try again.." });
    }
  }, [
    attributeSelectionDiscontinueData,
    attributeSelectionDiscontinueError,
    attributeSelectionDiscontinueLoading,
    attributeSelectionDiscontinueStatus,
    refreshAttribute,
    showErrorToast,
  ]);

  const handleSaveAttributeSelection = useCallback((attributeSelection: AttributeSelection) => {
    setEditingAttributeSelectionGuid(
      attributeSelection.attributeselectionGuid
    );
    refreshAttribute();
  }, [refreshAttribute]);

  const handlePhotoChange = useCallback(() => {
    refreshAttribute();
  }, [refreshAttribute]);

  const handleCloseAttributeSelection = () => {
    setIsEditingAttributeSelection(false);
  };

  const handleEditAttributeClick = () => {
    setIsEditingAttribute(true);
  };

  const handleSaveAttribute = useCallback(() => {
    refreshAttribute();
    setIsEditingAttribute(false);
  }, [refreshAttribute]);

  const handleCloseAttribute = () => {
    setIsEditingAttribute(false);
  };

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value.toLowerCase());
    },
    []
  );

  const columns: GridColDef<AttributeSelection>[] = useMemo(
    () => [
      {
        field: "attributeselectionName",
        headerName: "Attribute Selection",
        flex: 1,
        minWidth: 240,
        renderCell: (params) => {
          const imageUrl = getAttributeSelectionImageUrl(params.row);

          return (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
              height="100%"
              width="100%"
            >
              {attribute?.attributeIncludeImages ? (
                <Avatar src={imageUrl} />
              ) : null}
              <Typography>{params.row.attributeselectionName}</Typography>
            </Stack>
          );
        },
      },
      {
        field: "attributeselectionPrice",
        headerName: "Price",
        flex: 0.35,
        minWidth: 120,
        valueFormatter: (value: number) => formatAsCurrency(value),
      },
      {
        field: "startDate",
        headerName: "Start Date",
        flex: 0.35,
        minWidth: 110,
        valueFormatter: (value: number) => formatAsDate(value),
      },
      {
        field: "endDate",
        headerName: "End Date",
        flex: 0.35,
        minWidth: 110,
        valueFormatter: (value: number) =>
          value ? formatAsDate(value) : "No End Date",
      },
      {
        field: "actions",
        headerName: "",
        width: 10,
        align: "right",
        sortable: false,
        renderCell: ({ row }) => {
          return (
            <MoreMenuButton
              menuItems={[
                {
                  label: "Edit",
                  onClick: handleEditAttributeSelectionClick(row),
                },
                {
                  label: "Discontinue",
                  onClick: handleDiscontinueAttributeSelectionClick(row),
                },
              ]}
            />
          );
        },
      },
    ],
    [
      attribute?.attributeIncludeImages,
      handleDiscontinueAttributeSelectionClick,
      handleEditAttributeSelectionClick,
    ]
  );

  const handleDragEnd = useCallback(
    (sortedAttributeSelections: AttributeSelection[]) => {
      setAttribute((prevAttribute) => {
        const updatedAttributeSelections = updateSortOrder(
          [
            ...sortedAttributeSelections,
            ...(prevAttribute?.attributeselections ?? []),
          ],
          (a, b) => a.attributeselectionGuid === b.attributeselectionGuid
        );
        updateSortOrderRequest(
          "/company/attributeselections/sortorder/update",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            data: updatedAttributeSelections.map((s) => ({
              attributeselectionGuid: s.attributeselectionGuid,
              sortOrder: s.sortOrder,
            })),
          }
        );

        return {
          ...prevAttribute,
          attributeselections: updatedAttributeSelections,
        } as Attribute;
      });
    },
    [updateSortOrderRequest]
  );

  const filteredSelectionOptions = useMemo(() => {
    const attributeSelections = attribute?.attributeselections ?? [];

    return attributeSelections.filter((a) => {
      if (!searchText) {
        return true;
      }

      let matchesFilters = a.attributeselectionName
        .toLowerCase()
        .includes(searchText.toLowerCase());

      return matchesFilters;
    });
  }, [attribute?.attributeselections, searchText]);

  return (
    <>
      <PageHeader
        title={attribute?.attributeName ?? ""}
        actions={
          <MoreMenuButton
            menuItems={[{ label: "Edit", onClick: handleEditAttributeClick }]}
          />
        }
      />
      <InfoPanel
        panels={[
          {
            title: "Present as",
            text: attribute
              ? getAttributePresentAsDisplayName(attribute.attributePresentAs)
              : "",
          },
          {
            title: "Selection Image",
            text: attribute
              ? attribute.attributeIncludeImages
                ? "Included"
                : "Not Included"
              : "",
          },
          {
            title: "Required",
            text: attribute
              ? attribute.attributeSelectIsRequired
                ? "Yes"
                : "No"
              : "",
          },
        ]}
      />

      <Divider sx={{ marginBottom: 3 }} />

      <Grid container spacing={2}>
        <Grid xs={12} sm={8}>
          <Card variant="outlined">
            <CardContent sx={{ paddingX: 0, paddingBottom: "0 !important" }}>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="space-between"
                marginBottom={2}
                paddingX={2}
              >
                <Grid>
                  <Typography>Attribute Selections</Typography>
                </Grid>
                <Grid>
                  <Stack spacing={2} direction="row-reverse">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNewAttributeSelectionClick}
                    >
                      + New Attribute Selection
                    </Button>
                    <OutlinedInput
                      placeholder="Search"
                      onChange={handleSearchChange}
                      size="small"
                      color="primary"
                      sx={{ backgroundColor: "white", height: 37 }}
                      startAdornment={
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      }
                    />
                  </Stack>
                </Grid>
              </Grid>
              {attribute && attribute.attributeselections.length > 0 ? (
                <TableDraggable
                  columns={columns}
                  rows={filteredSelectionOptions}
                  rowSelection={false}
                  hideFooter
                  disableColumnFilter
                  disableColumnMenu
                  disableColumnResize
                  disableRowSelectionOnClick
                  getRowId={(row) => row.attributeselectionGuid}
                  onDragEnd={handleDragEnd}
                />
              ) : null}
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} sm={4}>
          <Card variant="outlined">
            <CardContent sx={{ paddingBottom: "0 !important" }}>
              <Typography fontWeight="700" marginBottom={2}>
                Linked Selection Options
              </Typography>
              <Divider variant="fullWidth" />
              <List dense>
                {attribute && attribute.optionNames.length === 0 ? (
                  <ListItem alignItems="flex-start" disableGutters>
                    <ListItemText primary="No linked selection options" />
                  </ListItem>
                ) : null}
                {attribute && attribute.optionNames.length > 0
                  ? attribute.optionNames.map((o) => {
                      return (
                        <ListItem
                          key={o.optionGuid}
                          alignItems="flex-start"
                          disableGutters
                        >
                          <ListItemText
                            primary={o.optionName}
                            secondary={o.optionPath}
                          />
                        </ListItem>
                      );
                    })
                  : null}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {attribute && isEditingAttribute && (
        <NewEditAttributeModal
          isOpen={isEditingAttribute}
          isNew={!attribute}
          attribute={attribute}
          onSaveSuccessful={handleSaveAttribute}
          onClose={handleCloseAttribute}
        />
      )}
      {attribute && isEditingAttributeSelection && (
        <NewEditAttributeSelectionModal
          isOpen={isEditingAttributeSelection}
          isNew={!editingAttributeSelection}
          attribute={attribute}
          attributeSelection={editingAttributeSelection}
          onSaveSuccessful={handleSaveAttributeSelection}
          onClose={handleCloseAttributeSelection}
          onPhotoChange={handlePhotoChange}
        />
      )}
      {attribute && discontinueAttributeSelection && (
        <AttributeSelectionDiscontinueModal
          attributeSelectionName={
            discontinueAttributeSelection.attributeselectionName
          }
          isOpen={!!discontinueAttributeSelection}
          onDiscontinue={handleDiscontinueAttributeConfirmed}
          onCancel={handleDiscontinueAttributeCancel}
        />
      )}
    </>
  );
};

export default ManageAttributesDetailRoute;
