import React, { useEffect, useState } from "react";
import { Button, Card, Container, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useApiRequest } from "../../hooks/useApiRequest";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";
import { PasswordInput } from "../../components/PasswordInput";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import { routes } from "../../routes";

const PasswordChangeRoute: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isRedirectPending, setIsRedirectPending] = useState(false);
  const { data, loading, error, request } = useApiRequest();
  const navigate = useNavigate();
  const { setGlobalToastNotification } = useGlobalToastNotificationContext();

  usePageMetadata({ title: "Change Password" });

  const isFormValid =
    password.length > 0 &&
    confirmPassword.length > 0 &&
    password === confirmPassword;

  // If there is no code in the query params, redirect to login
  useEffect(() => {
    if (!code) {
      navigate(routes.login.path);
    }
  }, [code, navigate]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (error) {
      setGlobalToastNotification({
        severity: "error",
        title: "Something went wrong!",
        message: "Please contact AtlasAlly support.",
      });

      return;
    }

    if (data) {
      setIsRedirectPending(true);
      setGlobalToastNotification({
        severity: "success",
        title: "Success!",
        message:
          "Password reset successfully. You will be redirected to the login page.",
      });

      timeout = setTimeout(() => {
        navigate(routes.login.path);
      }, 5 * 1000);
    }

    return () => clearTimeout(timeout);
  }, [data, error, setGlobalToastNotification, navigate]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isFormValid) {
      request(`/users/${code}/resetpassword`, {
        method: "POST",
        data: { password },
      });
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Card sx={{ padding: 3 }}>
        <Typography component="h1" variant="h5" sx={{ marginBottom: 3 }}>
          Reset Password
        </Typography>
        <Typography component="p" variant="body1" sx={{ marginBottom: 3 }}>
          Enter your new password below and then confirm that it matches.
        </Typography>

        <form onSubmit={handleSubmit}>
          <PasswordInput
            autoFocus
            tabIndex={0}
            disabled={loading || isRedirectPending}
            required
            fullWidth
            name="password"
            label="New Password"
            value={password}
            sx={{ marginBottom: 2 }}
            onChange={(e) => setPassword(e.target.value)}
          />
          <PasswordInput
            tabIndex={1}
            disabled={loading || isRedirectPending}
            required
            fullWidth
            name="confirmPassword"
            label="Confirm New Password"
            value={confirmPassword}
            sx={{ marginBottom: 2 }}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={!isFormValid || loading || isRedirectPending}
          >
            Continue
          </Button>
        </form>
      </Card>
    </Container>
  );
};

export default PasswordChangeRoute;
