import { Box, Breadcrumbs as MuiBreadcrumbs, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import { useBreadcrumbs } from "../context/BreadcrumbsContext";
import { routes } from "../routes";
import { useDetectMobile } from "../hooks/useDetectMobile";

export const Breadcrumbs: React.FC = () => {
  const isMobile = useDetectMobile();
  const { breadcrumbs, stickyTo } = useBreadcrumbs();

  return isMobile ? null : (
    <Box
      sx={
        stickyTo != null
          ? {
              position: "sticky",
              top: stickyTo,
              zIndex: 1200,
            }
          : {}
      }
    >
      <MuiBreadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" htmlColor="#D0D5DD" />}
        sx={{ marginTop: 0, marginBottom: 2, displayPrint: "none" }}
      >
        {breadcrumbs.length === 0 && <span>&nbsp;</span>}
        {breadcrumbs.length > 0 && (
          <Link
            color="inherit"
            to={routes.home.path}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            {routes.home.label}
          </Link>
        )}
        {breadcrumbs.map((breadcrumb, index) => {
          if (breadcrumb.href && index < breadcrumbs.length - 1) {
            return (
              <Link
                key={breadcrumb.label}
                color="inherit"
                to={breadcrumb.href}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {breadcrumb.label}
              </Link>
            );
          } else {
            return (
              <Typography key={breadcrumb.label} color="textPrimary">
                {breadcrumb.label}
              </Typography>
            );
          }
        })}
      </MuiBreadcrumbs>
    </Box>
  );
};
