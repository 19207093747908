import React, { useMemo } from "react";
import { OrderCard } from "../../types/orderCard";
import { groupBy } from "../../utils/array";
import { Box, List } from "@mui/material";
import { Order } from "../../types/order";
import { OrderCardNavMenuItem } from "./OrderCardNavMenuItem";

type OrderCardNavMenuProps = {
  order: Order;
  orderCards: OrderCard[];
  boldedOrderCardGuid: string | undefined;
  onLinkClicked: (orderCardGuid: string) => void;
};

export const OrderCardNavMenu: React.FC<OrderCardNavMenuProps> = ({
  order,
  orderCards,
  boldedOrderCardGuid,
  onLinkClicked,
}) => {
  const orderCardsByCategory = useMemo(
    () => groupBy(orderCards, (orderCard) => orderCard.categoryName),
    [orderCards]
  );

  return (
    <Box
      sx={{
        position: "sticky",
        top: 220,
      }}
    >
      <List dense={true}>
        {Object.keys(orderCardsByCategory).map((key) => {
          const orderCards = orderCardsByCategory[key];
          if (orderCards.length === 0) {
            return null;
          }
          return (
            <OrderCardNavMenuItem
              key={orderCards[0].categoryName}
              order={order}
              orderCards={orderCards}
              boldedOrderCardGuid={boldedOrderCardGuid}
              onLinkClicked={onLinkClicked}
            />
          );
        })}
      </List>
    </Box>
  );
};
