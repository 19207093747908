import { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { type OrderCardBusinessHome as OrderCardBusinessHomeType } from "../../types/orderCardBusinessHome";
import { OrderCardBase } from "./OrderCardBase";
import InputField from "../../components/InputField";
import { InputAdornment } from "@mui/material";
import { getModelConstructionTypeDisplayName, Model } from "../../types/model";
import { getOrderCardError } from "../../types/orderCard";
import { formatAsDate } from "../../utils/date";
import { ChangeModelModal } from "./ChangeModelModal";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Order } from "../../types/order";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";

interface OrderCardBusinessHomeProps {
  order: Order;
  orderCard: OrderCardBusinessHomeType;
  expanded: boolean;
  groupIndex: number;
  refreshOrder: () => void;
}

export const OrderCardBusinessHome: React.FC<OrderCardBusinessHomeProps> = ({
  order,
  orderCard,
  expanded,
  groupIndex,
  refreshOrder,
}) => {
  const { showErrorToast } = useGlobalToastNotificationContext();
  const [modelError, setModelError] = useState<string | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { request: updateOrderRequest, errorMessage: updateOrderErrorMessage } =
    useApiRequest<OrderCardBusinessHomeType>();

  const disabled = order.orderStatus !== "q";

  useEffect(() => {
    const orderCardError = getOrderCardError(orderCard, "ebhm");
    if (!!orderCardError) {
      setModelError(
        `${orderCardError.model.modelNumber} Exp ${formatAsDate(
          orderCardError.model.endDate
        )}`
      );
    } else {
      setModelError(undefined);
    }
  }, [disabled, orderCard, setModelError]);

  useEffect(() => {
    if (updateOrderErrorMessage) {
      showErrorToast({ message: updateOrderErrorMessage });
    }
  }, [updateOrderErrorMessage, showErrorToast]);

  const handleModelChangedSuccessfully = (modelGuid: Model["modelGuid"]) => {
    updateOrderRequest(
      `/orders/${order.orderGuid}/edit`,
      {
        method: "POST",
        data: {
          modelGuid,
        },
      },
      {
        onSuccess: refreshOrder,
      }
    );
    setIsModalOpen(false);
  };

  return (
    <>
      <OrderCardBase
        disabled={disabled}
        orderCard={orderCard}
        expanded={expanded}
        groupIndex={groupIndex}
        additionalIndicatorMenuOptions={[
          {
            label: "Change Model",
            onClick: () => {
              setIsModalOpen(true);
            },
          },
        ]}
      >
        <Grid container spacing={2}>
          <Grid xs={12} sm={12} md={6}>
            <InputField
              label="Series"
              fullWidth
              disabled
              required
              value={orderCard.cardData.seriesName}
              sx={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid xs={12} sm={12} md={6}>
            <InputField
              label="Model Name"
              fullWidth
              disabled
              required
              value={orderCard.cardData.modelName}
              sx={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid xs={12} sm={12} md={6}>
            <InputField
              label="Model Number"
              fullWidth
              disabled
              required
              error={!!modelError}
              helperText={modelError}
              value={orderCard.cardData.modelNumber}
              sx={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid xs={12} sm={12} md={6}>
            <InputField
              label="Construction"
              fullWidth
              disabled
              required
              value={getModelConstructionTypeDisplayName(
                orderCard.cardData.construction
              )}
              sx={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid xs={12} sm={12} md={6}>
            <InputField
              label="Gross Weight"
              fullWidth
              disabled
              required
              value={orderCard.cardData.grossWeight}
              sx={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid xs={12} sm={12} md={6}>
            <InputField
              label="Sections"
              fullWidth
              disabled
              required
              value={orderCard.cardData.sectionCount}
              sx={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid xs={12} sm={12} md={6}>
            <InputField
              label="Living Space"
              fullWidth
              disabled
              required
              value={orderCard.cardData.livingSpace}
              sx={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid xs={12} sm={12} md={6}>
            <InputField
              label="Length"
              fullWidth
              disabled
              required
              value={orderCard.cardData.length}
              sx={{ marginBottom: 2 }}
              endAdornment={
                <InputAdornment position="end">feet</InputAdornment>
              }
            />
          </Grid>
          <Grid xs={12} sm={12} md={6}>
            <InputField
              label="Width"
              fullWidth
              disabled
              required
              value={orderCard.cardData.width}
              sx={{ marginBottom: 2 }}
              endAdornment={
                <InputAdornment position="end">feet</InputAdornment>
              }
            />
          </Grid>
          <Grid xs={12} sm={12} md={6}>
            <InputField
              label="Hitch"
              fullWidth
              disabled
              required
              value={orderCard.cardData.hitch}
              sx={{ marginBottom: 2 }}
              endAdornment={
                <InputAdornment position="end">feet</InputAdornment>
              }
            />
          </Grid>
        </Grid>
      </OrderCardBase>
      {isModalOpen && (
        <ChangeModelModal
          currentModelNumber={orderCard.cardData.modelNumber}
          models={orderCard.models}
          onClose={() => setIsModalOpen(false)}
          onSaveSuccessful={handleModelChangedSuccessfully}
        />
      )}
    </>
  );
};
