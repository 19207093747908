import React from "react";
import { AttachFile, DeleteOutline, Download } from "@mui/icons-material";
import {
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
} from "@mui/material";
import { Attachment } from "../types/attachment";
import { formatAsDate } from "../utils/date";
import { formatAsDataSize } from "../utils/number";

type AttachmentListProps = {
  attachments: Attachment[];
  onDownload?: (attachment: Attachment) => void;
  onDelete?: (attachment: Attachment) => void;
  loading?: boolean;
};

const Item = ({
  title,
  subTitle,
  attachment,
  downloadFile,
  deleteFile,
  loading,
}: {
  title: string;
  subTitle: string;
  attachment: Attachment;
  downloadFile?: (attachment: Attachment) => void;
  deleteFile?: (attachment: Attachment) => void;
  loading?: boolean;
}) => {
  return (
    <ListItem
      secondaryAction={
        <Stack direction={"row"} spacing={1}>
          {deleteFile && (
            <IconButton
              onClick={() => deleteFile(attachment)}
              disabled={loading}
            >
              <DeleteOutline />
            </IconButton>
          )}
          {downloadFile && (
            <IconButton
              onClick={() => downloadFile(attachment)}
              disabled={loading}
            >
              <Download />
            </IconButton>
          )}
        </Stack>
      }
    >
      <ListItemAvatar>
        <AttachFile />
      </ListItemAvatar>
      <ListItemText primary={title} secondary={subTitle || null} style={{wordBreak:'break-all'}} />
    </ListItem>
  );
};

export const AttachmentList: React.FC<AttachmentListProps> = ({
  attachments,
  onDownload,
  onDelete,
  loading,
}) => {
  return (
    <>
      {attachments.map((attachment, index) => (
        <React.Fragment key={attachment.attachmentGuid}>
          <Item
            attachment={attachment}
            title={attachment.attachmentFilename}
            subTitle={`${formatAsDate(
              attachment.attachmentTimestamp
            )} * ${formatAsDataSize(attachment.attachmentSize)}`}
            downloadFile={onDownload}
            deleteFile={onDelete}
            loading={loading}
          />
          {index < attachments.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </>
  );
};
