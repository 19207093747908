import React from "react";
import {
  Box,
  Card,
  CardContent,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { FormState, UseFormRegister } from "react-hook-form";
import InputField from "../../components/InputField";
import { FormData } from "./FormData";
import { User } from "../../types/user";
import { UserRole, UserRoles } from "../../types/userRole";
import { useSession } from "../../hooks/useSession";

interface CompanyInfoTabBusinessRelationProps {
  salesReps: User[];
  // form props
  formState: FormState<FormData>;
  register: UseFormRegister<FormData>;
}

export const CompanyInfoTabBasePriceAdder: React.FC<
  CompanyInfoTabBusinessRelationProps
> = ({ formState, register }) => {
  const { user } = useSession();

  const canEdit =
    user?.userRole != null &&
    (
      [UserRole.OWNER, UserRole.ADMIN, UserRole.ADVANCEDUSER] as UserRoles[]
    ).includes(user.userRole);

  return (
    <Box>
      <Typography variant="body1" fontWeight={600} mb={1}>
        Base Price Adder
      </Typography>
      <Card variant="outlined">
        <CardContent>
          <Stack spacing={2}>
            <InputField
              {...register("businessRelation.percentPerBasePrice", {
                valueAsNumber: true,
                validate: (value) => {
                  if (value == null) {
                    return "Percent Per Model Base Price is required";
                  }
                  if (isNaN(value)) {
                    return "Must be a valid number";
                  }
                  return true;
                },
              })}
              fullWidth
              label="Percent Per Model Base Price"
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              required
              placeholder="Enter Percent Per Model Base Price"
              disabled={!canEdit}
              error={!!formState.errors.businessRelation?.percentPerBasePrice}
              helperText={
                formState.errors.businessRelation?.percentPerBasePrice?.message
              }
              intOnly={false}
            />
            <InputField
              {...register("businessRelation.amountPerFloor", {
                valueAsNumber: true,
                validate: (value) => {
                  if (value == null) {
                    return "Dollars Per Floor is required";
                  }
                  if (isNaN(value)) {
                    return "Must be a valid number";
                  }
                  if (value < 0) {
                    return "Dollars Per Floor must be greater than or equal to 0";
                  }
                  return true;
                },
              })}
              fullWidth
              label="Dollars Per Floor"
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
              required
              type="number"
              intOnly={true}
              placeholder="Enter Dollars Per Floor"
              disabled={!canEdit}
              error={!!formState.errors.businessRelation?.amountPerFloor}
              helperText={
                formState.errors.businessRelation?.amountPerFloor?.message
              }
            />
            <InputField
              {...register("businessRelation.amountPerUnit", {
                valueAsNumber: true,
                validate: (value) => {
                  if (value == null) {
                    return "Dollars Per Unit is required";
                  }
                  if (isNaN(value)) {
                    return "Must be a valid number";
                  }
                  if (value < 0) {
                    return "Dollars Per Unit must be greater than or equal to 0";
                  }
                  return true;
                },
              })}
              fullWidth
              label="Dollars Per Unit"
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
              type="number"
              intOnly={true}
              required
              placeholder="Enter Dollars Per Unit"
              disabled={!canEdit}
              error={!!formState.errors.businessRelation?.amountPerUnit}
              helperText={
                formState.errors.businessRelation?.amountPerUnit?.message
              }
            />
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
};
