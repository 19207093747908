import * as React from "react";
import MuiModal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import { CardContent, CardHeader, SxProps, Theme } from "@mui/material";

const style: SxProps<Theme> = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  width: { xs: "calc(100% - 2rem)", sm: "initial" },
};

type ModalProps = {
  children: React.ReactNode;
  heading?: React.ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  disableEscapeKeyDown?: boolean;
  disableBackdropClick?: boolean;
};

export const Modal = ({
  heading,
  isOpen,
  onClose,
  children,
  disableEscapeKeyDown,
  disableBackdropClick,
}: ModalProps) => {
  const handleOnClose = (_: React.SyntheticEvent, reason: string) => {
    if (disableBackdropClick && reason === "backdropClick") {
      return;
    }
    if (onClose) {
      onClose();
    }
  };
  return (
    <div>
      <MuiModal
        open={isOpen}
        onClose={handleOnClose}
        slotProps={{ backdrop: { sx: { backdropFilter: "blur(8px)" } } }}
        disableEscapeKeyDown={!!disableEscapeKeyDown}
      >
        <Card sx={style}>
          {heading && (
            <CardHeader
              title={heading}
              titleTypographyProps={{ variant: "body1", fontWeight: "bold" }}
            />
          )}
          <CardContent>{children}</CardContent>
        </Card>
      </MuiModal>
    </div>
  );
};
