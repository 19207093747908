import { DateTime } from "luxon";

export const objectToFormData = (
  obj: any,
  formData: FormData = new FormData(),
  namespace?: string
) => {
  Object.keys(obj).forEach((key) => {
    const formKey = namespace ? `${namespace}[${key}]` : key;
    const value = obj[key];
    const isObject = typeof value === "object";
    const isFile = isObject && value instanceof File;
    const isBlob = isObject && value instanceof Blob;
    const isDateTime = isObject && value instanceof DateTime;

    if (value === null) {
      formData.append(formKey, "");
    } else if (value === undefined) {
      // skip undefined values
    } else if (isFile) {
      formData.append(formKey, value);
    } else if (isBlob) {
      formData.append(formKey, value);
    } else if (isDateTime) {
      const unixTimestamp = DateTime.fromISO(value.toString()).toSeconds();
      formData.append(formKey, unixTimestamp.toString());
    } else if (isObject) {
      objectToFormData(value, formData, formKey);
    } else {
      formData.append(formKey, value);
    }
  });

  return formData;
};
