import { useCallback, useEffect, useMemo, useState } from "react";
import { useApiRequest } from "../../hooks/useApiRequest";
import { GridColDef } from "@mui/x-data-grid";
import { Inventory } from "../../types/order";
import {
  Box,
  Grid,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { Table } from "../../components/Table";
import { Search } from "@mui/icons-material";
import { MoreMenuButton } from "../../components/MoreMenuButton";
import { formatAsDate } from "../../utils/date";
import { InventoryTabBuyerDetailsModal } from "./InventoryTabBuyerDetailsModal";
import { OrderCardBusinessBuyer } from "../../types/orderCardBusinessBuyer";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";

interface InventoryTabProps {
  retailerGuid: string | null;
  isOwner?: boolean;
}

export const RetailerInventoryView: React.FC<InventoryTabProps> = ({
  retailerGuid,
  isOwner,
}) => {
  const { showSuccessToast, showErrorToast } =
    useGlobalToastNotificationContext();
  const [pendingInventoryGuid, setPendingInventoryGuid] = useState<
    Inventory["orderGuid"] | null
  >(null);
  const [searchText, setSearchText] = useState("");
  const { request: inventoryRequest, data: inventoryData } =
    useApiRequest<Inventory[]>();
  const { request: unitSoldRequest, errorMessage: inventoryErrorMessage } =
    useApiRequest(false);

  const refreshInventory = useCallback(() => {
    if (!retailerGuid) {
      return;
    }
    inventoryRequest(`/company/retailers/${retailerGuid}/inventory`);
  }, [inventoryRequest, retailerGuid]);

  const filteredInventory = useMemo(() => {
    if (!inventoryData) {
      return [];
    }

    if (searchText?.length === 0) {
      return inventoryData;
    }

    const filtered = inventoryData.filter((inv) => {
      return (
        inv.model.modelNumber.toLowerCase().includes(searchText) ||
        inv.model.modelName.toLowerCase().includes(searchText) ||
        inv.orderIdentifier.toLowerCase().includes(searchText)
      );
    });

    return filtered;
  }, [inventoryData, searchText]);

  const handleBuyerInfoFormSubmit = (
    buyerInfo: OrderCardBusinessBuyer["cardData"]
  ) => {
    unitSoldRequest(
      `/company/retailers/${retailerGuid}/inventory/${pendingInventoryGuid}/edit`,
      {
        method: "POST",
        data: { cardData: buyerInfo },
        headers: {
          "Content-Type": "application/json",
        },
      },
      {
        onSuccess: () => {
          showSuccessToast({ message: "Unit sold successfully" });
          setPendingInventoryGuid(null);
          refreshInventory();
        },
      }
    );
  };

  const columns: GridColDef<Inventory>[] = useMemo(
    () => [
      {
        field: "model.modelNumber",
        headerName: "Model",
        flex: 1,
        valueGetter: (_, order) => order.model.modelNumber,
      },
      { field: "orderIdentifier", headerName: "Order Number", flex: 1 },
      {
        field: "invoiceDateTimestamp",
        headerName: "Invoice Date",
        flex: 1,
        valueFormatter: (value: number) => formatAsDate(value),
      },
      {
        field: "actions",
        headerName: "",
        width: 10,
        sortable: false,
        renderCell: (params) => {
          return (
            <>
              {!isOwner && (
                <MoreMenuButton
                  menuItems={[
                    {
                      label: "Unit Sold",
                      onClick: () =>
                        setPendingInventoryGuid(params.row.orderGuid),
                    },
                  ]}
                />
              )}
            </>
          );
        },
      },
    ],
    [isOwner]
  );

  useEffect(() => {
    if (retailerGuid) {
      refreshInventory();
    }
  }, [retailerGuid, inventoryRequest, refreshInventory]);

  useEffect(() => {
    if (inventoryErrorMessage) {
      showErrorToast({ message: inventoryErrorMessage });
    }
  }, [inventoryErrorMessage, showErrorToast]);

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value.toLowerCase());
    },
    []
  );

  return (
    <>
      <Box>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent={"space-between"}
          marginBottom={2}
        >
          <Grid item>
            <Typography variant="h6" gutterBottom>
              {`Stock Inventory (${inventoryData?.length || 0})`}
            </Typography>
          </Grid>
          <Grid item>
            <OutlinedInput
              placeholder="Search"
              onChange={handleSearchChange}
              size="small"
              color="primary"
              sx={{ backgroundColor: "white", height: 37 }}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>
        <Table
          columns={columns}
          rows={filteredInventory}
          rowSelection={false}
          hideFooter
          disableColumnFilter
          disableColumnMenu
          disableColumnResize
          disableRowSelectionOnClick
          getRowId={(row) => row.orderGuid}
        />
      </Box>
      {pendingInventoryGuid && (
        <InventoryTabBuyerDetailsModal
          onSaveSuccessful={handleBuyerInfoFormSubmit}
          onClose={() => {
            setPendingInventoryGuid(null);
          }}
        />
      )}
    </>
  );
};
