import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import { routes } from "../../routes";
import { useApiRequest } from "../../hooks/useApiRequest";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  OutlinedInput,
  Stack,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { EmptyState } from "../../components/EmptyState";
import { Table } from "../../components/Table";
import { GridColDef } from "@mui/x-data-grid";
import { MoreMenuButton } from "../../components/MoreMenuButton";
import { Attribute } from "../../types/attribute";
import { NewEditAttributeModal } from "./NewEditAttributeModal";
import { generatePath, useNavigate } from "react-router-dom";

const ManageAttributesRoute: React.FC = () => {
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { setBreadcrumbs } = useBreadcrumbs();
  const {
    data: attributes,
    loading: attributesLoading,
    request: attributesRequest,
  } = useApiRequest<Attribute[]>(false);

  usePageMetadata({ title: `Manage ${routes.manageDataAttributes.label}` });

  useEffect(() => {
    setBreadcrumbs([{ label: routes.manageDataAttributes.label }]);
  }, [setBreadcrumbs]);

  const refreshAttributes = useCallback(() => {
    attributesRequest("/company/attributes", { method: "GET" });
  }, [attributesRequest]);

  useEffect(() => {
    refreshAttributes();
  }, [refreshAttributes]);

  const handleNewButtonClick = () => {
    setIsEditing(true);
  };

  const handleViewClick = useCallback(
    (attribute: Attribute) => () => {
      navigate(
        generatePath(routes.manageDataAttributesDetail.path, {
          attributeGuid: attribute.attributeGuid,
        })
      );
    },
    [navigate]
  );

  const handleSave = useCallback(
    (attributeGuid: string) => {
      navigate(
        generatePath(routes.manageDataAttributesDetail.path, {
          attributeGuid: attributeGuid,
        })
      );
    },
    [navigate]
  );

  const handleClose = () => {
    setIsEditing(false);
  };

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value.toLowerCase());
    },
    []
  );
  const columns: GridColDef<Attribute>[] = useMemo(
    () => [
      {
        field: "attributeName",
        headerName: "Name",
        flex: 0.6,
      },
      {
        field: "attributeSelections",
        headerName: "Attribute Selections",
        flex: 1,
        valueGetter: (_, row) => {
          return row.attributeselections
            .map((s) => s.attributeselectionName)
            .join(", ");
        },
      },
      {
        field: "optionNames",
        headerName: "Linked Options",
        flex: 0.3,
        valueGetter: (_, row) => {
          return row.optionNames.length;
        },
      },
      {
        field: "actions",
        headerName: "",
        width: 10,
        align: "right",
        sortable: false,
        renderCell: ({ row }) => {
          return (
            <MoreMenuButton
              menuItems={[
                {
                  label: "View",
                  onClick: handleViewClick(row),
                },
              ]}
            />
          );
        },
      },
    ],
    [handleViewClick]
  );

  const filteredAttributes = useMemo(() => {
    if (!attributes) {
      return [];
    }

    if (searchText.length === 0) {
      return attributes;
    }

    const filtered = attributes.filter((a) => {
      // Does the search text match any attribute names?
      let matchesSearchText = a.attributeName
        .toLowerCase()
        .includes(searchText.toLowerCase());

      if (!matchesSearchText) {
        // Does the search text match any attribute selection names?
        matchesSearchText = a.attributeselections.some((s) =>
          s.attributeselectionName
            .toLowerCase()
            .includes(searchText.toLowerCase())
        );
      }

      return matchesSearchText;
    });

    return filtered;
  }, [attributes, searchText]);

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent={"right"}
        marginBottom={2}
      >
        <Grid item />
        <Grid item>
          <Stack spacing={2} direction={"row-reverse"}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNewButtonClick}
            >
              + New Attribute
            </Button>
            <OutlinedInput
              placeholder="Search"
              onChange={handleSearchChange}
              size="small"
              color="primary"
              sx={{ backgroundColor: "white", height: 37 }}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
            />
          </Stack>
        </Grid>
      </Grid>
      {attributes && attributes.length === 0 && !attributesLoading && (
        <EmptyState heading="No Attributes" />
      )}
      <Box>
        {filteredAttributes && filteredAttributes.length > 0 && (
          <Table
            columns={columns}
            rows={filteredAttributes}
            rowSelection={false}
            hideFooter
            disableColumnFilter
            disableColumnMenu
            disableColumnResize
            disableRowSelectionOnClick
            getRowId={(row) => row.attributeGuid}
            onRowClick={({ row }) => {
              navigate(
                generatePath(routes.manageDataAttributesDetail.path, {
                  attributeGuid: row.attributeGuid,
                })
              );
            }}
          />
        )}
      </Box>
      {isEditing && (
        <NewEditAttributeModal
          isOpen={isEditing}
          isNew={true}
          attribute={null}
          onSaveSuccessful={handleSave}
          onClose={handleClose}
        />
      )}
    </>
  );
};

export default ManageAttributesRoute;
