import { useEffect } from "react";
import { Breadcrumb, useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { usePageMetadata } from "../../hooks/usePageMetadata";

type EmptyRouteProps = {
  breadcrumbs: Breadcrumb[];
  title: string;
};

const EmptyRoute: React.FC<EmptyRouteProps> = ({ breadcrumbs, title }) => {
  const { setBreadcrumbs } = useBreadcrumbs();

  usePageMetadata({ title });

  useEffect(() => {
    setBreadcrumbs(breadcrumbs);
  }, [breadcrumbs, setBreadcrumbs]);

  return <></>;
};

export default EmptyRoute;
