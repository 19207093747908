import React, { useEffect, useMemo, useState } from "react";
import { Popover, Box, InputAdornment } from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DateTime } from "luxon";
import InputField, { InputFieldProps } from "./InputField";
import CheckboxField from "./CheckboxField";
import { CalendarTodayOutlined } from "@mui/icons-material";

export type DatePickerProps = Omit<InputFieldProps, "onChange"> & {
  label?: string;
  allowNoDate?: boolean;
  onChange?: (date: DateTime | null) => void;
  onFocus?: () => void;
  value: DateTime | null | undefined;
  helperText?: string;
  noDateText?: string;
};

export const DatePicker = React.forwardRef<HTMLInputElement, DatePickerProps>(
  (
    {
      label,
      allowNoDate = false,
      onChange,
      onFocus,
      value,
      helperText,
      noDateText = "No End Date",
      disabled,
      ...props
    },
    ref
  ) => {
    const [selectedDate, setSelectedDate] = useState<
      DateTime | null | undefined
    >(value ?? null);
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const handleDateChange = (date: DateTime | null) => {
      const newSelectedDate = date;
      setSelectedDate(newSelectedDate);
      if (onChange) {
        onChange(newSelectedDate);
      }
      setAnchorEl(null);
    };

    const handleOpenPopover = (event: React.MouseEvent<HTMLDivElement>) => {
      if (onFocus) {
        onFocus();
      }
      setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
      setAnchorEl(null);
    };

    const handleNoEndDateCheckBox = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const newSelectedDate = event.target.checked
        ? null
        : selectedDate || DateTime.local();

      setSelectedDate(newSelectedDate);
      handleDateChange(newSelectedDate);
    };

    const calculatedValue = useMemo(() => {
      if (!selectedDate && allowNoDate) {
        return noDateText;
      }
      return selectedDate ? selectedDate.toFormat("MM/dd/yyyy") : "";
    }, [selectedDate, allowNoDate, noDateText]);

    useEffect(() => {
      setSelectedDate(value ?? null);
    }, [value]);

    return (
      <>
        <InputField
          name={props.name}
          inputRef={ref}
          onFocus={onFocus}
          fullWidth={props.fullWidth}
          placeholder="Select Date"
          disabled={disabled}
          startAdornment={
            <InputAdornment position="start">
              <CalendarTodayOutlined />
            </InputAdornment>
          }
          label={label}
          value={calculatedValue}
          onClick={!disabled ? (e) => handleOpenPopover(e) : undefined}
          readOnly
          required={props.required}
          onBlur={props.onBlur}
          helperText={helperText}
          error={props.error}
        />
        <Popover
          open={Boolean(anchorEl)}
          onFocus={onFocus}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          sx={{ marginTop: 1 }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box p={1} paddingLeft={2}>
            <DateCalendar onChange={handleDateChange} value={selectedDate} />
            {allowNoDate && (
              <CheckboxField
                label="No End Date"
                checked={!selectedDate}
                onChange={handleNoEndDateCheckBox}
              />
            )}
          </Box>
        </Popover>
      </>
    );
  }
);
