import { styled } from "@mui/material/styles";
import _Switch, { SwitchProps } from "@mui/material/Switch";

const SwitchComp = styled(_Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        // backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
    "&.Mui-disabled": {
      "& + .MuiSwitch-track": {
        opacity: 0.4,
      },
      "&.Mui-checked": {
        color: "#fff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

// Allows for the Switch to be pressed inside a table that has a row click event
const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  event.stopPropagation();
};

export const Switch = (props: SwitchProps) => {
  return <SwitchComp {...props} onClick={handleClick} />;
};
