import React from "react";
import { AttachmentManager } from "../../components/AttachmentManager";
import { DropzoneRef } from "react-dropzone";
import { Attachment } from "../../types/attachment";
import { useSession } from "../../hooks/useSession";
import { UserRole, UserRoles } from "../../types/userRole";
import { Order } from "../../types/order";

interface OrderTabAttachmentsProps {
  order: Order | null;
  dropzoneRef?: React.RefObject<DropzoneRef>;
  onAttachmentsChanged: () => void;
}

export const OrderTabAttachments: React.FC<OrderTabAttachmentsProps> = ({
  order,
  dropzoneRef,
  onAttachmentsChanged,
}) => {
  const { user } = useSession();
  const canDelete =
    user?.userRole != null &&
    (
      [UserRole.OWNER, UserRole.ADMIN, UserRole.ADVANCEDUSER] as UserRoles[]
    ).includes(user.userRole);

  return (
    <AttachmentManager
      createAttachmentUrl={`/orders/${order?.orderGuid}/attach`}
      deleteAttachmentUrl={
        canDelete
          ? (attachment: Attachment) =>
              `/company/attachments/${attachment.attachmentGuid}/delete`
          : undefined
      }
      downloadAttachmentUrl={(attachment: Attachment) =>
        `/company/orders/attachments/${attachment.attachmentGuid}`
      }
      dropzoneRef={dropzoneRef}
      attachments={order?.attachments ?? []}
      onRefreshAttachments={onAttachmentsChanged}
    />
  );
};
