import { Box, Button, Typography } from "@mui/material";
import { Modal } from "../../components/Modal";
import { useSession } from "../../hooks/useSession";

type MissingDataModalProps = {
  onClose: () => void;
};

export const MissingDataModal: React.FC<MissingDataModalProps> = ({
  onClose,
}) => {
  const { user } = useSession();

  const nonManager =
    user?.userRole === "basicuser" || user?.userRole === "advanceduser";
  return (
    <Modal
      isOpen={true}
      heading="Not Enough Data to Create Quotes"
      onClose={onClose}
    >
      <Box width={550}>
        <Typography component="div">
          {`Company setup is required to create quotes. ${
            nonManager ? "The owner or an admin needs to go to" : "Please go to"
          } the Manage Data page and complete the following sections:`}
          <ul>
            <li>Model/Series</li>
            <li>Selection Options</li>
          </ul>
        </Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button variant="contained" color="primary" onClick={onClose}>
          Okay
        </Button>
      </Box>
    </Modal>
  );
};
