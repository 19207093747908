import React from "react";
import { useEffect, useMemo } from "react";
import { Alert, Box, Button, Grid, Stack, InputAdornment } from "@mui/material";
import { Modal } from "../../components/Modal";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Surcharge } from "../../types/surcharge";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import InputField from "../../components/InputField";
import SelectField from "../../components/SelectField";
import { Series } from "../../types/series";
import { DatePicker } from "../../components/DatePicker";
import { DateTime } from "luxon";
import { isValidNumber } from "../../utils/validation";

type FormData = {
  surchargeGuid?: string;
  seriesGuid: string;
  surchargeAmount: number | null;
  surchargeUom: string;
  startDate: DateTime | null;
  endDate: DateTime | null | undefined;
};

type NewEditModalProps = {
  isOpen: boolean;
  onSaveSuccessful: () => void;
  onClose: () => void;
  surcharge?: Surcharge | null;
  isNew: boolean;
};

const getDefaultValues = (surcharge?: Surcharge | null): FormData => {
  return {
    surchargeGuid: surcharge?.surchargeGuid || "",
    surchargeAmount: surcharge?.surchargeAmount || null,
    surchargeUom: surcharge?.surchargeUom || "",
    startDate: surcharge?.startDate
      ? DateTime.fromSeconds(surcharge?.startDate)
      : null,
    endDate: surcharge?.endDate
      ? DateTime.fromSeconds(surcharge?.endDate)
      : null,
    seriesGuid: surcharge?.series?.seriesGuid || "",
  };
};
export const NewEditModal: React.FC<NewEditModalProps> = ({
  isOpen,
  onSaveSuccessful,
  onClose,
  surcharge,
  isNew,
}) => {
  const [hasConflict, setHasConflict] = React.useState(false);

  const { data, loading, request, status, error } =
    useApiRequest<Surcharge>(true);

  const {
    data: series,
    loading: seriesLoading,
    request: seriesRequest,
  } = useApiRequest<Series[]>(true);

  const {
    control,
    register,
    handleSubmit,
    watch,
    trigger,
    formState: { errors, isValid, isDirty },
  } = useForm<FormData>({
    defaultValues: getDefaultValues(surcharge),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const startDate = watch("startDate");
  const endDate = watch("endDate");

  useEffect(() => {
    trigger("startDate");
    trigger("endDate");
  }, [startDate, endDate, trigger]);

  useEffect(() => {
    setHasConflict(error?.response?.status === 409);
  }, [error, setHasConflict]);

  const seriesOptions = useMemo(() => {
    return series
      ?.filter((s) => s.isActive)
      ?.map((s) => ({
        label: s.seriesName,
        value: s.seriesGuid,
      }));
  }, [series]);

  useEffect(() => {
    seriesRequest("/company/series", {
      method: "GET",
    });
  }, [seriesRequest]);

  const onSubmit: SubmitHandler<FormData> = (data: FormData) => {
    if (isNew) {
      request("/company/surcharges/add", {
        method: "POST",
        data: data,
      });
    } else {
      request(`/company/surcharges/${surcharge?.surchargeGuid}/edit`, {
        method: "POST",
        data: data,
      });
    }
  };

  useEffect(() => {
    if (data && status === "ok" && loading === false) {
      onSaveSuccessful();
    }
  }, [data, onSaveSuccessful, status, loading]);

  return (
    <Modal heading={isNew ? "New Surcharge" : "Edit Surcharge"} isOpen={isOpen} onClose={onClose}>
      <Box sx={{ maxWidth: 600 }}>
        {hasConflict && (
          <Alert severity="error" sx={{ marginBottom: 2 }}>
            Surcharge for current series exists for provided dates
          </Alert>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} marginBottom={2}>
            <Grid item xs={12} sm={12}>
              <Grid item xs={12} sm={12} marginBottom={2}>
                <Controller
                  name="seriesGuid"
                  control={control}
                  rules={{ required: "Enter Series" }}
                  render={({ field }) => (
                    <SelectField
                      label="Series"
                      required
                      placeholder={
                        seriesOptions?.length === 0
                          ? "No Series Yet"
                          : "Select Series"
                      }
                      options={seriesOptions || []}
                      disabled={
                        seriesLoading || seriesOptions?.length === 0 || !isNew
                      }
                      error={!!errors.seriesGuid}
                      helperText={errors.seriesGuid?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid container spacing={2} marginBottom={2}>
                <Grid item xs={12} sm={6}>
                  <InputField
                    label="Price"
                    placeholder="Enter Price"
                    fullWidth
                    required
                    {...register("surchargeAmount", {
                      required: "Enter Price",
                      validate: isValidNumber,
                    })}
                    error={!!errors.surchargeAmount}
                    helperText={errors.surchargeAmount?.message}
                    type="number"
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    intOnly={false}            
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="surchargeUom"
                    control={control}
                    rules={{ required: "Enter Unit of Measure" }}
                    render={({ field }) => (
                      <SelectField
                        label="Per"
                        placeholder="Select"
                        required
                        options={[
                          { label: "Floor", value: "f" },
                          { label: "Square Foot", value: "s" },
                        ]}
                        error={!!errors.surchargeUom}
                        helperText={errors.surchargeUom?.message}
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="startDate"
                    control={control}
                    rules={{
                      required: "Enter Start Date",
                      validate: (startDate) => {
                        if (!startDate) {
                          return "Start Date is required";
                        }
                        if (startDate && !endDate) {
                          return true;
                        }
                        if (endDate && startDate > endDate) {
                          return "Start Date must be before End Date";
                        }
                        return true;
                      },
                    }}
                    render={({ field }) => (
                      <DatePicker
                        label="Start Date"
                        fullWidth
                        {...field}
                        required
                        error={!!errors.startDate}
                        helperText={errors.startDate?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="endDate"
                    control={control}
                    rules={{
                      validate: (endDateValue) => {
                        if (
                          endDateValue &&
                          startDate &&
                          startDate > endDateValue
                        ) {
                          return "End Date must be after Start Date";
                        }
                        return true;
                      },
                    }}
                    render={({ field }) => (
                      <DatePicker
                        label="End Date"
                        required
                        fullWidth
                        allowNoDate
                        {...field}
                        error={!!errors.endDate}
                        helperText={errors.endDate?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="end">
              <Stack spacing={1} direction="row">
                <Button variant="outlined" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading || !isValid || !isDirty}
                >
                  Save
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};
