import { OrderCardBusinessLogistics } from "./orderCardBusinessLogistics";
import { OrderCardError } from "./orderCard";
import { OrderCardStatus, orderCardTypes } from "./orderCardTypes";

export type OrderCardBusinessBuyer = {
  orderCardGuid: string;
  orderCardType: (typeof orderCardTypes)["businessBuyer"];
  categoryName: string;
  groupName: string;
  subgroupName: string;
  isRequired: boolean;
  isApproved: boolean;
  isHidden: boolean;
  isLocked: boolean;
  orderCardStatus: OrderCardStatus;
  sortOrder: number;
  errors: OrderCardError[];
  cardData: {
    firstName: string | null;
    lastName: string | null;
    address1: string | null;
    address2: string | null;
    lotNumber: string | null;
    postalCode: string | null;
    city: string | null;
    state: string | null;
    county: string | null;
    homePhone: string | null;
    mobilePhone: string | null;
    email: string | null;
  };
};

export const isBusinessBuyerOrderCardVisible = (
  orderCardLogistics: OrderCardBusinessLogistics
): boolean => {
  return orderCardLogistics.cardData.soldAs !== "y";
};
