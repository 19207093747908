import React, { useEffect, useMemo } from "react";
import {
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Button,
  Typography,
  Stack,
  Alert,
  FormLabel,
} from "@mui/material";
import { Modal } from "../../components/Modal";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  AutocompleteField,
  GroupedOption,
} from "../../components/AutocompleteField";
import {
  Order,
  OrderSoldAsType,
  getSoldAsTypeDisplayName,
} from "../../types/order";
import { Series } from "../../types/series";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Retailer } from "../../types/tenant";

type FormData = {
  modelGuid: string | null;
  retailerGuid: string | null;
  soldAsType: OrderSoldAsType;
};

const getDefaultValues = (): FormData => {
  return {
    modelGuid: null,
    retailerGuid: null,
    soldAsType: "r",
  };
};

type NewTemplateModalProps = {
  isOpen: boolean;
  onSaveSuccessful: (order: Order) => void;
  onClose: () => void;
};

export const NewTemplateModal: React.FC<NewTemplateModalProps> = ({
  isOpen,
  onSaveSuccessful,
  onClose,
}) => {
  const {
    data: seriesData,
    loading: seriesLoading,
    request: seriesRequest,
  } = useApiRequest<Series[]>();

  const {
    data: retailersData,
    loading: retailersLoading,
    request: retailersRequest,
  } = useApiRequest<Retailer[]>();

  const {
    data: orderData,
    loading: orderLoading,
    request: orderRequest,
    status: orderStatus,
    errorMessage: orderErrorMessage,
  } = useApiRequest<Order>();

  useEffect(() => {
    seriesRequest("/quotes/series", {
      method: "GET",
    });
  }, [seriesRequest]);

  useEffect(() => {
    retailersRequest("/quotes/retailers", {
      method: "GET",
    });
  }, [retailersRequest]);

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
    watch,
  } = useForm<FormData>({
    defaultValues: getDefaultValues(),
  });

  const soldAsType = watch("soldAsType");

  type GroupedOptionSelectionOption = GroupedOption<string> & {};

  const modelSelectionOptions = useMemo(() => {
    const options: Array<GroupedOptionSelectionOption> = [];

    seriesData?.forEach((series) => {
      series.models.forEach((model) => {
        options.push({
          label: `Model ${model.modelNumber}`,
          value: model.modelGuid,
          groupValue: series.seriesGuid,
          groupLabel: series.seriesName,
        });
      });
    });

    return options;
  }, [seriesData]);

  const retailerSelectionOptions = useMemo(() => {
    if (!retailersData) return [];
    return retailersData.map((retailer) => ({
      label: `${retailer.tenantName}`,
      citystate:
        retailer.tenantCity && retailer.tenantState
          ? `${retailer.tenantCity}, ${retailer.tenantState}`
          : "",
      value: retailer.tenantGuid,
    }));
  }, [retailersData]);

  const showRetailer = useMemo(() => {
    return ["r", "s"].includes(soldAsType);
  }, [soldAsType]);

  const onSubmit: SubmitHandler<FormData> = (data: FormData) => {
    const cleanData = {
      ...data,
      retailerGuid: showRetailer ? data.retailerGuid : null,
    };

    orderRequest("/company/templates/add", {
      method: "POST",
      data: cleanData,
    });
  };

  useEffect(() => {
    if (orderData && orderStatus === "ok") {
      onSaveSuccessful(orderData);
    }
  }, [orderStatus, onSaveSuccessful, orderData]);

  return (
    <Modal heading={"Create New Template"} isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ width: 300 }}>
          {orderErrorMessage && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {orderErrorMessage}
            </Alert>
          )}
          <Controller
            control={control}
            name="modelGuid"
            rules={{ required: "Please select a value." }}
            render={({ field }) => {
              const selectedValue = modelSelectionOptions.find(
                (s) => s.value === field.value
              );

              return (
                <AutocompleteField
                  label="Model"
                  {...field}
                  disabled={seriesLoading}
                  options={modelSelectionOptions}
                  value={selectedValue ?? null}
                  placeholder="Select Model Option"
                  onChange={(_, value) => {
                    field.onChange(value?.value ?? null);
                  }}
                />
              );
            }}
          />
          <Box sx={{ my: 2 }}>
            <Controller
              name="soldAsType"
              control={control}
              render={({ field }) => (
                <>
                  <FormLabel component="legend">Sold As</FormLabel>
                  <ToggleButtonGroup
                    fullWidth
                    value={field.value}
                    onChange={(_, value) => {
                      if (value == null) return;
                      return value != null && field.onChange(value);
                    }}
                    exclusive
                  >
                    <ToggleButton value="r" sx={{ fontSize: 12 }}>
                      {getSoldAsTypeDisplayName("r")}
                    </ToggleButton>
                    <ToggleButton value="s" sx={{ fontSize: 12 }}>
                      {getSoldAsTypeDisplayName("s")}
                    </ToggleButton>
                    <ToggleButton value="y" sx={{ fontSize: 12 }}>
                      {getSoldAsTypeDisplayName("y")}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </>
              )}
            />
          </Box>
          {showRetailer && (
            <Box sx={{ my: 2 }}>
              <Controller
                control={control}
                name="retailerGuid"
                rules={{ required: "Please select a value." }}
                render={({ field }) => {
                  const selectedValue = retailerSelectionOptions.find(
                    (s) => s.value === field.value
                  );

                  return (
                    <AutocompleteField
                      label="Retailer"
                      {...field}
                      disabled={retailersLoading}
                      options={retailerSelectionOptions}
                      value={selectedValue ?? null}
                      placeholder="Select Retailer"
                      onChange={(_, value) => {
                        field.onChange(value?.value ?? null);
                      }}
                      renderOption={(props, option) => (
                        <Box p={1} component="li" {...props}>
                          <Stack
                            spacing={1}
                            direction="row"
                            alignContent="center"
                          >
                            <Typography variant="body1">
                              {option.label}
                            </Typography>
                            <Typography variant="body2" color="gray" pt={0.3}>
                              {option.citystate}
                            </Typography>
                          </Stack>
                        </Box>
                      )}
                    />
                  );
                }}
              />
            </Box>
          )}
          <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="outlined"
              onClick={onClose}
              fullWidth
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isDirty || !isValid || orderLoading}
              fullWidth
              sx={{ ml: 1 }}
            >
              Create
            </Button>
          </Box>
        </Box>
      </form>
    </Modal>
  );
};
