import React from "react";
import { Card, CardContent, Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { Retailer } from "../../types/tenant";
import { Control, FormState, UseFormRegister } from "react-hook-form";
import { FormData } from "./FormData";
import { User } from "../../types/user";
import { CompanyInfoTabHomeDetails } from "./CompanyInfoTabHomeDetails";
import { CompanyInfoTabContact } from "./CompanyInfoTabContact";
import { CompanyInfoTabBusinessBillingAddress } from "./CompanyInfoTabBusinessBillingAddress";
import { CompanyInfoTabShippingAddress } from "./CompanyInfoTabShippingAddress";
import { CompanyInfoTabBusinessRelation } from "./CompanyInfoTabBusinessRelation";
import { CompanyInfoTabBasePriceAdder } from "./CompanyInfoTabBasePriceAdder";
import { CompanyInfoTabAttachments } from "./CompanyInfoTabAttachments";
import { CompanyInfoTabNotes } from "./CompanyInfoTabNotes";
import { RetailerNote } from "../../types/retailerNote";

interface CompanyInfoTabProps {
  retailer: Retailer | null;
  tenantGuid?: string;
  salesReps: User[];
  onAddNote: (note: RetailerNote) => void;
  onAttachmentsChanged: () => void;
  // form props
  control: Control<FormData>;
  formState: FormState<FormData>;
  register: UseFormRegister<FormData>;
}

export const CompanyInfoTab: React.FC<CompanyInfoTabProps> = ({
  retailer,
  tenantGuid,
  salesReps,
  control,
  formState,
  register,
  onAddNote,
  onAttachmentsChanged,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid xs={12} sm={8}>
        <Card variant="outlined">
          <CardContent>
            <CompanyInfoTabHomeDetails
              retailer={retailer}
              formState={formState}
              register={register}
            />
            <CompanyInfoTabContact
              retailer={retailer}
              formState={formState}
              register={register}
            />
            <CompanyInfoTabBusinessBillingAddress
              retailer={retailer}
              control={control}
              formState={formState}
              register={register}
            />
            <CompanyInfoTabShippingAddress
              retailer={retailer}
              control={control}
              formState={formState}
              register={register}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid xs={12} sm={4}>
        <Stack spacing={5}>
          <CompanyInfoTabBusinessRelation
            salesReps={salesReps}
            control={control}
            formState={formState}
            register={register}
          />
          <CompanyInfoTabBasePriceAdder
            salesReps={salesReps}
            formState={formState}
            register={register}
          />
          <CompanyInfoTabNotes retailer={retailer} onAddNote={onAddNote} />
          <CompanyInfoTabAttachments
            retailer={retailer}
            tenantGuid={tenantGuid}
            onAttachmentsChanged={onAttachmentsChanged}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};
