import React from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { getAttributePresentAsDisplayName } from "../types/attribute";

interface AttributePresentAsRadioGroupProps {
  onChange: (...event: any[]) => void;
  onBlur: () => void;
  value: string;
  disabled?: boolean;
  name: string;
}

export const AttributePresentAsRadioGroup = React.forwardRef<
  HTMLInputElement,
  AttributePresentAsRadioGroupProps
>(({ onChange, onBlur, value, disabled, name }, ref) => {
  return (
    <FormControl>
      <FormLabel id="attributePresentAsRadios" required>
        Present options as
      </FormLabel>
      <RadioGroup
        aria-labelledby="attributePresentAsRadios"
        row
        sx={{
          justifyContent: "space-evenly",
          flexWrap: "nowrap",
        }}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        name={name}
        ref={ref}
      >
        <FormControlLabel
          value="d"
          control={<Radio />}
          label={
            <Stack width="100%">
              <Typography variant="caption" fontWeight="500">
                {getAttributePresentAsDisplayName("d")}
              </Typography>
              <Stack
                height="19px"
                width="100%"
                border="1px solid"
                borderColor="primary.main"
                borderRadius="4px"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <ArrowDropDownIcon color="primary" />
              </Stack>
            </Stack>
          }
          sx={{
            border: "1px solid",
            borderColor: "grey.300",
            borderRadius: "8px",
            padding: 2,
            paddingLeft: "2px",
            width: "100%",
            marginLeft: 0,
            marginRight: 1,
            ".MuiTypography-root": {
              width: "100%",
            },
          }}
        />
        <FormControlLabel
          value="s"
          control={<Radio />}
          label={
            <Box>
              <Typography variant="caption" fontWeight="500">
                {getAttributePresentAsDisplayName("s")}
              </Typography>
              <Stack
                spacing="4px"
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                {Array.from({ length: 5 }, (_, i) => (
                  <Box
                    key={i}
                    height="19px"
                    width="100%"
                    border="1px solid"
                    borderColor="primary.main"
                    borderRadius="100px"
                  />
                ))}
              </Stack>
            </Box>
          }
          sx={{
            border: "1px solid",
            borderColor: "grey.300",
            borderRadius: "8px",
            padding: 2,
            paddingLeft: "2px",
            width: "100%",
            marginRight: 0,
            marginLeft: 1,
            ".MuiTypography-root": {
              width: "100%",
            },
          }}
        />
      </RadioGroup>
    </FormControl>
  );
});
