import { OrderCardError } from "./orderCard";
import { orderCardTypes } from "./orderCardTypes";

export type OrderCardAddAppliance = {
  orderCardGuid: string;
  orderCardType: (typeof orderCardTypes)["addAppliance"];
  categoryName: string;
  groupName: string;
  subgroupName: string;
  isRequired: undefined;
  isApproved: undefined;
  isHidden: undefined;
  isLocked: boolean;
  orderCardStatus: undefined;
  sortOrder: number;
  errors: OrderCardError[];
  cardData: undefined;
};

export const getOrderCardAddAppliance = (
  orderCard?: Partial<OrderCardAddAppliance>
): OrderCardAddAppliance => {
  return {
    orderCardGuid: "add-appliance",
    orderCardType: orderCardTypes.addAppliance,
    categoryName: "Invoice Details",
    groupName: "Appliances",
    subgroupName: "Appliances",
    isRequired: undefined,
    isApproved: undefined,
    isHidden: undefined,
    isLocked: false,
    orderCardStatus: undefined,
    sortOrder: 1000000,
    errors: [],
    cardData: undefined,
    ...orderCard,
  };
};
