import React, { useCallback, useEffect, useMemo } from "react";
import { Modal } from "../../components/Modal";
import InputField from "../../components/InputField";
import { Alert, Box, Button, Grid, Stack } from "@mui/material";
import { useApiRequest } from "../../hooks/useApiRequest";
import SelectField from "../../components/SelectField";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Vendor, VendorType, vendorTypeOptions } from "../../types/vendor";
import SwitchField from "../../components/SwitchField";
import { Tenant } from "../../types/tenant";
import { stateAbbrSelectOptions } from "../../types/states";

type FormData = {
  vendorGuid: string;
  vendorName: string;
  isActive: boolean;
  vendorType: Vendor["vendorType"] | "";
  vendorAddress: string;
  vendorCity: string;
  vendorState: string;
  vendorZip: string;
};

type NewEditModalProps = {
  isOpen: boolean;
  onSaveSuccessful: () => void;
  onClose: () => void;
  vendor?: Vendor | null;
  isNew: boolean;
};

const getDefaultValues = (vendor?: Vendor | null): FormData => {
  return {
    vendorGuid: vendor?.vendorGuid || "",
    vendorName: vendor?.vendorName || "",
    isActive: vendor?.isActive ?? true,
    vendorType: vendor?.vendorType || "",
    vendorAddress: vendor?.vendorAddress || "",
    vendorCity: vendor?.vendorCity || "",
    vendorState: vendor?.vendorState || "",
    vendorZip: vendor?.vendorZip || "",
  };
};

export const NewEditModal: React.FC<NewEditModalProps> = ({
  isOpen,
  onSaveSuccessful,
  onClose,
  vendor,
  isNew,
}) => {
  const { data, loading, request, status, error } = useApiRequest<Vendor>(true);

  const { data: tenantData, request: tenantRequest } =
    useApiRequest<Tenant>(true);

  const {
    control,
    register,
    handleSubmit,
    formState: { isValid, isDirty },
    watch,
  } = useForm<FormData>({
    defaultValues: getDefaultValues(vendor),
  });

  const vendorType = watch("vendorType");

  const isAddressRequired = useMemo(
    () => vendorType === VendorType.FloorPlanLender,
    [vendorType]
  );

  const onSubmit: SubmitHandler<FormData> = (data: FormData) => {
    if (isNew) {
      request("/company/vendors/add", {
        method: "POST",
        data: {
          ...data,
          vendorIdentifier: tenantData?.nextVendorIdentifier,
        },
      });
    } else {
      request(`/company/vendors/${vendor?.vendorGuid}/edit`, {
        method: "POST",
        data,
      });
    }
  };

  const refreshTenant = useCallback(() => {
    tenantRequest("/company", {
      method: "GET",
    });
  }, [tenantRequest]);

  useEffect(() => {
    refreshTenant();
  }, [refreshTenant]);

  useEffect(() => {
    if (data && status === "ok" && loading === false) {
      onSaveSuccessful();
    }

    if (error?.response?.status === 409) {
      refreshTenant();
    }
  }, [data, onSaveSuccessful, status, loading, error, refreshTenant]);

  return (
    <Modal
      heading={`${isNew ? "Create" : "Edit"} Vendor`}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box sx={{ maxWidth: 600 }}>
        {error && (
          <Alert severity="error" sx={{ marginBottom: 2 }}>
            {error.response?.status === 409
              ? "Vendor identifier is already assigned. Vendor identifier has been updated. Please re-submit."
              : "Something went wrong. Please try again."}
          </Alert>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputField
            label="Vendor Name"
            placeholder="Vendor Name"
            required
            fullWidth
            sx={{ mb: 2 }}
            {...register("vendorName", { required: "Enter Name" })}
          />
          <InputField
            label="VendorID"
            fullWidth
            disabled
            sx={{ mb: 2 }}
            value={
              isNew
                ? tenantData?.nextVendorIdentifier ?? ""
                : vendor?.vendorIdentifier
            }
          />
          <Controller
            name="vendorType"
            control={control}
            rules={{ required: "Enter Vendor Type" }}
            render={({ field }) => (
              <SelectField
                label="Vendor Type"
                placeholder="Select Type"
                required
                options={vendorTypeOptions}
                sx={{ mb: 2 }}
                {...field}
              />
            )}
          />
          <Controller
            name="isActive"
            control={control}
            render={({ field }) => (
              <SwitchField
                {...field}
                label="Active"
                checked={field.value}
                sx={{ mb: 2 }}
              />
            )}
          />
          <InputField
            label="Address"
            placeholder="Enter Address"
            required={isAddressRequired}
            fullWidth
            sx={{ mb: 2 }}
            {...register("vendorAddress", { required: isAddressRequired })}
          />
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <InputField
                label="City"
                placeholder="Enter City"
                required={isAddressRequired}
                fullWidth
                sx={{ mb: 2 }}
                {...register("vendorCity", { required: isAddressRequired })}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="vendorState"
                control={control}
                rules={{ required: isAddressRequired }}
                render={({ field }) => (
                  <SelectField
                    label="State"
                    required={isAddressRequired}
                    placeholder="Select"
                    options={stateAbbrSelectOptions}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <InputField
            label="ZIP"
            placeholder="Enter ZIP"
            required={isAddressRequired}
            fullWidth
            sx={{ mb: 2 }}
            {...register("vendorZip", {
              required: isAddressRequired,
            })}
          />
          <Stack spacing={1} direction="row-reverse">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!isValid || !isDirty || loading}
            >
              Save
            </Button>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
};
