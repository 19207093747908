import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import { routes } from "../../routes";
import { useApiRequest } from "../../hooks/useApiRequest";
import {
  Avatar,
  Box,
  Button,
  Grid,
  InputAdornment,
  OutlinedInput,
  Stack,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { EmptyState } from "../../components/EmptyState";
import { Table } from "../../components/Table";
import { GridColDef } from "@mui/x-data-grid";
import { MoreMenuButton } from "../../components/MoreMenuButton";
import { generatePath, useNavigate } from "react-router-dom";
import {
  Retailer,
  getTenantInitials,
  getTenantPhotoUrlSrc,
} from "../../types/tenant";
import { NewEditRetailerModal } from "./NewEditRetailerModal";
import { Switch } from "../../components/Switch";
import { useSession } from "../../hooks/useSession";
import { UserRole, UserRoles } from "../../types/userRole";
import {
  User,
  getUserFullName,
  getUserInitials,
  getUserPhotoUrlSrc,
} from "../../types/user";
import { RetailerManufacturer } from "../../types/retailerManufacturer";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";

const ManageRetailersRoute: React.FC = () => {
  const navigate = useNavigate();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { showErrorToast } = useGlobalToastNotificationContext();
  const { user } = useSession();
  const [isEditing, setIsEditing] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [retailers, setRetailers] = useState<Retailer[]>([]);

  const {
    data: retailersData,
    loading: retailersLoading,
    request: retailersRequest,
  } = useApiRequest<{
    retailersInCompany: Retailer[];
    retailersNotInCompany: Retailer[];
  }>(false);

  const {
    data: businessRelationEditData,
    loading: businessRelationEditLoading,
    request: businessRelationEditRequest,
    status: businessRelationEditStatus,
    error: businessRelationEditError,
  } = useApiRequest<RetailerManufacturer[]>(false);

  const {
    request: activationToggleRequest,
    status: activationToggleStatus,
    loading: activateLoading,
  } = useApiRequest();

  const { data: usersData, request: usersRequest } =
    useApiRequest<User[]>(false);

  usePageMetadata({ title: `Manage ${routes.manageDataRetailers.label}` });

  useEffect(() => {
    setBreadcrumbs([{ label: routes.manageDataRetailers.label }]);
  }, [setBreadcrumbs]);

  const refreshRetailers = useCallback(() => {
    retailersRequest("/company/retailers", { method: "GET" });
  }, [retailersRequest]);

  useEffect(() => {
    refreshRetailers();
  }, [refreshRetailers]);

  useEffect(() => {
    setRetailers(retailersData?.retailersInCompany ?? []);
  }, [retailersData]);

  useEffect(() => {
    if (
      businessRelationEditData &&
      businessRelationEditStatus === "ok" &&
      businessRelationEditLoading === false
    ) {
      refreshRetailers();
    } else if (businessRelationEditError) {
      showErrorToast({ message: "Something went wrong. Please try again.." });
    }
  }, [
    businessRelationEditData,
    businessRelationEditError,
    businessRelationEditLoading,
    businessRelationEditStatus,
    refreshRetailers,
    showErrorToast,
  ]);

  const handleActiveToggle = useCallback(
    (retailer: Retailer) => () => {
      const isActive = !(retailer.businessRelation?.isActive ?? false);
      activationToggleRequest(
        `/company/retailers/${retailer.tenantGuid}/businessrelation`,
        {
          method: "POST",
          data: { isActive },
        }
      );
    },
    [activationToggleRequest]
  );

  useEffect(() => {
    if (activateLoading === false && activationToggleStatus === "ok") {
      refreshRetailers();
    }
  }, [activateLoading, activationToggleStatus, refreshRetailers]);

  useEffect(() => {
    usersRequest(`/users`, { method: "GET" });
  }, [usersRequest]);

  const handleNewButtonClick = () => {
    setIsEditing(true);
  };

  const handleViewClick = useCallback(
    (retailer: Retailer) => () => {
      navigate(
        generatePath(routes.manageDataRetailerDetail.path, {
          tenantGuid: retailer.tenantGuid,
        })
      );
    },
    [navigate]
  );

  const handleSave = useCallback(
    (tenantGuid: string) => {
      navigate(
        generatePath(routes.manageDataRetailerDetail.path, {
          tenantGuid: tenantGuid,
        })
      );
    },
    [navigate]
  );

  const handleClose = () => {
    setIsEditing(false);
  };

  const handleProcessRowUpdate = useCallback(
    (updatedRow: Retailer, originalRow: Retailer) => {
      const isSalesRepUpdated =
        updatedRow.businessRelation?.manufacturerSalesRepUserGuid !==
        originalRow.businessRelation?.manufacturerSalesRepUserGuid;

      if (isSalesRepUpdated) {
        businessRelationEditRequest(
          `company/retailers/${updatedRow.tenantGuid}/businessrelation`,
          {
            method: "POST",
            data: {
              manufacturerSalesRepUserGuid:
                updatedRow.businessRelation?.manufacturerSalesRepUserGuid,
            },
          }
        );
      }

      return updatedRow;
    },
    [businessRelationEditRequest]
  );

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value.toLowerCase());
    },
    []
  );

  const columns: GridColDef<Retailer>[] = useMemo(() => {
    const canEdit =
      user?.userRole != null &&
      (
        [UserRole.OWNER, UserRole.ADMIN, UserRole.ADVANCEDUSER] as UserRoles[]
      ).includes(user.userRole);

    const cols: GridColDef<Retailer>[] = [
      {
        field: "tenantName",
        headerName: "Name",
        flex: 0.6,
        renderCell: ({ row, value }) => {
          return (
            <Stack direction="row" alignItems="center" columnGap={1}>
              <Avatar
                src={getTenantPhotoUrlSrc(row)}
                sx={{ width: 32, height: 32, fontSize: "16px" }}
              >
                {getTenantInitials(row)}
              </Avatar>
              {value}
            </Stack>
          );
        },
      },
      {
        field: "tenantCity",
        headerName: "City",
        flex: 0.6,
      },
      {
        field: "tenantState",
        headerName: "State",
        flex: 0.6,
      },
      {
        field: "businessRelationManufacturerSalesRepUserGuid",
        headerName: "Assigned Sales Rep",
        type: "singleSelect",
        flex: 0.6,
        editable: canEdit,
        valueOptions: usersData ?? [],
        getOptionValue: (value: any) => value.userGuid,
        getOptionLabel: (value: any) => getUserFullName(value),
        valueGetter: (_, row) => {
          return row.businessRelation?.manufacturerSalesRepUserGuid ?? "";
        },
        valueSetter: (value: string, row) => {
          const updatedBusinessRelation = {
            ...row.businessRelation,
            manufacturerSalesRepUserGuid: value ?? null,
          } as RetailerManufacturer;

          return { ...row, businessRelation: updatedBusinessRelation };
        },
        renderCell: (params) => {
          const manufacturerSalesRepUserGuid =
            params.row.businessRelation?.manufacturerSalesRepUserGuid;
          const salesRepUser = usersData?.find(
            (u) => u.userGuid === manufacturerSalesRepUserGuid
          );
          return (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                cursor: params.isEditable ? "pointer" : "initial",
                height: "100%",
              }}
            >
              {salesRepUser && (
                <Avatar
                  src={getUserPhotoUrlSrc(salesRepUser)}
                  sx={{
                    width: 24,
                    height: 24,
                    fontSize: "12px",
                    marginRight: 1,
                  }}
                >
                  {getUserInitials(salesRepUser)}
                </Avatar>
              )}
              {params.formattedValue}
              {params.isEditable ? (
                <ArrowDropDownIcon sx={{ marginLeft: "auto" }} />
              ) : null}
            </Stack>
          );
        },
      },
    ];

    if (canEdit) {
      cols.push({
        field: "businessRelationIsActive",
        headerName: "Active",
        headerAlign: "right",
        align: "right",
        width: 65,
        renderCell: (params) => (
          <Box sx={{ paddingTop: 2, paddingLeft: 1 }}>
            <Switch
              sx={{ marginLeft: "auto" }}
              checked={params.row.businessRelation?.isActive ?? false}
              disabled={!canEdit}
              onChange={handleActiveToggle(params.row)}
            />
          </Box>
        ),
      });
    }

    cols.push({
      field: "actions",
      headerName: "",
      width: 10,
      align: "right",
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <MoreMenuButton
            menuItems={[
              {
                label: canEdit ? "View/Edit" : "View",
                onClick: handleViewClick(row),
              },
            ]}
          />
        );
      },
    });

    return cols;
  }, [handleActiveToggle, handleViewClick, user, usersData]);

  const filteredRetailers = useMemo(() => {
    if (!retailers) {
      return [];
    }

    if (searchText.trim().length === 0) {
      return retailers;
    }

    const filtered = retailers.filter((a) => {
      // Does the search text match any of these retailer properties?
      const textsToSearch = [a.tenantName, a.tenantCity, a.tenantState];

      let matchesSearchText = textsToSearch.some((t) =>
        t.toLowerCase().includes(searchText)
      );

      // If not, see if it matches the sales rep.
      if (!matchesSearchText) {
        matchesSearchText = (usersData ?? []).some((u) => {
          if (u.userGuid !== a.businessRelation?.manufacturerSalesRepUserGuid) {
            return false;
          }
          return getUserFullName(u).toLowerCase().includes(searchText);
        });
      }

      return matchesSearchText;
    });

    return filtered;
  }, [retailers, searchText, usersData]);

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent={"right"}
        marginBottom={2}
      >
        <Grid item />
        <Grid item>
          <Stack spacing={2} direction={"row-reverse"}>
            {user != null &&
            (
              [
                UserRole.OWNER,
                UserRole.ADMIN,
                UserRole.ADVANCEDUSER,
              ] as UserRoles[]
            ).includes(user.userRole) ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleNewButtonClick}
              >
                + New Retailer
              </Button>
            ) : null}
            <OutlinedInput
              placeholder="Search"
              onChange={handleSearchChange}
              size="small"
              color="primary"
              sx={{ backgroundColor: "white", height: 37 }}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
            />
          </Stack>
        </Grid>
      </Grid>
      {retailersData && retailers.length === 0 && !retailersLoading && (
        <EmptyState heading="No Retailers" />
      )}
      <Box>
        {filteredRetailers && filteredRetailers.length > 0 && (
          <Table
            columns={columns}
            rows={filteredRetailers}
            rowSelection={false}
            //hideFooter
            disableColumnFilter
            disableColumnMenu
            disableColumnResize
            disableRowSelectionOnClick
            getRowId={(row) => row.tenantGuid}
            processRowUpdate={handleProcessRowUpdate}
          />
        )}
      </Box>
      {isEditing && (
        <NewEditRetailerModal
          isOpen={isEditing}
          retailersNotInCompany={retailersData?.retailersNotInCompany ?? []}
          onSaveSuccessful={handleSave}
          onClose={handleClose}
        />
      )}
    </>
  );
};

export default ManageRetailersRoute;
