import React from "react";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { Button, ButtonProps } from "@mui/material";

interface OrderCardActionButtonProps extends ButtonProps {}

export const OrderCardActionButton: React.FC<OrderCardActionButtonProps> = ({
  onClick,
  children,
}) => {
  return (
    <Button
      startIcon={<AddCircleOutlineOutlinedIcon />}
      sx={{
        paddingTop: 0,
        paddingBottom: 0,
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
