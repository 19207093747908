import { Controller, useForm } from "react-hook-form";
import { Modal } from "../../components/Modal";
import { Question } from "../../types/orderCardAdditionalBuyerQuestions";
import { Box, Button } from "@mui/material";
import InputField from "../../components/InputField";
import SwitchField from "../../components/SwitchField";
import { useApiRequest } from "../../hooks/useApiRequest";
import { useEffect } from "react";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";

type FormData = {
  text: string;
  alwaysInclude: boolean;
  answer: string;
};

const getDefaultValues = (question: Question | null): FormData => {
  return {
    text: question?.text ?? "",
    alwaysInclude: question?.alwaysInclude ?? false,
    answer: question?.answer ?? "",
  };
};

type NewQuestionModalProps = {
  question: Question | null;
  isOpen: boolean;
  onSaveSuccessful: (order: Question) => void;
  onClose: () => void;
  orderCardGuid: string;
};

export const NewQuestionModal = ({
  question,
  isOpen,
  onSaveSuccessful,
  onClose,
  orderCardGuid,
}: NewQuestionModalProps) => {
  const { showErrorToast } = useGlobalToastNotificationContext();
  const { request, data, errorMessage } = useApiRequest();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isValid },
    setValue,
    watch,
  } = useForm<FormData>({
    defaultValues: getDefaultValues(question),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const questionText = watch("text");
  const answer = watch("answer");

  const onSubmit = (data: FormData) => {
    if (!data.alwaysInclude) {
      return onSaveSuccessful(data);
    }

    request(`/ordercards/${orderCardGuid}/qa/add`, {
      method: "POST",
      data: {
        text: data.text,
        alwaysInclude: data.alwaysInclude,
      },
    });
  };

  useEffect(() => {
    if (errorMessage) {
      showErrorToast({ message: errorMessage });
    }
  }, [errorMessage, showErrorToast]);

  useEffect(() => {
    if (data) {
      onSaveSuccessful({
        text: questionText,
        answer: answer,
        alwaysInclude: true,
      });
    }
  }, [data, question, onSaveSuccessful, questionText, answer]);

  return (
    <Modal
      heading={`${question?.text ? "Edit" : "New"} Question`}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box
        component="form"
        sx={{ width: { xs: "100%", sm: "350px" } }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <InputField
          {...register("text", {
            required: { value: true, message: "Please enter text." },
          })}
          label="Question"
          required
          fullWidth
          error={!!errors.text}
          helperText={errors.text?.message}
          onFocus={(e) => e.target.select()}
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Controller
            name="alwaysInclude"
            control={control}
            render={({ field }) => (
              <SwitchField
                {...field}
                label="Always Include"
                checked={field.value}
                sx={{ marginRight: "auto" }}
                disabled={question?.alwaysInclude}
                onChange={(e, checked) => {
                  field.onChange(e);
                  if (!checked) {
                    setValue("alwaysInclude", false);
                  }
                }}
              />
            )}
          />

          <Button variant="outlined" onClick={onClose} sx={{ marginRight: 2 }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={!isValid}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
