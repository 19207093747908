import React, { useRef } from "react";
import { Box, Typography } from "@mui/material";
import { Retailer } from "../../types/tenant";
import { AttachmentManager } from "../../components/AttachmentManager";
import { DropzoneRef } from "react-dropzone";
import { Attachment } from "../../types/attachment";
import { useSession } from "../../hooks/useSession";
import { UserRole, UserRoles } from "../../types/userRole";

interface CompanyInfoTabAttachmentsProps {
  retailer: Retailer | null;
  tenantGuid?: string;
  onAttachmentsChanged: () => void;
}

export const CompanyInfoTabAttachments: React.FC<
  CompanyInfoTabAttachmentsProps
> = ({ retailer, tenantGuid, onAttachmentsChanged }) => {
  const dropzoneRef = useRef<DropzoneRef>(null);
  const { user } = useSession();
  const canEdit =
    user?.userRole != null &&
    (
      [UserRole.OWNER, UserRole.ADMIN, UserRole.ADVANCEDUSER] as UserRoles[]
    ).includes(user.userRole);

  return (
    <Box>
      <Typography variant="body1" fontWeight={600} mb={1}>
        Attachments
      </Typography>
      <AttachmentManager
        disabled={!tenantGuid || !canEdit}
        createAttachmentUrl={`/company/retailers/${tenantGuid}/attach`}
        deleteAttachmentUrl={
          canEdit
            ? (attachment: Attachment) =>
                `/company/attachments/${attachment.attachmentGuid}/delete`
            : undefined
        }
        downloadAttachmentUrl={(attachment: Attachment) =>
          attachment.attachmentUrl
        }
        dropzoneRef={dropzoneRef}
        attachments={retailer?.businessRelation?.attachments ?? []}
        onRefreshAttachments={onAttachmentsChanged}
      />
    </Box>
  );
};
