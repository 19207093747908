import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Stack, Typography, IconButton, Tooltip } from "@mui/material";
import { InfoPanel } from "../../components/InfoPanel";

interface TotalsRowProps {
  dimensionsWidth: number;
  dimensionsLength: number;
  livingSpace: number;
  nonHeatedSpace: number;
  totalSpace: number;
  grossWeight: number;
}

export const TotalsRow: React.FC<TotalsRowProps> = ({
  dimensionsWidth,
  dimensionsLength,
  livingSpace,
  nonHeatedSpace,
  totalSpace,
  grossWeight,
}) => {
  return (
    <InfoPanel
      panels={[
        {
          title: "Dimensions",
          html: (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              spacing="4px"
            >
              <Typography variant="body2" color="text.primary" fontWeight={500}>
                {`W ${dimensionsWidth}' x L ${dimensionsLength}'`}
              </Typography>
              <Tooltip title="This length includes the hitch.">
                <IconButton color="primary" sx={{ padding: 0 }}>
                  <InfoOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Stack>
          ),
        },
        { title: "Living Space", text: `${livingSpace} Sq Ft` },
        { title: "Non-Heated", text: `${nonHeatedSpace} Sq Ft` },
        { title: "Total Space", text: `${totalSpace} Sq Ft` },
        { title: "Est Gross Weight", text: `${grossWeight} Lbs` },
      ]}
    />
  );
};
