import { DateTime } from "luxon";

export const formatAsDate = (
  unixTimestamp: number | null | undefined,
  formatString: string = "LLL d, yyyy"
): string => {
  if (!unixTimestamp) return "";
  const date = DateTime.fromSeconds(unixTimestamp).toFormat(formatString);
  return date.toLocaleString();
};

export const getTimezone = () => {
  const timezone = new Date()
    .toLocaleString("en-US", { timeZoneName: "short" })
    .split(" ")[3];

  return timezone;
};
