import { Grid, Typography, Box } from "@mui/material";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import { routes } from "../../routes";

const TermsRoute: React.FC = () => {
  usePageMetadata({ title: routes.terms.label });

  return (
    <>
      <Grid
        container
        style={{
          background: "url(/login-background.jpg) no-repeat center center",
          backgroundSize: "cover",
          backgroundColor: 'rgba(255, 255, 255, 0.6)',
          backgroundBlendMode: 'overlay',
        }}
      >
        <Grid item xs={12} sm={4} style={{ height: "100vh" }}>
          <Box sx={{ padding: 2 }}>
            <Typography variant="h5" gutterBottom>
                AtlasAlly Terms of Use
              </Typography>
            </Box>
            <Box sx={{ position: "absolute", bottom: 20, left: 20 }}>
              <Typography variant="body2" color="textSecondary">
                &copy;2024 Red Roots Technologies
              </Typography>
            </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default TermsRoute;
