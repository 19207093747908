export type OptionPricing = {
  type: "a" | "sw" | "dw" | "tw" | "m";
  price: number;
  startDate: number;
  endDate: number | null;
};

export type OptionPricingType = OptionPricing["type"];

export const getOptionPricingDisplayName = (
  unit: OptionPricingType
): string => {
  const displayName: Record<OptionPricingType, string> = {
    a: "Pricing",
    sw: "Single Wide Price",
    dw: "Double Wide Price",
    tw: "Triple Wide Price",
    m: "MOD Price",
  };
  return displayName[unit];
};
