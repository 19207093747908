import React from "react";
import {
  FormControl,
  FormLabel,
  FormHelperText,
  RadioGroup,
  FormControlLabel,
  Radio,
  RadioGroupProps,
} from "@mui/material";

type RadioGroupFieldProps = RadioGroupProps & {
  label: string;
  options: Array<{ value: string | number; label: string }>;
  helperText?: string;
  required?: boolean;
  error?: boolean;
};

const RadioGroupField = React.forwardRef<
  HTMLInputElement,
  RadioGroupFieldProps
>(({ label, options, helperText, error, ...props }, ref) => {
  return (
    <FormControl fullWidth size="small">
      {label ? (
        <FormLabel component="legend" required={props.required}>
          {label}
        </FormLabel>
      ) : null}
      <RadioGroup row {...props}>
        {options.map((o) => (
          <FormControlLabel
            key={o.value}
            value={o.value}
            control={<Radio />}
            label={o.label}
          />
        ))}
      </RadioGroup>
      {helperText ? (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      ) : null}
    </FormControl>
  );
});

export default RadioGroupField;
