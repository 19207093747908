import React, { useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { Controller, useForm } from "react-hook-form";
import { DateTime } from "luxon";
import { OrderCardBase } from "./OrderCardBase";
import { useOrderCard } from "./OrderCardContext";
import InputField from "../../components/InputField";
import { DatePicker } from "../../components/DatePicker";
import {
  InvoiceSpecificationsHumidities,
  invoiceSpecificationsHumiditiesOptions,
  InvoiceSpecificationsRoofLoads,
  invoiceSpecificationsRoofLoadsOptions,
  InvoiceSpecificationsWindZones,
  invoiceSpecificationsWindZonesOptions,
  isInvoiceSpecificationsRoofLoadsCustom,
  type OrderCardInvoiceSpecifications as OrderCardInvoiceSpecificationsType,
} from "../../types/orderCardInvoiceSpecifications";
import SelectField from "../../components/SelectField";
import { InputAdornment } from "@mui/material";
import CheckboxField from "../../components/CheckboxField";

type FormData = {
  certifiedBy: string | null;
  customRoofLoad: number | null;
  dateOfMfgTimestamp: DateTime | null;
  humidity: InvoiceSpecificationsHumidities | null;
  meetsExposure: boolean;
  roofLoad: InvoiceSpecificationsRoofLoads | null;
  shuttersIncluded: boolean;
  siteConstruction: boolean;
  windZone: InvoiceSpecificationsWindZones | null;
};

const getDefaultValues = (
  orderCard: OrderCardInvoiceSpecificationsType | null
): FormData => {
  return {
    certifiedBy: orderCard?.cardData.certifiedBy ?? null,
    customRoofLoad: orderCard?.cardData.customRoofLoad ?? null,
    dateOfMfgTimestamp: orderCard?.cardData.dateOfMfgTimestamp
      ? DateTime.fromSeconds(
          parseInt(orderCard.cardData.dateOfMfgTimestamp.toString())
        )
      : null,
    humidity: orderCard?.cardData.humidity ?? null,
    meetsExposure: orderCard?.cardData.meetsExposure ?? false,
    roofLoad: orderCard?.cardData.roofLoad ?? null,
    shuttersIncluded: orderCard?.cardData.shuttersIncluded ?? false,
    siteConstruction: orderCard?.cardData.siteConstruction ?? false,
    windZone: orderCard?.cardData.windZone ?? null,
  };
};

interface OrderCardInvoiceSpecificationsProps {
  orderCard: OrderCardInvoiceSpecificationsType;
  expanded: boolean;
  groupIndex: number;
}

export const OrderCardInvoiceSpecifications: React.FC<
  OrderCardInvoiceSpecificationsProps
> = ({ orderCard, expanded, groupIndex }) => {
  const { saveOrderCard, setEditingOrderCard } = useOrderCard();

  const disabled = orderCard.isLocked;

  const {
    control,
    register,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: getDefaultValues(orderCard),
    reValidateMode: "onChange",
    mode: "onChange",
  });
  const roofLoad = watch("roofLoad");
  const isCustomRoofLoadSelected =
    roofLoad != null && isInvoiceSpecificationsRoofLoadsCustom(roofLoad);

  const getOrderCardData = (
    formData: FormData
  ): OrderCardInvoiceSpecificationsType["cardData"] => {
    const cardData: OrderCardInvoiceSpecificationsType["cardData"] = {
      ...formData,
      dateOfMfgTimestamp: formData.dateOfMfgTimestamp?.toSeconds() ?? null,
    };
    return cardData;
  };

  const handleBlurField = () => {
    const values = getValues();
    const cardData = getOrderCardData(values);
    saveOrderCard(orderCard.orderCardGuid, cardData);
    setEditingOrderCard(null);
  };

  const handleEditingField = () => {
    setEditingOrderCard(orderCard);
  };

  const handleClearClick = () => {
    const defaultValues = getDefaultValues(null);
    reset(defaultValues);

    const cardData = getOrderCardData(defaultValues);
    saveOrderCard(orderCard.orderCardGuid, cardData);
  };

  useEffect(() => {
    reset(getDefaultValues(orderCard), { keepDirtyValues: true });
  }, [orderCard, reset]);

  return (
    <OrderCardBase
      disabled={disabled}
      orderCard={orderCard}
      onClearClick={handleClearClick}
      expanded={expanded}
      groupIndex={groupIndex}
    >
      <Grid container spacing={2} component="form">
        <Grid xs={12} sm={12} md={6}>
          <Controller
            control={control}
            name="humidity"
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                label="Humidity"
                placeholder="Select Humidity"
                onFocus={handleEditingField}
                required
                fullWidth
                disabled={disabled}
                value={field.value ?? ""}
                options={invoiceSpecificationsHumiditiesOptions}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                onBlur={() => {
                  handleBlurField();
                  field.onBlur();
                }}
              />
            )}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <Controller
            control={control}
            name="windZone"
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                label="Wind Zone"
                placeholder="Select Wind Zone"
                onFocus={handleEditingField}
                required
                fullWidth
                disabled={disabled}
                value={field.value ?? ""}
                options={invoiceSpecificationsWindZonesOptions}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                onBlur={() => {
                  handleBlurField();
                  field.onBlur();
                }}
              />
            )}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <Controller
            control={control}
            name="roofLoad"
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                label="Roof Load"
                placeholder="Select Roof Load"
                onFocus={handleEditingField}
                required
                fullWidth
                disabled={disabled}
                value={field.value ?? ""}
                options={invoiceSpecificationsRoofLoadsOptions}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                onChange={(e) => {
                  setValue("customRoofLoad", null);
                  field.onChange(e);
                }}
                onBlur={() => {
                  handleBlurField();
                  field.onBlur();
                }}
              />
            )}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <InputField
            {...register("customRoofLoad", {
              valueAsNumber: true,
              onBlur: handleBlurField,
            })}
            type="number"
            label="Custom Roof Load"
            onFocus={handleEditingField}
            placeholder="Enter Custom Roof Load"
            required={isCustomRoofLoadSelected}
            fullWidth
            disabled={disabled || !isCustomRoofLoadSelected}
            endAdornment={<InputAdornment position="end">PSF</InputAdornment>}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <Controller
            name="dateOfMfgTimestamp"
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                label="Date of Manufacture"
                disabled={disabled}
                onFocus={handleEditingField}
                required
                fullWidth
                allowNoDate={false}
                error={!!errors.dateOfMfgTimestamp}
                helperText={errors.dateOfMfgTimestamp?.message}
                onBlur={() => {
                  handleBlurField();
                  field.onBlur();
                }}
              />
            )}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <InputField
            {...register("certifiedBy", { onBlur: handleBlurField })}
            label="Certified By"
            onFocus={handleEditingField}
            placeholder="Enter Certified By"
            required
            fullWidth
            disabled={disabled}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <Controller
            name="shuttersIncluded"
            control={control}
            disabled={disabled}
            render={({ field }) => (
              <CheckboxField
                {...field}
                label="Shutters included"
                checked={field.value}
                onChange={(e) => {
                  field.onChange(e);
                  handleBlurField();
                }}
              />
            )}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <Controller
            name="meetsExposure"
            control={control}
            disabled={disabled}
            render={({ field }) => (
              <CheckboxField
                {...field}
                label="Meets exposure D-ASCE 7"
                checked={field.value}
                onChange={(e) => {
                  field.onChange(e);
                  handleBlurField();
                }}
              />
            )}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <Controller
            name="siteConstruction"
            control={control}
            disabled={disabled}
            render={({ field }) => (
              <CheckboxField
                {...field}
                label="Site Construction"
                checked={field.value}
                onChange={(e) => {
                  field.onChange(e);
                  handleBlurField();
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </OrderCardBase>
  );
};
