import { Stack, SxProps, Theme, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

type FormSectionProps = {
  heading: string;
  headingAction?: React.ReactNode;
  children: React.ReactNode;
  sx?: SxProps<Theme>;
};

export const FormSection: React.FC<FormSectionProps> = ({
  heading,
  headingAction,
  children,
  sx,
}) => {
  return (
    <Stack sx={{ marginBottom: 5, ...sx }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={3}
      >
        <Typography variant="body1" fontWeight={600}>
          {heading}
        </Typography>
        {headingAction}
      </Stack>

      <Grid container columnSpacing={2} rowSpacing={3}>
        {children}
      </Grid>
    </Stack>
  );
};
