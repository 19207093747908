import React from "react";
import { FormControlLabel, SwitchProps } from "@mui/material";
import { Switch } from "./Switch";

type SwitchFieldProps = SwitchProps & {
  label: string;
};

const SwitchField = React.forwardRef<HTMLInputElement, SwitchFieldProps>(
  ({ label, sx, ...props }, ref) => {
    return (
      <FormControlLabel
        sx={{ marginRight: 0, marginLeft: 0, ...sx }}
        control={<Switch sx={{ marginRight: 1 }} {...props} inputRef={ref} />}
        label={label}
      />
    );
  }
);

export default SwitchField;
