import React from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { Retailer } from "../../types/tenant";
import {
  Control,
  Controller,
  FormState,
  UseFormRegister,
} from "react-hook-form";
import { FormSection } from "../../components/FormSection";
import InputField from "../../components/InputField";
import { stateSelectOptions } from "../../types/states";
import SelectField from "../../components/SelectField";
import { FormData } from "./FormData";

interface CompanyInfoTabBusinessBillingAddressProps {
  retailer: Retailer | null;
  // form props
  control: Control<FormData>;
  formState: FormState<FormData>;
  register: UseFormRegister<FormData>;
}

export const CompanyInfoTabBusinessBillingAddress: React.FC<
  CompanyInfoTabBusinessBillingAddressProps
> = ({ retailer, control, formState, register }) => {
  return (
    <FormSection heading="Business/Billing Address">
      <Grid xs={12} md={6}>
        <InputField
          {...register("tenantAddress1")}
          disabled={!retailer?.canEdit}
          fullWidth
          label="Address"
          placeholder="Enter Address"
          error={!!formState.errors.tenantAddress1}
          helperText={formState.errors.tenantAddress1?.message}
        />
      </Grid>
      <Grid xs={12} md={6}>
        <InputField
          {...register("tenantAddress2")}
          disabled={!retailer?.canEdit}
          fullWidth
          label="Address 2"
          placeholder="Enter Address 2"
          error={!!formState.errors.tenantAddress2}
          helperText={formState.errors.tenantAddress2?.message}
        />
      </Grid>
      <Grid xs={12} md={6}>
        <InputField
          {...register(
            "tenantCity",
            retailer?.canEdit
              ? { required: "Please enter a value." }
              : { required: false }
          )}
          required={retailer?.canEdit}
          disabled={!retailer?.canEdit}
          fullWidth
          label="City"
          placeholder="Enter City"
          error={!!formState.errors.tenantCity}
          helperText={formState.errors.tenantCity?.message}
        />
      </Grid>
      <Grid xs={12} md={6}>
        <Controller
          control={control}
          name="tenantState"
          render={({ field, fieldState }) => (
            <SelectField
              {...field}
              label="State"
              placeholder="Select State"
              required={retailer?.canEdit}
              disabled={!retailer?.canEdit}
              fullWidth
              options={stateSelectOptions}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
        />
      </Grid>
      <Grid xs={12} md={6}>
        <InputField
          {...register("tenantZip")}
          disabled={!retailer?.canEdit}
          fullWidth
          label="Zip"
          placeholder="Enter Zip"
          error={!!formState.errors.tenantZip}
          helperText={formState.errors.tenantZip?.message}
        />
      </Grid>
      <Grid xs={12} md={6}>
        <InputField
          {...register("retailerLicenseNumber")}
          disabled={!retailer?.canEdit}
          fullWidth
          label="License #"
          placeholder="Enter License #"
          error={!!formState.errors.retailerLicenseNumber}
          helperText={formState.errors.retailerLicenseNumber?.message}
        />
      </Grid>
    </FormSection>
  );
};
