import React, { useEffect, useMemo } from "react";
import { Modal } from "../../components/Modal";
import {
  Alert,
  Box,
  Button,
  FormLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Attribute, attributeNameMaxLength } from "../../types/attribute";
import { Option } from "../../types/option";
import CheckboxField from "../../components/CheckboxField";
import { AttributePresentAsRadioGroup } from "../../components/AttributePresentAsRadioGroup";
import {
  AutocompleteField,
  RegularOption,
} from "../../components/AutocompleteField";

type FormData = {
  attributeGuid?: string | undefined;
  attributeName?: string | undefined;
  attributeNameOrGuid?: string | undefined;
  attributePresentAs: "d" | "s";
  attributeIncludeImages: boolean;
  attributeSelectIsRequired: boolean;
};

type NewEditAttributeModalProps = {
  isOpen: boolean;
  onSaveSuccessful: () => void;
  onClose: () => void;
  attribute: Attribute | null;
  option: Option | null;
  isNew: boolean;
};

const getDefaultValues = (attribute: Attribute | null): FormData => {
  return {
    attributeGuid: attribute?.attributeGuid ?? undefined,
    attributeName: attribute?.attributeName ?? undefined,
    attributeNameOrGuid: attribute?.attributeGuid ?? undefined,
    attributePresentAs: attribute?.attributePresentAs ?? "d",
    attributeIncludeImages: attribute?.attributeIncludeImages ?? false,
    attributeSelectIsRequired: attribute?.attributeSelectIsRequired ?? false,
  };
};

export const NewEditAttributeModal: React.FC<NewEditAttributeModalProps> = ({
  isOpen,
  onSaveSuccessful,
  onClose,
  attribute,
  option,
  isNew,
}) => {
  const [newAttribute, setNewAttribute] = React.useState<
    RegularOption<string>[]
  >([]);

  const {
    data: saveAttributeData,
    loading: saveAttributeLoading,
    request: saveAttributeRequest,
    status: saveAttributeStatus,
    error: saveAttributeError,
  } = useApiRequest<Attribute>(true);

  const { data: attributesData, request: attributesRequest } =
    useApiRequest<Attribute[]>(false);

  const { control, handleSubmit, setValue, watch } = useForm<FormData>({
    defaultValues: getDefaultValues(attribute),
  });

  const selectedAttributeGuid = watch("attributeGuid");

  const selectedAttribute: Attribute | undefined = useMemo(() => {
    if (selectedAttributeGuid && attributesData) {
      return attributesData.find(
        (attribute) => attribute.attributeGuid === selectedAttributeGuid
      );
    }
    return undefined;
  }, [attributesData, selectedAttributeGuid]);

  const onSubmit: SubmitHandler<FormData> = (data: FormData) => {
    const dataToSend = {
      ...data,
      optionGuid: option?.optionGuid,
    };
    if (isNew && data.attributeName) {
      saveAttributeRequest("/company/attributes/add", {
        method: "POST",
        data: dataToSend,
      });
    } else {
      saveAttributeRequest(`company/attributes/${data?.attributeGuid}/edit`, {
        method: "POST",
        data: dataToSend,
      });
    }
  };

  const linkedOptions = useMemo(() => {
    if (selectedAttribute) {
      return selectedAttribute.optionNames.filter(
        (o) => o.optionGuid !== option?.optionGuid
      );
    }
    return [];
  }, [selectedAttribute, option]);

  useEffect(() => {
    if (!selectedAttribute) {
      return;
    }
    setValue(
      "attributeIncludeImages",
      selectedAttribute.attributeIncludeImages
    );
    setValue(
      "attributeSelectIsRequired",
      selectedAttribute.attributeSelectIsRequired
    );
    setValue("attributePresentAs", selectedAttribute.attributePresentAs);
  }, [selectedAttributeGuid, selectedAttribute, setValue]);

  // Obtain the list of attributes to populate dropdown
  useEffect(() => {
    attributesRequest(`company/attributes`);
  }, [attributesRequest]);

  useEffect(() => {
    if (
      saveAttributeData &&
      saveAttributeStatus === "ok" &&
      saveAttributeLoading === false
    ) {
      onSaveSuccessful();
    }
  }, [
    saveAttributeData,
    onSaveSuccessful,
    saveAttributeStatus,
    saveAttributeLoading,
  ]);

  const attributesOptions = useMemo(() => {
    if (!attributesData) return [];
    return [
      ...attributesData.map((attribute) => ({
        label: attribute.attributeName,
        value: attribute.attributeGuid,
      })),
      ...newAttribute,
    ];
  }, [attributesData, newAttribute]);

  const handleAttributeChange = (
    value: RegularOption<string>,
    isNew: boolean
  ) => {
    if (isNew) {
      setValue("attributeName", value.value);
      setValue("attributeGuid", undefined);
    } else {
      setValue("attributeName", undefined);
      setValue("attributeGuid", value.value);
    }

    setValue("attributeNameOrGuid", value.value);

    // Only add to new Attribute if it is not already in the list
    if (
      attributesData &&
      attributesData.every(
        (attribute) => attribute.attributeGuid !== value.value
      )
    ) {
      setNewAttribute([{ label: value.value, value: value.value }]);
    }
  };

  return (
    <Modal
      heading={isNew ? "Add Attribute" : "Edit Attribute"}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box sx={{ width: { xs: "100%", sm: "900px" } }}>
        {saveAttributeError && (
          <Alert severity="error" sx={{ marginBottom: 2 }}>
            {saveAttributeError.response?.status === 409
              ? "An attribute already exists with this name."
              : "Something went wrong. Please try again."}
          </Alert>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Stack spacing={3}>
                <Controller
                  control={control}
                  name="attributeNameOrGuid"
                  rules={{
                    required: "Please select a value.",
                    validate: {
                      attributeNameLength: (value) => {
                        const selectedValue = attributesOptions?.find(
                          (s) => s.value === value
                        );

                        const isNew =
                          selectedValue != null &&
                          selectedValue.label.includes(selectedValue.value);

                        // This validation only applies to dynamically created attributes...
                        if (!isNew) {
                          return true;
                        }

                        const isNameLessThanMaxLength =
                          selectedValue.value.length <= attributeNameMaxLength;

                        return (
                          isNameLessThanMaxLength ||
                          `Attribute name can only be ${attributeNameMaxLength} characters.`
                        );
                      },
                    },
                  }}
                  render={({ field, fieldState }) => {
                    const selectedValue = attributesOptions?.find(
                      (s) => s.value === field.value
                    );
                    return (
                      <AutocompleteField
                        {...field}
                        disabled={!isNew}
                        label="Attribute Name"
                        noOptionsText="No Attributes"
                        allowNewOption
                        options={attributesOptions ? attributesOptions : []}
                        onOptionChange={handleAttributeChange}
                        value={selectedValue ?? null}
                        placeholder="Create or Select Attribute"
                        onChange={(_, value) => {
                          field.onChange(value?.value ?? null);
                        }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    );
                  }}
                />

                <Controller
                  name="attributePresentAs"
                  control={control}
                  render={({ field }) => (
                    <AttributePresentAsRadioGroup {...field} />
                  )}
                />

                <Controller
                  name="attributeIncludeImages"
                  control={control}
                  render={({ field }) => (
                    <CheckboxField
                      {...field}
                      label="Include option image"
                      checked={field.value}
                    />
                  )}
                />
                <Controller
                  name="attributeSelectIsRequired"
                  control={control}
                  render={({ field }) => (
                    <CheckboxField
                      {...field}
                      label="Selection is required"
                      checked={field.value}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <FormLabel component="legend">Other Linked Options</FormLabel>
              <Box
                sx={{
                  border: "1px solid lightgray",
                  borderRadius: 2,
                  height: 300,
                  p: 1,
                  overflowY: "scroll",
                }}
              >
                {linkedOptions?.length === 0 ? (
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body2" textAlign="center" width={220}>
                      Select an existing attribute to see which options are
                      linked to it or create a new attribute
                    </Typography>
                  </Box>
                ) : (
                  <Stack>
                    {linkedOptions?.map((option) => (
                      <Box pb={2} key={option.optionGuid}>
                        <Typography variant="body1" fontWeight="bold">
                          {option.optionName}
                        </Typography>
                        <Typography variant="body2">
                          {option.optionPath}
                        </Typography>
                      </Box>
                    ))}
                  </Stack>
                )}
              </Box>
            </Grid>
          </Grid>
          <Stack direction="row" justifyContent="flex-end" mt={6}>
            <Button
              variant="outlined"
              onClick={onClose}
              sx={{ marginRight: 2 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={saveAttributeLoading}
            >
              Save
            </Button>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
};
