import React, { useImperativeHandle, useMemo } from "react";
import { Accept, DropzoneRef, useDropzone } from "react-dropzone";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import { Upload } from "@mui/icons-material";

const DropZoneContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "disabled" && prop !== "isDragActive",
})<{ disabled: boolean; isDragActive: boolean }>(
  ({ theme, disabled, isDragActive }) => ({
    border: `2px dashed ${
      isDragActive ? theme.palette.primary.main : theme.palette.grey["400"]
    }`,
    borderRadius: 10,
    padding: theme.spacing(3),
    textAlign: "center",
    cursor: disabled ? "not-allowed" : "pointer",
    transition: "border .24s ease-in-out",
    backgroundColor: disabled ? theme.palette.grey["100"] : "inherit",
    color: disabled ? theme.palette.text.disabled : "inherit",
    "&:hover": {
      border: !disabled
        ? `2px dashed ${theme.palette.primary.main}`
        : `2px dashed ${theme.palette.grey["400"]}`,
    },
  })
);

type DropZoneProps = {
  onDrop: (acceptedFiles: File[]) => void;
  loading?: boolean;
  disabled?: boolean;
  disabledText?: string;
  accept?: Accept;
  // Used to expose the open method to parent components
  dropzoneRef?: React.RefObject<DropzoneRef>;
};

export const DropZone: React.FC<DropZoneProps> = ({
  onDrop,
  loading,
  disabled = false,
  disabledText = "File Upload Not Allowed",
  accept = {},
  dropzoneRef,
}) => {
  const theme = useTheme();

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    disabled,
    accept,
  });

  // Expose open method to parent components by way of the ref if provided
  useImperativeHandle(dropzoneRef, () => ({
    open,
  }));

  const displayText = useMemo(() => {
    if (loading) {
      return "Uploading...";
    } else if (disabled) {
      return disabledText;
    } else {
      return "Drag and drop files here or";
    }
  }, [loading, disabled, disabledText]);

  return (
    <DropZoneContainer
      {...getRootProps()}
      disabled={disabled}
      isDragActive={isDragActive}
    >
      <input {...getInputProps()} disabled={disabled} />
      <Upload />
      <Typography variant="h6">{displayText}</Typography>
      <Button
        disabled={disabled}
        variant="contained"
        color="primary"
        sx={{
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.main,
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.light,
          },
        }}
      >
        Select File
      </Button>
    </DropZoneContainer>
  );
};
