import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";

interface LogicTextSelectionOptionProps {
  optionName: string;
}

export const LogicTextSelectionOption: React.FC<
  LogicTextSelectionOptionProps
> = ({ optionName }) => {
  return (
    <>
      <ChecklistRtlIcon color="primary" sx={{ marginRight: 1 }} />
      {optionName}
    </>
  );
};
