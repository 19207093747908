import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { type OrderCardHeatUValues as OrderCardHeatUValuesType } from "../../types/orderCardHeatUValues";
import { OrderCardBase } from "./OrderCardBase";
import { useOrderCard } from "./OrderCardContext";
import { Grid, InputAdornment } from "@mui/material";
import InputField from "../../components/InputField";

interface OrderCardHeatUValuesProps {
  orderCard: OrderCardHeatUValuesType;
  expanded: boolean;
  groupIndex: number;
}

type FormData = {
  adCeiling: number | null;
  adFloors: number | null;
  adOutside: number | null;
  crDark: number | null;
  crLight: number | null;
  floors: number | null;
  walls: number | null;
};

const getDefaultValues = (
  orderCard: OrderCardHeatUValuesType | null
): FormData => {
  return {
    adCeiling: orderCard?.cardData.adCeiling ?? null,
    adFloors: orderCard?.cardData.adFloors ?? null,
    adOutside: orderCard?.cardData.adOutside ?? null,
    crDark: orderCard?.cardData.crDark ?? null,
    crLight: orderCard?.cardData.crLight ?? null,
    floors: orderCard?.cardData.floors ?? null,
    walls: orderCard?.cardData.walls ?? null,
  };
};

export const OrderCardHeatUValues: React.FC<OrderCardHeatUValuesProps> = ({
  orderCard,
  expanded,
  groupIndex,
}) => {
  const { saveOrderCard, setEditingOrderCard } = useOrderCard();

  const disabled = orderCard.isLocked;

  const { register, reset, getValues } = useForm<FormData>({
    defaultValues: getDefaultValues(orderCard),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const handleBlurField = (value?: string) => {
    saveOrderCard(orderCard.orderCardGuid, getValues());
    setEditingOrderCard(null);
  };

  const handleEditingField = () => {
    setEditingOrderCard(orderCard);
  };

  const handleClearClick = () => {
    const defaultValues = getDefaultValues(null);
    reset(defaultValues);

    saveOrderCard(orderCard.orderCardGuid, defaultValues);
  };

  useEffect(() => {
    reset(getDefaultValues(orderCard), { keepDirtyValues: true });
  }, [orderCard, reset]);

  return (
    <OrderCardBase
      disabled={disabled}
      orderCard={orderCard}
      onClearClick={handleClearClick}
      expanded={expanded}
      groupIndex={groupIndex}
    >
      <Grid container spacing={2} component="form">
        <Grid xs={12} sm={12} md={6} item>
          <InputField
            {...register("walls", {
              onBlur: () => handleBlurField(),
            })}
            label="Walls"
            required
            onFocus={handleEditingField}
            placeholder="Enter Walls"
            fullWidth
            disabled={disabled}
            sx={{ marginBottom: 2 }}
            type="number"
            endAdornment={
              <InputAdornment position="end">U Value</InputAdornment>
            }
          />
        </Grid>
        <Grid xs={12} sm={12} md={6} item>
          <InputField
            {...register("floors", {
              onBlur: () => handleBlurField(),
            })}
            label="Floors"
            required
            onFocus={handleEditingField}
            placeholder="Enter Floors"
            fullWidth
            disabled={disabled}
            sx={{ marginBottom: 2 }}
            type="number"
            endAdornment={
              <InputAdornment position="end">U Value</InputAdornment>
            }
          />
        </Grid>
        <Grid xs={12} sm={12} md={6} item>
          <InputField
            {...register("crLight", {
              onBlur: () => handleBlurField(),
            })}
            label="Ceiling & Roofs (Light)"
            onFocus={handleEditingField}
            placeholder="Enter Ceiling & Roofs (Light)"
            fullWidth
            disabled={disabled}
            sx={{ marginBottom: 2 }}
            type="number"
            endAdornment={
              <InputAdornment position="end">U Value</InputAdornment>
            }
          />
        </Grid>
        <Grid xs={12} sm={12} md={6} item>
          <InputField
            {...register("crDark", {
              onBlur: () => handleBlurField(),
            })}
            label="Ceiling & Roofs (Dark)"
            onFocus={handleEditingField}
            placeholder="Enter Ceiling & Roofs (Dark)"
            fullWidth
            disabled={disabled}
            sx={{ marginBottom: 2 }}
            type="number"
            endAdornment={
              <InputAdornment position="end">U Value</InputAdornment>
            }
          />
        </Grid>
        <Grid xs={12} sm={12} md={6} item>
          <InputField
            {...register("adFloors", {
              onBlur: () => handleBlurField(),
            })}
            label="Air Ducts in Floors"
            required
            onFocus={handleEditingField}
            placeholder="Enter Air Ducts in Floors"
            fullWidth
            disabled={disabled}
            sx={{ marginBottom: 2 }}
            type="number"
            endAdornment={
              <InputAdornment position="end">U Value</InputAdornment>
            }
          />
        </Grid>
        <Grid xs={12} sm={12} md={6} item>
          <InputField
            {...register("adCeiling", {
              onBlur: () => handleBlurField(),
            })}
            label="Air Ducts in Ceiling"
            required
            onFocus={handleEditingField}
            placeholder="Enter Air Ducts in Ceiling"
            fullWidth
            disabled={disabled}
            sx={{ marginBottom: 2 }}
            type="number"
            endAdornment={
              <InputAdornment position="end">U Value</InputAdornment>
            }
          />
        </Grid>
        <Grid xs={12} sm={12} md={6} item>
          <InputField
            {...register("adOutside", {
              onBlur: () => handleBlurField(),
            })}
            label="Air Ducts Outside Home"
            required
            onFocus={handleEditingField}
            placeholder="Enter Air Ducts Outside Home"
            fullWidth
            disabled={disabled}
            sx={{ marginBottom: 2 }}
            type="number"
            endAdornment={
              <InputAdornment position="end">U Value</InputAdornment>
            }
          />
        </Grid>
      </Grid>
    </OrderCardBase>
  );
};
