import { Typography } from "@mui/material";
import { useEffect } from "react";
import { ConfirmModal } from "../../components/ConfirmModal";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Logic } from "../../types/logic";

type DeleteConditionalLogicModalProps = {
  logic: Logic;
  onClose: () => void;
  onDeleteSuccess: () => void;
};

export const DeleteConditionalLogicModal: React.FC<
  DeleteConditionalLogicModalProps
> = ({ logic, onClose, onDeleteSuccess }) => {
  const { showErrorToast, showSuccessToast } =
    useGlobalToastNotificationContext();

  const {
    data: deleteLogicData,
    request: deleteLogicRequest,
    loading: deleteLogicLoading,
    status: deleteLogicStatus,
    errorMessage: deleteLogicErrorMessage,
  } = useApiRequest(false);

  useEffect(() => {
    if (
      deleteLogicData &&
      deleteLogicStatus === "ok" &&
      deleteLogicLoading === false
    ) {
      showSuccessToast({ message: "Conditional Logic deleted!" });
      onDeleteSuccess();
    }
  }, [
    deleteLogicData,
    deleteLogicLoading,
    deleteLogicStatus,
    onDeleteSuccess,
    showSuccessToast,
  ]);

  useEffect(() => {
    if (deleteLogicErrorMessage) {
      showErrorToast({ message: deleteLogicErrorMessage });
    }
  }, [deleteLogicErrorMessage, showErrorToast]);

  const handleDeleteClick = () => {
    deleteLogicRequest(`/company/logics/${logic.logicGuid}/delete`, {
      method: "DELETE",
    });
  };

  return (
    <ConfirmModal
      isOpen={true}
      heading="Delete Conditional Logic Statement"
      body={
        <>
          <Typography variant="body2" color="grey.500">
            Delete this logic statement?
          </Typography>
          <br />
          <Typography variant="body2" color="grey.500">
            This cannot be undone.
          </Typography>
        </>
      }
      confirmButtonText="Delete"
      isDestructive={true}
      onConfirm={handleDeleteClick}
      onClose={onClose}
      confirmDisabled={deleteLogicLoading}
    />
  );
};
