import React, { useEffect } from "react";
import { Modal } from "../../components/Modal";
import InputField from "../../components/InputField";
import { Alert, Box, Button, Stack } from "@mui/material";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Series } from "../../types/series";
import SwitchField from "../../components/SwitchField";

type FormData = {
  seriesName: string;
  isActive: boolean;
};

type NewEditSeriesModalProps = {
  isOpen: boolean;
  onSaveSuccessful: () => void;
  onClose: () => void;
  series?: Series;
  isNew: boolean;
};

const getDefaultValues = (series?: Series): FormData => {
  return {
    seriesName: series?.seriesName ?? "",
    isActive: series?.isActive ?? true,
  };
};

export const NewEditSeriesModal: React.FC<NewEditSeriesModalProps> = ({
  isOpen,
  onSaveSuccessful,
  onClose,
  series,
  isNew,
}) => {
  const {
    data: saveSeriesData,
    loading: saveSeriesLoading,
    request: saveSeriesRequest,
    status: saveSeriesStatus,
    error: saveSeriesError,
  } = useApiRequest<Series>(true);

  const { control, register, handleSubmit, formState } = useForm<FormData>({
    defaultValues: getDefaultValues(series),
  });

  const onSubmit: SubmitHandler<FormData> = (data: FormData) => {
    if (isNew) {
      saveSeriesRequest("/company/series/add", {
        method: "POST",
        data,
      });
    } else {
      saveSeriesRequest(`company/series/${series?.seriesGuid}/edit`, {
        method: "POST",
        data,
      });
    }
  };

  useEffect(() => {
    if (
      saveSeriesData &&
      saveSeriesStatus === "ok" &&
      saveSeriesLoading === false
    ) {
      onSaveSuccessful();
    }
  }, [saveSeriesData, onSaveSuccessful, saveSeriesStatus, saveSeriesLoading]);

  return (
    <Modal
      heading={isNew ? "New Series" : "Edit Series"}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box sx={{ width: { xs: "100%", sm: "371px" } }}>
        {saveSeriesError && (
          <Alert severity="error" sx={{ marginBottom: 2 }}>
            {saveSeriesError.response?.status === 409
              ? "An active series already exists with this name."
              : "Something went wrong. Please try again."}
          </Alert>
        )}
        <Stack component="form" onSubmit={handleSubmit(onSubmit)} spacing={3}>
          <InputField
            label="Series Name"
            placeholder="Enter name"
            fullWidth
            required
            {...register("seriesName", { required: "Enter name" })}
            error={!!formState.errors.seriesName}
            helperText={formState.errors.seriesName?.message}
          />
          <Stack direction="row" justifyContent="flex-end">
            <Controller
              name="isActive"
              control={control}
              render={({ field }) => (
                <SwitchField
                  {...field}
                  label="Active"
                  checked={field.value}
                  sx={{ marginRight: "auto" }}
                />
              )}
            />
            <Button
              variant="outlined"
              onClick={onClose}
              sx={{ marginRight: 2 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={saveSeriesLoading}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};
