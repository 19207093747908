import { ThemeOptions, darken } from "@mui/material";

const isPrimary = ({ color }: any) => color === "primary";
const isError = ({ color }: any) => color === "error";

export const redRootsTechTheme: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 1024,
      sm: 1024,
      md: 1024,
      lg: 1024,
      xl: 1024,
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
  },
  palette: {
    primary: {
      contrastText: "#FFFFFF",
      main: "#7F56D9",
      light: "#F4EBFF",
    },
    secondary: {
      main: "#FFFFFF",
    },
    grey: {
      50: "#F9FAFB",
      100: "#F2F4F7",
      200: "#EAECF0",
      300: "#D0D5DD",
      400: "#98A2B3",
      500: "#667085",
      600: "#475467",
      700: "#344054",
      900: "#101828",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#000000",
          color: "#FFFFFF",
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.grey["100"],
          color: theme.palette.grey["400"],
          letterSpacing: "-0.1em",
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme, ownerState }) => {
          return {
            ":disabled": {
              backgroundColor: "#FCFAFF",
              color: "#D6BBFB",
              boxShadow: "0px 1px 2px 0px #1018280D",

              "&.MuiButton-colorPrimary": {
                backgroundColor: "#F4EBFF",
                color: "#D6BBFB",
              },
              "&.MuiButton-colorSecondary": {
                backgroundColor: "white",
                color: theme.palette.grey["300"],
                borderColor: theme.palette.grey["200"],
              },
            },
            ":hover": {
              ...(isPrimary(ownerState) && ownerState.variant === "contained"
                ? {
                    backGroundColor: "#6941C6",
                  }
                : (isError(ownerState) && {
                    backgroundColor: theme.palette.error.light,
                  }) || {
                    backgroundColor: "#F4EBFF",
                  }),
              boxShadow: "none",
            },
            borderRadius: 8,
            textTransform: "none",
            boxShadow: "none",
            fontFamily: "Inter, sans-serif",
            fontWeight: 600,
            ...(ownerState.color === "secondary" && {
              border: "1px solid #D0D5DD",
            }),
          };
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          boxShadow: "none",
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: 8,
          boxShadow: "none",
          border: "1px solid " + theme.palette.primary.main,
          marginTop: 20,
          height: 38,
        }),
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: ({ theme, ownerState }) => {
          return {
            textTransform: "inherit",
            padding: 0,
            "&.Mui-selected, &.Mui-selected:hover": {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            },
          };
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: "none",
          borderRadius: 8,
          "&.MuiPaper-elevation": {
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          },
          "& .MuiCardContent-root:last-child": {
            paddingBottom: "16px",
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: "Inter, sans-serif",
          fontWeight: 500,
          fontSize: "14px",
          color: "#344054",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: ({ theme }) => ({
          marginLeft: 0,
          marginRight: 0,
          color: theme.palette.grey["500"],
        }),
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          boxShadow: "none",
          border: "1px solid #D0D5DD",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.spacing(1),
          "&.Mui-disabled": {
            backgroundColor: theme.palette.grey["50"],

            "& .MuiOutlinedInput-input": {
              WebkitTextFillColor: theme.palette.grey["500"],
            },
            "& .MuiInputAdornment-root .MuiTypography-root": {
              color: theme.palette.grey["500"],
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.grey["300"],
            },
          },
        }),
        notchedOutline: ({ theme }) => ({
          borderColor: theme.palette.grey["300"],
        }),
      },
    },
    MuiList: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
        }),
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
        }),
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.spacing(1),
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          "&.MuiChip-colorDefault": {
            backgroundColor: "#E6E9EF",

            "& .MuiChip-label": {
              color: "#344054",
              fontWeight: 500,
            },

            "& .MuiChip-deleteIcon": {
              color: theme.palette.grey["500"],
              "&:hover": {
                color: darken(theme.palette.grey["500"], 0.5),
              },
            },
          },
          "&.MuiChip-colorError": {
            backgroundColor: "#FFF1F3",

            "& .MuiChip-label": {
              color: "#E31B54",
              fontWeight: 500,
            },
          },
          "&.MuiChip-colorSuccess": {
            backgroundColor: "#ECFDF3",

            "& .MuiChip-label": {
              color: "#027A48",
              fontWeight: 500,
            },
          },
          "&.MuiChip-colorWarning": {
            backgroundColor: "#FFFAEB",

            "& .MuiChip-label": {
              color: "#DC6803",
              fontWeight: 500,
            },
          },
          "&.MuiChip-colorInfo": {
            backgroundColor: "#EFF8FF",

            "& .MuiChip-label": {
              color: "#175CD3",
              fontWeight: 500,
            },
          },
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    //   MuiListItem: {
    //     styleOverrides: {
    //       root: {
    //         "&.Mui-selected, &.Mui-selected:hover": {
    //           backgroundColor: "red",
    //         },
    //         borderRadius: 8,
    //         fontFamily: "Inter, sans-serif",
    //         fontWeight: 500,
    //         fontSize: "15px",
    //       },
    //     },
    //   },
    //   MuiMenuItem: {
    //     styleOverrides: {
    //       root: {
    //         "&.Mui-selected, &.Mui-selected:hover": {
    //           backgroundColor: "#7F56D9",
    //           color: "purple",
    //         },
    //         fontFamily: "Inter, sans-serif",
    //         fontWeight: 500,
    //       },
    //     },
    //   },
    //   MuiListItemText: {
    //     styleOverrides: {
    //       root: {
    //         "&.Mui-selected, &.Mui-selected:hover": {
    //           color: "yellow",
    //         },
    //         fontFamily: "Inter, sans-serif",
    //         fontWeight: 500,
    //         fontSize: "15px",
    //         color: "#000000",
    //       },
    //     },
    //   },
  },
};
