import axios, { AxiosInstance, InternalAxiosRequestConfig } from "axios";
import { objectToFormData } from "./utils/formData";

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const accessKey = localStorage.getItem("access_token");
    if (accessKey) {
      config.headers["Authentication"] = `${accessKey}`;
    }

    // Set the default content-type
    if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] = "application/multipart/form-data";
    }

    // If were sending application/multipart/form-data, convert it into a FormData object.
    if (config.headers["Content-Type"] === "application/multipart/form-data") {
      config.data = config.data ? objectToFormData(config.data) : config.data;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default axiosInstance;
