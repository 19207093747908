import { Attribute } from "./attribute";
import { Logic } from "./logic";
import { OptionPricing } from "./optionPricing";
import {
  OptionRoom,
  isOptionRoomNonRoomOption,
  getOptionRoomOptions,
} from "./optionRoom";

export type Option = {
  optionGuid: string;
  optionName: string;
  sortOrder: number;
  optionIsUpgrade: boolean;
  optionRooms: OptionRoom[];
  series: string[];
  optionMinimum: number;
  optionMaximum: number;
  optionDisclaimer: string;
  optionPricedPer: "u" | "e" | "l" | "s";
  optionPricedFor: "a" | "u";
  optionPricing: OptionPricing[];
  startDate: number;
  endDate: number | null;
  uniqueAttributesPerRoom: boolean;
  attributes: Attribute[];
  logics: Logic[];
};

export type OptionPricedPerType = Option["optionPricedPer"];
export type OptionPricedForType = Option["optionPricedFor"];

export const getPricePerOptions: OptionPricedPerType[] = ["u", "e", "l", "s"];

export const getPriceForOptions: OptionPricedForType[] = ["a", "u"];

export const getOptionPricePerDisplayName = (
  per: OptionPricedPerType
): string => {
  const displayName: Record<OptionPricedPerType, string> = {
    u: "Single Unit",
    e: "Quantity",
    l: "Linear Foot",
    s: "Square Foot",
  };
  return displayName[per];
};

export const getOptionPricePerShortDisplayName = (
  per: OptionPricedPerType
): string => {
  const displayName: Record<OptionPricedPerType, string> = {
    u: "",
    e: "ea",
    l: "lf",
    s: "sq ft",
  };
  return displayName[per];
};

export const getOptionPriceForDisplayName = (
  forType: OptionPricedForType
): string => {
  const displayName: Record<OptionPricedForType, string> = {
    a: "All Home Types",
    u: "Unique Per Type",
  };
  return displayName[forType];
};

export const isOptionNonRoomOption = (
  option: Pick<Option, "optionRooms"> | undefined
): boolean => {
  return (option?.optionRooms ?? []).some((optionRoom) =>
    isOptionRoomNonRoomOption(optionRoom)
  );
};

export const getOptionOptionRoomOptions = (
  option: Pick<Option, "optionRooms"> | undefined
) => {
  return getOptionRoomOptions(option?.optionRooms ?? []);
};
