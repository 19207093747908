import React, { useEffect } from "react";
import { type OrderCardAddSalesChangeOrder as OrderCardAddSalesChangeOrderType } from "../../types/orderCardAddSalesChangeOrder";
import { OrderCardActionButton } from "./OrderCardActionButton";
import { OrderCardBase } from "./OrderCardBase";
import { Order } from "../../types/order";
import { useApiRequest } from "../../hooks/useApiRequest";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";
import { OrderCardSalesChangeOrder } from "../../types/orderCardSalesChangeOrder";
import { useSession } from "../../hooks/useSession";

interface OrderCardAddSalesChangeOrderProps {
  order: Order;
  orderCard: OrderCardAddSalesChangeOrderType;
  expanded: boolean;
  groupIndex: number;
  onAddSalesChangeOrder: (orderCard: OrderCardSalesChangeOrder) => void;
}

export const OrderCardAddSalesChangeOrder: React.FC<
  OrderCardAddSalesChangeOrderProps
> = ({ order, orderCard, expanded, groupIndex, onAddSalesChangeOrder }) => {
  const disabled = orderCard.isLocked;
  const { showErrorToast } = useGlobalToastNotificationContext();
  const { request, data, loading, errorMessage } =
    useApiRequest<OrderCardSalesChangeOrder>();
  const { user } = useSession();

  const handleAddSalesChangeOrderClick = () => {
    request(`/orders/${order.orderGuid}/changeorders/add`, {
      method: "GET",
    });
  };

  useEffect(() => {
    if (errorMessage) {
      showErrorToast({ message: errorMessage });
    }
  }, [errorMessage, showErrorToast]);

  useEffect(() => {
    if (data) {
      onAddSalesChangeOrder(data);
    }
  }, [data, onAddSalesChangeOrder]);

  return (
    <OrderCardBase
      disabled={disabled}
      orderCard={orderCard}
      expanded={expanded}
      groupIndex={groupIndex}
      action={
        (!user?.isRetailUser && 
        <OrderCardActionButton
          onClick={handleAddSalesChangeOrderClick}
          disabled={disabled || loading}
        >
          Add Sales Change Order
        </OrderCardActionButton>
        )
      }
    />
  );
};
