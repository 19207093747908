import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import useEventListener from "../hooks/useEventListener";

type FillContainerProps = {
  /**
   * The amount of padding beneath the provided children.
   */
  bottomPadding?: number;
  /**
   * The default height when the top position has not yet been calculated.
   */
  defaultHeight?: number;
  children: React.ReactNode;
};

/**
 * A container that who's height will be the remaining space on the page.
 *
 * Note: Currently, this component requires that the height of the elements
 * above it remain consistent from when the page is loaded. If we need to
 * handle a use case where the elements above it can change in height we
 * may need to add a resize observer.
 */
export const FillContainer: React.FC<FillContainerProps> = ({
  bottomPadding = 0,
  defaultHeight = 0,
  children,
}) => {
  const containerElementRef = useRef<HTMLElement | null>(null);
  const [containerTopPosition, setContainerTopPosition] = useState<
    number | undefined
  >(undefined);

  const setTopPosition = useCallback(() => {
    if (containerElementRef.current) {
      const clientRect = containerElementRef.current.getBoundingClientRect();
      setContainerTopPosition(clientRect.top);
    }
  }, []);

  // Set the position on initial render
  useEffect(() => {
    setTopPosition();
  }, [setTopPosition]);

  // And whenever the window is resized
  useEventListener(window, "resize", setTopPosition);

  return (
    <Box
      ref={containerElementRef}
      sx={{
        height: containerTopPosition
          ? `calc(100vh - ${containerTopPosition + bottomPadding}px)`
          : defaultHeight,
        overflow: "auto",
      }}
    >
      {children}
    </Box>
  );
};
