export const isValidEmail = (email: string | null | undefined): boolean => {
  if (!email) {
    return false;
  }

  return email.match(/[^@]+@[^@]+\.[^@]+/) != null;
};

export const isValidPhoneNumber = (
  phoneNumber: string | null | undefined
): boolean => {
  if (!phoneNumber) {
    return false;
  }

  return phoneNumber.match(/^\d{10}$/) != null;
};

export const isValidNumber = (
  number: string | number | null | undefined
): boolean => {
  if (number == null) {
    return false;
  }

  const numberString = number.toString();

  const numberRegex = /^-?\d+(\.\d+)?$/;

  return (
    numberRegex.test(numberString) &&
    !isNaN(parseFloat(numberString)) &&
    isFinite(parseFloat(numberString))
  );
};

export const isValidCurrency = (
  currency: string | number | null | undefined
): boolean => {
  if (currency == null) {
    return false;
  }

  const currencyString = currency.toString();
  const parsedCurrency = parseFloat(currencyString);

  const currencyRegex = /^\d+(\.\d{1,2})?$/;

  return (
    !isNaN(parsedCurrency) &&
    isFinite(parsedCurrency) &&
    currencyRegex.test(currencyString)
  );
};
