import React from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { FormSection } from "../../components/FormSection";
import { Control, Controller, UseFormSetValue } from "react-hook-form";
import MultiSelectField from "../../components/MultiSelectField";
import { ModelRoom, modelRoomOptions } from "../../types/modelRoom";
import { FormData } from "./FormData";
import { useSession } from "../../hooks/useSession";

interface RoomsFormSectionProps {
  modelRooms: ModelRoom[];
  control: Control<FormData>;
  setValue: UseFormSetValue<FormData>;
}

export const RoomsFormSection: React.FC<RoomsFormSectionProps> = ({
  modelRooms,
  control,
  setValue,
}) => {
  const { user } = useSession();
  return (
    <FormSection heading="Rooms" sx={{ marginBottom: 0 }}>
      <Grid xs={12}>
        <Controller
          control={control}
          name="modelRooms"
          rules={{ required: "Please select a value." }}
          render={({ field, fieldState }) => (
            <MultiSelectField
              {...field}
              label="Applicable Rooms"
              placeholder="Select applicable rooms"
              required
              fullWidth
              options={modelRoomOptions}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              onDelete={(option) => {
                const newModelRooms = modelRooms.filter(
                  (s) => s !== option.value
                );
                setValue("modelRooms", newModelRooms);
              }}
              disabled={user?.isRetailUser}
            />
          )}
        />
      </Grid>
    </FormSection>
  );
};
