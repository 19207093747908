import React, { useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { Controller, useForm } from "react-hook-form";
import { OrderCardBase } from "./OrderCardBase";
import { useOrderCard } from "./OrderCardContext";
import InputField from "../../components/InputField";
import {
  InvoiceFloorInsulationMaterials,
  invoiceFloorInsulationMaterialsOptions,
  InvoiceFloorInsulationTypes,
  invoiceFloorInsulationTypesOptions,
  type OrderCardInvoiceFloorInsulation as OrderCardInvoiceFloorInsulationType,
} from "../../types/orderCardInvoiceFloorInsulation";
import { type OrderCardInvoiceExteriorWallInsulation as OrderCardInvoiceExteriorWallInsulationType } from "../../types/orderCardInvoiceExteriorWallInsulation";
import { type OrderCardInvoiceCeilingInsulation as OrderCardInvoiceCeilingInsulationType } from "../../types/orderCardInvoiceCeilingInsulation";
import SelectField from "../../components/SelectField";
import { InputAdornment } from "@mui/material";

type FormData = {
  material: InvoiceFloorInsulationMaterials | null;
  rValue: number | null;
  thickness: number | null;
  type: InvoiceFloorInsulationTypes | null;
};

const getDefaultValues = (
  orderCard:
    | OrderCardInvoiceFloorInsulationType
    | OrderCardInvoiceExteriorWallInsulationType
    | OrderCardInvoiceCeilingInsulationType
    | null
): FormData => {
  return {
    material: orderCard?.cardData.material ?? null,
    rValue: orderCard?.cardData.rValue ?? null,
    thickness: orderCard?.cardData.thickness ?? null,
    type: orderCard?.cardData.type ?? null,
  };
};

interface OrderCardInvoiceInsulationProps {
  orderCard:
    | OrderCardInvoiceFloorInsulationType
    | OrderCardInvoiceExteriorWallInsulationType
    | OrderCardInvoiceCeilingInsulationType;
  expanded: boolean;
  groupIndex: number;
}

export const OrderCardInvoiceInsulation: React.FC<
  OrderCardInvoiceInsulationProps
> = ({ orderCard, expanded, groupIndex }) => {
  const { saveOrderCard, setEditingOrderCard } = useOrderCard();

  const disabled = orderCard.isLocked;

  const { control, register, reset, getValues } = useForm<FormData>({
    defaultValues: getDefaultValues(orderCard),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const handleBlurField = () => {
    const values = getValues();
    saveOrderCard(orderCard.orderCardGuid, values);
    setEditingOrderCard(null);
  };

  const handleEditingField = () => {
    setEditingOrderCard(orderCard);
  };

  const handleClearClick = () => {
    const defaultValues = getDefaultValues(null);
    reset(defaultValues);

    saveOrderCard(orderCard.orderCardGuid, defaultValues);
  };

  useEffect(() => {
    reset(getDefaultValues(orderCard), { keepDirtyValues: true });
  }, [orderCard, reset]);

  return (
    <OrderCardBase
      disabled={disabled}
      orderCard={orderCard}
      onClearClick={handleClearClick}
      expanded={expanded}
      groupIndex={groupIndex}
    >
      <Grid container spacing={2} component="form">
        <Grid xs={12} sm={12} md={6}>
          <Controller
            control={control}
            name="type"
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                label="Type"
                placeholder="Select Type"
                onFocus={handleEditingField}
                required
                fullWidth
                disabled={disabled}
                value={field.value ?? ""}
                options={invoiceFloorInsulationTypesOptions}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                onBlur={() => {
                  handleBlurField();
                  field.onBlur();
                }}
              />
            )}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <Controller
            control={control}
            name="material"
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                label="Material"
                placeholder="Select Material"
                onFocus={handleEditingField}
                required
                fullWidth
                disabled={disabled}
                value={field.value ?? ""}
                options={invoiceFloorInsulationMaterialsOptions}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                onBlur={() => {
                  handleBlurField();
                  field.onBlur();
                }}
              />
            )}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <InputField
            {...register("thickness", {
              onBlur: handleBlurField,
              valueAsNumber: true,
            })}
            label="Initial Installed Thickness"
            onFocus={handleEditingField}
            placeholder="Enter Initial Installed Thickness"
            required
            fullWidth
            disabled={disabled}
            type="number"
            endAdornment={<InputAdornment position="end">IN</InputAdornment>}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <InputField
            {...register("rValue", {
              onBlur: handleBlurField,
              valueAsNumber: true,
            })}
            label="R-Value"
            onFocus={handleEditingField}
            placeholder="Enter R-Value"
            required
            fullWidth
            disabled={disabled}
            type="number"
          />
        </Grid>
      </Grid>
    </OrderCardBase>
  );
};
