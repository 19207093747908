import React from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {
  FormHelperText,
  OutlinedInput,
  OutlinedInputProps,
  SxProps,
  Theme,
} from "@mui/material";

export type InputFieldProps = OutlinedInputProps & {
  label?: string;
  fixFor?: "username" | "new-password";
  formControlSx?: SxProps<Theme>;
  helperText?: string;
  intOnly?: boolean;
};

const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>(
  ({ label, fixFor, formControlSx, helperText, intOnly, ...props }, ref) => {
    const combinedSx = {
      ...props.sx,
      marginBottom: 0,
    };

    return (
      <>
        {/* Autofill hack to prevent browser from autofilling the form */}
        {fixFor && (
          <input
            type={fixFor === "new-password" ? "password" : "text"}
            style={{ display: "none" }}
            autoComplete={fixFor}
          />
        )}
        <FormControl
          fullWidth={props.fullWidth}
          required={props.required}
          sx={{ marginBottom: 2, ...formControlSx }}
        >
          {label && <FormLabel required={props.required}>{label}</FormLabel>}
          <OutlinedInput
            {...props}
            inputProps={{
              onWheel: (e) => e.currentTarget.blur(),
              style: {
                ...(props.disabled ? { cursor: "not-allowed" } : {}),
              },
              autoComplete: "off",
            }}
            sx={combinedSx}
            size="small"
            inputRef={ref}
            onKeyDown = {intOnly ? (e) => {
              if (e.key === '.') e.preventDefault();
            } : undefined}
          />
          {helperText ? (
            <FormHelperText error={props.error}>{helperText}</FormHelperText>
          ) : null}
        </FormControl>
      </>
    );
  }
);

export default InputField;
