import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "../../components/Modal";
import InputField from "../../components/InputField";
import { Alert, Box, Button, Stack } from "@mui/material";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Retailer } from "../../types/tenant";
import SelectField from "../../components/SelectField";
import { stateSelectOptions } from "../../types/states";
import {
  AutocompleteField,
  RegularOption,
} from "../../components/AutocompleteField";

type FormData = {
  tenantName: string;
  tenantType: "r";
  tenantCity: string;
  tenantState: string;
  // Used when the user selects an existing tenant.
  retailTenantGuid: string | null;
};

type NewEditRetailerModalProps = {
  isOpen: boolean;
  retailersNotInCompany: Retailer[];
  onSaveSuccessful: (tenantGuid: string) => void;
  onClose: () => void;
};

const getDefaultValues = (retailer: Retailer | null): FormData => {
  return {
    tenantName: retailer?.tenantName ?? "",
    tenantType: "r",
    tenantCity: retailer?.tenantCity ?? "",
    tenantState: retailer?.tenantState ?? "",
    retailTenantGuid: null,
  };
};

export const NewEditRetailerModal: React.FC<NewEditRetailerModalProps> = ({
  isOpen,
  retailersNotInCompany,
  onSaveSuccessful,
  onClose,
}) => {
  const [newRetailerOption, setNewRetailerOption] = useState<
    RegularOption<string>[]
  >([]);

  const {
    data: saveRetailerData,
    loading: saveRetailerLoading,
    request: saveRetailerRequest,
    status: saveRetailerStatus,
    error: saveRetailerError,
  } = useApiRequest<Retailer>(true);

  const { control, register, handleSubmit, setValue, formState, watch } =
    useForm<FormData>({
      defaultValues: getDefaultValues(null),
    });
  const retailTenantGuid = watch("retailTenantGuid");
  const isExistingTenantSelected = !!retailTenantGuid;

  const onSubmit: SubmitHandler<FormData> = (data: FormData) => {
    if (retailTenantGuid) {
      saveRetailerRequest(`/tenants/add`, {
        method: "POST",
        data: { retailTenantGuid },
      });
    } else {
      saveRetailerRequest("/tenants/add", { method: "POST", data });
    }
  };

  useEffect(() => {
    if (
      saveRetailerData &&
      saveRetailerStatus === "ok" &&
      saveRetailerLoading === false
    ) {
      onSaveSuccessful(saveRetailerData.tenantGuid);
    }
  }, [
    saveRetailerData,
    onSaveSuccessful,
    saveRetailerStatus,
    saveRetailerLoading,
  ]);

  const retailerOptions = useMemo(() => {
    if (!retailersNotInCompany) {
      return [];
    }
    return [
      ...retailersNotInCompany.map((retailer) => ({
        label: retailer.tenantName,
        value: retailer.tenantGuid,
      })),
      ...newRetailerOption,
    ];
  }, [retailersNotInCompany, newRetailerOption]);

  const handleAttributeChange = (
    value: RegularOption<string>,
    isNew: boolean
  ) => {
    const existingRetailer = retailersNotInCompany.find(
      (r) => r.tenantGuid === value.value
    );
    setValue("tenantCity", existingRetailer?.tenantCity ?? "");
    setValue("tenantState", existingRetailer?.tenantState ?? "");
    setValue("retailTenantGuid", existingRetailer?.tenantGuid ?? null);
    // Only add to new retailer if it is not already in the list
    if (!existingRetailer) {
      setNewRetailerOption([{ label: value.value, value: value.value }]);
    }
  };

  return (
    <Modal heading={"New Retailer"} isOpen={isOpen} onClose={onClose}>
      <Box sx={{ width: { xs: "100%", sm: "500px" } }}>
        {saveRetailerError && (
          <Alert severity="error" sx={{ marginBottom: 2 }}>
            Something went wrong. Please try again.
          </Alert>
        )}
        <Stack component="form" onSubmit={handleSubmit(onSubmit)} spacing={3}>
          <Controller
            control={control}
            name="tenantName"
            rules={{ required: "Please select a value." }}
            render={({ field, fieldState }) => {
              const selectedValue = retailerOptions?.find(
                (s) => s.value === field.value
              );
              return (
                <AutocompleteField
                  {...field}
                  label="Retailer Name"
                  noOptionsText="No Retailers"
                  allowNewOption
                  options={retailerOptions}
                  onOptionChange={handleAttributeChange}
                  value={selectedValue ?? null}
                  placeholder="Create or Select Retailer"
                  onChange={(e, value) => {
                    field.onChange(value?.value ?? null);
                  }}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  getOptionKey={(o) => o.value}
                />
              );
            }}
          />

          <Stack direction="row" spacing={1}>
            <Box sx={{ flexGrow: 2, flexShrink: 1, flexBasis: 0 }}>
              <InputField
                label="City"
                placeholder="Enter City"
                fullWidth
                required={!isExistingTenantSelected}
                disabled={isExistingTenantSelected}
                {...register(
                  "tenantCity",
                  isExistingTenantSelected
                    ? { required: false }
                    : { required: "Enter name" }
                )}
                error={!!formState.errors.tenantCity}
                helperText={formState.errors.tenantCity?.message}
              />
            </Box>
            <Box sx={{ flexGrow: 1, flexShrink: 1, flexBasis: 0 }}>
              <Controller
                control={control}
                name="tenantState"
                render={({ field, fieldState }) => (
                  <SelectField
                    {...field}
                    label="State"
                    placeholder="Select State"
                    required={!isExistingTenantSelected}
                    fullWidth
                    disabled={isExistingTenantSelected}
                    options={stateSelectOptions}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Box>
          </Stack>

          <Stack direction="row" justifyContent="flex-end">
            <Button
              variant="outlined"
              onClick={onClose}
              sx={{ marginRight: 2 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={saveRetailerLoading}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};
