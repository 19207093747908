import React, { useCallback, useState } from "react";
import {
  DataGrid,
  DataGridProps,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridValidRowModel,
  gridClasses,
} from "@mui/x-data-grid";

export function Table<R extends GridValidRowModel = any>(
  props: DataGridProps<R>
) {
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});

  const handleCellClick = useCallback(
    (params: GridCellParams, event: React.MouseEvent) => {
      if (!params.isEditable) {
        return;
      }

      // Ignore portal
      if (
        (event.target as any).nodeType === 1 &&
        !event.currentTarget.contains(event.target as Element)
      ) {
        return;
      }

      setCellModesModel((prevModel) => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View },
                }),
                {}
              ),
            }),
            {}
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({
                ...acc,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
            [params.field]: { mode: GridCellModes.Edit },
          },
        };
      });
    },
    []
  );

  const handleCellModesModelChange = useCallback(
    (newModel: GridCellModesModel) => {
      setCellModesModel(newModel);
    },
    []
  );

  const combinedSx = {
    ...props.sx,
    [`& .${gridClasses.columnHeader}`]: {
      backgroundColor: "#F9FAFB",
    },
    [`& .${gridClasses["scrollbarFiller--header"]}`]: {
      backgroundColor: "#F9FAFB",
    },
    [`& .${gridClasses.cell}`]: {
      backgroundColor: "white",
    },
    [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
      outline: "transparent",
    },
    [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]:
      {
        outline: "none",
      },

    // If onRowClick is provided, make the rows look clickable
    ...(props.onRowClick && {
      "& .MuiDataGrid-row:hover": {
        cursor: "pointer",
      },
    }),
  };

  return (
    <DataGrid
      {...props}
      sx={combinedSx}
      cellModesModel={cellModesModel}
      onCellModesModelChange={handleCellModesModelChange}
      onCellClick={handleCellClick}
    />
  );
}
