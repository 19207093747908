import { Container } from "@mui/material";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { MainLayoutBase } from "../../components/MainLayout";

export const ReportingAddEditRouteLayout: React.FC = () => {
  return (
    <MainLayoutBase
      containerProps={{
        maxWidth: false,
        disableGutters: true,
        sx: { paddingTop: 2, position: "relative" },
      }}
    >
      <Container maxWidth={false}>
        <Breadcrumbs />
      </Container>
    </MainLayoutBase>
  );
};
