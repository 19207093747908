import { Series } from "./series";

export type Surcharge = {
  surchargeGuid: string;
  surchargeAmount: number;
  surchargeUom: "f" | "s";
  startDate: number;
  endDate: number;
  series: Series;
};

export type SurchargeUom = Surcharge["surchargeUom"];

export const getSurchargeUomDisplayName = (
  surchargeUom: SurchargeUom
): string => (surchargeUom === "s" ? "Sq Ft" : "Floor");
