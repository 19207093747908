import React, { useCallback, useEffect, useMemo } from "react";
import { Modal } from "../../components/Modal";
import InputField from "../../components/InputField";
import { Alert, Box, Button, Stack, InputAdornment } from "@mui/material";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  AttributeSelection,
  getAttributeSelectionImageUrl,
} from "../../types/attributeSelection";
import { DateTime } from "luxon";
import { DatePicker } from "../../components/DatePicker";
import { AvatarUpload } from "../../components/AvatarUpload";
import { Attribute } from "../../types/attribute";
import { isValidNumber } from "../../utils/validation";

type FormData = {
  attributeselectionName: string;
  attributeselectionPrice: number | null;
  attributeselectionImageUrl: string | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
};

type NewEditAttributeSelectionModalProps = {
  isOpen: boolean;
  isNew: boolean;
  attribute: Attribute;
  attributeSelection: AttributeSelection | undefined;
  onSaveSuccessful: (attributeSelection: AttributeSelection) => void;
  onPhotoChange: () => void;
  onClose: () => void;
};

const getDefaultValues = (
  attributeSelection: AttributeSelection | undefined
): FormData => {
  return {
    attributeselectionName: attributeSelection?.attributeselectionName ?? "",
    attributeselectionPrice:
      attributeSelection?.attributeselectionPrice ?? null,
    attributeselectionImageUrl:
      attributeSelection?.attributeselectionImageUrl ?? null,
    startDate: attributeSelection?.startDate
      ? DateTime.fromSeconds(attributeSelection?.startDate)
      : null,
    endDate: attributeSelection?.endDate
      ? DateTime.fromSeconds(attributeSelection?.endDate)
      : null,
  };
};

export const NewEditAttributeSelectionModal: React.FC<
  NewEditAttributeSelectionModalProps
> = ({
  isOpen,
  onSaveSuccessful,
  onPhotoChange,
  onClose,
  attribute,
  attributeSelection,
  isNew,
}) => {
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const {
    data: saveAttributeSelectionData,
    loading: saveAttributeSelectionLoading,
    request: saveAttributeSelectionRequest,
    status: saveAttributeSelectionStatus,
    error: saveAttributeSelectionError,
  } = useApiRequest<AttributeSelection>(true);
  const {
    data: uploadPhotoData,
    error: uploadPhotoError,
    loading: uploadPhotoLoading,
    request: uploadPhotoRequest,
    status: uploadPhotoStatus,
    errorMessage: uploadPhotoErrorMessage,
  } = useApiRequest<AttributeSelection>(true);

  const { control, register, reset, handleSubmit, formState, trigger } =
    useForm<FormData>({
      defaultValues: getDefaultValues(attributeSelection),
    });

  const onSubmit: SubmitHandler<FormData> = (data: FormData) => {
    if (isNew) {
      saveAttributeSelectionRequest(
        `/company/attributes/${attribute.attributeGuid}/attributeselections/add`,
        {
          method: "POST",
          data,
        }
      );
    } else {
      saveAttributeSelectionRequest(
        `company/attributeselections/${attributeSelection?.attributeselectionGuid}/edit`,
        {
          method: "POST",
          data,
        }
      );
    }
  };

  const uploadFile = useCallback(
    (attributeselectionGuid: string, file: File) => {
      uploadPhotoRequest(
        `/company/attributeselections/${attributeselectionGuid}/attach`,
        {
          method: "POST",
          data: { attributeselectionImage: file },
        }
      );
    },
    [uploadPhotoRequest]
  );

  const handleSelectFile = (file: File) => {
    // Save file to state until form is submitted if new
    if (isNew && !attributeSelection?.attributeselectionGuid) {
      setSelectedFile(file);
    } else {
      uploadFile(attributeSelection!.attributeselectionGuid, file);
    }
  };

  const handleDeleteFile = () => {
    uploadPhotoRequest(
      `/company/attributeselections/${attributeSelection?.attributeselectionGuid}/deleteimage`,
      {
        method: "DELETE",
      }
    );
  };

  useEffect(() => {
    if (attributeSelection) {
      const defaultValues = getDefaultValues(attributeSelection);
      reset(defaultValues);
    }
  }, [reset, attributeSelection]);

  useEffect(() => {
    if (
      uploadPhotoData &&
      uploadPhotoStatus === "ok" &&
      uploadPhotoLoading === false
    ) {
      onPhotoChange();
      setSelectedFile(null);
    }
  }, [onPhotoChange, uploadPhotoData, uploadPhotoLoading, uploadPhotoStatus]);

  useEffect(() => {
    if (
      saveAttributeSelectionData &&
      saveAttributeSelectionStatus === "ok" &&
      saveAttributeSelectionLoading === false
    ) {
      onSaveSuccessful(saveAttributeSelectionData);
    }
  }, [
    onSaveSuccessful,
    saveAttributeSelectionData,
    saveAttributeSelectionLoading,
    saveAttributeSelectionStatus,
  ]);

  useEffect(() => {
    const isSaveAttributeSelectionSuccess =
      saveAttributeSelectionData &&
      saveAttributeSelectionStatus === "ok" &&
      saveAttributeSelectionLoading === false;

    // Do nothing if the save attribute selection failed.
    if (!isSaveAttributeSelectionSuccess) {
      return;
    }

    // Do nothing if were uploading a photo or there was an error while uploading the photo
    if (uploadPhotoError || uploadPhotoLoading) {
      return;
    }

    if (isNew && selectedFile) {
      uploadFile(
        saveAttributeSelectionData.attributeselectionGuid,
        selectedFile
      );
    } else {
      onClose();
    }
  }, [
    isNew,
    onClose,
    saveAttributeSelectionData,
    saveAttributeSelectionLoading,
    saveAttributeSelectionStatus,
    selectedFile,
    uploadFile,
    uploadPhotoError,
    uploadPhotoLoading,
  ]);

  const imageUrl = useMemo(() => {
    if (selectedFile) {
      return URL.createObjectURL(selectedFile);
    }

    return getAttributeSelectionImageUrl(attributeSelection);
  }, [selectedFile, attributeSelection]);

  return (
    <Modal
      heading={
        isNew
          ? "New Attribute Selection"
          : `Edit ${attributeSelection?.attributeselectionName ?? ""}`
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box
        sx={{ width: { xs: "100%", sm: "500px" } }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        {saveAttributeSelectionError && (
          <Alert severity="error" sx={{ marginBottom: 2 }}>
            {saveAttributeSelectionError.response?.status === 409
              ? "An attribute selection already exists with this name."
              : "Something went wrong. Please try again."}
          </Alert>
        )}
        {uploadPhotoErrorMessage && (
          <Alert severity="error" sx={{ marginBottom: 2 }}>
            {uploadPhotoErrorMessage}
          </Alert>
        )}
        <Stack direction="row" spacing={3}>
          {attribute.attributeIncludeImages ? (
            <AvatarUpload
              alt="attribute selection image"
              src={imageUrl}
              variant="circular"
              avatarSx={{ height: 114, width: 114 }}
              deletable={!!attributeSelection?.attributeselectionImageUrl}
              onSelectFile={handleSelectFile}
              onDeleteFile={handleDeleteFile}
            />
          ) : null}
          <Stack spacing={3} width="100%">
            <InputField
              label="Name"
              placeholder="Enter name"
              fullWidth
              required
              {...register("attributeselectionName", {
                required: "Enter name",
              })}
              error={!!formState.errors.attributeselectionName}
              helperText={formState.errors.attributeselectionName?.message}
            />
            <InputField
              label="Price"
              placeholder="Enter price"
              fullWidth
              required
              {...register("attributeselectionPrice", {
                required: "Enter price",
                validate: {
                  isNumber: (value) => {
                    return isValidNumber(value) || "Please enter a number.";
                  },
                },
              })}
              error={!!formState.errors.attributeselectionPrice}
              helperText={formState.errors.attributeselectionPrice?.message}
              type="number"
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
              intOnly={true}      
            />
            <Controller
              name="startDate"
              control={control}
              rules={{
                required: "Enter Start Date",
                validate: (startDate, fields) => {
                  if (!startDate) {
                    return "Start Date is required";
                  }
                  if (startDate && !fields.endDate) {
                    return true;
                  }
                  if (fields.endDate && startDate > fields.endDate) {
                    return "Start Date must be before End Date";
                  }
                  return true;
                },
              }}
              render={({ field, formState }) => (
                <DatePicker
                  label="Start Date"
                  fullWidth
                  {...field}
                  required
                  error={!!formState.errors.startDate}
                  helperText={formState.errors.startDate?.message}
                  onChange={(e) => {
                    field.onChange(e);
                    trigger("endDate");
                  }}
                />
              )}
            />
            <Controller
              name="endDate"
              control={control}
              rules={{
                validate: (endDateValue, fields) => {
                  if (
                    endDateValue &&
                    fields.startDate &&
                    fields.startDate > endDateValue
                  ) {
                    return "End Date must be after Start Date";
                  }
                  return true;
                },
              }}
              render={({ field, formState }) => (
                <DatePicker
                  label="End Date"
                  required
                  fullWidth
                  allowNoDate
                  {...field}
                  error={!!formState.errors.endDate}
                  helperText={formState.errors.endDate?.message}
                  onChange={(e) => {
                    field.onChange(e);
                    trigger("startDate");
                  }}
                />
              )}
            />
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" marginTop={3}>
          <Button variant="outlined" onClick={onClose} sx={{ marginRight: 2 }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={
              !formState.isDirty ||
              !formState.isValid ||
              saveAttributeSelectionLoading
            }
          >
            Save
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
