import {
  OrderQuotePhase,
  getOrderQuotePhaseDisplayName,
} from "../../types/order";
import { Chip } from "@mui/material";

type OrderPhaseChipProps = {
  quotePhase: OrderQuotePhase;
};

export const OrderPhaseChip: React.FC<OrderPhaseChipProps> = ({
  quotePhase,
}) => {
  const orderPhaseColor = () => {
    switch (quotePhase) {
      case "qpo":
        return "success";
      case "qpp":
        return "info";
      case "qpn":
        return "warning";
      default:
        return undefined;
    }
  };

  return (
    <Chip
      label={getOrderQuotePhaseDisplayName(quotePhase)}
      color={orderPhaseColor()}
      variant="filled"
      size="small"
    />
  );
};
