import React, { useMemo } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box, Grid, Stack } from "@mui/material";
import {
  Order,
  getOrderPrice,
  canAddChangeOrder,
  canDeleteOrder,
} from "../../types/order";

import { formatAsDate } from "../../utils/date";
import { formatAsCurrency } from "../../utils/number";
import {
  MoreMenuButton,
  MoreMenuButtonProps,
} from "../../components/MoreMenuButton";
import { OrderPhaseChip } from "./OrderPhaseChip";
import { useSession } from "../../hooks/useSession";

type OrderCardProps = {
  order: Order;
  handleOrderEditClick: (orderGuid: string) => void;
  handleNewChangeOrderClick: (orderGuid: string) => void;
  handleDeleteOrderClick: (orderGuid: string) => void;
};

export const OrderCard: React.FC<OrderCardProps> = ({
  order,
  handleOrderEditClick,
  handleNewChangeOrderClick,
  handleDeleteOrderClick,
}) => {
  const { user } = useSession();

  const menuItems = useMemo(() => {
    const items: MoreMenuButtonProps["menuItems"] = [
      {
        label: "Edit",
        onClick: () => handleOrderEditClick(order.orderGuid),
      },
    ];

    if (order.orderStatus === "p") {
      items.push({
        label: "Convert to Quote",
        onClick: () => {alert("Not Implemented");}, // handleConvertToQuoteClick(order.orderGuid),
      });
    }

    if (canAddChangeOrder(order) && !user?.isRetailUser) {
      items.push({
        label: "New Sales Change Order",
        onClick: () => handleNewChangeOrderClick(order.orderGuid),
      });
    }

    if (user && canDeleteOrder(order, user)) {
      items.push({
        label: "Delete",
        onClick: () => handleDeleteOrderClick(order.orderGuid),
      });
    }

    return items;
  }, [
    handleDeleteOrderClick,
    handleNewChangeOrderClick,
    handleOrderEditClick,
    order,
    user,
  ]);

  return (
    <Card
      sx={{
        mb: 2,
        touchAction: "none",
        boxShadow: "0px 1px 2px 0px #1018280D",
        cursor: "grab",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          height: user?.isRetailUser ? "210px" : "176px",
        }}
      >
        <Box>
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <Typography
              variant="h5"
              component="div"
              sx={{
                fontSize: 18,
                cursor: "pointer",
              }}
              onClick={() => handleOrderEditClick(order.orderGuid)}
            >
              ID {order.orderIdentifier}
            </Typography>
            <Stack direction="row">
              {order.orderStatus === "q" ? (
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleOrderEditClick(order.orderGuid)}
                >
                  <OrderPhaseChip quotePhase={order.quotePhase} />
                </Box>
              ) : null}
              <div style={{ touchAction: "none" }}>
                {menuItems.length > 0 && (
                  <MoreMenuButton
                    menuItems={menuItems}
                    componentProps={{ sx: { marginTop: -1, marginRight: -1 } }}
                  />
                )}
              </div>
            </Stack>
          </Stack>
        </Box>
        <Typography noWrap sx={{ fontSize: 12 }} color="text.secondary">
          {order.calculatedVinNumber && `VIN: ${order.calculatedVinNumber}`}
        </Typography>
        <Typography sx={{ mb: 1.5, fontSize: 12 }} color="text.secondary">
          Created: {formatAsDate(order.createdTimestamp)}
        </Typography>
        <Box>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ fontSize: 12 }}
            noWrap
          >
            {order?.orderStatus === "p" ? "" :
            user?.isRetailUser ? 
              `Manufacturer: ${order?.mfgTenantName}`
              :
              order?.soldAsType !== "y" ? `Retailer: ${order?.retailer?.retailerFriendlyName || order?.retailer?.tenantName}` : ""
            }
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ fontSize: 12 }}
          >
            {order?.soldAsType === "r"
              ? `Buyer: ${order?.buyerLastName ?? ""}`
              : ""}
          </Typography>
        </Box>
        {order.model && (
          <Grid
            container
            justifyContent="space-between"
            marginTop="auto"
            alignItems="flex-end"
          >
            <Grid item>
              <>
                <Typography variant="caption" color="text.secondary">
                  Model
                </Typography>
                <Typography noWrap variant="body1" sx={{ fontSize: 12, maxWidth: "180px" }}>
                  {order.model?.modelName}
                </Typography>
              </>
            </Grid>
            {!["a","p"].includes(order.orderStatus) && (
              <Grid item>
                <Box width="100px">
                  {user?.isRetailUser ? (
                    <>
                      <Typography variant="caption" color="text.secondary">
                        Buyer Price
                      </Typography>
                      <Typography variant="body1" sx={{ fontSize: 12 }}>
                        {formatAsCurrency(order.buyerPrice)}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography variant="caption" color="text.secondary">
                        Base + Options
                      </Typography>
                      <Typography variant="body1" sx={{ fontSize: 12 }}>
                        {formatAsCurrency(getOrderPrice(order))}
                      </Typography>
                    </>
                  )}
                </Box>
              </Grid>
            )}
          </Grid>
        )}
        {user?.isRetailUser && (
          <Grid
          container
          justifyContent="space-between"
          marginTop="14px"
          alignItems="flex-end"
        >
          {!["p"].includes(order.orderStatus) && (
            <Grid item>
              <Typography variant="caption" color="text.secondary">
                Created By
              </Typography>
              <Typography variant="body1" noWrap sx={{ fontSize: 12, maxWidth: "180px" }}>
                {order.createdByText}
              </Typography>
            </Grid>
          )}
          {!["a","p"].includes(order.orderStatus) && (
            <Grid item>
              <Box width="100px">
                <Typography variant="caption" color="text.secondary">
                  Mfg Invoice Total
                </Typography>
                <Typography variant="body1" sx={{ fontSize: 12 }}>
                  {formatAsCurrency(order.invoiceTotal)}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      )}
      </CardContent>
    </Card>
  );
};
