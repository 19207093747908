import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import { Retailer } from "../../types/tenant";
import InputField from "../../components/InputField";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";
import { useApiRequest } from "../../hooks/useApiRequest";
import {
  getUserFullName,
  getUserInitials,
  getUserPhotoUrlSrc,
} from "../../types/user";
import { formatAsDate } from "../../utils/date";
import { RetailerNote } from "../../types/retailerNote";

interface CompanyInfoTabNotesProps {
  retailer: Retailer | null;
  onAddNote: (note: RetailerNote) => void;
}

export const CompanyInfoTabNotes: React.FC<CompanyInfoTabNotesProps> = ({
  retailer,
  onAddNote,
}) => {
  const { showErrorToast } = useGlobalToastNotificationContext();
  const [noteText, setNoteText] = useState<string>("");

  const {
    data: addNoteData,
    loading: addNoteLoading,
    request: addNoteRequest,
    status: addNoteStatus,
    error: addNoteError,
  } = useApiRequest<RetailerNote>(false);

  useEffect(() => {
    if (addNoteData && addNoteStatus === "ok" && addNoteLoading === false) {
      onAddNote(addNoteData);
    } else if (addNoteError) {
      showErrorToast({ message: "Something went wrong. Please try again.." });
    }
    setNoteText("");
  }, [
    showErrorToast,
    addNoteData,
    addNoteError,
    addNoteLoading,
    addNoteStatus,
    onAddNote,
  ]);

  const handleAddNoteClick = () => {
    if (!noteText) {
      return;
    }
    addNoteRequest(`company/retailers/${retailer?.tenantGuid}/notes/add`, {
      method: "POST",
      data: {
        noteText: noteText,
      },
    });
  };

  return (
    <Box>
      <Typography variant="body1" fontWeight={600} mb={1}>
        Notes
      </Typography>
      <Card variant="outlined">
        <CardContent component={Stack} spacing={2}>
          {retailer?.businessRelation?.retailerNotes.map((note) => {
            return (
              <Box key={note.retailerNotesGuid}>
                <Stack direction="row" justifyContent="space-between">
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Avatar
                      sx={{ height: 20, width: 20, fontSize: "10px" }}
                      src={getUserPhotoUrlSrc(note.user)}
                    >
                      {getUserInitials(note.user)}
                    </Avatar>
                    <Typography fontWeight="500">
                      {getUserFullName(note.user)}
                    </Typography>
                  </Stack>
                  <Typography color="grey.500">
                    {formatAsDate(note.createdTimestamp)}
                  </Typography>
                </Stack>
                <Typography
                  variant="body2"
                  color="text.primary"
                  marginTop={1}
                  whiteSpace="pre-wrap"
                >
                  {note.noteText}
                </Typography>
              </Box>
            );
          })}
          <InputField
            fullWidth
            label=""
            multiline
            minRows={3}
            maxRows={6}
            placeholder="Enter notes..."
            value={noteText}
            onChange={(e) => setNoteText(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            disabled={noteText.length === 0 || addNoteLoading}
            onClick={handleAddNoteClick}
          >
            Add Note
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};
