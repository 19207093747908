import React, { ForwardedRef } from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {
  Avatar,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
  Typography,
  toggleButtonClasses,
  toggleButtonGroupClasses,
} from "@mui/material";

export type SwatchFieldOption<TValue extends NonNullable<string | number>> = {
  value: TValue;
  label: React.ReactNode;
  labelSecondary: React.ReactNode;
  imageUrl?: string;
  error?: boolean;
  helperText?: string;
};

type SwatchBaseProps<TValue extends NonNullable<string | number>> = Omit<
  ToggleButtonGroupProps,
  "onChange"
> & {
  required?: boolean;
  label?: string;
  error?: boolean;
  helperText?: string;
  options: SwatchFieldOption<TValue>[];
  isOptionEqualToValue?: (option: TValue, value: TValue) => boolean;
};

type SwatchFieldExclusiveProps<TValue extends NonNullable<string | number>> = {
  exclusive: true;
  value: TValue;
  onChange?: (value: string) => void;
};

type SwatchFieldNonExclusiveProps<TValue extends NonNullable<string | number>> =
  {
    exclusive?: false;
    value: TValue[];
    onChange?: (value: string[]) => void;
  };

type SwatchFieldProps<TValue extends NonNullable<string | number>> =
  | (SwatchFieldExclusiveProps<TValue> & SwatchBaseProps<TValue>)
  | (SwatchFieldNonExclusiveProps<TValue> & SwatchBaseProps<TValue>);

const SwatchFieldInner = <TValue extends NonNullable<string | number>>(
  {
    label,
    required,
    error,
    helperText,
    options,
    onChange,
    ...props
  }: SwatchFieldProps<TValue>,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const handleChange = (_event: React.MouseEvent<HTMLElement>, value: any) => {
    onChange?.(value);
  };

  return (
    <>
      <FormControl fullWidth size="small">
        {label && (
          <FormLabel
            component="legend"
            required={required}
            sx={{ mb: 1, display: "flex", flexDirection: "row" }}
          >
            <Stack direction="row" spacing={1}>
              <Typography variant="inherit">{label}</Typography>
              {helperText && (
                <Typography color={error ? "error" : undefined}>
                  {helperText}
                </Typography>
              )}
            </Stack>
          </FormLabel>
        )}
        <ToggleButtonGroup
          exclusive
          sx={{
            height: "100%",
            border: 0,
            margin: 0,
            [`& .${toggleButtonGroupClasses.grouped}`]: {
              borderRadius: "100px",
            },
            [`&.${toggleButtonGroupClasses.disabled}`]: {
              border: 0,
            },
            [`& .${toggleButtonClasses.root}.${toggleButtonClasses.selected}`]:
              {
                backgroundColor: "initial",
                outlineColor: "grey.900",
                outlineWidth: "2px",
                outlineStyle: "solid",

                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              },
          }}
          onChange={handleChange}
          {...props}
        >
          <Stack
            direction="row"
            alignItems="flex-start"
            spacing={1}
            useFlexGap
            flexWrap="wrap"
          >
            {options.map((option) => {
              return (
                <ToggleButton
                  key={option.value}
                  value={option.value}
                  sx={{ padding: "2px 20px 2px 12px" }}
                >
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                  >
                    {option.imageUrl ? (
                      <Avatar
                        src={option.imageUrl}
                        sx={{ width: 26, height: 26 }}
                      />
                    ) : null}
                    <Stack alignItems="flex-start">
                      <Typography
                        variant="subtitle2"
                        fontWeight="500"
                        color="#000"
                      >
                        {option.label}
                      </Typography>
                      <Stack direction="row" spacing={1}>
                        <Typography
                          variant="body2"
                          fontSize="12px"
                          color="grey.500"
                        >
                          {option.labelSecondary}
                        </Typography>
                        {option.error && (
                          <Typography
                            variant="body2"
                            fontSize="12px"
                            fontWeight="500"
                            color="error"
                          >
                            {option.helperText}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                </ToggleButton>
              );
            })}
          </Stack>
        </ToggleButtonGroup>
      </FormControl>
    </>
  );
};

const SwatchField = React.forwardRef(SwatchFieldInner) as <
  TTValue extends NonNullable<string | number>
>(
  props: SwatchFieldProps<TTValue> & {
    ref?: ForwardedRef<HTMLInputElement>;
  }
) => ReturnType<typeof SwatchFieldInner>;

export default SwatchField;
