import { Button, Stack, Card } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { User, UserSession } from "../../types/user";
import { useApiRequest } from "../../hooks/useApiRequest";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import InputField from "../../components/InputField";
import { PasswordInput } from "../../components/PasswordInput";
import { AvatarUpload } from "../../components/AvatarUpload";
import { isValidEmail, isValidPhoneNumber } from "../../utils/validation";
import { routes } from "../../routes";
import { PageHeader } from "../../components/PageHeader";
import { useNavigateBack } from "../../hooks/useNavigateBack";
import { useSession } from "../../hooks/useSession";
import { getDirtyFormValues } from "../../utils/form";

type FormData = {
  userFirstName?: string;
  userLastName?: string;
  userPhoneNumber?: string;
  userEmail?: string;
  existingPassword?: string;
  userPassword?: string;
};

const getDefaultValues = (user: UserSession | null): FormData => {
  return {
    userFirstName: user?.userFirstName,
    userLastName: user?.userLastName,
    userPhoneNumber: user?.userPhoneNumber,
    userEmail: user?.userEmail,
    userPassword: "",
    existingPassword: "",
  };
};

function UpdateProfileRoute() {
  const navigateBack = useNavigateBack();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { showErrorToast, showSuccessToast } =
    useGlobalToastNotificationContext();
  const { user, updateUserSession } = useSession();
  const {
    data: userUpdateData,
    loading: userUpdateLoading,
    request: userUpdateRequest,
    status: userUpdateStatus,
    error: userUpdateError,
  } = useApiRequest<User>(true);

  const { handleSubmit, register, reset, trigger, formState, watch } =
    useForm<FormData>({
      defaultValues: getDefaultValues(user),
      reValidateMode: "onChange",
      mode: "onChange",
    });

  const [userPassword, userEmail] = watch(["userPassword", "userEmail"]);
  const isUserPasswordUnchanged = !userPassword;
  const isUserEmailUnchanged = userEmail === user?.userEmail;
  const isExistingPasswordRequired =
    !isUserEmailUnchanged || !isUserPasswordUnchanged;

  usePageMetadata({ title: routes.profile.label });

  useEffect(() => {
    setBreadcrumbs([{ label: routes.profile.label }]);
  }, [setBreadcrumbs]);

  useEffect(() => {
    if (
      userUpdateData &&
      userUpdateStatus === "ok" &&
      userUpdateLoading === false
    ) {
      showSuccessToast({ message: "Profile updated!" });
    } else if (userUpdateError != null) {
      showErrorToast({ message: "Something went wrong. Please try again.." });
    }
  }, [
    showErrorToast,
    showSuccessToast,
    userUpdateData,
    userUpdateError,
    userUpdateLoading,
    userUpdateStatus,
  ]);

  useEffect(() => {
    if (userUpdateData) {
      updateUserSession(userUpdateData);
    }
  }, [userUpdateData, updateUserSession]);

  useEffect(() => {
    if (user) {
      const defaultValues = getDefaultValues(user);
      reset(defaultValues, { keepDirtyValues: true });
    }
  }, [reset, user]);

  const onSubmit = (values: FormData) => {
    const data = getDirtyFormValues(values, formState);
    if (Object.keys(data).length === 0) {
      return;
    }

    userUpdateRequest("/users/me/edit", {
      method: "POST",
      data: data,
    });
  };

  const handleCancelClick = () => {
    navigateBack();
  };

  const handleSelectFile = (file: File) => {
    userUpdateRequest("/users/me/edit", {
      method: "POST",
      data: { userPhoto: file },
    });
  };

  return (
    <>
      <PageHeader title={routes.profile.label} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={1}
          marginBottom={1}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={
              !formState.isValid || !formState.isDirty || userUpdateLoading
            }
          >
            Save
          </Button>
        </Stack>
        <Card variant="outlined" sx={{ padding: 4 }}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            width="100%"
            spacing={{ xs: 2, sm: 6 }}
          >
            <AvatarUpload
              alt="Profile Image"
              src={
                user && user.userPhotoUrl
                  ? `${process.env.REACT_APP_API_BASE_URL}/${user.userPhotoUrl}`
                  : undefined
              }
              variant="circular"
              onSelectFile={handleSelectFile}
            />
            <Grid
              container
              columnSpacing={2}
              rowSpacing={4}
              columns={{ xs: 1, sm: 2 }}
            >
              <Grid xs={1}>
                <InputField
                  {...register("userFirstName", {
                    required: { value: true, message: "Please enter a value." },
                  })}
                  fullWidth
                  required
                  label="First Name"
                  error={!!formState.errors.userFirstName}
                  helperText={formState.errors.userFirstName?.message}
                />
              </Grid>
              <Grid xs={1}>
                <InputField
                  {...register("userLastName", {
                    required: { value: true, message: "Please enter a value." },
                  })}
                  fullWidth
                  required
                  label="Last Name"
                  error={!!formState.errors.userLastName}
                  helperText={formState.errors.userLastName?.message}
                />
              </Grid>
              <Grid xs={1}>
                <InputField
                  {...register("userEmail", {
                    required: { value: true, message: "Please enter a value." },
                    validate: {
                      validEmail: (v) =>
                        isValidEmail(v) ||
                        "Please enter a valid email address.",
                    },
                    onChange: () => trigger("existingPassword"),
                  })}
                  fullWidth
                  label="Email"
                  required
                  fixFor="username"
                  error={!!formState.errors.userEmail}
                  helperText={formState.errors.userEmail?.message}
                />
              </Grid>
              <Grid xs={1}>
                <InputField
                  {...register("userPhoneNumber", {
                    required: { value: true, message: "Please enter a value." },
                    validate: {
                      validPhoneNumber: (v) =>
                        isValidPhoneNumber(v) ||
                        "Please enter a valid phone number.",
                    },
                  })}
                  fullWidth
                  label="Phone"
                  required
                  error={!!formState.errors.userPhoneNumber}
                  helperText={formState.errors.userPhoneNumber?.message}
                />
              </Grid>
              <Grid xs={1}>
                <Stack>
                  <PasswordInput
                    {...register("existingPassword", {
                      validate: (value, formValues) => {
                        const isUserPasswordUnchanged =
                          !formValues.userPassword;

                        const isUserEmailUnchanged =
                          formValues?.userEmail === user?.userEmail;

                        if (isUserPasswordUnchanged && isUserEmailUnchanged) {
                          return true;
                        }

                        return value && value.length > 0;
                      },
                    })}
                    required={isExistingPasswordRequired}
                    fullWidth
                    label="Current Password"
                    fixFor="new-password"
                    error={!!formState.errors.existingPassword}
                    helperText="Current password is required if email or password is changed."
                  />
                </Stack>
              </Grid>
              <Grid xs={1}>
                <PasswordInput
                  {...register("userPassword", {
                    onChange: () => trigger("existingPassword"),
                  })}
                  fullWidth
                  label="New Password"
                  fixFor="new-password"
                  error={!!formState.errors.userPassword}
                />
              </Grid>
            </Grid>
          </Stack>
        </Card>
      </form>
    </>
  );
}

export default UpdateProfileRoute;
