import { Alert, Snackbar, Typography } from "@mui/material";

import { useGlobalToastNotificationContext } from "../context/GlobalToastNotificationContext";

function GlobalToastNotification() {
  const { globalToastNotification, setGlobalToastNotification } =
    useGlobalToastNotificationContext();
  const { severity, title, message } = globalToastNotification ?? {};

  const onClose = () => {
    setGlobalToastNotification(null);
  };

  return (
    <div>
      <Snackbar
        open={Boolean(message)}
        autoHideDuration={severity === "error" ? 5000 : 2000}
        onClose={onClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{ maxWidth: 400 }}
      >
        <Alert severity={severity} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            {title}
          </Typography>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default GlobalToastNotification;
