import { styled } from "@mui/material/styles";
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Stack,
  SxProps,
  Theme,
  Typography,
  TypographyProps,
} from "@mui/material";
import { useEffect, useState } from "react";

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-content": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export type AccordionCardProps<C extends React.ElementType> = AccordionProps<
  C,
  { component?: C }
> & {
  heading?: React.ReactNode;
  headingHtml?: React.ReactNode;
  children: React.ReactNode;
  startActions?: React.ReactNode;
  endActions?: React.ReactNode;
  summarySx?: SxProps<Theme>;
  accordionSx?: SxProps<Theme>;
  detailsSx?: SxProps<Theme>;
  headingProps?: TypographyProps;
  expanded?: boolean;
  onToggleExpand?: (expanded: boolean) => void;
};

export const AccordionCard = <C extends React.ElementType>({
  heading,
  headingHtml,
  startActions,
  endActions,
  children,
  detailsSx,
  summarySx,
  accordionSx,
  headingProps,
  defaultExpanded,
  expanded: expandedProp = defaultExpanded ?? false,
  onToggleExpand,
  ...accordionProps
}: AccordionCardProps<C>) => {
  const [expanded, setExpanded] = useState<boolean>(expandedProp);

  const handleChange = (_event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
    if (onToggleExpand) {
      onToggleExpand(isExpanded);
    }
  };

  useEffect(() => {
    setExpanded(expandedProp);
  }, [expandedProp]);

  return (
    <>
      <Accordion
        expanded={expanded}
        onChange={handleChange}
        sx={{
          my: 1,
          borderRadius: 2,
          "&:first-of-type": {
            borderRadius: 2,
          },
          "&:last-of-type": {
            borderRadius: 2,
          },
          "&::before": {
            display: "none",
          },
          ...accordionSx,
        }}
        {...accordionProps}
      >
        <CustomAccordionSummary sx={summarySx}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            {startActions}
            <ExpandMore
              htmlColor="#98A2B3"
              sx={{
                transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                transform: expanded ? "rotate(0deg)" : "rotate(-90deg)",
              }}
            />
            {heading && <Typography {...headingProps}>{heading}</Typography>}
            {headingHtml}
          </Stack>
          {endActions}
        </CustomAccordionSummary>
        <AccordionDetails sx={detailsSx}>{children}</AccordionDetails>
      </Accordion>
    </>
  );
};
