import React from "react";
import { Typography } from "@mui/material";
import { ConfirmModal } from "../../components/ConfirmModal";

interface AttributeRemoveConfirmModalProps {
  attributeName: string;
  isOpen: boolean;
  onRemove: () => void;
  onCancel: () => void;
}

export const AttributeRemoveConfirmModal: React.FC<
  AttributeRemoveConfirmModalProps
> = ({ attributeName, isOpen, onRemove, onCancel }) => {
  return (
    <ConfirmModal
      isOpen={!!isOpen}
      heading={`Remove ${attributeName}?`}
      body={
        <>
          <Typography variant="body2" color="grey.500">
            {`You are about to disconnect ${attributeName} from this option. This
            does not remove the attribute from other options.`}
          </Typography>
        </>
      }
      confirmButtonText="Remove"
      onConfirm={onRemove}
      onClose={onCancel}
    />
  );
};
