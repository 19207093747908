import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Grid,
  InputAdornment,
  OutlinedInput,
  Stack,
} from "@mui/material";
import Search from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined";
import { EmptyState } from "../../components/EmptyState";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import { routes } from "../../routes";
import { Category } from "../../types/category";
import { useApiRequest } from "../../hooks/useApiRequest";
import { NewEditCategoryModal } from "./NewEditCategoryModal";
import { FilterSearchButton } from "../../components/FilterSearchButton";
import { MoreMenuButton } from "../../components/MoreMenuButton";
import { Group } from "../../types/group";
import { NewEditGroupModal } from "./NewEditGroupModal";
import { Switch } from "../../components/Switch";
import { NewEditSubGroupModal } from "./NewEditSubGroupModal";
import { Series } from "../../types/series";
import { FilterSearchDateRangeButton } from "../../components/FilterSearchDateRangeButton";
import { DateTime } from "luxon";
import { Subgroup } from "../../types/subgroup";
import { GridColDef } from "@mui/x-data-grid";
import { formatAsDate } from "../../utils/date";
import { DraggableList } from "../../components/DraggableList";
import { AccordionCardDraggable } from "../../components/AccordionCardDraggable";
import { TableDraggable } from "../../components/TableDraggable";
import { generatePath, useNavigate } from "react-router-dom";
import { updateSortOrder } from "../../utils/array";

const ManageSelectionOptionsRoute: React.FC = () => {
  const { setBreadcrumbs } = useBreadcrumbs();
  const [isEditingCategory, setIsEditingCategory] = useState(false);
  const [isEditingGroup, setIsEditingGroup] = useState(false);
  const [isEditingSubGroup, setIsEditingSubGroup] = useState(false);
  const [editingCategory, setEditingCategory] = useState<Category | undefined>(
    undefined
  );
  const [editingGroup, setEditingGroup] = useState<Group | undefined>(
    undefined
  );
  const [editingSubGroup, setEditingSubGroup] = useState<Subgroup | undefined>(
    undefined
  );
  const navigate = useNavigate();

  const [filters, setFilters] = useState<PageFilters>({
    seriesGuid: "",
    startDate: null,
    endDate: null,
    searchText: "",
  });
  const isFiltering = isFilteringPage(filters);

  const { data: categoriesData, request: categoriesRequest } =
    useApiRequest<Category[]>(false);
  const { data: groupResponse, request: groupRequest } = useApiRequest();
  const { request: updateSortOrderRequest } = useApiRequest<[]>(false);

  const [categories, setCategories] = useState<Category[]>(
    categoriesData ?? []
  );

  usePageMetadata({
    title: `Manage ${routes.manageDataSelectionOptions.label}`,
  });

  useEffect(() => {
    setBreadcrumbs([{ label: routes.manageDataSelectionOptions.label }]);
  }, [setBreadcrumbs]);

  const refreshCategories = useCallback(() => {
    categoriesRequest("/company/options/categories", { method: "GET" });
  }, [categoriesRequest]);

  useEffect(() => {
    refreshCategories();
  }, [refreshCategories, groupResponse]);

  useEffect(() => {
    setCategories(categoriesData ?? []);
  }, [categoriesData]);

  const handleNewButtonClick = () => {
    setEditingCategory(undefined);
    setIsEditingCategory(true);
  };

  const handleEditCategoryClick = (category: Category) => () => {
    setEditingCategory(category);
    setIsEditingCategory(true);
  };

  const handleSaveCategory = useCallback(() => {
    refreshCategories();
    setIsEditingCategory(false);
  }, [refreshCategories]);

  const handleSaveGroup = useCallback(() => {
    refreshCategories();
    setIsEditingCategory(false);
    setIsEditingGroup(false);
  }, [refreshCategories]);

  const handleSaveSubGroup = useCallback(() => {
    refreshCategories();
    setIsEditingGroup(false);
    setIsEditingSubGroup(false);
  }, [refreshCategories]);

  const handleAddGroupClick = (category: Category) => (e: React.MouseEvent) => {
    e.stopPropagation();
    setEditingGroup(undefined);
    setEditingCategory(category);
    setIsEditingGroup(true);
  };

  const handleActiveToggle =
    (group: Group) =>
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      groupRequest(`/company/options/groups/${group?.groupGuid}/edit`, {
        method: "POST",
        data: { isActive: checked },
      });
    };

  const handleSeriesSelection = useCallback((seriesGuid: string) => {
    setFilters((prevFilters) => ({ ...prevFilters, seriesGuid }));
  }, []);

  const handleStartDateSelection = useCallback((startDate: DateTime | null) => {
    setFilters((prevFilters) => ({ ...prevFilters, startDate }));
  }, []);

  const handleEndDateSelection = useCallback((endDate: DateTime | null) => {
    setFilters((prevFilters) => ({ ...prevFilters, endDate }));
  }, []);

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        searchText: event.target.value.toLowerCase(),
      }));
    },
    []
  );

  const seriesMenuItem = useMemo(() => {
    const series = getSeriesForCategories(categories ?? []).sort((a, b) =>
      a.seriesName.localeCompare(b.seriesName)
    );

    return series.map((s) => ({
      label: s.seriesName,
      value: s.seriesGuid,
    }));
  }, [categories]);

  const filteredCategories = useMemo(
    () => getFilteredCategories(categories ?? [], filters),
    [categories, filters]
  );

  const handleEditClick = useCallback(
    (subgroup: Subgroup) => () => {
      setEditingSubGroup(subgroup);
      setIsEditingSubGroup(true);
    },
    []
  );

  const columns: GridColDef<Subgroup>[] = useMemo(
    () => [
      { field: "subgroupName", headerName: "Name", width: 300 },
      {
        field: "startDate",
        headerName: "Begin Date",
        width: 110,
        valueFormatter: (value: number) => formatAsDate(value),
      },
      {
        field: "endDate",
        headerName: "End Date",
        width: 110,
        valueFormatter: (value: number) => {
          const date = formatAsDate(value);
          return date === "" ? "Current" : date;
        },
      },
      {
        field: "series",
        headerName: "Series",
        flex: 1,
        minWidth: 400,
        renderCell: (params) =>
          params.value.map((series: Series) => (
            <Chip
              key={series.seriesGuid}
              label={series.seriesName}
              size="small"
              sx={{ marginRight: 1 }}
            />
          )),
      },
      {
        field: "subgroupIsRequired",
        headerName: "Required",
        width: 80,
        renderCell: (params) => (params.value ? "Yes" : "No"),
      },
      {
        field: "actions",
        headerName: "",
        sortable: false,
        width: 10,
        renderCell: ({ row }) => {
          return (
            <MoreMenuButton
              menuItems={[
                {
                  label: "Edit",
                  onClick: handleEditClick(row),
                },
                {
                  label: "View",
                  onClick: () => {
                    navigate(
                      generatePath(
                        routes.manageDataSelectionOptionsSubGroup.path,
                        {
                          subgroupGuid: row.subgroupGuid,
                        }
                      )
                    );
                  },
                },
              ]}
            />
          );
        },
      },
    ],
    [navigate, handleEditClick]
  );

  const handleDragEndCategories = (sortedCategories: Category[]) => {
    setCategories((prevCategories) => {
      const updatedCategories = updateSortOrder(
        [...sortedCategories, ...prevCategories],
        (a, b) => a.categoryGuid === b.categoryGuid
      );
      updateSortOrderRequest("/company/options/categories/sortorder/update", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: updatedCategories.map((c) => ({
          categoryGuid: c.categoryGuid,
          sortOrder: c.sortOrder,
        })),
      });

      return updatedCategories;
    });
  };

  const handleDragEndGroups = (category: Category, sortedGroups: Group[]) => {
    setCategories((prevCategories) => {
      return prevCategories.map((prevCategory) => {
        if (prevCategory.categoryGuid !== category.categoryGuid) {
          return prevCategory;
        }

        const updatedGroups = updateSortOrder(
          [...sortedGroups, ...prevCategory.groups],
          (a, b) => a.groupGuid === b.groupGuid
        );
        updateSortOrderRequest("/company/options/groups/sortorder/update", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          data: updatedGroups.map((g) => ({
            groupGuid: g.groupGuid,
            sortOrder: g.sortOrder,
          })),
        });

        return { ...prevCategory, groups: updatedGroups };
      });
    });
  };

  const handleDragEndSubgroups = (
    category: Category,
    group: Group,
    sortedSubgroups: Subgroup[]
  ) => {
    setCategories((prevCategories) => {
      return prevCategories.map((prevCategory) => {
        if (prevCategory.categoryGuid !== category.categoryGuid) {
          return prevCategory;
        }

        const updatedGroups = prevCategory.groups.map((prevGroup) => {
          if (prevGroup.groupGuid !== group.groupGuid) {
            return prevGroup;
          }

          const updatedSubgroups = updateSortOrder(
            [...sortedSubgroups, ...prevGroup.subgroups],
            (a, b) => a.subgroupGuid === b.subgroupGuid
          );
          updateSortOrderRequest(
            "/company/options/subgroups/sortorder/update",
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              data: updatedSubgroups.map((s) => ({
                subgroupGuid: s.subgroupGuid,
                sortOrder: s.sortOrder,
              })),
            }
          );

          return { ...prevGroup, subgroups: updatedSubgroups };
        });

        return { ...prevCategory, groups: updatedGroups };
      });
    });
  };

  const getCategoryDragId = (category: Category) => category.categoryGuid;

  const getGroupDragId = (group: Group) => group.groupGuid;

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent={"space-between"}
        marginBottom={2}
      >
        <Grid item>
          <Stack spacing={2} direction="row">
            <FilterSearchButton
              active={false}
              icon={<DnsOutlinedIcon />}
              items={seriesMenuItem}
              onItemSelect={handleSeriesSelection}
            >
              Series
            </FilterSearchButton>
            <FilterSearchDateRangeButton
              active={false}
              icon={<CalendarTodayOutlinedIcon />}
              onStartDateSelect={handleStartDateSelection}
              onEndDateSelect={handleEndDateSelection}
            >
              Date
            </FilterSearchDateRangeButton>
          </Stack>
        </Grid>
        <Grid item>
          <Stack spacing={2} direction={"row-reverse"}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNewButtonClick}
            >
              + Category
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{ padding: 1, minWidth: "initial" }}
              disabled={true}
            >
              <EditIcon aria-label="edit" fontSize="small" />
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{ padding: 1, minWidth: "initial" }}
              disabled={true}
            >
              <FileDownloadOutlinedIcon
                aria-label="download"
                fontSize="small"
              />
            </Button>
            <OutlinedInput
              placeholder="Search"
              size="small"
              color="primary"
              sx={{ backgroundColor: "white", height: 37 }}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
              value={filters.searchText}
              onChange={handleSearchChange}
            />
          </Stack>
        </Grid>
      </Grid>
      {categories && categories.length === 0 ? (
        <EmptyState
          heading="No Selection Options"
          body="At least one selection option must be added before a quote can be created"
        />
      ) : categories && categories.length > 0 ? (
        <Box>
          <DraggableList
            items={filteredCategories}
            getItemDragId={getCategoryDragId}
            onDragEnd={handleDragEndCategories}
          >
            {filteredCategories.map((c) => {
              return (
                <AccordionCardDraggable
                  key={getCategoryDragId(c)}
                  dragId={getCategoryDragId(c)}
                  heading={c.categoryName}
                  headingProps={{ fontWeight: "bold" }}
                  endActions={
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Button
                        startIcon={<AddCircleOutlineOutlinedIcon />}
                        onClick={handleAddGroupClick(c)}
                      >
                        Group
                      </Button>
                      <MoreMenuButton
                        menuItems={[
                          {
                            label: "Edit Name",
                            onClick: handleEditCategoryClick(c),
                          },
                        ]}
                      />
                    </Stack>
                  }
                  detailsSx={{ padding: 0, pb: 1 }}
                  expanded={isFiltering}
                >
                  <DraggableList
                    items={c.groups}
                    getItemDragId={getGroupDragId}
                    onDragEnd={(sortedGroups) =>
                      handleDragEndGroups(c, sortedGroups)
                    }
                  >
                    {c.groups.map((g) => (
                      <AccordionCardDraggable
                        key={g.groupGuid}
                        dragId={getGroupDragId(g)}
                        heading={g.groupName}
                        headingProps={{ fontSize: "0.9rem" }}
                        endActions={
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Switch
                              checked={g.isActive}
                              onChange={handleActiveToggle(g)}
                            />
                            <MoreMenuButton
                              menuItems={[
                                {
                                  label: "Edit Name",
                                  onClick: () => {
                                    setEditingGroup(g);
                                    setEditingCategory(c);
                                    setIsEditingGroup(true);
                                  },
                                },
                                {
                                  label: "Add Sub-Group",
                                  onClick: () => {
                                    setEditingGroup(g);
                                    setEditingSubGroup(undefined);
                                    setIsEditingSubGroup(true);
                                  },
                                },
                              ]}
                            />
                          </Stack>
                        }
                        accordionSx={{
                          m: 0,
                          padding: 0,
                          borderRadius: "0 !important",
                          borderLeft: "none",
                          borderRight: "none",
                          borderBottom: "none",
                        }}
                        detailsSx={{ padding: 0, pb: 1 }}
                        expanded={isFiltering}
                      >
                        {g.subgroups.length > 0 && (
                          <Box
                            border={(theme) =>
                              `1px solid ${theme.palette.divider}`
                            }
                          >
                            <TableDraggable
                              columns={columns}
                              rows={g.subgroups}
                              rowSelection={false}
                              hideFooter
                              disableColumnFilter
                              disableColumnMenu
                              disableColumnResize
                              disableRowSelectionOnClick
                              getRowId={(row) => row.subgroupGuid}
                              onDragEnd={(sortedSubgroups) =>
                                handleDragEndSubgroups(c, g, sortedSubgroups)
                              }
                            />
                          </Box>
                        )}
                        <Button
                          startIcon={<AddCircleOutlineOutlinedIcon />}
                          onClick={() => {
                            setEditingGroup(g);
                            setEditingSubGroup(undefined);
                            setIsEditingSubGroup(true);
                          }}
                          sx={{ margin: 2, ml: 1.2, mb: 0 }}
                        >
                          Sub Group
                        </Button>
                      </AccordionCardDraggable>
                    ))}
                  </DraggableList>
                </AccordionCardDraggable>
              );
            })}
          </DraggableList>
        </Box>
      ) : null}
      {isEditingCategory && (
        <NewEditCategoryModal
          isOpen={isEditingCategory}
          onClose={() => setIsEditingCategory(false)}
          onSaveSuccessful={handleSaveCategory}
          category={editingCategory}
          isNew={!editingCategory}
        />
      )}
      {isEditingGroup && (
        <NewEditGroupModal
          isOpen={isEditingGroup}
          onClose={() => setIsEditingGroup(false)}
          onSaveSuccessful={handleSaveGroup}
          category={editingCategory!}
          group={editingGroup}
          isNew={!editingGroup}
        />
      )}
      {isEditingSubGroup && (
        <NewEditSubGroupModal
          isOpen={isEditingSubGroup}
          onClose={() => setIsEditingSubGroup(false)}
          onSaveSuccessful={handleSaveSubGroup}
          group={editingGroup}
          subgroup={editingSubGroup}
          isNew={!editingSubGroup}
        />
      )}
    </>
  );
};

const getSeriesForCategories = (categories: Category[]): Series[] => {
  const series: Series[] = [];

  (categories ?? []).forEach((category) => {
    category.groups.forEach((group) => {
      group.subgroups.forEach((subgroup) => {
        subgroup.series.forEach((subgroupSeries) => {
          const alreadyExists = series.some(
            (s) => s.seriesGuid === subgroupSeries.seriesGuid
          );
          if (!alreadyExists) {
            series.push(subgroupSeries);
          }
        });
      });
    });
  });

  return series;
};

type PageFilters = {
  seriesGuid: string;
  startDate: DateTime | null;
  endDate: DateTime | null;
  searchText: string;
};

const isFilteringPage = (filters: PageFilters): boolean => {
  const filtering =
    filters.seriesGuid.length > 0 ||
    filters.startDate != null ||
    filters.searchText.length > 0;

  return filtering;
};

const getFilteredCategories = (
  categories: Category[],
  filters: PageFilters
): Category[] => {
  const isFiltering = isFilteringPage(filters);

  if (!isFiltering) {
    return categories;
  }

  return categories
    .map((category) => {
      const categoryMatchesSearchText = category.categoryName
        .toLowerCase()
        .includes(filters.searchText.toLowerCase());

      const filteredGroups = category.groups
        .map((group) => {
          const groupMatchesSearchText = group.groupName
            .toLowerCase()
            .includes(filters.searchText.toLowerCase());

          const filteredSubgroups = group.subgroups.filter((subgroup) => {
            let matchesFilters = true;
            const subgroupMatchesSearchText = subgroup.subgroupName
              .toLowerCase()
              .includes(filters.searchText.toLowerCase());

            const subgroupSeriesMatchesSearchText = subgroup.series.some((s) =>
              s.seriesName
                .toLowerCase()
                .includes(filters.searchText.toLowerCase())
            );

            if (filters.seriesGuid) {
              matchesFilters = subgroup.series.some(
                (s) => s.seriesGuid === filters.seriesGuid
              );
            }

            if (matchesFilters && filters.startDate) {
              const dateFormat = "yyyy/MM/dd";
              const subgroupStartDate = DateTime.fromSeconds(
                subgroup.startDate
              ).toFormat(dateFormat);
              const subgroupEndDate = subgroup.endDate
                ? DateTime.fromSeconds(subgroup.endDate).toFormat(dateFormat)
                : null;

              if (filters.endDate === null) {
                matchesFilters =
                  subgroupStartDate >= filters.startDate.toFormat(dateFormat);
              } else {
                matchesFilters =
                  subgroupStartDate >= filters.startDate.toFormat(dateFormat) &&
                  subgroupEndDate != null &&
                  subgroupEndDate <= filters.endDate.toFormat(dateFormat);
              }
            }

            if (matchesFilters && filters.searchText) {
              matchesFilters =
                subgroupMatchesSearchText ||
                groupMatchesSearchText ||
                categoryMatchesSearchText ||
                subgroupSeriesMatchesSearchText;
            }

            return matchesFilters;
          });

          return groupMatchesSearchText || filteredSubgroups.length > 0
            ? { ...group, subgroups: filteredSubgroups }
            : null;
        })
        .filter(Boolean) as Group[];

      return categoryMatchesSearchText || filteredGroups.length > 0
        ? { ...category, groups: filteredGroups }
        : null;
    })
    .filter(Boolean) as Category[];
};

export default ManageSelectionOptionsRoute;
