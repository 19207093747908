import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  Alert,
  Box,
  Button,
  OutlinedInput,
  OutlinedInputProps,
  Typography,
  useTheme,
} from "@mui/material";
import { Modal } from "./Modal";
import { PhoneIphone } from "@mui/icons-material";
import { useApiRequest } from "../hooks/useApiRequest";
import { useSession } from "../hooks/useSession";
import { UserSession } from "../types/user";
import { formatAsPhoneNumber } from "../utils/number";

type VerifyCodeModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSuccessfulVerification: (userSession: UserSession) => void;
  obfuscatePhoneNumber?: boolean;
  showResend?: boolean;
};

export const VerifyCodeModal: React.FC<VerifyCodeModalProps> = ({
  isOpen,
  onClose,
  onSuccessfulVerification,
  obfuscatePhoneNumber = false,
  showResend = true,
}) => {
  const { user } = useSession();
  const sendCodeRequest = useApiRequest<UserSession>();
  const retryRequest = useApiRequest();
  const [values, setValues] = useState<string[]>(["", "", "", ""]);
  const [showError, setShowError] = useState(false);
  const [resendAllowed, setResendAllowed] = useState(false);
  const smsField1Ref = useRef<HTMLInputElement>(null);
  const smsCodeField2Ref = useRef<HTMLInputElement>(null);
  const smsField3Ref = useRef<HTMLInputElement>(null);
  const smsField4Ref = useRef<HTMLInputElement>(null);

  // Resend timeout logic
  useEffect(() => {
    if (resendAllowed === false && showResend) {
      const timeout = setTimeout(() => {
        setResendAllowed(true);
      }, 60 * 1000);

      return () => clearTimeout(timeout);
    }
  }, [resendAllowed, showResend]);

  // Clear values when modal is opened
  useEffect(() => {
    setValues(["", "", "", ""]);
    smsField1Ref.current?.focus();
  }, []);

  // Show error message when error is present
  useEffect(() => {
    setShowError(!!sendCodeRequest.error);
  }, [sendCodeRequest.error]);

  // Handle successful verification
  useEffect(() => {
    if (sendCodeRequest?.status === "ok" && sendCodeRequest.data) {
      onSuccessfulVerification(sendCodeRequest.data);
    }
  }, [sendCodeRequest.data, sendCodeRequest.status, onSuccessfulVerification]);

  const isFormValid = values.every((value) => value !== "");

  const handleFocusNext = (
    index: number,
    event: ChangeEvent<HTMLInputElement>,
    nextRef?: React.RefObject<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setValues((prev) => {
      const newValues = [...prev];
      newValues[index] = value;
      return newValues;
    });
    if (value && nextRef && nextRef.current) {
      nextRef.current.focus();
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    sendCodeRequest.request("/users/verify", {
      method: "POST",
      data: { smsCode: values.join("") },
    });
  };

  const handleRequestRetry = () => {
    smsField1Ref.current?.focus();
    setValues(["", "", "", ""]);
    retryRequest.request("/users/newcode", {
      method: "GET",
    });
    setResendAllowed(false);
  };

  const handleRequestTerms = () => {
    window.open("/terms", "_blank");
  };

  const clearPreviousField = (index: number) => {
    setValues((prev) => {
      const newValues = [...prev];
      newValues[index] = "";
      return newValues;
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      disableEscapeKeyDown={true}
      disableBackdropClick
    >
      <Box
        sx={{
          margin: "0 auto",
          display: "flex",
          alignItems: "center",
          mb: 2,
          color: "#7F56D9",
          paddingLeft: 1.5,
          backgroundColor: "#D6C6F4",
          borderRadius: 40,
          width: 35,
          height: 48,
          border: "10px solid #F4EBFF",
        }}
      >
        <PhoneIphone />
      </Box>
      <Typography variant="h6" textAlign="center" sx={{ marginBottom: 2 }}>
        Please check your phone
      </Typography>
      <Typography variant="body1" textAlign="center" sx={{ marginBottom: 3 }}>
        We've sent a code to{" "}
        {formatAsPhoneNumber(user?.userPhoneNumber ?? "", obfuscatePhoneNumber)}
      </Typography>
      <form onSubmit={handleSubmit}>
        {showError && (
          <Alert severity="error" sx={{ marginTop: 3, marginBottom: 3 }}>
            Incorrect Code
          </Alert>
        )}
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <LargeInputField
            inputRef={smsField1Ref}
            value={values[0]}
            onChange={(e: any) => handleFocusNext(0, e, smsCodeField2Ref)}
          />
          <LargeInputField
            value={values[1]}
            prevRef={smsField1Ref}
            inputRef={smsCodeField2Ref}
            onChange={(e: any) => handleFocusNext(1, e, smsField3Ref)}
            clearPrevField={() => clearPreviousField(0)}
          />
          <LargeInputField
            value={values[2]}
            prevRef={smsCodeField2Ref}
            inputRef={smsField3Ref}
            onChange={(e: any) => handleFocusNext(2, e, smsField4Ref)}
            clearPrevField={() => clearPreviousField(1)}
          />
          <LargeInputField
            value={values[3]}
            prevRef={smsField3Ref}
            inputRef={smsField4Ref}
            sx={{ marginRight: 0 }}
            onChange={(e: any) => handleFocusNext(3, e)}
            clearPrevField={() => clearPreviousField(2)}
          />
        </Box>
        {showResend && (
          <Typography textAlign="center" mt={2}>
            {resendAllowed ? (
              <>
                Didn't receive the code?
                <Button
                  onClick={handleRequestRetry}
                  sx={{ textDecoration: "underline", color: "#667085" }}
                >
                  Click to resend
                </Button>
              </>
            ) : (
              <>You can resend the code in a minute.</>
            )}
          </Typography>
        )}
        <Box sx={{ mt: 2, display: "flex" }}>
          <Button
            sx={{ flexGrow: 1, mr: 1 }}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            sx={{ flexGrow: 1 }}
            variant="contained"
            color="primary"
            fullWidth
            disabled={!isFormValid || sendCodeRequest.loading}
          >
            Accept Terms & Verify
          </Button>
        </Box>
        <Box sx={{ textAlign: "center", width: "100%", marginTop: "10px" }}>
          <Button
            onClick={handleRequestTerms}
            sx={{ textDecoration: "underline", color: "#667085" }}
          >
            Terms of Use
          </Button>
        </Box>
      </form>
    </Modal>
  );
};

interface LargeInputFieldProps {
  value: string;
  prevRef?: React.RefObject<HTMLInputElement>;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  sx?: OutlinedInputProps["sx"];
  clearPrevField?: () => void;
}

const LargeInputField: React.FC<LargeInputFieldProps> = ({
  inputRef,
  prevRef,
  value,
  onChange,
  sx,
  clearPrevField,
}) => {
  const theme = useTheme();

  return (
    <OutlinedInput
      className="centered-input"
      inputRef={inputRef}
      type="number"
      inputProps={{
        maxLength: 1,
        pattern: "[0-9]",
        className: "centered-input",
      }}
      value={value}
      onChange={onChange}
      onKeyDown={(e) => {
        if (e.key === "Backspace" && !value) {
          e.preventDefault();
          if (prevRef && prevRef.current) {
            if (clearPrevField) {
              clearPrevField();
            }
            prevRef.current.focus();
          }
        }
      }}
      style={{ textAlign: "center" }}
      sx={{
        "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
        "input[type=number]": {
          "-moz-appearance": "textfield",
        },
        // paddingLeft: 1,
        fontSize: 48,
        color: theme.palette.primary.main,
        width: 80,
        height: 80,
        textAlign: "center",
        marginRight: 3,
        ...sx,
      }}
    />
  );
};
