import { useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { Controller, useForm } from "react-hook-form";
import { OrderCard } from "../../types/orderCard";
import { type OrderCardFinancialPricing as OrderCardFinancialPricingType } from "../../types/orderCardFinancialPricing";
import { OrderCardBase } from "./OrderCardBase";
import { useOrderCard } from "./OrderCardContext";
import InputField from "../../components/InputField";
import { formatAsCurrency } from "../../utils/number";
import SelectField from "../../components/SelectField";
import { manufacturerDefaultVepLevelOptions } from "../../types/retailerManufacturer";
import { DatePicker } from "../../components/DatePicker";
import { DateTime } from "luxon";
import { Order } from "../../types/order";

type FormData = {
  vepLevel: 0 | 1 | 2 | 3;
  priceDateTimestamp: DateTime;
};

const getDefaultValues = (
  orderCard: OrderCardFinancialPricingType | null
): FormData => {
  return {
    vepLevel: orderCard?.cardData.vepLevel ?? 0,
    priceDateTimestamp: orderCard?.cardData.priceDateTimestamp
      ? DateTime.fromSeconds(
          parseInt(orderCard.cardData.priceDateTimestamp.toString())
        )
      : DateTime.local(),
  };
};

interface OrderCardFinancialPricingProps {
  isTemplate: Order["isTemplate"];
  orderCard: OrderCardFinancialPricingType;
  expanded: boolean;
  groupIndex: number;
}

export const OrderCardFinancialPricing: React.FC<
  OrderCardFinancialPricingProps
> = ({ isTemplate, orderCard, expanded, groupIndex }) => {
  const { saveOrderCard, setEditingOrderCard } = useOrderCard();

  const disabled = orderCard.isLocked;

  const { control, reset, formState, getValues } = useForm<FormData>({
    defaultValues: getDefaultValues(orderCard),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const handleBlurField = () => {
    const values = getValues();
    saveOrderCard(orderCard.orderCardGuid, {
      vepLevel: values.vepLevel,
      priceDateTimestamp: values.priceDateTimestamp.toSeconds(),
    } as OrderCard["cardData"]);
    setEditingOrderCard(null);
  };

  const handleEditingField = () => {
    setEditingOrderCard(orderCard);
  };

  const handleClearClick = () => {
    const defaultValues = getDefaultValues(null);
    reset(defaultValues);
    saveOrderCard(orderCard.orderCardGuid, {
      vepLevel: defaultValues.vepLevel,
      priceDateTimestamp: defaultValues.priceDateTimestamp.toSeconds(),
    } as OrderCard["cardData"]);
  };

  useEffect(() => {
    reset(getDefaultValues(orderCard), { keepDirtyValues: true });
  }, [orderCard, reset]);

  return (
    <OrderCardBase
      disabled={disabled}
      orderCard={orderCard}
      onClearClick={handleClearClick}
      expanded={expanded}
      groupIndex={groupIndex}
    >
      <Grid container spacing={2} component="form">
        {isTemplate ? null : (
          <Grid xs={12} sm={12} md={6}>
            <Controller
              control={control}
              name="vepLevel"
              render={({ field, fieldState }) => (
                <SelectField
                  {...field}
                  label="VEP Level"
                  placeholder="Select VEP Level"
                  onFocus={handleEditingField}
                  fullWidth
                  options={manufacturerDefaultVepLevelOptions}
                  disabled={disabled}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  onBlur={() => {
                    handleBlurField();
                    field.onBlur();
                  }}
                />
              )}
            />
          </Grid>
        )}
        <Grid xs={12} sm={12} md={6}>
          <Controller
            name="priceDateTimestamp"
            control={control}
            render={({ field }) => (
              <DatePicker
                label="Price Date"
                disabled={disabled}
                onFocus={handleEditingField}
                required
                fullWidth
                allowNoDate={false}
                {...field}
                error={!!formState.errors.priceDateTimestamp}
                helperText={formState.errors.priceDateTimestamp?.message}
                onBlur={() => {
                  handleBlurField();
                  field.onBlur();
                }}
              />
            )}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <InputField
            label="Base Total"
            fullWidth
            disabled
            value={formatAsCurrency(orderCard.cardData.baseTotal)}
            sx={{ marginBottom: 2 }}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <InputField
            label="Options Total"
            fullWidth
            disabled
            value={formatAsCurrency(orderCard.cardData.optionsTotal)}
            sx={{ marginBottom: 2 }}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <InputField
            label="Selectable Options"
            fullWidth
            disabled
            value={formatAsCurrency(orderCard.cardData.selectableOptions)}
            sx={{ marginBottom: 2 }}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <InputField
            label="Custom Options"
            fullWidth
            disabled
            value={formatAsCurrency(orderCard.cardData.customOptions)}
            sx={{ marginBottom: 2 }}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <InputField
            label="Base + Options"
            fullWidth
            disabled
            value={formatAsCurrency(orderCard.cardData.basePlusOptions)}
            sx={{ marginBottom: 2 }}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <InputField
            label="Grand Total"
            fullWidth
            disabled
            value={formatAsCurrency(orderCard.cardData.grandTotal)}
            sx={{ marginBottom: 2 }}
          />
        </Grid>
      </Grid>
    </OrderCardBase>
  );
};
