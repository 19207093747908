import { Box, Stack, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { tabA11yProps } from "../../components/TabPanel";
import { useSession } from "../../hooks/useSession";
import {
  Report,
  ReportQueryOptions,
  ReportSelectedQueryOptions,
  SavedReport,
} from "../../types/report";
import { ReportingRightSidebarTabReportFilters } from "./ReportingRightSidebarTabReportFilters";
import { ReportingRightSidebarTabSavedReports } from "./ReportingRightSidebarTabSavedReports";

const tabs = {
  reportFilters: 0,
  savedReports: 1,
} as const;
type TabValue = (typeof tabs)[keyof typeof tabs];
const tabsAriaLabel = "report-tabs";

interface ReportingRightSidebarProps {
  report: Report | undefined;
  queryOptions: ReportQueryOptions;
  savedReports: SavedReport[];
  selectedReportGuid?: string;
  sidebarWidth: string | number;
  onClearAllClick: () => void;
  onReportSelectedQueryOptionsChange: (
    selectedQueryOptions: ReportSelectedQueryOptions
  ) => void;
  onViewReport: (reportGuid: string) => void;
  onDeleteReport: (reportGuid: string) => void;
}

export const ReportingRightSidebar: React.FC<ReportingRightSidebarProps> = ({
  report,
  queryOptions,
  savedReports,
  selectedReportGuid,
  sidebarWidth,
  onClearAllClick,
  onReportSelectedQueryOptionsChange,
  onViewReport,
  onDeleteReport,
}) => {
  const { isImpersonating } = useSession();
  const [tabValue, setTabValue] = useState<TabValue>(tabs.reportFilters);

  const handleTabChange = (_: React.SyntheticEvent, newTabValue: TabValue) => {
    setTabValue(newTabValue);
  };

  return (
    <Box
      sx={{
        right: 0,
        bottom: 0,
        backgroundColor: "white",
        position: "fixed",
        top: isImpersonating ? "128px" : "64px",
      }}
      width={{ xs: "100%", sm: sidebarWidth }}
    >
      <Stack height="100%" overflow="hidden">
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label={tabsAriaLabel}
          sx={{ padding: 2 }}
        >
          <Tab
            label="Report Filters"
            {...tabA11yProps(tabsAriaLabel, tabs.reportFilters)}
          />
          <Tab
            label="Saved Reports"
            {...tabA11yProps(tabsAriaLabel, tabs.savedReports)}
          />
        </Tabs>
        {tabValue === tabs.reportFilters && report != null ? (
          <ReportingRightSidebarTabReportFilters
            report={report}
            queryOptions={queryOptions}
            onReportSelectedQueryOptionsChange={
              onReportSelectedQueryOptionsChange
            }
            onClearAllClick={onClearAllClick}
          />
        ) : tabValue === tabs.savedReports ? (
          <ReportingRightSidebarTabSavedReports
            savedReports={savedReports}
            selectedReportGuid={selectedReportGuid}
            onViewReport={onViewReport}
            onDeleteReport={onDeleteReport}
          />
        ) : null}
      </Stack>
    </Box>
  );
};
