import React, { useCallback, useEffect, useMemo, useState } from "react";
import { EmptyState } from "../../components/EmptyState";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import { routes } from "../../routes";
import { useApiRequest } from "../../hooks/useApiRequest";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  OutlinedInput,
  Stack,
} from "@mui/material";
import Search from "@mui/icons-material/Search";
import { GridColDef } from "@mui/x-data-grid";
import { MoreMenuButton } from "../../components/MoreMenuButton";
import {
  InvoiceDisclosure,
  getInvoiceDisclosureTypeDisplayName,
} from "../../types/invoiceDisclosure";
import { NewEditInvoiceDisclosureModal } from "./NewEditInvoiceDisclosureModal";
import { Switch } from "../../components/Switch";
import { TableDraggable } from "../../components/TableDraggable";
import { updateSortOrder } from "../../utils/array";

const ManageInvoiceDisclosuresRoute: React.FC = () => {
  const { setBreadcrumbs } = useBreadcrumbs();
  const [isEditing, setIsEditing] = useState(false);
  const [selectedInvoiceDisclosure, setSelectedInvoiceDisclosure] =
    useState<InvoiceDisclosure | null>(null);
  const [invoiceDisclosures, setInvoiceDisclosures] = useState<
    InvoiceDisclosure[]
  >([]);
  const [searchText, setSearchText] = useState("");

  const {
    data: invoiceDisclosuresData,
    request: invoiceDisclosuresRequest,
    loading: invoiceDisclosuresLoading,
  } = useApiRequest<InvoiceDisclosure[]>(false);

  const {
    request: activationToggleRequest,
    status: activationToggleStatus,
    loading: activateLoading,
  } = useApiRequest();

  const { request: updateSortOrderRequest } = useApiRequest<[]>(false);

  usePageMetadata({
    title: `Manage ${routes.manageDataInvoiceDisclosures.label}`,
  });

  useEffect(() => {
    setBreadcrumbs([{ label: routes.manageDataInvoiceDisclosures.label }]);
  }, [setBreadcrumbs]);

  const refreshInvoiceDisclosures = useCallback(() => {
    invoiceDisclosuresRequest("/company/invoicedisclosures", {
      method: "GET",
    });
  }, [invoiceDisclosuresRequest]);

  useEffect(() => {
    refreshInvoiceDisclosures();
  }, [refreshInvoiceDisclosures]);

  useEffect(() => {
    setInvoiceDisclosures(invoiceDisclosuresData ?? []);
  }, [invoiceDisclosuresData]);

  useEffect(() => {
    if (activateLoading === false && activationToggleStatus === "ok") {
      refreshInvoiceDisclosures();
    }
  }, [activationToggleStatus, activateLoading, refreshInvoiceDisclosures]);

  const handleEditClick = (invoiceDisclosure: InvoiceDisclosure) => {
    setSelectedInvoiceDisclosure(invoiceDisclosure);
    setIsEditing(true);
  };

  const handleNewInvoiceDisclosureButtonClick = () => {
    setSelectedInvoiceDisclosure(null);
    setIsEditing(true);
  };

  const handleClose = () => {
    setSelectedInvoiceDisclosure(null);
    setIsEditing(false);
  };

  const handleSave = useCallback(() => {
    refreshInvoiceDisclosures();
    setSelectedInvoiceDisclosure(null);
    setIsEditing(false);
  }, [refreshInvoiceDisclosures]);

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value.toLowerCase());
    },
    []
  );

  const handleActiveToggle = useCallback(
    (invoiceDisclosure: InvoiceDisclosure) => () => {
      activationToggleRequest(
        `/company/invoicedisclosures/${invoiceDisclosure.invoiceDisclosureGuid}/edit`,
        {
          method: "POST",
          data: { isActive: !invoiceDisclosure.isActive },
        }
      );
    },
    [activationToggleRequest]
  );

  const handleDragEnd = useCallback(
    (sortedInvoiceDisclosures: InvoiceDisclosure[]) => {
      setInvoiceDisclosures((prevInvoiceDisclosure) => {
        const updatedAttributeSelections = updateSortOrder(
          [...sortedInvoiceDisclosures, ...prevInvoiceDisclosure],
          (a, b) => a.invoiceDisclosureGuid === b.invoiceDisclosureGuid
        );
        updateSortOrderRequest(
          "/company/invoicedisclosures/sortorder/update",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            data: updatedAttributeSelections.map((i) => ({
              invoiceDisclosureGuid: i.invoiceDisclosureGuid,
              sortOrder: i.sortOrder,
            })),
          }
        );

        return updatedAttributeSelections;
      });
    },
    [updateSortOrderRequest]
  );

  const columns: GridColDef<InvoiceDisclosure>[] = useMemo(() => {
    const cols: GridColDef<InvoiceDisclosure>[] = [
      {
        field: "disclosureType",
        headerName: "Disclosure Type",
        cellClassName: "font-500",
        width: 200,
        valueGetter: (value) => {
          return getInvoiceDisclosureTypeDisplayName(value);
        },
      },
      {
        field: "disclosureText",
        headerName: "Disclosure Text",
        flex: 1,
      },
      {
        field: "isActive",
        headerName: "Active",
        headerAlign: "right",
        align: "right",
        width: 65,
        renderCell: (params) => (
          <Box sx={{ paddingTop: 2, paddingLeft: 1 }}>
            <Switch
              sx={{ marginLeft: "auto" }}
              checked={params.row.isActive}
              onChange={handleActiveToggle(params.row)}
            />
          </Box>
        ),
      },
      {
        field: "actions",
        headerName: "",
        width: 10,
        sortable: false,
        renderCell: ({ row }) => {
          return (
            <MoreMenuButton
              menuItems={[
                {
                  label: "Edit",
                  onClick: () => handleEditClick(row),
                },
              ]}
            />
          );
        },
      },
    ];

    return cols;
  }, [handleActiveToggle]);

  const filteredInvoiceDisclosures = useMemo(() => {
    if (!invoiceDisclosures) {
      return [];
    }

    if (searchText.length === 0) {
      return invoiceDisclosures;
    }

    const filtered = invoiceDisclosures.filter((i) => {
      const searchFields = [
        i.disclosureText,
        getInvoiceDisclosureTypeDisplayName(i.disclosureType),
      ];

      let matchesSearchText = searchFields.some((s) =>
        s.toLowerCase().includes(searchText.toLowerCase())
      );

      return matchesSearchText;
    });

    return filtered;
  }, [invoiceDisclosures, searchText]);

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent={"right"}
        marginBottom={2}
      >
        <Grid item />
        <Grid item>
          <Stack spacing={2} direction={"row-reverse"}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNewInvoiceDisclosureButtonClick}
            >
              + New Disclosure
            </Button>
            <OutlinedInput
              placeholder="Search"
              onChange={handleSearchChange}
              size="small"
              color="primary"
              sx={{ backgroundColor: "white", height: 37 }}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
            />
          </Stack>
        </Grid>
      </Grid>
      {invoiceDisclosures &&
        invoiceDisclosures.length === 0 &&
        !invoiceDisclosuresLoading && (
          <EmptyState
            heading={`No ${routes.manageDataInvoiceDisclosures.label}`}
          />
        )}
      <Box>
        {filteredInvoiceDisclosures &&
          filteredInvoiceDisclosures.length > 0 && (
            <TableDraggable
              columns={columns}
              rows={filteredInvoiceDisclosures}
              rowSelection={false}
              hideFooter
              disableColumnFilter
              disableColumnMenu
              disableColumnResize
              disableRowSelectionOnClick
              getRowId={(row) => row.invoiceDisclosureGuid}
              onDragEnd={handleDragEnd}
            />
          )}
      </Box>
      {isEditing && (
        <NewEditInvoiceDisclosureModal
          isOpen={isEditing}
          isNew={!selectedInvoiceDisclosure}
          invoiceDisclosure={selectedInvoiceDisclosure}
          onSaveSuccessful={handleSave}
          onClose={handleClose}
        />
      )}
    </>
  );
};

export default ManageInvoiceDisclosuresRoute;
