import { Box, Typography } from "@mui/material";

type EmptyStateProps = {
  heading?: string;
  body?: string;
  maxWidth?: string;
};

export const EmptyState: React.FC<EmptyStateProps> = ({
  heading,
  body,
  maxWidth = "315px",
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        color: "text.secondary",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: maxWidth,
        margin: "auto",
        marginTop: "250px",
        textAlign: "center",
      }}
    >
      {heading && (
        <Typography variant="h5" fontWeight={"800"}>
          {heading}
        </Typography>
      )}
      {heading && <Typography variant="body2">{body}</Typography>}
    </Box>
  );
};
