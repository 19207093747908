import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

type GlobalToastNotificationType = {
  severity: "error" | "success";
  title: string;
  message: string;
};

type ShowToastOptions = {
  title?: string;
  message: string;
};

interface GlobalToastNotificationProviderProps {
  children: ReactNode;
}

interface GlobalToastNotificationContextType {
  globalToastNotification: GlobalToastNotificationType | null;
  setGlobalToastNotification: (
    globalToastNotification: GlobalToastNotificationType | null
  ) => void;
  showSuccessToast: (options: ShowToastOptions) => void;
  showErrorToast: (options: ShowToastOptions) => void;
}

const GlobalToastNotificationContext =
  createContext<GlobalToastNotificationContextType>({
    globalToastNotification: null,
    setGlobalToastNotification: () => {},
    showSuccessToast: () => {},
    showErrorToast: () => {},
  });

export const GlobalToastNotificationContextProvider = ({
  children,
}: GlobalToastNotificationProviderProps) => {
  const [globalToastNotification, setGlobalToastNotification] =
    useState<GlobalToastNotificationType | null>(null);

  const showSuccessToast = useCallback(
    ({ title, message }: ShowToastOptions) => {
      setGlobalToastNotification({
        severity: "success",
        message: message,
        title: title ?? "Success",
      });
    },
    []
  );

  const showErrorToast = useCallback(({ title, message }: ShowToastOptions) => {
    setGlobalToastNotification({
      severity: "error",
      message: message,
      title: title ?? "Error",
    });
  }, []);

  const value = useMemo(
    () => ({
      globalToastNotification,
      setGlobalToastNotification,
      showSuccessToast,
      showErrorToast,
    }),
    [globalToastNotification, showErrorToast, showSuccessToast]
  );

  return (
    <GlobalToastNotificationContext.Provider value={value}>
      {children}
    </GlobalToastNotificationContext.Provider>
  );
};
export const useGlobalToastNotificationContext = () =>
  useContext(GlobalToastNotificationContext);
