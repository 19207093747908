export type Markup = {
  type: "f" | "p";
  markup: "m" | "s" | "d" | "t";
  value: number;
};

export const getMarkupDisplayName = (markup: Markup["markup"]): string => {
  switch (markup) {
    case "m":
      return "MOD";
    case "s":
      return "Single Wide";
    case "d":
      return "Double Wide";
    case "t":
      return "Triple Wide";
  }
};

export const getMarkupTypeDisplayName = (type: Markup["type"]): string => {
  switch (type) {
    case "f":
      return "Flat Profit";
    case "p":
      return "Percent Profit";
  }
};

export const getMarkupTypeOptions = [
  { label: getMarkupTypeDisplayName("f"), value: "f" },
  { label: getMarkupTypeDisplayName("p"), value: "p" },
];
