import React from "react";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  useFieldArray,
} from "react-hook-form";
import { FormSection } from "../../components/FormSection";
import { Grid, InputAdornment } from "@mui/material";
import InputField from "../../components/InputField";
import { FormData, FormOptionPricing } from "./FormData";
import SelectField from "../../components/SelectField";
import {
  getOptionPricePerDisplayName,
  getOptionPricePerShortDisplayName,
  getOptionPriceForDisplayName,
  getPriceForOptions,
  getPricePerOptions,
  OptionPricedPerType,
} from "../../types/option";
import { DatePicker } from "../../components/DatePicker";
import { getOptionPricingDisplayName } from "../../types/optionPricing";
import { DateTime } from "luxon";
import { isValidCurrency } from "../../utils/validation";

interface OptionPricingFormSectionProps {
  errors: FieldErrors<FormData>;
  register: UseFormRegister<FormData>;
  control: Control<FormData>;
  trigger: UseFormTrigger<FormData>;
  setValue: UseFormSetValue<FormData>;
  optionPricedPer: FormData["optionPricedPer"];
}

const defaultPricingOptions: FormOptionPricing[] = [
  {
    type: "a",
    price: null,
    startDate: DateTime.local(),
    endDate: null,
  },
  {
    type: "sw",
    price: null,
    startDate: DateTime.local(),
    endDate: null,
  },
  {
    type: "dw",
    price: null,
    startDate: DateTime.local(),
    endDate: null,
  },
  {
    type: "tw",
    price: null,
    startDate: DateTime.local(),
    endDate: null,
  },
  {
    type: "m",
    price: null,
    startDate: DateTime.local(),
    endDate: null,
  },
];

export const OptionPricingFormSection: React.FC<
  OptionPricingFormSectionProps
> = ({ errors, register, control, trigger, setValue, optionPricedPer }) => {
  const { fields, replace } = useFieldArray({
    control,
    name: "optionPricing",
  });

  return (
    <>
      <FormSection heading="Option Pricing">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} marginBottom={2}>
            <Controller
              control={control}
              name="optionPricedPer"
              rules={{ required: "Please select a price per." }}
              render={({ field, fieldState }) => (
                <SelectField
                  {...field}
                  label="Price Per"
                  placeholder="Enter Per Price"
                  required
                  fullWidth
                  options={getPricePerOptions.map((perOption) => ({
                    label: getOptionPricePerDisplayName(perOption),
                    value: perOption,
                  }))}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="optionPricedFor"
              rules={{ required: "Please select a price for." }}
              render={({ field, fieldState }) => (
                <SelectField
                  {...field}
                  label="Price For"
                  placeholder="Enter Price For"
                  required
                  fullWidth
                  options={getPriceForOptions.map((forOption) => ({
                    label: getOptionPriceForDisplayName(forOption),
                    value: forOption,
                  }))}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  onChange={(e) => {
                    field.onChange(e);

                    const optionPricedForValue = e.target.value;
                    if (optionPricedForValue === "a") {
                      replace(defaultPricingOptions.slice(0, 1));
                    } else {
                      replace(defaultPricingOptions.slice(1));
                    }
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        {fields.map((field, index) => (
          <React.Fragment key={field.id}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} marginBottom={2}>
                <InputField
                  {...register(`optionPricing.${index}.price`, {
                    valueAsNumber: true,
                    required: {
                      value: true,
                      message: "Please enter a value.",
                    },
                    validate: {
                      isNumber: (value) => {
                        return (
                          isValidCurrency(value) ||
                          "Please enter a valid price."
                        );
                      },
                    },
                  })}
                  fullWidth
                  required
                  label={getOptionPricingDisplayName(field.type)}
                  placeholder="Enter Price"
                  error={!!errors.optionPricing?.[index]?.price}
                  helperText={errors.optionPricing?.[index]?.price?.message}
                  type="number"
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  intOnly={true}      
                  endAdornment={
                    optionPricedPer !== "u" ? (
                      <InputAdornment position="end">
                        {`/${getOptionPricePerShortDisplayName(
                          optionPricedPer as OptionPricedPerType
                        )}`}
                      </InputAdornment>
                    ) : undefined
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  name={`optionPricing.${index}.startDate`}
                  control={control}
                  rules={{
                    required: "Enter Start Date",
                    validate: (startDate, fields) => {
                      const endDate = fields.optionPricing?.[index]?.endDate;

                      if (!startDate) {
                        return "Start Date is required";
                      }
                      if (startDate && !endDate) {
                        return true;
                      }
                      if (endDate && startDate > endDate) {
                        return "Start Date must be before End Date";
                      }
                      return true;
                    },
                  }}
                  render={({ field }) => (
                    <DatePicker
                      label="Start Date"
                      fullWidth
                      {...field}
                      required
                      error={!!errors.optionPricing?.[index]?.startDate}
                      helperText={
                        errors.optionPricing?.[index]?.startDate?.message
                      }
                      onChange={(e) => {
                        field.onChange(e);
                        if (e) {
                          setValue(`optionPricing.${index}.startDate`, e);
                        }
                        trigger(`optionPricing.${index}.endDate`);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  name={`optionPricing.${index}.endDate`}
                  control={control}
                  rules={{
                    validate: (endDateValue, fields) => {
                      const startDate =
                        fields.optionPricing?.[index]?.startDate;
                      if (
                        endDateValue &&
                        startDate &&
                        startDate > endDateValue
                      ) {
                        return "End Date must be after Start Date";
                      }
                      return true;
                    },
                  }}
                  render={({ field }) => (
                    <DatePicker
                      label="End Date"
                      required
                      fullWidth
                      allowNoDate
                      noDateText="Current"
                      {...field}
                      error={!!errors.optionPricing?.[index]?.endDate}
                      helperText={
                        errors.optionPricing?.[index]?.endDate?.message
                      }
                      onChange={(e) => {
                        field.onChange(e);
                        setValue(`optionPricing.${index}.endDate`, e);
                        trigger(`optionPricing.${index}.startDate`);
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        ))}
      </FormSection>
    </>
  );
};
