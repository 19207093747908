import { FormState } from "react-hook-form";

export function getDirtyFormValues<T extends Record<string, any>>(
  values: T,
  formState: FormState<T>
): Partial<T> {
  const data: Partial<T> = {};

  Object.keys(formState.dirtyFields).forEach((dirtyField: keyof T) => {
    const isDirty =
      formState.dirtyFields[dirtyField as keyof typeof formState.dirtyFields];

    if (isDirty) {
      data[dirtyField] = values[dirtyField];
    }
  });

  return data;
}
