import { DateTime } from "luxon";
import { Model, ModelConstructionType } from "./model";
import { OrderQuotePhase, OrderSoldAsType, OrderStatus } from "./order";
import { ReportDataType } from "./reportDataType";
import { Series } from "./series";
import { Retailer } from "./tenant";

export type Report = {
  createdByUserName: string | null;
  createdTimestamp: number | null;
  headerReport: {
    mainColumns: {
      title: string;
      type: ReportDataType;
      value: number;
    }[];
    subColumns: {
      title: string;
      type: ReportDataType;
      value: number;
    }[];
  };
  mainReport: {
    columns: {
      title: string;
      type: ReportDataType;
    }[];
    data: (string | number | null)[][];
  };
  reportGuid: string | null;
  reportName: string;
  selectedQueryOptions: {
    fromDate: string;
    toDate: string;
    constructionTypes: ModelConstructionType[];
    models: Model["modelGuid"][];
    phases: OrderQuotePhase[];
    retailers: Retailer["tenantGuid"][];
    series: Series["seriesGuid"][];
    soldas: OrderSoldAsType[];
    states: string[];
    status: OrderStatus[];
    types: ("m" | "s" | "d" | "t")[];
  };
};

export type ReportSelectedQueryOptions = Report["selectedQueryOptions"];

export type SavedReport = {
  reportName: string;
  reportGuid: string;
  createdTimestamp: number;
  createdByUserName: string;
  selectedQueryOptions: ReportSelectedQueryOptions;
  canEditOrDelete: boolean;
};

export type DefaultReport = Report & {
  queryOptions: {
    constructionTypes: ModelConstructionType[];
    models: (Model & { seriesId: number })[];
    phases: OrderQuotePhase[];
    retailers: Retailer[];
    series: (Series & { seriesId: number })[];
    soldas: OrderSoldAsType[];
    states: null;
    status: OrderStatus[];
    types: ("m" | "s" | "d" | "t")[];
  };
};

export type ReportQueryOptions = DefaultReport["queryOptions"];

export type ReportQueryOptionsType =
  NonNullable<ReportQueryOptions>["types"][number];

export const reportSelectedQueryOptionsDateFormat = "LLL d, y";

export const getReportQueryOptionsTypeDisplayName = (
  type: ReportQueryOptionsType
): string => {
  const displayNames: Record<ReportQueryOptionsType, string> = {
    m: "Modular",
    s: "Single Wide",
    d: "Double Wide",
    t: "Triple Wide",
  };

  return displayNames[type] || type;
};

export const getEmptySelectedQueryOptions = (): ReportSelectedQueryOptions => {
  return {
    fromDate: DateTime.now()
      .startOf("year")
      .toFormat(reportSelectedQueryOptionsDateFormat),
    toDate: DateTime.now()
      .endOf("year")
      .toFormat(reportSelectedQueryOptionsDateFormat),
    constructionTypes: [],
    models: [],
    phases: [],
    retailers: [],
    series: [],
    soldas: [],
    states: [],
    status: [],
    types: [],
  };
};
