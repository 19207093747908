import React, { useState } from "react";
import { Popover, Box, Stack } from "@mui/material";
import { FilterToggleButton } from "./FilterToggleButton";
import { DatePicker } from "./DatePicker";
import { DateTime } from "luxon";
import { useDetectMobile } from "../hooks/useDetectMobile";

type FilterSearchDateRangeButtonProps = {
  children: React.ReactNode;
  active?: boolean;
  icon?: React.ReactNode;
  disabled?: boolean;
  onStartDateSelect: (date: DateTime | null) => void;
  onEndDateSelect: (date: DateTime | null) => void;
};

export const FilterSearchDateRangeButton: React.FC<
  FilterSearchDateRangeButtonProps
> = ({
  active,
  children,
  icon,
  disabled,
  onStartDateSelect,
  onEndDateSelect,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isActive, setIsActive] = useState<boolean>(active ?? false);
  const [startDate, setStartDate] = useState<DateTime | null>(null);
  const [endDate, setEndDate] = useState<DateTime | null>(null);
  const isMobile = useDetectMobile();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isActive) {
      setStartDate(null);
      setEndDate(null);
      onStartDateSelect(null);
      onEndDateSelect(null);
      setIsActive(false);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStartSelect = (datetime: DateTime | null) => {
    setStartDate(datetime);
    onStartDateSelect(datetime);
    setIsActive(true);
  };

  const handleEndSelect = (datetime: DateTime | null) => {
    setEndDate(datetime);
    onEndDateSelect(datetime);
    setIsActive(true);
  };

  const startDateLabel = startDate?.toFormat("MM/dd/yyyy") ?? "";
  const endDateLabel = endDate?.toFormat("MM/dd/yyyy") ?? "No end date";
  const selectedLabel = startDate
    ? `${startDateLabel} - ${endDateLabel}`
    : null;

  const open = Boolean(anchorEl);
  const id = open ? "popover" : undefined;

  return (
    <div>
      <FilterToggleButton
        active={isActive}
        icon={icon}
        disabled={disabled}
        onClick={handleClick}
      >
        {selectedLabel ?? children}
      </FilterToggleButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{ marginTop: 1 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box p={2} width={isMobile ? 300 : 350} maxHeight={280}>
          <Stack direction={isMobile ? "column" : "row"} spacing={2}>
            <DatePicker
              label="Start Date"
              fullWidth
              required
              onChange={handleStartSelect}
              value={startDate}
            />
            <DatePicker
              label="End Date"
              fullWidth
              required
              onChange={handleEndSelect}
              value={endDate}
              allowNoDate
            />
          </Stack>
        </Box>
      </Popover>
    </div>
  );
};
