import { useCallback, useMemo, useState } from "react";
import {
  useSensors,
  useSensor,
  PointerSensor,
  KeyboardSensor,
  closestCenter,
  DragEndEvent,
  DndContext,
} from "@dnd-kit/core";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  DataGridProps,
  GridColDef,
  GridEventListener,
  GridRowIdGetter,
  GridValidRowModel,
  useGridApiRef,
} from "@mui/x-data-grid";
import { Table } from "./Table";
import { TableRowDraggable } from "./TableRowDraggable";
import { TableCellDraggable } from "./TableCellDraggable";

interface TableDraggableProps<R extends GridValidRowModel = any>
  extends Omit<DataGridProps<R>, "getRowId"> {
  getRowId: GridRowIdGetter<R>;
  onDragEnd: (sortedRows: R[]) => void;
}

export function TableDraggable<R extends GridValidRowModel = any>({
  columns,
  rows,
  getRowId,
  onDragEnd,
  onStateChange,
  ...props
}: TableDraggableProps<R>) {
  const apiRef = useGridApiRef();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const draggableColumns: GridColDef<R>[] = useMemo(() => {
    return [
      {
        field: "draggable",
        headerName: "",
        width: 10,
        sortable: false,
        renderCell: (params) => (
          <TableCellDraggable dragId={params.rowNode.id}>
            {params.value}
          </TableCellDraggable>
        ),
      },
      ...columns,
    ];
  }, [columns]);

  const [sortedRowIds, setSortedRowIds] = useState(
    (rows ?? []).map((r) => getRowId(r))
  );

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (active.id !== over?.id) {
        const gridRows = apiRef.current.getSortedRows() as R[];
        const oldIndex = gridRows.findIndex((m) => getRowId(m) === active.id);
        const newIndex = gridRows.findIndex((m) => getRowId(m) === over?.id);
        const sortedRows = arrayMove(gridRows, oldIndex, newIndex);

        onDragEnd(sortedRows);

        apiRef.current.setSortModel([]);
      }
    },
    [apiRef, getRowId, onDragEnd]
  );

  const handleStateChange: GridEventListener<"stateChange"> = useCallback(
    (params, event, details) => {
      const currentSortedRowIds = apiRef.current.getSortedRowIds();
      setSortedRowIds(currentSortedRowIds);
      onStateChange?.(params, event, details);
    },
    [apiRef, onStateChange]
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      modifiers={[restrictToParentElement]}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={sortedRowIds}
        strategy={verticalListSortingStrategy}
      >
        <Table
          {...props}
          apiRef={apiRef}
          rows={rows}
          columns={draggableColumns}
          slots={draggableSlots}
          getRowId={getRowId}
          onStateChange={handleStateChange}
        />
      </SortableContext>
    </DndContext>
  );
}

const draggableSlots: React.ComponentProps<typeof TableDraggable>["slots"] = {
  row: TableRowDraggable,
};
