import { AttributeSelection } from "./attributeSelection";
import { LogicAffected } from "./logicAffected";
import { Option } from "./option";

export type LogicResult<TEntity> = {
  /**
   * The list of entities that were not affected by logic and have no logic errors.
   */
  entitiesWithoutLogicErrors: TEntity[];
  /**
   * The list of entities that have logic errors.
   */
  entitiesWithLogicErrors: TEntity[];
};

export const applyLogicToOptions = (
  options: Option[],
  selectedOptionGuid: Option["optionGuid"] | null,
  logicAffected: LogicAffected | null
): LogicResult<Option> => {
  const results: LogicResult<Option> = {
    entitiesWithoutLogicErrors: [],
    entitiesWithLogicErrors: [],
  };

  const hasRequiredOptions = options.some(({ optionGuid }) => {
    return (logicAffected?.requireOptions ?? []).includes(optionGuid);
  });

  options.forEach((option) => {
    const isOptionRequired = (logicAffected?.requireOptions ?? []).includes(
      option.optionGuid
    );

    const isOptionHidden = (logicAffected?.hideOptions ?? []).includes(
      option.optionGuid
    );

    const isOptionSelected = option.optionGuid === selectedOptionGuid;

    if (hasRequiredOptions && isOptionRequired) {
      results.entitiesWithoutLogicErrors.push(option);
    } else if (hasRequiredOptions && isOptionSelected) {
      results.entitiesWithLogicErrors.push(option);
    } else if (hasRequiredOptions) {
      // Remove from list...
    } else if (isOptionHidden && isOptionSelected) {
      results.entitiesWithLogicErrors.push(option);
    } else if (isOptionHidden) {
      // Remove from list...
    } else {
      results.entitiesWithoutLogicErrors.push(option);
    }
  });

  return results;
};

export const applyLogicToAttributeSelections = (
  attributeSelections: AttributeSelection[],
  selectedAttributeSelections: AttributeSelection["attributeselectionGuid"][],
  logicAffected: LogicAffected | null,
  selectedOptionGuid: Option["optionGuid"] | undefined
): LogicResult<AttributeSelection> => {
  const results: LogicResult<AttributeSelection> = {
    entitiesWithoutLogicErrors: [],
    entitiesWithLogicErrors: [],
  };

  const hideAttributeselections = logicAffected?.hideMatchedAttributeselections
    .filter((matchedAttributeSelection) =>
      filterAttributeSelections(
        matchedAttributeSelection,
        selectedAttributeSelections,
        selectedOptionGuid
      )
    )
    .map((a) => a.attributeselectionGuid);

  const requireAttributeselections =
    logicAffected?.requireMatchedAttributeselections
      .filter((matchedAttributeSelection) =>
        filterAttributeSelections(
          matchedAttributeSelection,
          selectedAttributeSelections,
          selectedOptionGuid
        )
      )
      .map((a) => a.attributeselectionGuid);

  const hasRequiredAttributeSelections = attributeSelections.some(
    ({ attributeselectionGuid }) => {
      return (requireAttributeselections ?? []).includes(
        attributeselectionGuid
      );
    }
  );

  attributeSelections.forEach((attributeSelection) => {
    const isRequired = (requireAttributeselections ?? []).includes(
      attributeSelection.attributeselectionGuid
    );

    const isHidden = (hideAttributeselections ?? []).includes(
      attributeSelection.attributeselectionGuid
    );

    const isSelected = selectedAttributeSelections.includes(
      attributeSelection.attributeselectionGuid
    );

    if (hasRequiredAttributeSelections && isRequired) {
      results.entitiesWithoutLogicErrors.push(attributeSelection);
    } else if (hasRequiredAttributeSelections && isSelected) {
      results.entitiesWithLogicErrors.push(attributeSelection);
    } else if (hasRequiredAttributeSelections) {
      // Remove it...
    } else if (isHidden && isSelected) {
      results.entitiesWithLogicErrors.push(attributeSelection);
    } else if (isHidden) {
      // Remove it...
    } else {
      results.entitiesWithoutLogicErrors.push(attributeSelection);
    }
  });

  return results;
};

const filterAttributeSelections = (
  matchedAttrSelection:
    | LogicAffected["hideMatchedAttributeselections"][number]
    | LogicAffected["requireMatchedAttributeselections"][number],
  selectedAttributeSelections: string[],
  selectedOptionGuid: string | undefined
): boolean => {
  const isSameIfThisOptionLogic =
    matchedAttrSelection.ifthisOptionGuid != null &&
    matchedAttrSelection.ifthisOptionGuid === selectedOptionGuid &&
    matchedAttrSelection.ifAttributeselectionGuid != null;

  // Has the user selected the attribute selection for the "if" part of the logic?
  const isIfAttrSelectionSelected =
    selectedAttributeSelections.length > 0 &&
    selectedAttributeSelections.includes(
      matchedAttrSelection.ifAttributeselectionGuid ?? ""
    );

  return isSameIfThisOptionLogic && isIfAttrSelectionSelected;
};
