import React, { ReactNode, useCallback } from "react";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToParentElement } from "@dnd-kit/modifiers";

interface DraggableListProps<T> {
  items: T[];
  getItemDragId: (item: T) => string | number;
  children: ReactNode;
  onDragEnd: (sortedItems: T[]) => void;
}

export function DraggableList<T>({
  items,
  getItemDragId,
  children,
  onDragEnd,
}: DraggableListProps<T>) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      const oldIndex = items.findIndex((m) => getItemDragId(m) === active.id);
      const newIndex = items.findIndex((m) => getItemDragId(m) === over?.id);
      const sortedArray = arrayMove(items, oldIndex, newIndex);
      onDragEnd(sortedArray);
    },
    [getItemDragId, items, onDragEnd]
  );

  const itemDragIds = items.map((i) => getItemDragId(i));

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      modifiers={[restrictToParentElement]}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={itemDragIds}
        strategy={verticalListSortingStrategy}
      >
        {children}
      </SortableContext>
    </DndContext>
  );
}
