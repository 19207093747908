import React, { useMemo, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { FileDownloadOutlined } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import { Table } from "../../components/Table";
import { Report } from "../../types/report";
import { formatReportData } from "../../types/reportDataType";
import { FillContainer } from "../../components/FillContainer";
import { EmptyState } from "../../components/EmptyState";
import { MenuButton, MenuItemProp } from "../../components/MenuButton";
import { EditReportNameModal } from "./EditReportNameModal";

interface ReportingContentProps {
  report: Report;
  canEditOrDelete: boolean;
  sidebarWidth: number;
  isReportDownloadDisabled: boolean;
  isReportSaveDisabled: boolean;
  onReportDownloadClick: () => void;
  onReportNameUpdated: (report: Report) => void;
  onSaveReportClick: (reportGuid: string) => void;
  onSaveAsNewClick: () => void;
}

export const ReportingContent: React.FC<ReportingContentProps> = ({
  report,
  canEditOrDelete,
  sidebarWidth,
  isReportDownloadDisabled,
  isReportSaveDisabled,
  onReportDownloadClick,
  onReportNameUpdated,
  onSaveReportClick,
  onSaveAsNewClick,
}) => {
  const [isEditingReportName, setIsEditingReportName] = useState(false);

  const columns: GridColDef[] = useMemo(() => {
    const reportColumns = report.mainReport.columns.map(
      (column, columnIndex) => {
        // Get the length of every value for this column
        const dataLengths = report.mainReport.data.map((rowData) => {
          const columnData = rowData[columnIndex];
          const formattedValue = formatReportData(columnData, column.type);
          const formattedValueLength = formattedValue?.toString().length ?? 0;
          return formattedValueLength;
        });
        const maxLength = Math.max(column.title.length, ...dataLengths);
        const maxLengthMultiplier = 11.0; // Just a random number that looked good in the ui
        const columnWidth = maxLength * maxLengthMultiplier;

        const gridColDef: GridColDef = {
          field: `column${columnIndex}`,
          minWidth: columnWidth,
          flex: 1,
          headerName: column.title,
          valueFormatter: (value) => {
            return formatReportData(value, column.type);
          },
        };
        return gridColDef;
      }
    );
    return reportColumns ?? [];
  }, [report.mainReport.columns, report.mainReport.data]);

  const rows = useMemo(() => {
    return (
      report.mainReport.data.map((data, rowDataIndex) => {
        return data.reduce((rowData, columnData, columnDataIndex) => {
          return {
            ...rowData,
            [`column${columnDataIndex}`]: columnData,
            rowId: `row${rowDataIndex}`,
          };
        }, {});
      }) ?? []
    );
  }, [report.mainReport.data]);

  const saveMenuItems = useMemo(() => {
    const menuItems: MenuItemProp[] = [];

    if (canEditOrDelete) {
      menuItems.push({
        label: "Save Report",
        onClick: () =>
          report.reportGuid && onSaveReportClick(report.reportGuid),
      });
    }

    menuItems.push({
      label: "Save as New",
      onClick: () => onSaveAsNewClick(),
    });

    return menuItems;
  }, [onSaveAsNewClick, onSaveReportClick, canEditOrDelete, report.reportGuid]);

  const handleEditReportNameClick = () => {
    setIsEditingReportName(true);
  };

  const handleEditReportNameSave = (updatedReport: Report) => {
    setIsEditingReportName(false);
    onReportNameUpdated(updatedReport);
  };

  const handleEditReportNameClose = () => {
    setIsEditingReportName(false);
  };

  return (
    <>
      {report.mainReport.data.length === 0 ? (
        <Box marginRight={`${sidebarWidth}px`}>
          <EmptyState
            heading="No Data Matches Applied Filters"
            body="Try adjusting the applied filters in order to see reporting data"
            maxWidth="402px"
          />
        </Box>
      ) : (
        <Stack
          spacing={3}
          marginRight={`${sidebarWidth}px`}
          paddingX={3}
          paddingBottom={3}
        >
          <Card variant="outlined">
            <CardContent>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Stack direction="row" spacing={1.5} minWidth={0}>
                  <Avatar>
                    <TextSnippetOutlinedIcon sx={{ color: "grey.600" }} />
                  </Avatar>
                  <Stack minWidth={0}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography fontWeight={600} fontSize="20px" noWrap>
                        {report.reportName}
                      </Typography>
                      <IconButton
                        aria-label="edit"
                        color="primary"
                        sx={{ padding: 0 }}
                        onClick={handleEditReportNameClick}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Stack>
                    <Typography
                      fontWeight={500}
                      fontSize="14px"
                      color="grey.500"
                    >
                      {report.selectedQueryOptions.fromDate} -{" "}
                      {report.selectedQueryOptions.toDate}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={1.5} alignContent="center">
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ padding: 1, minWidth: "initial" }}
                    disabled={isReportDownloadDisabled}
                    onClick={onReportDownloadClick}
                  >
                    <FileDownloadOutlined />
                  </Button>
                  <MenuButton
                    menuItems={saveMenuItems}
                    disabled={isReportSaveDisabled}
                  >
                    Save
                  </MenuButton>
                </Stack>
              </Stack>

              <Divider sx={{ marginY: 3 }} />

              <Stack direction="row" spacing={8} marginBottom={2}>
                {report.headerReport.mainColumns.map((column) => {
                  return (
                    <Stack key={column.title}>
                      <Typography
                        variant="body1"
                        color="grey.500"
                        fontWeight={500}
                        fontSize="14px"
                      >
                        {column.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="grey.900"
                        fontWeight={600}
                        fontSize="30px"
                      >
                        {formatReportData(column.value, column.type)}
                      </Typography>
                    </Stack>
                  );
                })}
              </Stack>
              <Stack direction="row" spacing={3}>
                {report.headerReport.subColumns.map((column) => {
                  const formattedValue = formatReportData(
                    column.value,
                    column.type
                  );

                  return (
                    <Stack
                      component={Paper}
                      justifyContent="space-between"
                      sx={{ paddingX: 2, paddingY: 1.5 }}
                      key={column.title}
                    >
                      <Typography
                        variant="body1"
                        color="grey.500"
                        fontWeight={500}
                        fontSize="14px"
                      >
                        {column.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="grey.900"
                        fontWeight={600}
                        fontSize="16px"
                      >
                        {formattedValue}
                      </Typography>
                    </Stack>
                  );
                })}
              </Stack>
            </CardContent>
          </Card>
          <Card variant="outlined">
            <CardContent sx={{ paddingBottom: "0 !important" }}>
              <FillContainer bottomPadding={24} defaultHeight={1}>
                <Table
                  columns={columns}
                  rows={rows}
                  rowSelection={false}
                  disableColumnFilter
                  disableColumnMenu
                  disableColumnResize
                  disableRowSelectionOnClick
                  getRowId={(row) => row.rowId}
                />
              </FillContainer>
            </CardContent>
          </Card>
        </Stack>
      )}
      {isEditingReportName ? (
        <EditReportNameModal
          report={report}
          isOpen={isEditingReportName}
          onSaveClick={handleEditReportNameSave}
          onClose={handleEditReportNameClose}
        />
      ) : null}
    </>
  );
};
