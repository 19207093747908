import { UserRoles } from "./userRole";

export type UserSession = {
  tenantGuid: string;
  tenantName: string;
  userEmail: string;
  userFirstName: string;
  userLastName: string;
  userGuid: string;
  userRole: UserRoles;
  userPhoneNumber: string;
  userDeleteDate: string;
  authentication: string;
  isVerified: boolean;
  isImposter?: boolean;
  isRetailUser: boolean;
  isBuyerMode: boolean;
  userPhotoUrl: string | null;
};

export type ForgotPassword = {
  status: string;
};

export type User = {
  isVerified: boolean;
  tenantGuid: string;
  tenantName: string;
  userGuid: string;
  userRole: UserRoles;
  userDeleteDate: string;
  userFirstName: string;
  userLastName: string;
  userPhoneNumber: string;
  userEmail: string;
  userPassword?: string;
  isActive: boolean;
  isRetailUser: boolean;
  isBuyerMode: boolean;
  userPhotoUrl: string | null;
};

export const getUserFullName = (
  user: Pick<User, "userFirstName" | "userLastName">
): string => {
  return `${
    (user?.userFirstName ? `${user.userFirstName} ` : "") +
      user?.userLastName || ""
  }`;
};

export const getUserInitials = (
  user: Pick<User, "userFirstName" | "userLastName">
): string => {
  const firstNameInitial = user.userFirstName.charAt(0);
  const userLastNameInitial = user.userLastName.charAt(0);
  return `${firstNameInitial} ${userLastNameInitial}`;
};

export const getUserPhotoUrlSrc = (
  user: Pick<User, "userPhotoUrl">
): string | undefined => {
  return user.userPhotoUrl
    ? `${process.env.REACT_APP_API_BASE_URL}/${user.userPhotoUrl}`
    : undefined;
};
