import { useState } from "react";
import Typography from "@mui/material/Typography";
import MuiModal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import { Box, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InputField from "./InputField";
import { useGlobalToastNotificationContext } from "../context/GlobalToastNotificationContext";
import { useApiRequest } from "../hooks/useApiRequest";
import { ForgotPassword } from "../types/user";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  p: 4,
};

const closeButtonStyle = {
  position: "absolute",
  right: 8,
  top: 8,
};

type ModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export const ForgotPasswordModal = ({ isOpen, setIsOpen }: ModalProps) => {
  const [email, setEmail] = useState<string>("");
  const { setGlobalToastNotification } = useGlobalToastNotificationContext();

  const {
    data: forgotPasswordData,
    error: forgotPasswordError,
    request: forgotPasswordRequest,
  } = useApiRequest<ForgotPassword>();

  const sendForgotPasswordEmail = () => {
    forgotPasswordRequest("/users/resetpassword", {
      method: "POST",
      data: { userEmail: email },
    });
    if (forgotPasswordData || !forgotPasswordData) {
      setGlobalToastNotification({
        severity: "success",
        title: "Success!",
        message:
          "If your email is registered, you will receive a reset password link that is good for 24 hours.",
      });
    }
    if (forgotPasswordError) {
      setGlobalToastNotification({
        severity: "error",
        title: "Something went wrong!",
        message: "Please contact AtlasAlly support.",
      });
    }
    setIsOpen(false);
  };

  return (
    <MuiModal open={isOpen}>
      <Card sx={style}>
        <IconButton
          aria-label="close"
          onClick={() => setIsOpen(false)}
          sx={closeButtonStyle}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h5" fontWeight="bold" component="h2">
          Forgot Password
        </Typography>
        <form onSubmit={sendForgotPasswordEmail}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography sx={{ fontSize: "14px", marginTop: "5px" }}>
              Enter your email to receive a link where you can reset your
              password.
            </Typography>
            <InputField
              label="Email"
              fullWidth
              size="small"
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ height: "48px" }}
            >
              Submit
            </Button>
          </Box>
        </form>
      </Card>
    </MuiModal>
  );
};
