import { useEffect } from "react";
import { Box, Button } from "@mui/material";
import { Modal } from "../../components/Modal";
import InputField from "../../components/InputField";
import { useForm } from "react-hook-form";
import { Report } from "../../types/report";

type EditReportNameModalProps = {
  report: Report;
  isOpen: boolean;
  onSaveClick: (report: Report) => void;
  onClose: () => void;
};

type FormData = {
  reportName: string;
};

const getDefaultValues = (report: Report): FormData => {
  return {
    reportName: report.reportName,
  };
};

export const EditReportNameModal = ({
  report,
  isOpen,
  onSaveClick,
  onClose,
}: EditReportNameModalProps) => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isValid },
  } = useForm<FormData>({
    defaultValues: getDefaultValues(report),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  useEffect(() => {
    if (report) {
      const defaultValues = getDefaultValues(report);
      reset(defaultValues);
    }
  }, [reset, report]);

  const onSubmit = (data: FormData) => {
    onSaveClick({ ...report, ...data });
  };

  return (
    <Modal heading={`Edit Report Name`} isOpen={isOpen} onClose={onClose}>
      <Box
        component="form"
        sx={{ width: { xs: "100%", sm: "350px" } }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <InputField
          {...register("reportName", {
            required: { value: true, message: "Please enter name." },
          })}
          label="Report Name"
          required
          fullWidth
          error={!!errors.reportName}
          helperText={errors.reportName?.message}
          onFocus={(e) => e.target.select()}
        />
        <Box sx={{ mt: 2, display: "flex", justifyContent: "end" }}>
          <Button variant="outlined" onClick={onClose} sx={{ marginRight: 2 }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={!isValid}
          >
            Okay
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
