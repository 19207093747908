import React, { useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { PageHeader } from "../../components/PageHeader";
import { useApiRequest } from "../../hooks/useApiRequest";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { Reporting as ReportingType } from "../../types/reporting";
import { Table } from "../../components/Table";
import { GridColDef } from "@mui/x-data-grid";
import { FileDownloadOutlined } from "@mui/icons-material";
import { routes } from "../../routes";
import { ValueHeader } from "./ValueHeader";
import { formatAsNumber } from "../../utils/number";

const ReportingRoute: React.FC = () => {
  const { setBreadcrumbs } = useBreadcrumbs();
  const { data: reportsData, request: reportsRequest } =
    useApiRequest<ReportingType>();

  usePageMetadata({ title: routes.reporting.label });

  useEffect(() => {
    setBreadcrumbs([{ label: routes.reporting.label }]);
  }, [setBreadcrumbs]);

  useEffect(() => {
    reportsRequest("/reports", {
      method: "GET",
    });
  }, [reportsRequest]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "title",
        flex: 1,
        headerName: "Title",
      },
      {
        field: "values.0",
        flex: 0.5,
        type: "number",
        headerName: "Yesterday",
        valueGetter: (_, row) =>
          row.type === "a" ? `$${formatAsNumber(row.values[0])}` : row.values[0],
      },
      {
        field: "values.1",
        flex: 0.5,
        type: "number",
        headerName: "Week to Date (Fiscal)",
        valueGetter: (_, row) =>
          row.type === "a" ? `$${formatAsNumber(row.values[1])}` : row.values[1],
      },
      {
        field: "values.2",
        flex: 0.5,
        type: "number",
        headerName: "Month to Date (Fiscal)",
        valueGetter: (_, row) =>
          row.type === "a" ? `$${formatAsNumber(row.values[2])}` : row.values[2],
      },
    ],
    []
  );

  return (
    <Box>
      <PageHeader
        title="Reporting"
        actions={
          <Stack direction="row" spacing={2} alignContent="center">
            <Button
              variant="contained"
              color="secondary"
              sx={{ padding: 1, minWidth: "initial" }}
              onClick={window.print}
            >
              <FileDownloadOutlined />
            </Button>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={routes.reportingAdd.path}
            >
              Run Report
            </Button>
          </Stack>
        }
      />
      <Card variant="outlined">
        <CardContent sx={{ "@media print": { padding: 0 } }}>
          {/* TODO: API does not yet handle Today's data so ignore */}
          {reportsData?.productivity &&
            [reportsData?.productivity[0]].map((report) => (
              <React.Fragment key={report.title}>
                <Box
                  sx={{
                    borderRadius: 1,
                    bgcolor: "grey.100",
                    mt: 2,
                    mr: 2,
                    mb: 2,
                    p: 1,
                  }}
                >
                  {report.title}
                </Box>
                <Grid container spacing={2} sx={{ width: "100%" }}>
                  {report.columns.map((column) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={2}
                      key={column.title}
                      sx={{ flexGrow: 1, mb: 3 }}
                    >
                      <Card
                        sx={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <CardHeader
                          title={<ValueHeader value={column.value} />}
                          titleTypographyProps={{
                            align: "center",
                            variant: "h4",
                          }}
                        />
                        <CardContent
                          sx={{
                            flexGrow: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="body1" align="center">
                            {column.title}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </React.Fragment>
            ))}
          {!!reportsData?.report && reportsData.report.length > 0 && (
            <Box mt={5} mr={2} sx={{ "@media print": { mt: 2 } }}>
              <Table
                columns={columns}
                rows={reportsData?.report}
                rowSelection={false}
                hideFooter
                disableColumnFilter
                disableColumnMenu
                disableColumnResize
                disableRowSelectionOnClick
                getRowId={(row) => row.title}
                sx={{
                  "@media print": {
                    ".MuiDataGrid-cell[aria-colindex='1']": {
                      "--width": "170px !important",
                    },
                    ".MuiDataGrid-columnHeader[aria-colindex='1']": {
                      width: "170px !important",
                      minWidth: "170px !important",
                      maxWidth: "170px !important",
                    },
                    ".MuiDataGrid-cell[aria-colindex='2']": {
                      "--width": "150px !important",
                    },
                    ".MuiDataGrid-columnHeader[aria-colindex='2']": {
                      width: "150px !important",
                      minWidth: "150px !important",
                      maxWidth: "150px !important",
                    },
                    ".MuiDataGrid-cell[aria-colindex='3']": {
                      "--width": "163px !important",
                    },
                    ".MuiDataGrid-columnHeader[aria-colindex='3']": {
                      width: "163px !important",
                      minWidth: "163px !important",
                      maxWidth: "163px !important",
                    },
                    ".MuiDataGrid-cell[aria-colindex='4']": {
                      "--width": "170px !important",
                    },
                    ".MuiDataGrid-columnHeader[aria-colindex='4']": {
                      width: "170px !important",
                      minWidth: "170px !important",
                      maxWidth: "170px !important",
                    },
                  },
                }}
              />
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default ReportingRoute;
