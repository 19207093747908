import React, { useCallback, useEffect, useState } from "react";
import { Grid, Button, Paper, Typography, Alert, Box } from "@mui/material";
import { generatePath, useNavigate } from "react-router-dom";
import InputField from "../../components/InputField";
import { ForgotPasswordModal } from "../../components/ForgotPasswordModal";
import ForgotPasswordButton from "../../components/ForgotPasswordButton";
import { VerifyCodeModal } from "../../components/VerifyCodeModal";
import { UserSession } from "../../types/user";
import { useApiRequest } from "../../hooks/useApiRequest";
import { useSession } from "../../hooks/useSession";
import { PasswordInput } from "../../components/PasswordInput";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import { routes } from "../../routes";
import { useDetectMobile } from "../../hooks/useDetectMobile";

const LoginRoute: React.FC = () => {
  const navigate = useNavigate();
  const isMobile = useDetectMobile();

  const [email, setEmail] = useState<string>(
    process.env.REACT_APP_USER_EMAIL || ""
  );
  const [password, setPassword] = useState<string>(
    process.env.REACT_APP_USER_PASSWORD || ""
  );
  // const [captchaValue, setCaptchaValue] = useState<string | null>(null);
  const [showVerifyCodeModal, setShowVerifyCodeModal] = React.useState(false);
  const [showErrorAlert, setShowErrorAlert] = React.useState(false);

  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [isVerified, setIsVerified] = React.useState(false);

  usePageMetadata({ title: routes.login.label });

  const {
    data: userSessionResponse,
    loading,
    status: userSessionStatus,
    error,
    request,
  } = useApiRequest<UserSession>();
  const { login, isLoggedIn } = useSession();

  const navigateByRole = useCallback(() => {
    let route: string = routes.home.path;
    switch (userSessionResponse?.userRole) {
      case "sysadmin":
        route = routes.manageSystemAdmins.path;
        break;
      case "owner":
      case "admin":
      case "advanceduser":
      case "basicuser":
        route = `${generatePath(routes.quotesAndOrders.path, {
          tenantGuid: userSessionResponse?.tenantGuid,
        })}?view=${isMobile ? "list" : "kanban"}`;
        break;
    }
    navigate(route);
  }, [
    navigate,
    userSessionResponse?.userRole,
    userSessionResponse?.tenantGuid,
    isMobile,
  ]);

  useEffect(() => {
    if (isVerified && isLoggedIn) {
      navigateByRole();
    }
  }, [isLoggedIn, isVerified, navigateByRole]);

  // Logic to determine when to show the verify code modal
  useEffect(() => {
    if (userSessionResponse && userSessionStatus === "ok") {
      setShowVerifyCodeModal(true);
      setShowErrorAlert(false);
    }
    if (error && !userSessionStatus) {
      setShowErrorAlert(true);
    }
  }, [userSessionResponse, error, userSessionStatus]);

  // Logic to store user information in local storage
  useEffect(() => {
    if (userSessionResponse) {
      login(userSessionResponse);
    }
  }, [userSessionResponse, login]);

  const isValidEmail = (email: string) => {
    const re = /[^@]+@[^@]+\.[^@]+/;
    return re.test(email);
  };

  const isFormEmpty = !email || !password;
  // const isCaptchaSolved = captchaValue != null;
  const isButtonDisabled = isFormEmpty || !isValidEmail(email); // TODO: || !isCaptchaSolved;

  // const onCaptchaChange = (value: string | null) => {
  //   setCaptchaValue(value);
  // };

  const handleClose = () => {
    setShowVerifyCodeModal(false);
  };

  const handleLogin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    request("/users/login", {
      method: "POST",
      data: { userEmail: email, userPassword: password },
    });
  };

  const handleSuccessfulVerification = (userSession: UserSession) => {
    login(userSession);
    setShowVerifyCodeModal(false);
    setIsVerified(true);
    navigateByRole();
  };

  return (
    <>
      <Grid
        container
        style={{
          background: "url(/login-background.jpg) no-repeat center center",
          backgroundSize: "cover",
        }}
      >
        <Grid item xs={12} sm={4} style={{ height: "100vh" }}>
          <Paper
            elevation={6}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "2rem",
              maxWidth: "500px",
            }}
          >
            {showErrorAlert && (
              <Alert severity="error" style={{ marginBottom: 15 }}>
                Something went wrong. Please check your email and password.
              </Alert>
            )}
            <Typography variant="h5" gutterBottom>
              Welcome Back!
            </Typography>
            <form onSubmit={handleLogin}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <InputField
                    label="Email"
                    type="email"
                    fullWidth
                    value={email}
                    error={!isValidEmail(email)}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setEmail(event.target.value)
                    }
                  />
                </Grid>
                <Grid item>
                  <PasswordInput
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Grid>
                {/* <Grid item>
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
                    onChange={onCaptchaChange}
                    size="normal"
                    style={{ width: "100%" }}
                  />
                </Grid> */}
                <Grid item>
                  <ForgotPasswordButton
                    showForgotPasswordModal={setShowForgotPassword}
                  />
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={isButtonDisabled && !loading}
                  >
                    Sign In
                  </Button>
                </Grid>
              </Grid>
            </form>
            <Box sx={{ position: "absolute", bottom: 20, left: 20 }}>
              <Typography variant="body2" color="textSecondary">
                &copy;2024 Red Roots Technologies
              </Typography>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={8}></Grid>
      </Grid>
      {showVerifyCodeModal && (
        <VerifyCodeModal
          isOpen={showVerifyCodeModal}
          onClose={handleClose}
          onSuccessfulVerification={handleSuccessfulVerification}
        />
      )}
      <ForgotPasswordModal
        isOpen={showForgotPassword}
        setIsOpen={setShowForgotPassword}
      />
    </>
  );
};

export default LoginRoute;
