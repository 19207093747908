import React, { useMemo } from "react";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import {
  Control,
  Controller,
  FormState,
  UseFormRegister,
} from "react-hook-form";
import InputField from "../../components/InputField";
import SelectField from "../../components/SelectField";
import SwitchField from "../../components/SwitchField";
import { manufacturerDefaultVepLevelOptions } from "../../types/retailerManufacturer";
import { FormData } from "./FormData";
import { User, getUserFullName } from "../../types/user";
import { UserRole, UserRoles } from "../../types/userRole";
import { useSession } from "../../hooks/useSession";

interface CompanyInfoTabBusinessRelationProps {
  salesReps: User[];
  // form props
  control: Control<FormData>;
  formState: FormState<FormData>;
  register: UseFormRegister<FormData>;
}

export const CompanyInfoTabBusinessRelation: React.FC<
  CompanyInfoTabBusinessRelationProps
> = ({ salesReps, control, formState, register }) => {
  const { user } = useSession();

  const canEdit =
    user?.userRole != null &&
    (
      [UserRole.OWNER, UserRole.ADMIN, UserRole.ADVANCEDUSER] as UserRoles[]
    ).includes(user.userRole);

  const salesRepsOptions = useMemo(() => {
    return salesReps.map((u) => ({
      label: getUserFullName(u),
      value: u.userGuid,
    }));
  }, [salesReps]);

  return (
    <Box>
      <Typography variant="body1" fontWeight={600} mb={1}>
        Business Relation
      </Typography>
      <Card variant="outlined">
        <CardContent>
          <Stack spacing={2}>
            <Controller
              name="businessRelation.isActive"
              control={control}
              render={({ field }) => (
                <SwitchField
                  {...field}
                  label="Active"
                  checked={field.value}
                  disabled={!canEdit}
                  sx={{ marginRight: "auto" }}
                />
              )}
            />
            <Controller
              control={control}
              name="businessRelation.manufacturerSalesRepUserGuid"
              render={({ field, fieldState }) => (
                <SelectField
                  {...field}
                  label="Assigned Sales Rep"
                  placeholder="Select Sales Rep"
                  fullWidth
                  options={salesRepsOptions}
                  disabled={!canEdit}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <InputField
              {...register("businessRelation.manufacturerRetailerIdentifier")}
              fullWidth
              label="Retailer ID"
              placeholder="Enter Retailer ID"
              disabled={!canEdit}
              error={
                !!formState.errors.businessRelation
                  ?.manufacturerRetailerIdentifier
              }
              helperText={
                formState.errors.businessRelation
                  ?.manufacturerRetailerIdentifier?.message
              }
            />
            <Controller
              control={control}
              name="businessRelation.manufacturerDefaultVepLevel"
              render={({ field, fieldState }) => (
                <SelectField
                  {...field}
                  label="Default VEP Level"
                  placeholder="Select Default VEP Level"
                  fullWidth
                  options={manufacturerDefaultVepLevelOptions}
                  disabled={!canEdit}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
};
