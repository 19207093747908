import { Group } from "./group";
import { Option } from "./option";
import { Subgroup } from "./subgroup";

export type Category = {
  categoryGuid: string;
  categoryName: string;
  sortOrder: number;
  groups: Group[];
};

export const findOption = (
  categories: Category[],
  optionGuid: string
): Option | undefined => {
  for (const category of categories) {
    for (const group of category.groups) {
      for (const subgroup of group.subgroups) {
        for (const option of subgroup.options) {
          if (option.optionGuid === optionGuid) {
            return option;
          }
        }
      }
    }
  }
};

export const findSubgroup = (
  categories: Category[],
  subgroupGuid: string
): Subgroup | undefined => {
  for (const category of categories) {
    for (const group of category.groups) {
      for (const subgroup of group.subgroups) {
        if (subgroup.subgroupGuid === subgroupGuid) {
          return subgroup;
        }
      }
    }
  }
};
