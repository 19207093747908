import { useCallback, useEffect, useMemo } from "react";
import { Box, Stack, Avatar } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { Switch } from "../../components/Switch";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { User } from "../../types/user";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Table } from "../../components/Table";
import { UserRole, getUserRoleDisplayName } from "../../types/userRole";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import { routes } from "../../routes";
import { MoreMenuButton } from "../../components/MoreMenuButton";
import { formatAsPhoneNumber } from "../../utils/number";
import { useParams } from "react-router-dom";
import { Tenant } from "../../types/tenant";

function TenantUsersRoute() {
  const { setBreadcrumbs } = useBreadcrumbs();

  const { tenantGuid } = useParams();

  usePageMetadata({ title: `Manage ${routes.manageDataUsers.label}` });

  const { data: users, request: usersRequest } = useApiRequest<User[]>(false);
  const { data: tenant, request: tenantRequest } = useApiRequest<Tenant>(false);
  const {
    loading: setOwnerLoading,
    status: setOwnerStatus,
    request: setOwnerRequest,
  } = useApiRequest<Tenant>(false);

  const refreshTenants = useCallback(() => {
    usersRequest(`/tenants/${tenantGuid}/users`, {
      method: "GET",
    });
  }, [usersRequest, tenantGuid]);

  useEffect(() => {
    tenantRequest(`/tenants/${tenantGuid}`, {
      method: "GET",
    });
  }, [tenantGuid, tenantRequest]);

  useEffect(() => {
    if (!tenant) return;
    setBreadcrumbs([
      { label: routes.manageTenants.label, href: routes.manageTenants.path },
      { label: `${tenant.tenantName} Users` },
    ]);
  }, [tenant, setBreadcrumbs]);

  useEffect(() => {
    if (setOwnerLoading === false && setOwnerStatus === "ok") refreshTenants();
  }, [setOwnerStatus, refreshTenants, setOwnerLoading]);

  useEffect(() => {
    refreshTenants();
  }, [refreshTenants]);

  const handleSetAsOwner = useCallback(
    (user: User) => () => {
      const result = window.confirm(
        `You are changing the owner of ${tenant?.tenantName} to ${user.userFirstName} ${user.userLastName}. This will logout the current and new owner. Continue?`
      );
      if (!result) return;
      setOwnerRequest(`/tenants/${tenantGuid}/edit`, {
        method: "POST",
        data: { ownerGuid: user.userGuid },
      });
    },
    [setOwnerRequest, tenantGuid, tenant?.tenantName]
  );

  const columns: GridColDef<User>[] = useMemo(() => {
    const cols: GridColDef<User>[] = [
      {
        field: "userFirstName",
        headerName: "Name",
        width: 200,
        renderCell({ row, value }) {
          const userInitials = `${row.userFirstName.charAt(
            0
          )} ${row.userLastName.charAt(0)}`;

          const userProfileImageSrc = row.userPhotoUrl
            ? `${process.env.REACT_APP_API_BASE_URL}/${row.userPhotoUrl}`
            : undefined;

          return (
            <Stack direction="row" alignItems="center" columnGap={1}>
              <Avatar alt={userInitials} src={userProfileImageSrc}>
                {userInitials}
              </Avatar>
              <Box component="span" fontWeight={500}>
                {value}
              </Box>
            </Stack>
          );
        },
        valueGetter: (_value, row) => {
          return `${row.userFirstName} ${row.userLastName}`;
        },
      },
      {
        field: "userEmail",
        headerName: "Email",
        width: 240,
      },
      {
        field: "userPhoneNumber",
        headerName: "Phone",
        width: 160,
        renderCell: ({ row }) => formatAsPhoneNumber(row.userPhoneNumber),
      },
      {
        field: "userRole",
        headerName: "Role",
        width: 150,
        valueGetter: (value) => {
          if (!value) {
            return value;
          }
          return getUserRoleDisplayName(value);
        },
      },
      {
        field: "isActive",
        headerName: "Active",
        headerAlign: "right",
        align: "right",
        flex: 1,
        minWidth: 100,
        renderCell: (params) => (
          <Box sx={{ paddingTop: 2, paddingLeft: 1 }}>
            <Switch
              sx={{ marginLeft: "auto" }}
              disabled={true}
              checked={params.row.isActive}
            />
          </Box>
        ),
      },
      {
        field: "actions",
        headerName: "",
        sortable: false,
        renderCell: ({ row }) => {
          return (
            <MoreMenuButton
              menuItems={[
                {
                  label: "Set as Owner",
                  disabled: row.userRole === UserRole.OWNER || !row.isActive,
                  onClick: handleSetAsOwner(row),
                },
              ]}
            />
          );
        },
      },
    ];

    return cols;
  }, [handleSetAsOwner]);

  return (
    <>
      <Stack width="100%" spacing={2}>
        {users ? (
          <Box width="100%">
            <Table
              rows={users}
              columns={columns}
              rowSelection={false}
              hideFooter
              disableColumnFilter
              disableColumnMenu
              disableColumnResize
              disableRowSelectionOnClick
              getRowId={(row) => row.userGuid}
            />
          </Box>
        ) : null}
      </Stack>
    </>
  );
}

export default TenantUsersRoute;
