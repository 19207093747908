import React, { useCallback, useEffect, useMemo } from "react";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import { routes } from "../../routes";
import { Box, Button, Grid } from "@mui/material";
import { NewEditModal } from "./NewEditModal";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Surcharge, getSurchargeUomDisplayName } from "../../types/surcharge";
import { EmptyState } from "../../components/EmptyState";
import { Table } from "../../components/Table";
import { GridColDef } from "@mui/x-data-grid";
import { MoreMenuButton } from "../../components/MoreMenuButton";
import { formatAsDate } from "../../utils/date";
import { formatAsCurrency } from "../../utils/number";

const ManageSurchargeCalculatorRoute: React.FC = () => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [editingSurcharge, setEditingSurcharge] =
    React.useState<Surcharge | null>(null);

  const { setBreadcrumbs } = useBreadcrumbs();

  usePageMetadata({
    title: `Manage ${routes.manageDataSurchargeCalculator.label}`,
  });

  useEffect(() => {
    setBreadcrumbs([{ label: routes.manageDataSurchargeCalculator.label }]);
  }, [setBreadcrumbs]);

  const {
    data: surcharges,
    loading,
    request: surchargesRequest,
  } = useApiRequest<Surcharge[]>(false);

  const refreshSurcharges = useCallback(() => {
    surchargesRequest("/company/surcharges", {
      method: "GET",
    });
  }, [surchargesRequest]);

  const handleNewButtonClick = () => {
    setEditingSurcharge(null);
    setIsEditing(true);
  };

  const handleEditClick = (surcharge: Surcharge) => () => {
    setEditingSurcharge(surcharge);
    setIsEditing(true);
  };

  const handleSave = useCallback(() => {
    refreshSurcharges();
    setIsEditing(false);
  }, [refreshSurcharges]);

  useEffect(() => {
    refreshSurcharges();
  }, [refreshSurcharges]);

  const columns: GridColDef<Surcharge>[] = useMemo(
    () => [
      {
        field: "series",
        headerName: "Series",
        flex: 1,
        minWidth: 200,
        valueFormatter: (surcharge: any) => {
          return surcharge.seriesName;
        },
      },
      {
        field: "surchargeAmount",
        headerName: "Price",
        flex: 1,
        minWidth: 80,
        type: "number",
        valueGetter: (_: any, row: Surcharge) => {
          return `${formatAsCurrency(
            row.surchargeAmount
          )}/${getSurchargeUomDisplayName(row.surchargeUom)}`;
        },
      },
      {
        field: "startDate",
        headerName: "Start Date",
        flex: 1,
        minWidth: 120,
        type: "number",
        valueFormatter: (value: number) => formatAsDate(value),
      },
      {
        field: "endDate",
        headerName: "End Date",
        flex: 1,
        minWidth: 120,
        type: "number",
        valueFormatter: (value: number) =>
          value ? formatAsDate(value) : "No End Date",
      },
      {
        field: "actions",
        headerName: "",
        sortable: false,
        width: 10,
        alighn: "right",
        renderCell: ({ row }) => {
          return (
            <MoreMenuButton
              menuItems={[
                {
                  label: "Edit",
                  onClick: handleEditClick(row),
                },
              ]}
            />
          );
        },
      },
    ],
    []
  );
  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent={"right"}
        marginBottom={2}
      >
        <Grid item>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleNewButtonClick}
            >
              + Surcharge
            </Button>
          </Box>
        </Grid>
      </Grid>
      {surcharges && surcharges.length === 0 && !loading && (
        <EmptyState heading="No Surcharges" />
      )}
      {surcharges && surcharges.length > 0 && (
        <Table
          columns={columns}
          rows={surcharges}
          rowSelection={false}
          hideFooter
          disableColumnFilter
          disableColumnMenu
          disableColumnResize
          disableRowSelectionOnClick
          getRowId={(row) => row.surchargeGuid}
          disableVirtualization
        />
      )}
      {isEditing && (
        <NewEditModal
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          onSaveSuccessful={handleSave}
          surcharge={editingSurcharge}
          isNew={!editingSurcharge}
        />
      )}
    </>
  );
};

export default ManageSurchargeCalculatorRoute;
