import { useEffect } from "react";
import { Alert, Box, Button, InputAdornment } from "@mui/material";
import { Modal } from "../../components/Modal";
import InputField from "../../components/InputField";
import { useApiRequest } from "../../hooks/useApiRequest";
import {
  TenantFreight,
  getTenantFreightCostDisplayName,
} from "../../types/tenantFreight";
import { isValidNumber } from "../../utils/validation";
import { useForm } from "react-hook-form";

type TenantFreightDefaultsModalProps = {
  tenantFreight: TenantFreight;
  tenantFreightKey: keyof TenantFreight;
  isOpen: boolean;
  onSaveSuccessful: (updatedTenantFreight: TenantFreight) => void;
  onClose: () => void;
};

type FormData = {
  tenantFreightCost14: TenantFreight["tenantFreightCost14"];
  tenantFreightCost16: TenantFreight["tenantFreightCost16"];
  tenantCostPerMile: TenantFreight["tenantCostPerMile"];
  tenantMileageCostCap: TenantFreight["tenantMileageCostCap"];
};

const getDefaultValues = (tenantFreight: TenantFreight | null): FormData => {
  return {
    tenantFreightCost14: tenantFreight?.tenantFreightCost14 ?? 0,
    tenantFreightCost16: tenantFreight?.tenantFreightCost16 ?? 0,
    tenantCostPerMile: tenantFreight?.tenantCostPerMile ?? 0,
    tenantMileageCostCap: tenantFreight?.tenantMileageCostCap ?? 0,
  };
};

export const TenantFreightDefaultsModal = ({
  tenantFreight,
  tenantFreightKey,
  isOpen,
  onSaveSuccessful,
  onClose,
}: TenantFreightDefaultsModalProps) => {
  const label = getTenantFreightCostDisplayName(tenantFreightKey);
  const {
    data,
    loading: saveTenantFreightLoading,
    request: saveTenantFreightRequest,
    status: saveTenantFreightStatus,
    error: saveTenantFreightError,
  } = useApiRequest<TenantFreight>(true);

  const { handleSubmit, register, reset, formState } = useForm<FormData>({
    defaultValues: getDefaultValues(tenantFreight),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  useEffect(() => {
    if (tenantFreight) {
      const defaultValues = getDefaultValues(tenantFreight);
      reset(defaultValues);
    }
  }, [reset, tenantFreight]);

  const onSubmit = (data: FormData) => {
    saveTenantFreightRequest("/company/freightbase/edit", {
      method: "POST",
      data: data,
    });
  };

  useEffect(() => {
    if (
      data &&
      saveTenantFreightStatus === "ok" &&
      saveTenantFreightLoading === false
    ) {
      onSaveSuccessful(data);
    }
  }, [
    data,
    saveTenantFreightLoading,
    onSaveSuccessful,
    saveTenantFreightStatus,
  ]);

  return (
    <Modal heading={`Edit ${label}`} isOpen={isOpen} onClose={onClose}>
      {saveTenantFreightError && (
        <Alert severity="error" sx={{ marginBottom: 2 }}>
          Something went wrong. Please try again.
        </Alert>
      )}
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <InputField
          {...register(tenantFreightKey, {
            valueAsNumber: true,
            required: { value: true, message: "Please enter a value." },
            validate: {
              isNumber: (value) => {
                return isValidNumber(value) || "Please enter a number.";
              },
            },
          })}
          label={label}
          required
          fullWidth
          error={!!formState.errors[tenantFreightKey]}
          helperText={formState.errors[tenantFreightKey]?.message}
          onFocus={(e) => e.target.select()}
          startAdornment={
            <InputAdornment position="start">$</InputAdornment>
          }
          intOnly={label === "Cost/Mile" ? false : true}
        />
        <Box sx={{ mt: 2, display: "flex", justifyContent: "end" }}>
          <Button variant="outlined" onClick={onClose} sx={{ marginRight: 2 }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={!formState.isValid}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
