import { Box, Grid, Typography } from "@mui/material";

type PageHeaderProps = {
  title?: React.ReactNode;
  actions?: React.ReactNode;
};

export const PageHeader: React.FC<PageHeaderProps> = ({ title, actions }) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        paddingTop: 3,
        paddingBottom: 2,
        displayPrint: "none",
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {title && typeof title === "string" ? (
              <Typography variant="h5" fontWeight="bold">
                {title}
              </Typography>
            ) : title ? (
              title
            ) : null}
          </Box>
        </Grid>

        <Grid item>{actions}</Grid>
      </Grid>
    </Box>
  );
};
