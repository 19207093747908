export const formatAsCurrency = (value: number): string => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return formatter.format(value);
};

export const formatAsPhoneNumber = (
  number: string,
  obfuscated: boolean = false
): string => {
  if (!number) return "";
  if (number.toString().length !== 10) return number;

  if (obfuscated) {
    return number.toString().replace(/(\d{3})(\d{3})(\d{4})/, "(***) ***-$3");
  } else {
    return number.toString().replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }
};

export const formatAsDataSize = (bytes: number): string => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Bytes";
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const formattedSize = (bytes / Math.pow(1024, i)).toFixed(2);
  if (formattedSize.endsWith(".00")) {
    return `${parseInt(formattedSize)} ${sizes[i]}`;
  } else {
    return `${formattedSize} ${sizes[i]}`;
  }
};

export const formatWithCommas = (value: number): string => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatAsNumber = (value: number): string => {
  const absValue = Math.abs(value);
  const sign = value < 0 ? "-" : "";

  if (absValue < 1000) {
    return sign + absValue.toString();
  } else if (absValue < 1000000) {
    const thousands = absValue / 1000;
    const formatted = Math.floor(thousands * 10) / 10;
    return sign + `${formatted}K`.replace(".0K", "K");
  } else {
    const millions = absValue / 1000000;
    const formatted = Math.floor(millions * 10) / 10;
    return sign + `${formatted}M`.replace(".0M", "M");
  }
};
