import React from "react";
import { GridRow, GridRowProps } from "@mui/x-data-grid";
import { DraggableBox } from "./DraggableBox";

export const TableRowDraggable: React.FC<GridRowProps> = (props) => {
  return (
    <DraggableBox dragId={props.rowId}>
      <GridRow {...props} />
    </DraggableBox>
  );
};
