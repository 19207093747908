import React, { useState, useCallback } from "react";
import { formatAsNumber, formatWithCommas } from "../../utils/number";
import { Box, Popover } from "@mui/material";

type ValueHeaderProps = {
  value: number;
};

export const ValueHeader: React.FC<ValueHeaderProps> = ({ value }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <span onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
        {formatAsNumber(value)}
      </span>
      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: "none", mt: 1 }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box sx={{ p: 3 }}>{formatWithCommas(value)}</Box>
      </Popover>
    </>
  );
};
