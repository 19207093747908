import React, { useEffect } from "react";
import { Modal } from "../../components/Modal";
import InputField from "../../components/InputField";
import { Alert, Box, Button, Stack } from "@mui/material";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Controller, useForm } from "react-hook-form";
import SelectField from "../../components/SelectField";
import {
  InvoiceDisclosure,
  getInvoiceDisclosureTypeDisplayName,
} from "../../types/invoiceDisclosure";

type NewEditInvoiceDisclosureModalProps = {
  isOpen: boolean;
  onSaveSuccessful: () => void;
  onClose: () => void;
  invoiceDisclosure: InvoiceDisclosure | null;
  isNew: boolean;
};

type FormData = Partial<{
  disclosureType: InvoiceDisclosure["disclosureType"] | "";
  disclosureText: string;
}>;

const getDefaultValues = (
  invoiceDisclosure: InvoiceDisclosure | null
): FormData => {
  return {
    disclosureType: invoiceDisclosure?.disclosureType ?? "",
    disclosureText: invoiceDisclosure?.disclosureText,
  };
};

export const NewEditInvoiceDisclosureModal: React.FC<
  NewEditInvoiceDisclosureModalProps
> = ({ isOpen, onSaveSuccessful, onClose, invoiceDisclosure, isNew }) => {
  const {
    data: saveInvoiceDisclosureData,
    loading: saveInvoiceDisclosureLoading,
    request: saveInvoiceDisclosureRequest,
    status: saveInvoiceDisclosureStatus,
    error: saveInvoiceDisclosureError,
  } = useApiRequest<InvoiceDisclosure>(true);

  const { control, handleSubmit, register, reset, formState } =
    useForm<FormData>({
      defaultValues: getDefaultValues(invoiceDisclosure),
      reValidateMode: "onChange",
      mode: "onChange",
    });

  useEffect(() => {
    if (invoiceDisclosure) {
      const defaultValues = getDefaultValues(invoiceDisclosure);
      reset(defaultValues);
    }
  }, [reset, invoiceDisclosure]);

  const onSubmit = (values: FormData) => {
    if (isNew) {
      saveInvoiceDisclosureRequest("/company/invoicedisclosures/add", {
        method: "POST",
        data: values,
      });
    } else {
      saveInvoiceDisclosureRequest(
        `/company/invoicedisclosures/${invoiceDisclosure?.invoiceDisclosureGuid}/edit`,
        {
          method: "POST",
          data: values,
        }
      );
    }
  };

  useEffect(() => {
    if (
      saveInvoiceDisclosureData &&
      saveInvoiceDisclosureStatus === "ok" &&
      saveInvoiceDisclosureLoading === false
    ) {
      onSaveSuccessful();
    }
  }, [
    onSaveSuccessful,
    saveInvoiceDisclosureData,
    saveInvoiceDisclosureLoading,
    saveInvoiceDisclosureStatus,
  ]);

  return (
    <Modal heading={"Disclosure Details"} isOpen={isOpen} onClose={onClose}>
      {saveInvoiceDisclosureError && (
        <Alert severity="error" sx={{ marginBottom: 2 }}>
          Something went wrong. Please try again.
        </Alert>
      )}
      <Stack
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        rowGap={3}
        sx={{ width: { xs: "100%", sm: "500px" } }}
      >
        <Controller
          control={control}
          name="disclosureType"
          render={({ field, fieldState }) => (
            <SelectField
              {...field}
              label="Disclosure Type"
              placeholder="Select Type"
              required
              fullWidth
              options={[
                { label: getInvoiceDisclosureTypeDisplayName("c"), value: "c" },
                { label: getInvoiceDisclosureTypeDisplayName("t"), value: "t" },
                { label: getInvoiceDisclosureTypeDisplayName("v"), value: "v" },
              ]}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
        />
        <InputField
          {...register("disclosureText", {
            required: {
              value: true,
              message: "Please enter a value.",
            },
          })}
          multiline
          minRows={6}
          maxRows={12}
          fullWidth
          required
          label="Disclosure Text"
          placeholder="Enter text"
          error={!!formState.errors.disclosureText}
          helperText={formState.errors.disclosureText?.message}
        />
        <Box sx={{ mt: 2, display: "flex", justifyContent: "end" }}>
          <Button variant="outlined" onClick={onClose} sx={{ marginRight: 2 }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={!formState.isValid || saveInvoiceDisclosureLoading}
          >
            Save
          </Button>
        </Box>
      </Stack>
    </Modal>
  );
};
