export const states = {
  alabama: { name: "Alabama", abbr: "AL" },
  alaska: { name: "Alaska", abbr: "AK" },
  arizona: { name: "Arizona", abbr: "AZ" },
  arkansas: { name: "Arkansas", abbr: "AR" },
  california: { name: "California", abbr: "CA" },
  colorado: { name: "Colorado", abbr: "CO" },
  connecticut: { name: "Connecticut", abbr: "CT" },
  delaware: { name: "Delaware", abbr: "DE" },
  districtOfColumbia: { name: "District of Columbia", abbr: "DC" },
  florida: { name: "Florida", abbr: "FL" },
  georgia: { name: "Georgia", abbr: "GA" },
  hawaii: { name: "Hawaii", abbr: "HI" },
  idaho: { name: "Idaho", abbr: "ID" },
  illinois: { name: "Illinois", abbr: "IL" },
  indiana: { name: "Indiana", abbr: "IN" },
  iowa: { name: "Iowa", abbr: "IA" },
  kansas: { name: "Kansas", abbr: "KS" },
  kentucky: { name: "Kentucky", abbr: "KY" },
  louisiana: { name: "Louisiana", abbr: "LA" },
  maine: { name: "Maine", abbr: "ME" },
  maryland: { name: "Maryland", abbr: "MD" },
  massachusetts: { name: "Massachusetts", abbr: "MA" },
  michigan: { name: "Michigan", abbr: "MI" },
  minnesota: { name: "Minnesota", abbr: "MN" },
  mississippi: { name: "Mississippi", abbr: "MS" },
  missouri: { name: "Missouri", abbr: "MO" },
  montana: { name: "Montana", abbr: "MT" },
  nebraska: { name: "Nebraska", abbr: "NE" },
  nevada: { name: "Nevada", abbr: "NV" },
  newHampshire: { name: "New Hampshire", abbr: "NH" },
  newJersey: { name: "New Jersey", abbr: "NJ" },
  newMexico: { name: "New Mexico", abbr: "NM" },
  newYork: { name: "New York", abbr: "NY" },
  northCarolina: { name: "North Carolina", abbr: "NC" },
  northDakota: { name: "North Dakota", abbr: "ND" },
  ohio: { name: "Ohio", abbr: "OH" },
  oklahoma: { name: "Oklahoma", abbr: "OK" },
  oregon: { name: "Oregon", abbr: "OR" },
  pennsylvania: { name: "Pennsylvania", abbr: "PA" },
  puertoRico: { name: "Puerto Rico", abbr: "PR" },
  rhodeIsland: { name: "Rhode Island", abbr: "RI" },
  southCarolina: { name: "South Carolina", abbr: "SC" },
  southDakota: { name: "South Dakota", abbr: "SD" },
  tennessee: { name: "Tennessee", abbr: "TN" },
  texas: { name: "Texas", abbr: "TX" },
  utah: { name: "Utah", abbr: "UT" },
  vermont: { name: "Vermont", abbr: "VT" },
  virginia: { name: "Virginia", abbr: "VA" },
  washington: { name: "Washington", abbr: "WA" },
  westVirginia: { name: "West Virginia", abbr: "WV" },
  wisconsin: { name: "Wisconsin", abbr: "WI" },
  wyoming: { name: "Wyoming", abbr: "WY" },
} as const;

export const stateSelectOptions = Object.values(states).map((state) => ({
  label: state.name,
  value: state.abbr,
}));

export const stateAbbrSelectOptions = Object.values(states).map((state) => ({
  label: state.abbr,
  value: state.abbr,
}));
