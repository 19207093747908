import React, { useEffect } from "react";
import { Modal } from "../../components/Modal";
import InputField from "../../components/InputField";
import { Alert, Box, Button, Stack, InputAdornment } from "@mui/material";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Tenant } from "../../types/tenant";
import {
  StateFee,
  getStateFeeTypeDisplayName,
  getStateFeeUomDisplayName,
} from "../../types/stateFee";
import { Controller, useForm } from "react-hook-form";
import SelectField from "../../components/SelectField";
import { stateSelectOptions } from "../../types/states";
import { isValidNumber } from "../../utils/validation";

type NewEditStateFeeModalProps = {
  isOpen: boolean;
  onSaveSuccessful: () => void;
  onClose: () => void;
  stateFee: StateFee | null;
  isNew: boolean;
};

type FormData = Partial<{
  statefeeState: StateFee["statefeeState"];
  statefeeType: StateFee["statefeeType"] | "";
  statefeeAmount: StateFee["statefeeAmount"];
  statefeeUom: StateFee["statefeeUom"] | "";
}>;

const getDefaultValues = (stateFee: StateFee | null): FormData => {
  return {
    statefeeState: stateFee?.statefeeState ?? "",
    statefeeType: stateFee?.statefeeType ?? "",
    statefeeAmount: stateFee?.statefeeAmount,
    statefeeUom: stateFee?.statefeeUom ?? "",
  };
};

export const NewEditStateFeeModal: React.FC<NewEditStateFeeModalProps> = ({
  isOpen,
  onSaveSuccessful,
  onClose,
  stateFee,
  isNew,
}) => {
  const {
    data,
    loading: saveStateFeeLoading,
    request: saveStateFeeRequest,
    status: saveStateFeeStatus,
    error: saveStateFeeError,
  } = useApiRequest<Tenant>(true);

  const { control, handleSubmit, register, reset, formState } =
    useForm<FormData>({
      defaultValues: getDefaultValues(stateFee),
      reValidateMode: "onChange",
      mode: "onChange",
    });

  useEffect(() => {
    if (stateFee) {
      const defaultValues = getDefaultValues(stateFee);
      reset(defaultValues);
    }
  }, [reset, stateFee]);

  const onSubmit = (values: FormData) => {
    if (isNew) {
      saveStateFeeRequest("/company/statefees/add", {
        method: "POST",
        data: values,
      });
    } else {
      saveStateFeeRequest(`/company/statefees/${stateFee?.statefeeGuid}/edit`, {
        method: "POST",
        data: values,
      });
    }
  };

  useEffect(() => {
    if (data && saveStateFeeStatus === "ok" && saveStateFeeLoading === false) {
      onSaveSuccessful();
    }
  }, [data, saveStateFeeLoading, onSaveSuccessful, saveStateFeeStatus]);

  return (
    <Modal
      heading={`${isNew ? "New" : "Edit"} State Fee`}
      isOpen={isOpen}
      onClose={onClose}
    >
      {saveStateFeeError && (
        <Alert severity="error" sx={{ marginBottom: 2 }}>
          {saveStateFeeError.response?.status === 409
            ? "Only one matching State + Construction Type + Per is allowed."
            : "Something went wrong. Please try again."}
        </Alert>
      )}
      <Stack
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        rowGap={3}
        sx={{ width: { xs: "100%", sm: "500px" } }}
      >
        <Controller
          control={control}
          name="statefeeState"
          render={({ field, fieldState }) => (
            <SelectField
              {...field}
              label="State"
              placeholder="Select State"
              required
              fullWidth
              options={stateSelectOptions}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="statefeeType"
          render={({ field, fieldState }) => (
            <SelectField
              {...field}
              label="Construction Type"
              placeholder="Select Type"
              required
              fullWidth
              options={[
                { label: getStateFeeTypeDisplayName("m"), value: "m" },
                { label: getStateFeeTypeDisplayName("d"), value: "d" },
              ]}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
        />
        <Stack direction="row" columnGap={2}>
          <InputField
            {...register("statefeeAmount", {
              valueAsNumber: true,
              required: { value: true, message: "Please enter a value." },
              validate: {
                isNumber: (value) => {
                  return isValidNumber(value) || "Please enter a number.";
                },
              },
            })}
            label="Fee"
            placeholder="Enter Fee"
            required
            fullWidth
            error={!!formState.errors.statefeeAmount}
            helperText={formState.errors.statefeeAmount?.message}
            type="number"
            startAdornment={
              <InputAdornment position="start">$</InputAdornment>
            }
            intOnly={true}
          />
          <Controller
            control={control}
            name="statefeeUom"
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                label="Per"
                placeholder="Select Fee Per"
                required
                fullWidth
                options={[
                  { label: getStateFeeUomDisplayName("f"), value: "f" },
                  { label: getStateFeeUomDisplayName("u"), value: "u" },
                ]}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Stack>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "end" }}>
          <Button variant="outlined" onClick={onClose} sx={{ marginRight: 2 }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={!formState.isValid || saveStateFeeLoading}
          >
            Save
          </Button>
        </Box>
      </Stack>
    </Modal>
  );
};
