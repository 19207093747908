import { Typography } from "@mui/material";
import React from "react";

export const RouteErrorBoundary: React.FC = () => {
  return (
    <Typography>
      An unexpected error has occurred. If the error persists please contact
      support.
    </Typography>
  );
};
