import React, { useMemo } from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useSession } from "../hooks/useSession";
import { canAccessRoute, RouteConfig } from "../routes";

export type SidebarItem = RouteConfig & {
  icon: React.ReactNode;
  iconSecondary?: React.ReactNode;
};

type SidebarLayoutProps = {
  heading: string;
  sidebarItems: SidebarItem[];
};

export const SidebarLayout: React.FC<SidebarLayoutProps> = ({
  heading,
  sidebarItems,
}) => {
  const { user } = useSession();
  const location = useLocation();

  const visibleSidebarItems: SidebarItem[] = useMemo(() => {
    if (!user?.userRole) {
      return [];
    }

    const accessibleSidebarItems = sidebarItems.filter((sidebarItem) => {
      return canAccessRoute(user, sidebarItem);
    });

    return accessibleSidebarItems;
  }, [user, sidebarItems]);

  return (
    <>
      <Grid container spacing={4}>
        <Grid width={{ xs: "100%", sm: "339px" }}>
          {heading && (
            <Typography variant="h5" fontWeight="bold" marginBottom={2}>
              {heading}
            </Typography>
          )}
          <List>
            {visibleSidebarItems.map((item) => (
              <ListItem disablePadding key={item.label}>
                <ListItemButton
                  component={Link}
                  to={item.path}
                  selected={item.path === location.pathname}
                >
                  <ListItemIcon sx={{ minWidth: "auto", marginRight: "8px" }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.label} sx={{ margin: 0 }} />
                  {item.iconSecondary ? (
                    <ListItemIcon sx={{ minWidth: "auto", marginLeft: "8px" }}>
                      {item.iconSecondary}
                    </ListItemIcon>
                  ) : null}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid xs={12} sm paddingTop={0}>
          <Outlet />
        </Grid>
      </Grid>
    </>
  );
};
