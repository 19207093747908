import { useEffect } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useSession } from "../hooks/useSession";
import { routes } from "../routes";

export default function HomeRedirect() {
  const navigate = useNavigate();
  const { user } = useSession();

  useEffect(() => {
    let route: string = routes.home.path;
    switch (user?.userRole) {
      case "sysadmin":
        route = routes.manageSystemAdmins.path;
        break;
      case "owner":
      case "basicuser":
      case "advanceduser":
        route = generatePath(routes.quotesAndOrders.path, {
          tenantGuid: user?.tenantGuid,
        });
        break;
    }
    navigate(route);
  }, [navigate, user?.userRole, user?.tenantGuid]);
  return null;
}
