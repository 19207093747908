// https://mui.com/material-ui/customization/creating-themed-components/
import React from "react";
import { Button, styled } from "@mui/material";
import {
  ComponentsOverrides,
  ComponentsVariants,
  Theme as MuiTheme,
} from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

type Theme = Omit<MuiTheme, "components">;

declare module "@mui/material/styles" {
  interface ComponentNameToClassKey {
    FilterButton: "root";
  }

  interface ComponentsPropsList {
    FilterButton: Partial<FilterButtonProps>;
  }

  interface Components {
    FilterButton?: {
      defaultProps?: ComponentsPropsList["FilterButton"];
      styleOverrides?: ComponentsOverrides<Theme>["FilterButton"];
      variants?: ComponentsVariants["FilterButton"];
    };
  }
}

type FilterButtonRootProps = {
  active?: string;
};

const FilterButtonRoot = styled(Button, {
  name: "FilterButton",
  slot: "root",
})<FilterButtonRootProps>(({ theme, active }) => ({
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#F9F5FF",
  },
  color: active ? theme.palette.primary.main : theme.palette.text.primary,
  borderColor: !active ? "#D0D5DD" : "transparent",
  borderWidth: 2,
  borderStyle: "solid",
  backgroundColor: active ? theme.palette.primary.light : "white",
  "&.Mui-disabled": {
    "&.MuiButton-colorPrimary": {
      color: theme.palette.grey['300'],
      backgroundColor: "white",
      borderColor: theme.palette.grey['200'],
    },
  },
}));

type FilterButtonProps = {
  children: React.ReactNode;
  active?: boolean;
  icon?: React.ReactNode;
} & React.ComponentProps<typeof Button>;

export const FilterToggleButton = ({
  active,
  children,
  icon,
  ...props
}: FilterButtonProps) => {
  return (
    <FilterButtonRoot
      active={active ? "true" : undefined}
      startIcon={icon}
      endIcon={active && <CloseIcon />}
      {...props}
    >
      {children}
    </FilterButtonRoot>
  );
};
