export type AttributeSelection = {
  attributeselectionGuid: string;
  attributeselectionName: string;
  sortOrder: number;
  attributeselectionPrice: number;
  attributeselectionImageUrl: string | null;
  startDate: number;
  endDate: number | null;
};

export const getAttributeSelectionImageUrl = (
  attributeSelection?: Pick<AttributeSelection, "attributeselectionImageUrl">
): string => {
  return attributeSelection?.attributeselectionImageUrl
    ? `${process.env.REACT_APP_API_BASE_URL}/${attributeSelection.attributeselectionImageUrl}`
    : "/no-image.png";
};
