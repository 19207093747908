import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "../../components/Modal";
import InputField from "../../components/InputField";
import { User } from "../../types/user";
import { Alert, Box, Button } from "@mui/material";
import { useApiRequest } from "../../hooks/useApiRequest";
import { PasswordInput } from "../../components/PasswordInput";

type NewEditModalProps = {
  isOpen: boolean;
  onSaveSuccessful: () => void;
  onClose: () => void;
  user: Partial<User> | null;
  isNew: boolean;
  isCurrentUser: boolean;
};

const defaultFormData = {
  userFirstName: "",
  userLastName: "",
  userPhoneNumber: "",
  userEmail: "",
  userPassword: "",
};

export const NewEditModal: React.FC<NewEditModalProps> = ({
  isOpen,
  onSaveSuccessful,
  onClose,
  user,
  isNew,
  isCurrentUser,
}) => {
  const [formData, setFormData] = useState<Partial<User>>(defaultFormData);
  const { data, loading, request, status, error } = useApiRequest<User>(true);

  const isPhoneNumberValid = useMemo(
    () => !!formData.userPhoneNumber?.match(/^\d{10}$/),
    [formData.userPhoneNumber]
  );
  const isValidEmail = useMemo(
    () => formData.userEmail?.match(/[^@]+@[^@]+\.[^@]+/),
    [formData.userEmail]
  );

  const handleChange =
    (prop: keyof typeof formData) =>
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setFormData({ ...formData, [prop]: event.target.value });
    };

  useEffect(() => {
    if (isNew) {
      setFormData(defaultFormData);
    } else if (user) {
      setFormData({ ...user, userPassword: "" });
    }
  }, [user, isNew]);

  const isValid = useCallback(() => {
    if (isNew) {
      return (
        formData.userFirstName &&
        formData.userLastName &&
        isPhoneNumberValid &&
        isValidEmail &&
        formData.userPassword
      );
    } else {
      return (
        formData.userFirstName &&
        formData.userLastName &&
        isPhoneNumberValid &&
        isValidEmail
      );
    }
  }, [formData, isNew, isPhoneNumberValid, isValidEmail]);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (isNew) {
      // Create new user
      request("/sysadmins/add", {
        method: "POST",
        data: {
          ...formData,
          userRole: "sysadmin",
        },
      });
    } else {
      request("/users/me/edit", {
        method: "POST",
        data: {
          ...formData,
          userGuid: user?.userGuid,
        },
      });
    }
  };

  useEffect(() => {
    if (data && status === "ok" && loading === false) {
      onSaveSuccessful();
    }
  }, [data, onSaveSuccessful, status, loading]);

  return (
    <Modal
      heading={`${isNew ? "Create" : "Edit"} Super Admin`}
      isOpen={isOpen}
      onClose={onClose}
    >
      {isCurrentUser && (
        <Alert severity="warning" sx={{ marginBottom: 2 }}>
          Warning! Editing your own account can potentially prevent you from
          logging in.
        </Alert>
      )}
      {error && (
        <Alert severity="error" sx={{ marginBottom: 2 }}>
          Something went wrong. Please try again.
        </Alert>
      )}
      <form onSubmit={onSubmit}>
        <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
          <InputField
            fullWidth
            label="First Name"
            value={formData.userFirstName}
            onChange={handleChange("userFirstName")}
          />
          <InputField
            fullWidth
            label="Last Name"
            value={formData.userLastName}
            onChange={handleChange("userLastName")}
          />
        </Box>
        <InputField
          fullWidth
          label="Phone"
          error={!!formData.userPhoneNumber && !isPhoneNumberValid}
          value={formData.userPhoneNumber}
          onChange={handleChange("userPhoneNumber")}
          sx={{ marginBottom: 2 }}
        />
        <InputField
          fullWidth
          fixFor="username"
          label="Email"
          error={!!formData.userEmail && !isValidEmail}
          value={formData.userEmail}
          onChange={handleChange("userEmail")}
          sx={{ marginBottom: 2 }}
        />
        <PasswordInput
          fullWidth
          fixFor="new-password"
          label="Password"
          value={formData.userPassword || ""}
          onChange={handleChange("userPassword")}
          sx={{ marginBottom: 2 }}
        />
        <Box sx={{ mt: 2, display: "flex", justifyContent: "end" }}>
          <Button variant="outlined" onClick={onClose} sx={{ marginRight: 2 }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={!isValid()}
          >
            Save
          </Button>
        </Box>
      </form>
    </Modal>
  );
};
