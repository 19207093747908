import React from "react";
import { Modal } from "./Modal";
import { Box, Stack, Button } from "@mui/material";

interface ConfirmModalProps {
  isOpen: boolean;
  heading: string;
  body: React.ReactNode;
  confirmButtonText: string;
  cancelButtonText?: string;
  isDestructive?: boolean;
  onConfirm: () => void;
  onClose: () => void;
  confirmDisabled?: boolean;
  confirmOnly?: boolean;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  isOpen,
  heading,
  body,
  confirmButtonText,
  cancelButtonText = "Cancel",
  onConfirm,
  isDestructive,
  confirmDisabled = false,
  onClose,
  confirmOnly = false,
}) => {
  return (
    <Modal heading={heading} isOpen={isOpen} onClose={onClose}>
      <Box marginTop={-2} width={{ xs: "100%", sm: "500px" }}>
        {body}
      </Box>
      <Stack spacing={1} direction="row-reverse" marginTop={3}>
        <Button
          disabled={confirmDisabled}
          variant="contained"
          color={isDestructive ? "error" : "primary"}
          onClick={onConfirm}
        >
          {confirmButtonText}
        </Button>
        {!confirmOnly && (
          <Button
            variant="outlined"
            onClick={onClose}
            sx={{ marginLeft: "auto" }}
          >
            {cancelButtonText}
          </Button>
        )}
      </Stack>
    </Modal>
  );
};
