import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import { Order } from "../../types/order";
import InputField from "../../components/InputField";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";
import { useApiRequest } from "../../hooks/useApiRequest";
import {
  getUserFullName,
  getUserInitials,
  getUserPhotoUrlSrc,
} from "../../types/user";
import { formatAsDate } from "../../utils/date";
import { OrderNote } from "../../types/orderNote";

interface OrderNotesProps {
  order: Order | null;
}

export const OrderNotes: React.FC<OrderNotesProps> = ({
  order,
}) => {
  const { showErrorToast } = useGlobalToastNotificationContext();
  const [noteText, setNoteText] = useState<string>("");

  const {
    data: addNoteData,
    loading: addNoteLoading,
    request: addNoteRequest,
    status: addNoteStatus,
    error: addNoteError,
  } = useApiRequest<OrderNote>(false);

  const [orderNotes, setOrderNotes] = useState<OrderNote[]>([]);
  const [didLoadNotes, setDidLoadNotes] = useState<boolean>(false);

  useEffect(() => {
    if (addNoteData && addNoteStatus === "ok" && addNoteLoading === false) {
      setOrderNotes((prevOrderNotes) => {
        if (prevOrderNotes.includes(addNoteData)) {
          return prevOrderNotes;
        }
        return [...prevOrderNotes, addNoteData];
      });
    } else if (addNoteError) {
      showErrorToast({ message: "Something went wrong. Please try again.." });
    }
    setNoteText("");
  }, [
    showErrorToast,
    addNoteData,
    addNoteError,
    addNoteLoading,
    addNoteStatus,
    orderNotes,
  ]);

  const handleAddNoteClick = () => {
    if (!noteText) {
      return;
    }
    addNoteRequest(`orders/${order?.orderGuid}/notes/add`, {
      method: "POST",
      data: {
        noteText: noteText,
      },
    });
  };

  const {
    data: orderNotesData,
    loading: orderNotesLoading,
    request: orderNotesRequest,
  } = useApiRequest<OrderNote[]>();

  useEffect(() => {
    if (!order) return;
    orderNotesRequest(`/orders/${order.orderGuid}/notes`, {
      method: "GET",
    });
  }, [orderNotesRequest, order]);

  useEffect(() => {
    if (orderNotesData && !didLoadNotes) {
      setDidLoadNotes(true);
      setOrderNotes(orderNotesData);
    }
  },[orderNotesData, orderNotes, didLoadNotes]);

  return (
    <Box>
      <Typography variant="body1" fontWeight={600} mb={1}>
        Comments
      </Typography>
      <Card variant="outlined">
        {orderNotesLoading ? (
          <Typography fontWeight="500">
            Loading
          </Typography>        
        ) : (
          <CardContent component={Stack} spacing={2}>
          {orderNotes?.map((note) => {
            return (
              <Box key={note.orderNoteGuid}>
                <Stack direction="row" justifyContent="space-between">
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Avatar
                      sx={{ height: 20, width: 20, fontSize: "10px" }}
                      src={getUserPhotoUrlSrc(note.user)}
                    >
                      {getUserInitials(note.user)}
                    </Avatar>
                    <Typography fontWeight="500">
                      {getUserFullName(note.user)}
                    </Typography>
                  </Stack>
                  <Typography color="grey.500">
                    {formatAsDate(note.createdTimestamp)}
                  </Typography>
                </Stack>
                <Typography
                  variant="body2"
                  color="text.primary"
                  marginTop={1}
                  whiteSpace="pre-wrap"
                >
                  {note.noteText}
                </Typography>
              </Box>
            );
          })}
          <InputField
            fullWidth
            label=""
            multiline
            minRows={3}
            maxRows={6}
            placeholder="Enter comment..."
            value={noteText}
            onChange={(e) => setNoteText(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            disabled={noteText.length === 0 || addNoteLoading}
            onClick={handleAddNoteClick}
          >
            Add Comment
          </Button>
          </CardContent>
      )}
      </Card>
    </Box>
  );
};
