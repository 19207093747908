import { AxiosResponse } from "axios";

export const printFile = (blobData: string) => {
  const blob = new Blob([blobData], { type: "application/pdf" });
  const url = window.URL.createObjectURL(blob);

  const iframe = document.createElement("iframe");
  iframe.style.display = "none";
  iframe.src = url;
  document.body.appendChild(iframe);

  iframe.onload = function () {
    try {
      if (iframe.contentWindow) {
        // wait just a sec to allow the contentWindow in safari to be ready.
        // otherwise, its possible the print will just show a blank screen
        // and not contain all of the pages.
        setTimeout(function () {
          iframe?.contentWindow?.print();
        }, 1);
      } else {
        throw new Error("contentWindow is null");
      }
    } catch (e) {
      window.open(url, "_blank");
    }
  };
};

export const downloadFile = (blobData: string, blobResponse: AxiosResponse) => {
  const blob = new Blob([blobData], { type: "application/pdf" });
  downloadBlobFile(blob, blobResponse);
};

export const downloadBlobFile = (blob: Blob, blobResponse: AxiosResponse) => {
  const url = window.URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  const filename = blobResponse?.headers["content-disposition"]
    ?.split("filename=")[1]
    .replace(/"/g, "");
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  setTimeout(() => {
    window.URL.revokeObjectURL(url);
  }, 100);
};
