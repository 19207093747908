import React, { useRef, useEffect, useState, useCallback } from "react";
import { useDroppable } from "@dnd-kit/core";
import { Box, Chip, Stack, Typography } from "@mui/material";

export interface ContainerProps {
  id: string;
  heading: string;
  children: React.ReactNode;
  count: number;
  isOver: boolean;
  hidden?: boolean;
}

export const DroppableContainer = ({
  id,
  heading,
  count,
  children,
  isOver,
  hidden,
}: ContainerProps) => {
  const { setNodeRef } = useDroppable({ id });
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [minHeight, setMinHeight] = useState<number | null>(null);

  const updateMinHeight = useCallback(() => {
    if (containerRef.current) {
      const newHeight = containerRef.current.offsetHeight;
      setMinHeight((prevHeight) => Math.max(prevHeight || 0, newHeight));
    }
  }, []);

  useEffect(() => {
    updateMinHeight();
  }, [count, updateMinHeight]);

  useEffect(() => {
    if (containerRef.current && !minHeight) {
      setMinHeight(containerRef.current.offsetHeight);
    }
  }, [children, minHeight]);

  const setRefs = useCallback(
    (node: HTMLDivElement | null) => {
      containerRef.current = node;
      setNodeRef(node);
    },
    [setNodeRef]
  );

  return (
    <Stack
      sx={{
        width: hidden ? 0 : 370,
        flexShrink: 0,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          backgroundColor: (theme) => theme.palette.grey[100],
          padding: 2,
        }}
      >
        <Stack direction="row" spacing={1}>
          <Typography variant="h6">{heading}</Typography>
          <Chip label={count} variant="filled" />
        </Stack>
      </Box>
      <Box
        ref={setRefs}
        sx={{
          backgroundColor: (theme) =>
            isOver ? theme.palette.primary.light : theme.palette.grey[50],
          borderRadius: 2,
          marginRight: 2,
          padding: 2,
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          minHeight: minHeight ? `${minHeight}px` : "auto",
        }}
      >
        {children}
      </Box>
    </Stack>
  );
};
