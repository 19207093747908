import { Attachment } from "./attachment";
import { RetailerNote } from "./retailerNote";

export type RetailerManufacturer = {
  manufacturerSalesRepUserGuid: string | null;
  manufacturerRetailerIdentifier: string;
  manufacturerDefaultVepLevel: 0 | 1 | 2 | 3;
  isActive: boolean;
  retailerNotes: RetailerNote[];
  attachments: Attachment[];
  percentPerBasePrice: number;
  amountPerFloor: number;
  amountPerUnit: number;
};

export type ManufacturerDefaultVepLevel =
  RetailerManufacturer["manufacturerDefaultVepLevel"];

const manufacturerDefaultVepLevels: ManufacturerDefaultVepLevel[] = [
  0, 1, 2, 3,
];

export const getManufacturerDefaultVepLevelDisplayName = (
  manufacturerDefaultVepLevel: ManufacturerDefaultVepLevel
): string => {
  const displayName: Record<ManufacturerDefaultVepLevel, string> = {
    0: "VEP 0",
    1: "VEP 1",
    2: "VEP 2",
    3: "VEP 3",
  };
  return displayName[manufacturerDefaultVepLevel];
};

export const manufacturerDefaultVepLevelOptions =
  manufacturerDefaultVepLevels.map((m) => ({
    label: getManufacturerDefaultVepLevelDisplayName(m),
    value: m,
  }));
