import React, { useEffect } from "react";
import { Modal } from "../../components/Modal";
import InputField from "../../components/InputField";
import { Alert, Box, Button, Stack } from "@mui/material";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Attribute, attributeNameMaxLength } from "../../types/attribute";
import CheckboxField from "../../components/CheckboxField";
import { AttributePresentAsRadioGroup } from "../../components/AttributePresentAsRadioGroup";

type FormData = {
  attributeName: string;
  attributePresentAs: "d" | "s";
  attributeIncludeImages: boolean;
  attributeSelectIsRequired: boolean;
};

type NewEditAttributeModalProps = {
  isOpen: boolean;
  onSaveSuccessful: (attributeGuid: string) => void;
  onClose: () => void;
  attribute: Attribute | null;
  isNew: boolean;
};

const getDefaultValues = (attribute: Attribute | null): FormData => {
  return {
    attributeName: attribute?.attributeName ?? "",
    attributePresentAs: attribute?.attributePresentAs ?? "d",
    attributeIncludeImages: attribute?.attributeIncludeImages ?? false,
    attributeSelectIsRequired: attribute?.attributeSelectIsRequired ?? false,
  };
};

export const NewEditAttributeModal: React.FC<NewEditAttributeModalProps> = ({
  isOpen,
  onSaveSuccessful,
  onClose,
  attribute,
  isNew,
}) => {
  const {
    data: saveAttributeData,
    loading: saveAttributeLoading,
    request: saveAttributeRequest,
    status: saveAttributeStatus,
    error: saveAttributeError,
  } = useApiRequest<Attribute>(true);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: getDefaultValues(attribute),
  });

  const onSubmit: SubmitHandler<FormData> = (data: FormData) => {
    if (isNew) {
      saveAttributeRequest("/company/attributes/add", {
        method: "POST",
        data,
      });
    } else {
      saveAttributeRequest(
        `company/attributes/${attribute?.attributeGuid}/edit`,
        {
          method: "POST",
          data,
        }
      );
    }
  };

  useEffect(() => {
    if (
      saveAttributeData &&
      saveAttributeStatus === "ok" &&
      saveAttributeLoading === false
    ) {
      onSaveSuccessful(saveAttributeData.attributeGuid);
    }
  }, [
    saveAttributeData,
    onSaveSuccessful,
    saveAttributeStatus,
    saveAttributeLoading,
  ]);

  return (
    <Modal
      heading={isNew ? "New Attribute" : "Edit Attribute"}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box sx={{ width: { xs: "100%", sm: "500px" } }}>
        {saveAttributeError && (
          <Alert severity="error" sx={{ marginBottom: 2 }}>
            {saveAttributeError.response?.status === 409
              ? "An attribute already exists with this name."
              : "Something went wrong. Please try again."}
          </Alert>
        )}
        <Stack component="form" onSubmit={handleSubmit(onSubmit)} spacing={3}>
          <InputField
            label="Attribute Name"
            placeholder="Enter name"
            fullWidth
            required
            {...register("attributeName", {
              required: "Enter name",
              maxLength: {
                value: attributeNameMaxLength,
                message: `Attribute name can only be ${attributeNameMaxLength} characters.`,
              },
            })}
            error={!!errors.attributeName}
            helperText={errors.attributeName?.message}
          />

          <Controller
            name="attributePresentAs"
            control={control}
            render={({ field }) => <AttributePresentAsRadioGroup {...field} />}
          />

          <Controller
            name="attributeIncludeImages"
            control={control}
            render={({ field }) => (
              <CheckboxField
                {...field}
                label="Include option image"
                checked={field.value}
              />
            )}
          />
          <Controller
            name="attributeSelectIsRequired"
            control={control}
            render={({ field }) => (
              <CheckboxField
                {...field}
                label="Selection is required"
                checked={field.value}
              />
            )}
          />

          <Stack direction="row" justifyContent="flex-end">
            <Button
              variant="outlined"
              onClick={onClose}
              sx={{ marginRight: 2 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={saveAttributeLoading}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};
