export type TenantFreight = {
  tenantFreightCost14: number;
  tenantFreightCost16: number;
  tenantCostPerMile: number;
  tenantMileageCostCap: number;
};

export const getTenantFreightCostDisplayName = (
  tenantFreightKey: keyof TenantFreight
) => {
  const displayNames: Record<keyof TenantFreight, string> = {
    tenantFreightCost14: "Cost/14' Floor",
    tenantFreightCost16: "Cost/16' Floor",
    tenantCostPerMile: "Cost/Mile",
    tenantMileageCostCap: "Mileage Cost Cap",
  };

  return displayNames[tenantFreightKey] || tenantFreightKey;
};
