import React, { useState } from "react";
import { IconButton, InputAdornment } from "@mui/material";
import InputField, { InputFieldProps } from "./InputField";
import { Visibility, VisibilityOff } from "@mui/icons-material";

type PasswordInputProps = Omit<InputFieldProps, "label"> & {
  value?: string;
  label?: string;
  fixFor?: "new-password" | "password";
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

export const PasswordInput = React.forwardRef<
  HTMLInputElement,
  PasswordInputProps
>(({ value, onChange, fixFor, label = "Password", ...props }, ref) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <InputField
      {...props}
      ref={ref}
      label={label}
      fixFor={fixFor}
      type={showPassword ? "text" : "password"}
      value={value}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            tabIndex={-1}
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      }
      onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
        onChange(event)
      }
    />
  );
});
