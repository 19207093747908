import WorkspacesIcon from "@mui/icons-material/Workspaces";

interface LogicTextSubgroupProps {
  subgroupName: string;
}

export const LogicTextSubgroup: React.FC<LogicTextSubgroupProps> = ({
  subgroupName,
}) => {
  return (
    <>
      <WorkspacesIcon color="primary" sx={{ marginRight: 1 }} />
      {subgroupName}
    </>
  );
};
