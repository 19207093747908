import React, { useState } from "react";
import { Divider, IconButton, Stack, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { formatAsCurrency } from "../../utils/number";
import {
  TenantFreight,
  getTenantFreightCostDisplayName,
} from "../../types/tenantFreight";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";
import { TenantFreightDefaultsModal } from "./TenantFreightDefaultsModal";

interface TenantFreightDefaultsProps {
  tenantFreight: TenantFreight;
  onTenantFreightChange: (tenantFreight: TenantFreight) => void;
}

export const TenantFreightDefaults: React.FC<TenantFreightDefaultsProps> = ({
  tenantFreight,
  onTenantFreightChange,
}) => {
  const { showSuccessToast } = useGlobalToastNotificationContext();
  const [selectedFreightCost, setSelectedFreightCost] = useState<
    keyof TenantFreight | undefined
  >();

  const handleEdit = (tenantFreightCost: keyof TenantFreight) => () => {
    setSelectedFreightCost(tenantFreightCost);
  };

  const handleSaveSuccessful = (updatedTenantFreight: TenantFreight) => {
    showSuccessToast({ message: "Tenant freight cost updated!" });
    handleClose();
    onTenantFreightChange(updatedTenantFreight);
  };

  const handleClose = () => {
    setSelectedFreightCost(undefined);
  };

  return tenantFreight ? (
    <>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
      >
        {(
          [
            "tenantFreightCost14",
            "tenantFreightCost16",
            "tenantCostPerMile",
            "tenantMileageCostCap",
          ] as const
        ).map((key: keyof TenantFreight) => {
          return (
            <Stack alignItems="flex-end" key={key}>
              <Typography variant="caption" color="grey.500">
                {getTenantFreightCostDisplayName(key)}
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing="4px"
              >
                <Typography
                  variant="body2"
                  color="text.primary"
                  fontWeight={500}
                >
                  {formatAsCurrency(tenantFreight[key])}
                </Typography>
                <IconButton
                  aria-label="edit"
                  color="primary"
                  sx={{ padding: 0 }}
                  onClick={handleEdit(key)}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Stack>
            </Stack>
          );
        })}
      </Stack>
      {selectedFreightCost && (
        <TenantFreightDefaultsModal
          tenantFreight={tenantFreight}
          tenantFreightKey={selectedFreightCost}
          isOpen={selectedFreightCost != null}
          onSaveSuccessful={handleSaveSuccessful}
          onClose={handleClose}
        />
      )}
    </>
  ) : null;
};
