export const UserRole = {
  SYSADMIN: "sysadmin",
  OWNER: "owner",
  ADMIN: "admin",
  ADVANCEDUSER: "advanceduser",
  BASICUSER: "basicuser",
} as const;

export type UserRoles = (typeof UserRole)[keyof typeof UserRole];

export const getUserRoleDisplayName = (userRole: UserRoles) => {
  const displayNames: Record<UserRoles, string> = {
    [UserRole.SYSADMIN]: "System Admin",
    [UserRole.OWNER]: "Owner",
    [UserRole.ADMIN]: "Admin",
    [UserRole.ADVANCEDUSER]: "Advanced User",
    [UserRole.BASICUSER]: "Basic User",
  };

  return displayNames[userRole] || userRole;
};
