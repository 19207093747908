import { OrderCardError } from "./orderCard";
import { orderCardTypes } from "./orderCardTypes";

export type OrderCardAddSalesChangeOrder = {
  orderCardGuid: string;
  orderCardType: (typeof orderCardTypes)["addSalesChangeOrder"];
  categoryName: string;
  groupName: string;
  subgroupName: string;
  isRequired: undefined;
  isApproved: undefined;
  isHidden: undefined;
  isLocked: boolean;
  orderCardStatus: undefined;
  sortOrder: number;
  errors: OrderCardError[];
  cardData: undefined;
};

export const getOrderCardAddSalesChangeOrder = (
  orderCard?: Partial<OrderCardAddSalesChangeOrder>
): OrderCardAddSalesChangeOrder => {
  return {
    orderCardGuid: "add-sales-change-order",
    orderCardType: orderCardTypes.addSalesChangeOrder,
    categoryName: "Sales Change Orders",
    groupName: "Sales Change Orders",
    subgroupName: "Sales Change Orders",
    isRequired: undefined,
    isApproved: undefined,
    isHidden: undefined,
    isLocked: false,
    orderCardStatus: undefined,
    sortOrder: 1000000,
    errors: [],
    cardData: undefined,
    ...orderCard,
  };
};
