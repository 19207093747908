import { useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import {
  getOrderApplianceOptions,
  getOrderVendorOptions,
  type OrderCardAppliance as OrderCardAppliancesType,
} from "../../types/orderCardAppliance";
import { OrderCardBase } from "./OrderCardBase";
import { useOrderCard } from "./OrderCardContext";
import InputField from "../../components/InputField";
import { Controller, useForm } from "react-hook-form";
import SelectField from "../../components/SelectField";
import { OrderCardActionButton } from "./OrderCardActionButton";
import { Order } from "../../types/order";
import { useApiRequest } from "../../hooks/useApiRequest";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";
import { ConfirmModal } from "../../components/ConfirmModal";
import { Typography } from "@mui/material";
import { getOrderCardError } from "../../types/orderCard";
import { useSession } from "../../hooks/useSession";

type FormData = {
  appliance: string | null;
  vendorGuid: string | null;
  modelNumber: string | null;
  serialNumber: string | null;
};

const getDefaultValues = (
  orderCard: OrderCardAppliancesType | null
): FormData => {
  return {
    appliance: orderCard?.cardData.appliance ?? null,
    vendorGuid: orderCard?.cardData.vendorGuid ?? null,
    modelNumber: orderCard?.cardData.modelNumber ?? null,
    serialNumber: orderCard?.cardData.serialNumber ?? null,
  };
};

interface OrderCardApplianceProps {
  order: Order;
  orderCard: OrderCardAppliancesType;
  expanded: boolean;
  groupIndex: number;
  refreshOrder: () => void;
}

export const OrderCardAppliance: React.FC<OrderCardApplianceProps> = ({
  order,
  orderCard,
  expanded,
  groupIndex,
  refreshOrder,
}) => {
  const [pendingDeletion, setPendingDeletion] = useState(false);
  const { saveOrderCard, setEditingOrderCard } = useOrderCard();
  const { showErrorToast } = useGlobalToastNotificationContext();
  const {
    status: deleteStatus,
    request: deleteRequest,
    loading: deleteLoading,
  } = useApiRequest();

  const disabled = orderCard.isLocked;

  const { user } = useSession();

  const { control, register, getValues, setError, reset } = useForm<FormData>({
    defaultValues: getDefaultValues(orderCard),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  useEffect(() => {
    reset(getDefaultValues(orderCard), { keepDirtyValues: true });
  }, [orderCard, reset]);

  useEffect(() => {
    if (orderCard.errors.length === 0) return;

    if (!!getOrderCardError(orderCard, "eiav")) {
      setError("vendorGuid", {
        type: "manual",
        message: "Inactive",
      });
    }
  }, [setError, orderCard]);

  const applianceOptions = useMemo(
    () => getOrderApplianceOptions(orderCard),
    [orderCard]
  );
  const vendorOptions = useMemo(
    () => getOrderVendorOptions(orderCard),
    [orderCard]
  );

  const handleBlurField = () => {
    saveOrderCard(orderCard.orderCardGuid, getValues());
    setEditingOrderCard(null);
  };

  const handleEditingField = () => {
    setEditingOrderCard(orderCard);
  };

  const handleDeleteConfirm = () => {
    deleteRequest(`/ordercards/${orderCard.orderCardGuid}/delete`, {
      method: "DELETE",
    });
  };

  const { request, data, loading, errorMessage } = useApiRequest();

  const handleAddAppliance = () => {
    request(`/orders/${order.orderGuid}/appliances/add`, {
      method: "GET",
    });
  };

  useEffect(() => {
    if (deleteStatus === "ok") {
      refreshOrder();
    }
  }, [deleteStatus, refreshOrder]);

  useEffect(() => {
    if (errorMessage) {
      showErrorToast({ message: errorMessage });
    }
  }, [errorMessage, showErrorToast]);

  useEffect(() => {
    if (data) {
      refreshOrder();
    }
  }, [data, refreshOrder]);

  return (
    <>
      <OrderCardBase
        disabled={disabled}
        orderCard={orderCard}
        onDeleteClick={() => setPendingDeletion(true)}
        expanded={expanded}
        groupIndex={groupIndex}
        action={
          !disabled &&
          !user?.isRetailUser && (
            <OrderCardActionButton
              disabled={loading}
              onClick={handleAddAppliance}
            >
              Add Appliance
            </OrderCardActionButton>
          )
        }
      >
        <Grid container spacing={2} component="form">
          <Grid xs={12} sm={12} md={6}>
            <Controller
              control={control}
              name="appliance"
              render={({ field, fieldState }) => (
                <SelectField
                  {...field}
                  label="Appliance"
                  placeholder="Choose Appliance"
                  onFocus={handleEditingField}
                  fullWidth
                  required
                  disabled={disabled}
                  options={applianceOptions}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  value={field.value || ""}
                  onBlur={() => {
                    handleBlurField();
                    field.onBlur();
                  }}
                />
              )}
            />
          </Grid>
          <Grid xs={12} sm={12} md={6}>
            <Controller
              control={control}
              name="vendorGuid"
              render={({ field, fieldState }) => (
                <SelectField
                  {...field}
                  label="Vendor"
                  placeholder={
                    vendorOptions.length > 0
                      ? "Choose Vendor"
                      : "No Vendors Available"
                  }
                  onFocus={handleEditingField}
                  required
                  fullWidth
                  disabled={disabled || vendorOptions.length === 0}
                  options={vendorOptions}
                  value={field.value ?? ""}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  onBlur={() => {
                    handleBlurField();
                    field.onBlur();
                  }}
                />
              )}
            />
          </Grid>
          <Grid xs={12} sm={12} md={6}>
            <InputField
              {...register("modelNumber", { onBlur: handleBlurField })}
              label="Model Number"
              placeholder="Enter Model Number"
              onFocus={handleEditingField}
              fullWidth
              required
              disabled={disabled}
              sx={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid xs={12} sm={12} md={6}>
            <InputField
              {...register("serialNumber", { onBlur: handleBlurField })}
              label="Serial Number"
              placeholder="Enter Serial Number"
              onFocus={handleEditingField}
              fullWidth
              required
              disabled={disabled}
              sx={{ marginBottom: 2 }}
            />
          </Grid>
        </Grid>
      </OrderCardBase>
      {pendingDeletion && (
        <ConfirmModal
          isOpen={true}
          heading={"Delete this appliance?"}
          isDestructive
          confirmDisabled={deleteLoading}
          body={
            <>
              <Typography variant="body2" color="grey.500">
                Deleting this appliance will remove it from the Dataplate,
                resulting in its exclusion from the Order.
              </Typography>
              <br />
              <Typography variant="body2" color="grey.500">
                Deleting this appliance cannot be undone.
              </Typography>
            </>
          }
          confirmButtonText={"Delete"}
          onConfirm={handleDeleteConfirm}
          onClose={() => {
            setPendingDeletion(false);
          }}
        />
      )}
    </>
  );
};
