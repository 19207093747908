import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";
import { useApiRequest } from "../../hooks/useApiRequest";
import { areOrderCardsEqual, OrderCard } from "../../types/orderCard";
import { OrderCardEditResponse } from "./orderCardEditResponse";

interface OrderCardContextType {
  isAutoSaved: boolean;
  onCardInView: (orderCard: OrderCard) => void;
  setEditingOrderCard: (orderCard: OrderCard | null) => void;
  saveOrderCard: (
    orderCardGuid: string,
    cardData: OrderCard["cardData"],
    subgroupName?: string
  ) => void;
  jumpToCardGuid?: string | null;
}

const OrderCardContext = createContext<OrderCardContextType>({
  isAutoSaved: false,
  onCardInView: () => {},
  setEditingOrderCard: () => {},
  saveOrderCard: () => {},
  jumpToCardGuid: null,
});

export const useOrderCard = () => {
  const context = useContext(OrderCardContext);
  if (!context) {
    throw new Error("OrderCardProvider is required");
  }
  return context;
};

export const OrderCardProvider: React.FC<{
  onCurrentCardInViewChange: (orderCardGuid: OrderCard) => void;
  onCurrentEditingOrderCardChange: (orderCardGuid: OrderCard | null) => void;
  onOrderCardEdited: (
    orderCardEditResponse: OrderCardEditResponse<OrderCard>
  ) => void;
  children: ReactNode;
  jumpToCardGuid?: string | null;
  orderCard: OrderCard | null;
}> = ({
  onOrderCardEdited,
  onCurrentCardInViewChange,
  onCurrentEditingOrderCardChange,
  jumpToCardGuid,
  orderCard,
  children,
}) => {
  const { showErrorToast } = useGlobalToastNotificationContext();
  const [isAutoSaved, setIsAutoSaved] = useState(false);

  const {
    data: saveOrderCardData,
    loading: saveOrderCardLoading,
    request: saveOrderCardRequest,
    status: saveOrderCardStatus,
    errorMessage: saveOrderCardErrorMessage,
  } = useApiRequest<OrderCardEditResponse<OrderCard>>(false, "orderCard");

  useEffect(() => {
    if (
      saveOrderCardData &&
      saveOrderCardStatus === "ok" &&
      saveOrderCardLoading === false
    ) {
      onOrderCardEdited(saveOrderCardData);

      setIsAutoSaved(true);

      const timeout = setTimeout(() => {
        setIsAutoSaved(false);
      }, 500);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [
    onOrderCardEdited,
    saveOrderCardData,
    saveOrderCardLoading,
    saveOrderCardStatus,
  ]);

  useEffect(() => {
    if (saveOrderCardErrorMessage) {
      showErrorToast({ message: saveOrderCardErrorMessage });
    }
  }, [saveOrderCardErrorMessage, showErrorToast]);

  const saveOrderCard = useCallback(
    (
      orderCardGuid: string,
      cardData: OrderCard["cardData"],
      subgroupName?: string
    ) => {
      if (
        (orderCard?.cardData &&
          cardData &&
          !areOrderCardsEqual(cardData, orderCard.cardData)) ||
        (!!subgroupName && orderCard?.subgroupName !== subgroupName)
      ) {
        saveOrderCardRequest(`/ordercards/${orderCardGuid}/edit`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          data: { cardData: cardData, subgroupName },
        });
      }
    },
    [saveOrderCardRequest, orderCard]
  );

  const onCardInView = useCallback(
    (orderCard: OrderCard) => {
      onCurrentCardInViewChange(orderCard);
    },
    [onCurrentCardInViewChange]
  );

  const setEditingOrderCard = useCallback(
    (orderCard: OrderCard | null) => {
      onCurrentEditingOrderCardChange(orderCard);
    },
    [onCurrentEditingOrderCardChange]
  );

  const contextValue = useMemo(
    () => ({
      isAutoSaved,
      onCardInView,
      saveOrderCard,
      setEditingOrderCard,
      jumpToCardGuid,
      orderCard,
    }),
    [
      isAutoSaved,
      onCardInView,
      saveOrderCard,
      setEditingOrderCard,
      jumpToCardGuid,
      orderCard,
    ]
  );

  return (
    <OrderCardContext.Provider value={contextValue}>
      {children}
    </OrderCardContext.Provider>
  );
};
