import { Divider, Stack, Typography } from "@mui/material";

type Panel = {
  title: string;
  html?: React.ReactNode;
  text?: string;
};

export type InfoPanelProps = {
  panels: Panel[];
};

export const InfoPanel: React.FC<InfoPanelProps> = ({ panels }) => {
  return (
    <Stack
      direction="row"
      divider={<Divider orientation="vertical" flexItem />}
      spacing={2}
      marginBottom={3}
    >
      {panels.map((panel, index) => (
        <Stack alignItems="flex-start" key={index}>
          <Typography variant="caption" color="grey.500">
            {panel.title}
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing="4px"
          >
            {panel.text && (
              <Typography variant="body2" color="text.primary" fontWeight={500}>
                {panel.text}
              </Typography>
            )}
            {panel.html && panel.html}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
