import SelectField from "../../components/SelectField";
import {
  AttributeSelection,
  getAttributeSelectionImageUrl,
} from "../../types/attributeSelection";
import { formatAsCurrency } from "../../utils/number";
import React, { useMemo } from "react";
import { Attribute } from "../../types/attribute";
import SwatchField, { SwatchFieldOption } from "../../components/SwatchField";
import InputField from "../../components/InputField";
import { Box, Stack, Typography } from "@mui/material";
import { OrderCardError, getOrderCardError } from "../../types/orderCard";
import { OrderCardSubgroupOption } from "../../types/orderCardSubgroupOption";
import { formatAsDate } from "../../utils/date";
import { LogicAffected } from "../../types/logicAffected";
import { applyLogicToAttributeSelections } from "../../types/logicResult";

interface OrderCardSubgroupOptionAttributeFieldProps {
  orderCard: OrderCardSubgroupOption;
  attribute: Attribute;
  errors: OrderCardError[];
  name: string;
  disabled: boolean;
  value: string;
  logicAffected: LogicAffected | null;
  onChange: (value: string) => void;
  onBlur: () => void;
}

export const OrderCardSubgroupOptionAttributeField = React.forwardRef<
  HTMLInputElement,
  OrderCardSubgroupOptionAttributeFieldProps
>(
  (
    {
      orderCard,
      attribute,
      disabled,
      value,
      name,
      logicAffected,
      onChange,
      onBlur,
    },
    ref
  ) => {
    const isAttributeSelectionRemoved = useMemo(() => {
      const errorData = getOrderCardError(orderCard, "esoar");
      return errorData?.attribute.attributeGuid === attribute.attributeGuid;
    }, [attribute.attributeGuid, orderCard]);

    const attributeSelectionOptions: SwatchFieldOption<string>[] =
      useMemo(() => {
        if (attribute.attributeselections.length === 0) {
          return [];
        }

        const invalidAttributeSelection = getOrderCardError(
          orderCard,
          "esoae"
        )?.attributeselection;

        const invalidSelectionOption = invalidAttributeSelection
          ? [
              getAttributeSelectionOption(
                attribute,
                invalidAttributeSelection,
                false,
                `Exp ${formatAsDate(invalidAttributeSelection?.endDate)}`
              ),
            ]
          : [];

        const selectedOption = orderCard.selectionOptions.find(
          (option) =>
            option.optionGuid === orderCard.cardData.selectedOptionGuid
        );

        const selectedAttributeSelectionGuids =
          orderCard.cardData.attributeSelections.map(
            ({ attributeselectionGuid }) => attributeselectionGuid
          );

        const logicResult = applyLogicToAttributeSelections(
          attribute.attributeselections,
          selectedAttributeSelectionGuids,
          logicAffected,
          selectedOption?.optionGuid
        );

        const selectionOptions: SwatchFieldOption<string>[] = [
          ...invalidSelectionOption,
          ...logicResult.entitiesWithLogicErrors.map((attributeSelection) =>
            getAttributeSelectionOption(attribute, attributeSelection, true)
          ),
          ...logicResult.entitiesWithoutLogicErrors.map((attributeSelection) =>
            getAttributeSelectionOption(attribute, attributeSelection, false)
          ),
        ];

        return selectionOptions;
      }, [attribute, logicAffected, orderCard]);

    const selectedOption = useMemo(
      () => attributeSelectionOptions.find((a) => a.value === value),
      [attributeSelectionOptions, value]
    );

    return attribute.attributePresentAs === "s" ? (
      <SwatchField
        ref={ref}
        label={attribute.attributeName}
        exclusive={true}
        required={attribute.attributeSelectIsRequired}
        error={isAttributeSelectionRemoved}
        helperText={isAttributeSelectionRemoved ? "Removed" : undefined}
        options={attributeSelectionOptions}
        disabled={disabled}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    ) : attribute.attributePresentAs === "d" ? (
      <Stack direction="row" spacing={2}>
        <SelectField
          ref={ref}
          label={attribute.attributeName}
          placeholder={`Enter ${attribute.attributeName}`}
          fullWidth
          required={attribute.attributeSelectIsRequired}
          error={isAttributeSelectionRemoved}
          labelHelperText={isAttributeSelectionRemoved ? "Removed" : undefined}
          disabled={disabled}
          options={attributeSelectionOptions}
          value={value}
          onChange={(e) => {
            onChange(e.target.value as string);
          }}
          onBlur={onBlur}
          name={name}
        />
        <Box width="20%" minWidth="90px" flexShrink={0}>
          <InputField
            label="Price"
            fullWidth
            disabled
            value={selectedOption?.labelSecondary ?? ""}
          />
        </Box>
      </Stack>
    ) : null;
  }
);

const getAttributeSelectionOption = (
  attribute: Attribute,
  attributeSelection: AttributeSelection,
  hasLogicError: boolean,
  error?: string
): SwatchFieldOption<string> => {
  return {
    label: (
      <>
        {attributeSelection.attributeselectionName}
        {hasLogicError ? (
          <Typography
            component={"span"}
            color="error"
            marginLeft={0.5}
            fontSize="inherit"
          >
            (Logic Error)
          </Typography>
        ) : null}
      </>
    ),
    labelSecondary: formatAsCurrency(
      attributeSelection.attributeselectionPrice
    ),
    value: attributeSelection.attributeselectionGuid,
    imageUrl: attribute.attributeIncludeImages
      ? getAttributeSelectionImageUrl(attributeSelection)
      : undefined,
    error: !!error,
    helperText: error,
  };
};
