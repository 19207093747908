import React, { useCallback, useEffect, useMemo } from "react";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import {
  Alert,
  AutocompleteRenderOptionState,
  Avatar,
  Box,
  Button,
  Divider,
  InputBase,
  Stack,
  Typography,
  styled,
  Card,
  CardContent,
  IconButton,
} from "@mui/material";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useApiRequest } from "../../hooks/useApiRequest";
import {
  Logic,
  getLogicIfTypeAsDisplayName,
  getLogicThenRequireHideAsDisplayName,
  getLogicThisTypeAsDisplayName,
} from "../../types/logic";
import SelectField from "../../components/SelectField";
import RadioGroupField from "../../components/RadioGroupField";
import { Category } from "../../types/category";
import {
  AutocompleteField,
  GroupedOption,
} from "../../components/AutocompleteField";
import SwitchField from "../../components/SwitchField";
import { DateTime } from "luxon";
import { getAttributeSelectionImageUrl } from "../../types/attributeSelection";
import { PageHeader } from "../../components/PageHeader";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "../../routes";
import { FormLabelAsterisk } from "../../components/FormLabelAsterisk";

type FormData = {
  ifType: "o" | "a" | "";
  ifOptionGuid: string | null;
  ifAttributeselectionGuid: string | null;
  thenRequireHide: "r" | "h" | "";
  isActive: boolean;
  thisTargets: {
    thisAttributeselectionGuid: string | null;
    thisOptionGuid: string | null;
    thisSubgroupGuid: string | null;
    thisType: "o" | "a" | "s" | "";
  }[];
};

const getDefaultValues = (logic: Logic | undefined): FormData => {
  return {
    ifType: logic?.ifType ?? "",
    ifOptionGuid: logic?.ifOptionGuid ?? null,
    ifAttributeselectionGuid: logic?.ifAttributeselectionGuid ?? null,
    thenRequireHide: logic?.thenRequireHide ?? "",
    isActive: logic?.isActive ?? true,
    thisTargets: logic?.thisTargets ?? [
      {
        thisAttributeselectionGuid: null,
        thisOptionGuid: null,
        thisSubgroupGuid: null,
        thisType: "",
      },
    ],
  };
};

const ManageConditionalLogicAddEditRoute: React.FC = () => {
  const navigate = useNavigate();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { showErrorToast } = useGlobalToastNotificationContext();
  const { logicGuid } = useParams();
  const isNew = !logicGuid;

  const { data: categoriesData, request: categoriesRequest } =
    useApiRequest<Category[]>(false);

  const {
    data: logicData,
    request: logicRequest,
    status: logicStatus,
    loading: logicLoading,
    errorMessage: logicErrorMessage,
  } = useApiRequest<Logic>(false);

  const {
    loading: saveLogicLoading,
    request: saveLogicRequest,
    errorMessage: saveLogicErrorMessage,
  } = useApiRequest<Logic>(true);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { isValid },
  } = useForm<FormData>({
    defaultValues: getDefaultValues(undefined),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "thisTargets",
    rules: { required: true },
  });

  const ifType = watch("ifType");
  const ifOptionGuid = watch("ifOptionGuid");
  const thisTargets = watch("thisTargets");

  useEffect(() => {
    setBreadcrumbs([
      {
        label: routes.manageDataConditionalLogic.label,
        href: routes.manageDataConditionalLogic.path,
      },
      { label: isNew ? "Add" : "Edit" },
    ]);
  }, [isNew, setBreadcrumbs]);

  useEffect(() => {
    categoriesRequest("/company/options/categories", { method: "GET" });
  }, [categoriesRequest]);

  useEffect(() => {
    if (logicGuid) {
      logicRequest(`/company/logics2/${logicGuid}/get`, { method: "GET" });
    }
  }, [logicGuid, logicRequest]);

  useEffect(() => {
    if (logicData && logicStatus === "ok" && logicLoading === false) {
      const defaultValues = getDefaultValues(logicData);
      reset(defaultValues);
    }
  }, [logicData, logicLoading, logicStatus, reset]);

  useEffect(() => {
    if (saveLogicErrorMessage) {
      showErrorToast({ message: saveLogicErrorMessage });
    }
  }, [saveLogicErrorMessage, showErrorToast]);

  useEffect(() => {
    if (logicErrorMessage) {
      showErrorToast({ message: logicErrorMessage });
    }
  }, [logicErrorMessage, showErrorToast]);

  type GroupedOptionSelectionOption = GroupedOption<string> & {
    endDate: number | null;
  };

  const selectionOptions = useMemo(() => {
    const options: Array<GroupedOptionSelectionOption> = [];

    categoriesData?.forEach((category) => {
      category.groups.forEach((group) => {
        group.subgroups.forEach((subgroup) => {
          subgroup.options.forEach((option) => {
            options.push({
              label: option.optionName,
              value: option.optionGuid,
              groupValue: subgroup.subgroupGuid,
              groupLabel: subgroup.subgroupName,
              endDate: option.endDate,
            });
          });
        });
      });
    });

    return options;
  }, [categoriesData]);

  type GroupedOptionAttributeSelection = GroupedOption<string> & {
    attributeIncludeImages: boolean;
    attributeselectionImageUrl: string | null;
    endDate: number | null;
  };

  const ifTypeOptions = useMemo(
    () => [
      {
        label: getLogicIfTypeAsDisplayName("o"),
        value: "o",
      },
      {
        label: getLogicIfTypeAsDisplayName("a"),
        value: "a",
      },
    ],
    []
  );

  const thisTypeOptions = useMemo(
    () => [
      {
        label: getLogicThisTypeAsDisplayName("o"),
        value: "o",
      },
      {
        label: getLogicThisTypeAsDisplayName("a"),
        value: "a",
      },
      {
        label: getLogicThisTypeAsDisplayName("s"),
        value: "s",
      },
    ],
    []
  );

  const ifAttributeSelectionOptions = useMemo(() => {
    const options: Array<GroupedOptionAttributeSelection> = [];

    categoriesData?.forEach((category) => {
      category.groups.forEach((group) => {
        group.subgroups.forEach((subgroup) => {
          subgroup.options.forEach((option) => {
            if (option.optionGuid !== ifOptionGuid) {
              return;
            }

            option.attributes.forEach((attribute) => {
              attribute.attributeselections.forEach((attributeSelection) => {
                options.push({
                  label: attributeSelection.attributeselectionName,
                  value: attributeSelection.attributeselectionGuid,
                  groupValue: attribute.attributeGuid,
                  groupLabel: attribute.attributeName,
                  attributeIncludeImages: attribute.attributeIncludeImages,
                  attributeselectionImageUrl:
                    attributeSelection.attributeselectionImageUrl,
                  endDate: attributeSelection.endDate,
                });
              });
            });
          });
        });
      });
    });

    return options;
  }, [categoriesData, ifOptionGuid]);

  const thisSubGroupOptions = useMemo(() => {
    const options: Array<GroupedOption<string>> = [];

    categoriesData?.forEach((category) => {
      category.groups.forEach((group) => {
        group.subgroups.forEach((subgroup) => {
          subgroup.series.forEach((series) => {
            options.push({
              label: subgroup.subgroupName,
              value: subgroup.subgroupGuid,
              groupValue: series.seriesGuid,
              groupLabel: series.seriesName,
            });
          });
        });
      });
    });

    options.sort(
      (a, b) =>
        a.groupLabel.localeCompare(b.groupLabel) ||
        a.label.localeCompare(b.label)
    );

    return options;
  }, [categoriesData]);

  const renderValue =
    (options: { value: any; label: string }[], placeholder: string) =>
    (v: any) => {
      const option = options.find((o) => o.value === v);
      return (
        <Box>
          {option?.label ?? placeholder}
          <FormLabelAsterisk />
        </Box>
      );
    };

  const renderOptionSelectionOption = useCallback(
    (
      props: React.HTMLAttributes<HTMLLIElement>,
      option: GroupedOptionSelectionOption,
      { selected }: AutocompleteRenderOptionState
    ) => {
      const currentDateTime = DateTime.local();
      const endDateTime = option.endDate
        ? DateTime.fromSeconds(option.endDate)
        : null;
      const isExpired =
        endDateTime &&
        endDateTime.toFormat("yyyy/MM/dd") <
          currentDateTime.toFormat("yyyy/MM/dd");

      return (
        <Box
          component="li"
          {...props}
          sx={{
            paddingX: "14px !important",
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <span>{option.label}</span>
          {isExpired ? (
            <Typography component="span" color="error" marginLeft={1}>
              Exp {endDateTime.toLocaleString(DateTime.DATE_MED)}
            </Typography>
          ) : null}
          {selected ? (
            <CheckOutlinedIcon color="primary" sx={{ marginLeft: "auto" }} />
          ) : null}
        </Box>
      );
    },
    []
  );

  const renderOptionAttributeSelection = useCallback(
    (
      props: React.HTMLAttributes<HTMLLIElement>,
      option: GroupedOptionAttributeSelection,
      { selected }: AutocompleteRenderOptionState
    ) => {
      const imageUrl = getAttributeSelectionImageUrl(option);

      const currentDateTime = DateTime.local();
      const endDateTime = option.endDate
        ? DateTime.fromSeconds(option.endDate)
        : null;
      const isExpired =
        endDateTime &&
        endDateTime.toFormat("yyyy/MM/dd") <
          currentDateTime.toFormat("yyyy/MM/dd");

      return (
        <Box
          component="li"
          {...props}
          sx={{
            paddingX: "14px !important",
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          {option.attributeIncludeImages ? (
            <Avatar
              src={imageUrl}
              sx={{
                width: 24,
                height: 24,
                marginRight: 1,
              }}
            />
          ) : null}
          <span>{option.label}</span>
          {isExpired ? (
            <Typography component="span" color="error" marginLeft={1}>
              Exp {endDateTime.toLocaleString(DateTime.DATE_MED)}
            </Typography>
          ) : null}
          {selected ? (
            <CheckOutlinedIcon color="primary" sx={{ marginLeft: "auto" }} />
          ) : null}
        </Box>
      );
    },
    []
  );

  const onSubmit: SubmitHandler<FormData> = (data: FormData) => {
    if (isNew) {
      saveLogicRequest(
        "/company/logics2/add",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          data,
        },
        {
          onSuccess: () => {
            navigate(routes.manageDataConditionalLogic.path);
          },
        }
      );
    } else {
      saveLogicRequest(`company/logics2/${logicData?.logicGuid}/edit`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data,
      });
    }
  };

  const handleCancelClick = () => {
    navigate(routes.manageDataConditionalLogic.path);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageHeader
          title={"Conditional Logic Statement"}
          actions={
            <Stack spacing={2} direction="row">
              {!isNew ? (
                <Controller
                  name="isActive"
                  control={control}
                  render={({ field }) => (
                    <SwitchField
                      {...field}
                      label="Active"
                      checked={field.value}
                      sx={{ marginRight: "auto" }}
                    />
                  )}
                />
              ) : null}
              <Button
                variant="outlined"
                onClick={handleCancelClick}
                sx={{ marginRight: 2 }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={!isValid || saveLogicLoading}
              >
                {isNew ? "Create" : "Save"}
              </Button>
            </Stack>
          }
        />
        <Card variant="outlined">
          <CardContent>
            {logicData && logicData.logicError && (
              <Alert severity="error" sx={{ marginBottom: 2 }}>
                {logicData.logicError.longDesc}
              </Alert>
            )}
            <Box border="1px solid" borderColor="grey.300" borderRadius="4px">
              <Stack divider={<Divider flexItem />}>
                <LogicSection avatarText="If">
                  <Box>
                    <Controller
                      control={control}
                      name="ifType"
                      rules={{ required: "Please select a value." }}
                      render={({ field, fieldState }) => (
                        <SelectField
                          {...field}
                          label=""
                          placeholder="Select Type"
                          fullWidth={false}
                          required
                          notched={undefined}
                          input={<BasicInput />}
                          options={ifTypeOptions}
                          renderValue={renderValue(
                            ifTypeOptions,
                            "Select Type"
                          )}
                          onChange={(e) => {
                            field.onChange(e);
                            setValue("ifOptionGuid", null);
                            setValue("ifAttributeselectionGuid", null);
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                  <Stack direction="row" spacing={1}>
                    {ifType === "" ? <Box height="42px" /> : null}
                    {ifType === "o" || ifType === "a" ? (
                      <Controller
                        control={control}
                        name="ifOptionGuid"
                        rules={{ required: "Please select a value." }}
                        render={({ field, fieldState }) => {
                          const selectedValue = selectionOptions.find(
                            (s) => s.value === field.value
                          );

                          return (
                            <AutocompleteField
                              {...field}
                              options={selectionOptions}
                              value={selectedValue ?? null}
                              placeholder="Select Selection Option"
                              onChange={(_, value) => {
                                field.onChange(value?.value ?? null);
                                setValue("ifAttributeselectionGuid", null);
                              }}
                              renderOption={renderOptionSelectionOption}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          );
                        }}
                      />
                    ) : null}
                    {ifType === "a" ? (
                      <Controller
                        control={control}
                        name="ifAttributeselectionGuid"
                        rules={{ required: "Please select a value." }}
                        render={({ field, fieldState }) => {
                          const selectedValue =
                            ifAttributeSelectionOptions.find(
                              (s) => s.value === field.value
                            );

                          return (
                            <AutocompleteField
                              {...field}
                              options={ifAttributeSelectionOptions}
                              value={selectedValue ?? null}
                              placeholder="Select Attribute Selection"
                              onChange={(_, value) => {
                                field.onChange(value?.value ?? null);
                              }}
                              renderOption={renderOptionAttributeSelection}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          );
                        }}
                      />
                    ) : null}
                  </Stack>
                </LogicSection>

                <LogicSection avatarText="Then">
                  <Controller
                    name="thenRequireHide"
                    control={control}
                    rules={{ required: "Please select a value." }}
                    render={({ field, fieldState }) => (
                      <RadioGroupField
                        {...field}
                        label=""
                        options={[
                          {
                            label: getLogicThenRequireHideAsDisplayName("r"),
                            value: "r",
                          },
                          {
                            label: getLogicThenRequireHideAsDisplayName("h"),
                            value: "h",
                          },
                        ]}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </LogicSection>

                <Box>
                  {fields.map((field, index) => {
                    const thisOptionGuid = thisTargets[index].thisOptionGuid;
                    const thisType = thisTargets[index].thisType;
                    const thisAttributeSelectionOptions: Array<GroupedOptionAttributeSelection> =
                      [];

                    categoriesData?.forEach((category) => {
                      category.groups.forEach((group) => {
                        group.subgroups.forEach((subgroup) => {
                          subgroup.options.forEach((option) => {
                            if (option.optionGuid !== thisOptionGuid) {
                              return;
                            }

                            option.attributes.forEach((attribute) => {
                              attribute.attributeselections.forEach(
                                (attributeSelection) => {
                                  thisAttributeSelectionOptions.push({
                                    label:
                                      attributeSelection.attributeselectionName,
                                    value:
                                      attributeSelection.attributeselectionGuid,
                                    groupValue: attribute.attributeGuid,
                                    groupLabel: attribute.attributeName,
                                    attributeIncludeImages:
                                      attribute.attributeIncludeImages,
                                    attributeselectionImageUrl:
                                      attributeSelection.attributeselectionImageUrl,
                                    endDate: attributeSelection.endDate,
                                  });
                                }
                              );
                            });
                          });
                        });
                      });
                    });

                    return (
                      <LogicSection key={field.id} avatarText="This">
                        <Box>
                          <Controller
                            control={control}
                            name={`thisTargets.${index}.thisType`}
                            rules={{ required: "Please select a value." }}
                            render={({ field, fieldState }) => (
                              <SelectField
                                {...field}
                                label=""
                                placeholder="Select Type"
                                required
                                fullWidth={false}
                                notched={undefined}
                                input={<BasicInput />}
                                renderValue={renderValue(
                                  thisTypeOptions,
                                  "Select Type"
                                )}
                                options={thisTypeOptions}
                                onChange={(e) => {
                                  field.onChange(e);
                                  setValue(
                                    `thisTargets.${index}.thisOptionGuid`,
                                    null
                                  );
                                  setValue(
                                    `thisTargets.${index}.thisAttributeselectionGuid`,
                                    null
                                  );
                                  setValue(
                                    `thisTargets.${index}.thisSubgroupGuid`,
                                    null
                                  );
                                }}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                              />
                            )}
                          />
                        </Box>
                        <Stack
                          direction="row"
                          spacing={1}
                          justifyContent="flex-end"
                        >
                          {thisType === "" ? <Box height="42px" /> : null}
                          {thisType === "o" || thisType === "a" ? (
                            <Controller
                              control={control}
                              name={`thisTargets.${index}.thisOptionGuid`}
                              rules={{ required: "Please select a value." }}
                              render={({ field, fieldState }) => {
                                const selectedValue = selectionOptions.find(
                                  (s) => s.value === field.value
                                );

                                return (
                                  <AutocompleteField
                                    {...field}
                                    options={selectionOptions}
                                    value={selectedValue ?? null}
                                    placeholder="Select Selection Option"
                                    onChange={(_, value) => {
                                      field.onChange(value?.value ?? null);
                                      setValue(
                                        `thisTargets.${index}.thisAttributeselectionGuid`,
                                        null
                                      );
                                    }}
                                    renderOption={renderOptionSelectionOption}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                  />
                                );
                              }}
                            />
                          ) : null}
                          {thisType === "a" ? (
                            <Controller
                              control={control}
                              name={`thisTargets.${index}.thisAttributeselectionGuid`}
                              rules={{ required: "Please select a value." }}
                              render={({ field, fieldState }) => {
                                const selectedValue =
                                  thisAttributeSelectionOptions.find(
                                    (s) => s.value === field.value
                                  );

                                return (
                                  <AutocompleteField
                                    {...field}
                                    options={thisAttributeSelectionOptions}
                                    value={selectedValue ?? null}
                                    placeholder="Select Attribute Selection"
                                    onChange={(_, value) => {
                                      field.onChange(value?.value ?? null);
                                    }}
                                    renderOption={
                                      renderOptionAttributeSelection
                                    }
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                  />
                                );
                              }}
                            />
                          ) : null}
                          {thisType === "s" ? (
                            <Controller
                              control={control}
                              name={`thisTargets.${index}.thisSubgroupGuid`}
                              rules={{ required: "Please select a value." }}
                              render={({ field, fieldState }) => {
                                const selectedValue = thisSubGroupOptions.find(
                                  (s) => s.value === field.value
                                );

                                return (
                                  <AutocompleteField
                                    {...field}
                                    options={thisSubGroupOptions}
                                    value={selectedValue ?? null}
                                    placeholder="Select Sub-Group"
                                    onChange={(_, value) => {
                                      field.onChange(value?.value ?? null);
                                    }}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                  />
                                );
                              }}
                            />
                          ) : null}
                          {index !== 0 ? (
                            <IconButton
                              aria-label="delete"
                              color="primary"
                              sx={{ padding: 0, marginLeft: "auto" }}
                              onClick={() => {
                                remove(index);
                              }}
                            >
                              <DeleteForeverOutlinedIcon color="error" />
                            </IconButton>
                          ) : (
                            // TODO
                            <Box width="24px" flexShrink={0} flexGrow={0} />
                          )}
                        </Stack>
                      </LogicSection>
                    );
                  })}
                  <Button
                    sx={{ marginLeft: 3, marginBottom: 3 }}
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                    onClick={() => {
                      append({
                        thisAttributeselectionGuid: null,
                        thisOptionGuid: null,
                        thisSubgroupGuid: null,
                        thisType: "",
                      });
                    }}
                  >
                    Add "This" Statement
                  </Button>
                </Box>
              </Stack>
            </Box>
          </CardContent>
        </Card>
      </form>
    </>
  );
};

interface LogicSectionProps {
  avatarText: string;
  children: React.ReactNode;
}

const LogicSection: React.FC<LogicSectionProps> = ({
  avatarText,
  children,
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      spacing={2}
      padding={3}
    >
      <Avatar
        sx={{
          color: "#6941C6",
          backgroundColor: "#F9F5FF",
          width: 70,
          height: 70,
          fontSize: "14px",
          letterSpacing: "normal",
        }}
      >
        {avatarText}
      </Avatar>
      <Stack spacing={1} flexGrow="1" width="100%">
        {children}
      </Stack>
    </Stack>
  );
};

const BasicInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    border: "none",
    position: "relative",
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 600,
    padding: 0,
    "& .MuiTypography-root": {
      fontSize: 14,
      fontWeight: 600,
      opacity: 1,
    },
  },
  "& .MuiSelect-icon": {
    color: theme.palette.primary.main,
  },
}));

export default ManageConditionalLogicAddEditRoute;
