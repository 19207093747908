import React, { useState } from "react";
import { Popover, List, Box, ListItemButton } from "@mui/material";
import InputField from "./InputField";
import { FilterToggleButton } from "./FilterToggleButton";
import { Search } from "@mui/icons-material";
import { MenuItem } from "../types/menuItem";

type FilterButtonProps = {
  children: React.ReactNode;
  active?: boolean;
  buttonIcon?: React.ReactNode;
  icon?: React.ReactNode;
  items: MenuItem[];
  disabled?: boolean;
  renderItem?: (item: MenuItem) => React.ReactNode;
  onItemSelect: (item: string) => void;
};

export const FilterSearchButton: React.FC<FilterButtonProps> = ({
  active,
  children,
  icon,
  items,
  disabled,
  renderItem,
  onItemSelect,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [search, setSearch] = useState<string>("");
  const [selectedLabel, setSelectedLabel] = useState<string | null>(null);
  const [isActive, setIsActive] = useState<boolean>(active ?? false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isActive) {
      onItemSelect("");
      setSelectedLabel(null);
      setIsActive(false);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setSearch("");
    setAnchorEl(null);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleItemSelect = (item: MenuItem) => {
    onItemSelect(item.value);
    handleClose();
    setSelectedLabel(item.label);
    setIsActive(true);
  };

  const handleReturn = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      if (filteredItems.length === 1) {
        handleItemSelect(filteredItems[0]);
      }
    }
  };

  const filteredItems = items.filter(
    (item) =>
      item?.label?.toLowerCase().includes(search.toLowerCase()) ||
      item?.value?.toLowerCase().includes(search.toLowerCase())
  );

  const open = Boolean(anchorEl);
  const id = open ? "popover" : undefined;

  return (
    <div>
      <FilterToggleButton
        active={isActive}
        icon={icon}
        disabled={disabled}
        onClick={handleClick}
      >
        {selectedLabel ?? children}
      </FilterToggleButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{ marginTop: 1 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box p={2} width={300} maxHeight={280}>
          <InputField
            fullWidth
            value={search}
            onChange={handleSearchChange}
            onKeyUp={handleReturn}
            startAdornment={<Search />}
          />
          <Box style={{ overflowY: "auto", maxHeight: 250 }}>
            <List>
              {filteredItems.map((item) => (
                <ListItemButton
                  key={item.value}
                  onClick={() => handleItemSelect(item)}
                >
                  {renderItem ? renderItem(item) : item.label}
                </ListItemButton>
              ))}
            </List>
          </Box>
        </Box>
      </Popover>
    </div>
  );
};
