import { DraggableIndicator } from "./DraggableIndicator";

interface TableCellDraggableProps {
  dragId: string | number;
  children: React.ReactNode;
}

export const TableCellDraggable = ({
  dragId,
  children,
}: TableCellDraggableProps) => {
  return <DraggableIndicator dragId={dragId}>{children}</DraggableIndicator>;
};
