import { orderCardTypes } from "./orderCardTypes";
import { OrderCardBusinessBuyer } from "./orderCardBusinessBuyer";
import { OrderCardError } from "./orderCard";
import { Vendor } from "./vendor";

export type OrderCardFinancialFinancingInvalidVendor = OrderCardError<{ vendor: Vendor }>;

export type OrderCardFinancialFinancing = Omit<
  OrderCardBusinessBuyer,
  "orderCardType" | "cardData"
> & {
  orderCardType: (typeof orderCardTypes)["financialFinancing"];
  isLocked: boolean;
  errors: OrderCardFinancialFinancingInvalidVendor[];
  vendors: Vendor[]
  cardData: {
    poNumber: string | null;
    paymentTerms: "COD" | "Financed" | null;
    floorPlanLenderGuid: string | null;
    fiApprovedBy: string | null;
    fiApprovalNumber: string | null;
  };
};

export type OrderCardFinancialFinancingPaymentTerms = Exclude<
  OrderCardFinancialFinancing["cardData"]["paymentTerms"],
  null
>;

export const getOrderCardFinancingPaymentTermsDisplayName = (
  paymentTerms: OrderCardFinancialFinancingPaymentTerms
): string => {
  const displayName: Record<OrderCardFinancialFinancingPaymentTerms, string> = {
    COD: "COD",
    Financed: "Financed",
  };
  return displayName[paymentTerms];
};
