export function updateSortOrder<T extends { sortOrder: number }>(
  objects: T[],
  isEqual: (a: T, b: T) => boolean
) {
  // Remove any duplicates
  const uniqueObjects = objects.filter((obj, index, array) => {
    const objIndex = array.findIndex((o) => isEqual(obj, o));
    return objIndex === index;
  });

  // Update sort order
  const updatedObjects = uniqueObjects.map((o, index) => ({
    ...o,
    sortOrder: index,
  }));

  return updatedObjects;
}

export function groupBy<T, K extends string | number | symbol>(
  array: T[],
  getGroupKey: (item: T) => K
): Record<K, T[]> {
  const groupedArray = array.reduce((map, item) => {
    const mapKey = getGroupKey(item);

    if (!map[mapKey]) {
      map[mapKey] = [];
    }
    map[mapKey].push(item);

    return map;
  }, {} as Record<K, T[]>);

  return groupedArray;
}
