import React, { useEffect } from "react";
import { Modal } from "../../components/Modal";
import InputField from "../../components/InputField";
import { Alert, Box, Button, Stack } from "@mui/material";
import { useApiRequest } from "../../hooks/useApiRequest";
import SelectField from "../../components/SelectField";
import { UserRole, getUserRoleDisplayName } from "../../types/userRole";
import { User } from "../../types/user";
import CheckboxField from "../../components/CheckboxField";
import SwitchField from "../../components/SwitchField";
import { Controller, useForm } from "react-hook-form";
import { isValidEmail, isValidPhoneNumber } from "../../utils/validation";
import { getDirtyFormValues } from "../../utils/form";

type FormData = {
  userFirstName: User["userFirstName"];
  userLastName: User["userLastName"];
  userPhoneNumber: User["userPhoneNumber"];
  userEmail: User["userEmail"];
  userRole: User["userRole"] | "";
  sendInvite: boolean;
  isActive: User["isActive"];
};

const getDefaultValues = (user: User | null): FormData => {
  return {
    userFirstName: user?.userFirstName ?? "",
    userLastName: user?.userLastName ?? "",
    userPhoneNumber: user?.userPhoneNumber ?? "",
    userEmail: user?.userEmail ?? "",
    userRole: user?.userRole ?? "",
    sendInvite: user ? false : true,
    isActive: user?.isActive ?? true,
  };
};

type NewEditUserModalProps = {
  isOpen: boolean;
  isNew: boolean;
  user: User | null;
  onSaveSuccessful: () => void;
  onClose: () => void;
};

export const NewEditUserModal: React.FC<NewEditUserModalProps> = ({
  isOpen,
  isNew,
  user,
  onSaveSuccessful,
  onClose,
}) => {
  const { control, handleSubmit, register, reset, formState, setValue, watch } =
    useForm<FormData>({
      defaultValues: getDefaultValues(user),
      reValidateMode: "onChange",
      mode: "onChange",
    });
  const isActive = watch("isActive");

  const { data, loading, request, status, errorMessage } = useApiRequest<User>(
    true,
    "user"
  );
  const { request: sendInviteRequest } = useApiRequest();
  const { request: activationToggleRequest } = useApiRequest();

  useEffect(() => {
    if (user) {
      const defaultValues = getDefaultValues(user);
      reset(defaultValues);
    }
  }, [reset, user]);

  const onSubmit = (values: FormData) => {
    if (isNew) {
      request("/company/users/add", {
        method: "POST",
        data: values,
      });
    } else {
      const data = getDirtyFormValues(values, formState);

      if (data.sendInvite) {
        sendInviteRequest("/users/invite", {
          method: "POST",
          data: { userGuid: user?.userGuid },
        });
      }

      if (data.isActive != null) {
        activationToggleRequest(`/users/${user?.userGuid}/activate`, {
          method: "POST",
          data: { isActive: data.isActive },
        });
      }

      request(`/company/users/${user?.userGuid}/edit`, {
        method: "POST",
        data: data,
      });
    }
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  useEffect(() => {
    if (data && status === "ok" && loading === false) {
      onSaveSuccessful();
    }
  }, [data, onSaveSuccessful, status, loading]);

  return (
    <Modal
      heading={`${isNew ? "New" : "Edit"} User`}
      isOpen={isOpen}
      onClose={handleClose}
    >
      {errorMessage && (
        <Alert severity="error" sx={{ marginBottom: 3 }}>
          {errorMessage}
        </Alert>
      )}
      <Stack
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        rowGap={3}
        sx={{ width: { xs: "100%", sm: "556px" } }}
      >
        <Box sx={{ display: "flex", gap: 2 }}>
          <InputField
            {...register("userFirstName", {
              required: { value: true, message: "Please enter a value." },
            })}
            required
            fullWidth
            label="First Name"
            placeholder="Enter first name"
            error={!!formState.errors.userFirstName}
            helperText={formState.errors.userFirstName?.message}
          />
          <InputField
            {...register("userLastName", {
              required: { value: true, message: "Please enter a value." },
            })}
            required
            fullWidth
            label="Last Name"
            placeholder="Enter last name"
            error={!!formState.errors.userLastName}
            helperText={formState.errors.userLastName?.message}
          />
        </Box>
        <InputField
          {...register("userEmail", {
            required: { value: true, message: "Please enter a value." },
            validate: {
              validEmail: (v) =>
                isValidEmail(v) || "Please enter a valid email address.",
            },
          })}
          required
          fullWidth
          label="Email"
          error={!!formState.errors.userEmail}
          helperText={formState.errors.userEmail?.message}
        />
        <InputField
          {...register("userPhoneNumber", {
            required: { value: true, message: "Please enter a value." },
            validate: {
              validPhoneNumber: (v) =>
                isValidPhoneNumber(v) || "Please enter a valid phone number.",
            },
          })}
          required
          fullWidth
          label="Phone"
          placeholder="Enter number"
          error={!!formState.errors.userPhoneNumber}
          helperText={formState.errors.userPhoneNumber?.message}
        />
        <Controller
          control={control}
          name="userRole"
          render={({ field, fieldState }) => (
            <SelectField
              {...field}
              label="Permission"
              required
              fullWidth
              options={[
                {
                  label: getUserRoleDisplayName(UserRole.ADMIN),
                  value: UserRole.ADMIN,
                },
                {
                  label: getUserRoleDisplayName(UserRole.ADVANCEDUSER),
                  value: UserRole.ADVANCEDUSER,
                },
                {
                  label: getUserRoleDisplayName(UserRole.BASICUSER),
                  value: UserRole.BASICUSER,
                },
              ]}
              placeholder="Select"
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
        />
        <Controller
          name="sendInvite"
          control={control}
          disabled={!isActive}
          render={({ field }) => (
            <CheckboxField
              {...field}
              label="Send invite email"
              checked={field.value}
            />
          )}
        />

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          {!isNew ? (
            <Controller
              name="isActive"
              control={control}
              render={({ field }) => (
                <SwitchField
                  {...field}
                  label="Active"
                  checked={field.value}
                  sx={{ marginRight: "auto" }}
                  onChange={(e, checked) => {
                    field.onChange(e);
                    if (!checked) {
                      setValue("sendInvite", false);
                    }
                  }}
                />
              )}
            />
          ) : null}
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{ marginRight: 2 }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={!formState.isValid || loading}
          >
            Save
          </Button>
        </Box>
      </Stack>
    </Modal>
  );
};
