import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../routes";
import { useCallback } from "react";

type NavigateBackOptions = {
  /**
   * The path to navigate to if there's no more pages on the navigation stack.
   */
  fallback?: string;
};

/**
 * A hook to navigate back to the previous page. If there is no previous page,
 * then navigate back to the provided fallback page or the home page.
 */
export const useNavigateBack = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const navigateBack = useCallback(
    ({ fallback }: NavigateBackOptions = {}) => {
      if (location.key === "default") {
        navigate(fallback || routes.home.path);
      } else {
        navigate(-1);
      }
    },
    [location.key, navigate]
  );

  return navigateBack;
};
