import { MenuItem } from "./menuItem";
import { OrderCardError } from "./orderCard";
import { OrderCardStatus, orderCardTypes } from "./orderCardTypes";
import { Retailer } from "./tenant";
import { Vendor } from "./vendor";

export type OrderCardApplianceVendorError = OrderCardError<{ vendor: Vendor }>;

export type OrderCardAppliance = {
  orderCardGuid: string;
  orderCardType: (typeof orderCardTypes)["invoiceAppliances"];
  categoryName: string;
  groupName: string;
  subgroupName: string;
  isRequired: boolean;
  isApproved: boolean;
  isHidden: boolean;
  isLocked: boolean;
  orderCardStatus: OrderCardStatus;
  sortOrder: number;
  retailers: Retailer[];
  vendors: Vendor[];
  errors: OrderCardApplianceVendorError[];
  appliances: string[];
  cardData: {
    appliance: string | null;
    vendorGuid: string | null;
    modelNumber: string | null;
    serialNumber: string | null;
  };
};

export const getOrderApplianceOptions = (
  orderCard: OrderCardAppliance
): MenuItem[] =>
  orderCard.appliances.map((appliance) => ({
    label: appliance,
    value: appliance,
  }));

export const getOrderVendorOptions = (
  orderCard: OrderCardAppliance
): MenuItem[] =>
  orderCard.vendors.map((vendor) => ({
    label: vendor.vendorName,
    value: vendor.vendorGuid,
  }));
