import { Button } from "@mui/material";

interface ForgotPasswordButtonProps {
  showForgotPasswordModal: (showForgotPassword: boolean) => void;
}

function ForgotPasswordButton({
  showForgotPasswordModal,
}: ForgotPasswordButtonProps) {
  return (
    <Button
      onClick={() => showForgotPasswordModal(true)}
      variant="text"
      sx={{
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline", 
            background: 'none'
          }
      }}
    >
      Forgot Password
    </Button>
  );
}

export default ForgotPasswordButton;
