import { Typography } from "@mui/material";
import { ConfirmModal } from "../../components/ConfirmModal";

type QuoteRequestErrorModalProps = {
  onClose: () => void;
};

export const QuoteRequestErrorModal: React.FC<QuoteRequestErrorModalProps> = ({
  onClose,
}) => {
  return (
    <ConfirmModal
      isOpen={true}
      onConfirm={onClose}
      onClose={onClose}
      heading="Unable to confirm this quote"
      confirmButtonText="Okay"
      confirmOnly
      body={
        <Typography variant="body2" color="grey.500">
          This quote has one of the following: unapproved custom options,
          incomplete custom options, incomplete required subgroups, errors.
        </Typography>
      }
    />
  );
};
