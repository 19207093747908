import React from "react";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import {
  Stack,
  Button,
  Card,
  CardContent,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { FormSection } from "../../components/FormSection";
import InputField from "../../components/InputField";
import { Section, getTotalSpace, sectionNames } from "../../types/section";
import { isValidNumber } from "../../utils/validation";
import {
  FormState,
  UseFormRegister,
  UseFormSetValue,
  useFieldArray,
  Control,
} from "react-hook-form";
import { FormData } from "./FormData";
import { Nullable } from "../../utils/nullable";
import { useSession } from "../../hooks/useSession";

interface SectionsFormSectionProps {
  sections: Nullable<Section>[];
  control: Control<FormData>;
  formState: FormState<FormData>;
  register: UseFormRegister<FormData>;
  setValue: UseFormSetValue<FormData>;
}

export const SectionsFormSection: React.FC<SectionsFormSectionProps> = ({
  sections,
  control,
  formState,
  register,
  setValue,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "sections",
    rules: { required: true },
  });
  const { user } = useSession();
  return (
    <Stack spacing={2}>
      <Card variant="outlined">
        <CardContent
          component={Stack}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="body1" fontWeight={600}>
            Sections
          </Typography>
          {!user?.isRetailUser && (
            <Button
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={() => {
                const sectionName = sectionNames[fields.length];
                if (sectionName) {
                  append({
                    sectionName: sectionName,
                    sectionLength: null,
                    sectionHitchLength: null,
                    sectionWidth: null,
                    sectionHeight: null,
                    sectionLivingSpace: null,
                    sectionUnheatedSpace: null,
                    sectionWeight: null,
                  });
                }
              }}
            >
              Add Section
            </Button>
          )}
        </CardContent>
      </Card>
      {fields.map((field, index) => {
        const fieldErrors = formState.errors.sections?.[index];
        const totalSpace = getTotalSpace(sections[index]);

        return (
          <Card variant="outlined" key={field.id}>
            <CardContent sx={{ paddingBottom: 0 }}>
              <FormSection
                heading={`Section ${field.sectionName}`}
                headingAction={
                  !user?.isRetailUser && (
                    <IconButton
                      aria-label="delete"
                      color="primary"
                      sx={{ padding: 0 }}
                      onClick={() => {
                        // Update the sectionName of all sections after the one being removed.
                        for (var i = index; i < fields.length; i++) {
                          const sectionName = sectionNames[i - 1];
                          setValue(`sections.${i}.sectionName`, sectionName);
                        }
                        remove(index);
                      }}
                    >
                      <DeleteForeverOutlinedIcon color="error" />
                    </IconButton>
                  )
                }
                sx={{ marginBottom: 0 }}
              >
                <Grid xs={12} md={6}>
                  <InputField
                    {...register(`sections.${index}.sectionLength`, {
                      valueAsNumber: true,
                      required: {
                        value: true,
                        message: "Please enter a value.",
                      },
                      validate: {
                        isNumber: (value) => {
                          return (
                            isValidNumber(value) || "Please enter a number."
                          );
                        },
                      },
                    })}
                    type="number"
                    fullWidth
                    required
                    label="Section Length"
                    placeholder="Enter section length"
                    error={!!fieldErrors?.sectionLength}
                    helperText={fieldErrors?.sectionLength?.message}
                    endAdornment={
                      <InputAdornment position="end">FT</InputAdornment>
                    }
                    disabled={user?.isRetailUser}
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <InputField
                    {...register(`sections.${index}.sectionHitchLength`, {
                      valueAsNumber: true,
                      required: {
                        value: true,
                        message: "Please enter a value.",
                      },
                      validate: {
                        isNumber: (value) => {
                          return (
                            isValidNumber(value) || "Please enter a number."
                          );
                        },
                      },
                    })}
                    type="number"
                    fullWidth
                    required
                    label="Hitch Length"
                    placeholder="Enter hitch length"
                    error={!!fieldErrors?.sectionHitchLength}
                    helperText={fieldErrors?.sectionHitchLength?.message}
                    endAdornment={
                      <InputAdornment position="end">FT</InputAdornment>
                    }
                    disabled={user?.isRetailUser}
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <InputField
                    {...register(`sections.${index}.sectionWidth`, {
                      valueAsNumber: true,
                      required: {
                        value: true,
                        message: "Please enter a value.",
                      },
                      validate: {
                        isNumber: (value) => {
                          return (
                            isValidNumber(value) || "Please enter a number."
                          );
                        },
                      },
                    })}
                    type="number"
                    fullWidth
                    required
                    label="Section Width"
                    placeholder="Enter section width"
                    error={!!fieldErrors?.sectionWidth}
                    helperText={fieldErrors?.sectionWidth?.message}
                    endAdornment={
                      <InputAdornment position="end">FT</InputAdornment>
                    }
                    disabled={user?.isRetailUser}
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <InputField
                    {...register(`sections.${index}.sectionHeight`, {
                      valueAsNumber: true,
                      required: {
                        value: true,
                        message: "Please enter a value.",
                      },
                      validate: {
                        isNumber: (value) => {
                          return (
                            isValidNumber(value) || "Please enter a number."
                          );
                        },
                      },
                    })}
                    type="number"
                    fullWidth
                    required
                    label="Section Height"
                    placeholder="Enter section height"
                    error={!!fieldErrors?.sectionHeight}
                    helperText={fieldErrors?.sectionHeight?.message}
                    endAdornment="FT"
                    disabled={user?.isRetailUser}
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <InputField
                    {...register(`sections.${index}.sectionLivingSpace`, {
                      valueAsNumber: true,
                      required: {
                        value: true,
                        message: "Please enter a value.",
                      },
                      validate: {
                        isNumber: (value) => {
                          return (
                            isValidNumber(value) || "Please enter a number."
                          );
                        },
                      },
                    })}
                    type="number"
                    fullWidth
                    required
                    label="Living Space"
                    placeholder="Enter living space"
                    error={!!fieldErrors?.sectionLivingSpace}
                    helperText={fieldErrors?.sectionLivingSpace?.message}
                    endAdornment={
                      <InputAdornment position="end">SQ FT</InputAdornment>
                    }
                    disabled={user?.isRetailUser}
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <InputField
                    {...register(`sections.${index}.sectionUnheatedSpace`, {
                      valueAsNumber: true,
                      required: {
                        value: true,
                        message: "Please enter a value.",
                      },
                      validate: {
                        isNumber: (value) => {
                          return (
                            isValidNumber(value) || "Please enter a number."
                          );
                        },
                      },
                    })}
                    type="number"
                    fullWidth
                    required
                    label="Non-Heated Space"
                    placeholder="Enter non-heated space"
                    error={!!fieldErrors?.sectionUnheatedSpace}
                    helperText={fieldErrors?.sectionUnheatedSpace?.message}
                    endAdornment={
                      <InputAdornment position="end">SQ FT</InputAdornment>
                    }
                    disabled={user?.isRetailUser}
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <InputField
                    fullWidth
                    readOnly
                    disabled
                    name={`sections.${index}.totalSpace`}
                    label="Total Space"
                    value={totalSpace}
                    endAdornment={
                      <InputAdornment position="end">SQ FT</InputAdornment>
                    }
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <InputField
                    {...register(`sections.${index}.sectionWeight`, {
                      valueAsNumber: true,
                      required: {
                        value: true,
                        message: "Please enter a value.",
                      },
                      validate: {
                        isNumber: (value) => {
                          return (
                            isValidNumber(value) || "Please enter a number."
                          );
                        },
                      },
                    })}
                    type="number"
                    fullWidth
                    required
                    label="Section Weight"
                    placeholder="Enter section weight"
                    error={!!fieldErrors?.sectionWeight}
                    helperText={fieldErrors?.sectionWeight?.message}
                    endAdornment={
                      <InputAdornment position="end">LBS</InputAdornment>
                    }
                    disabled={user?.isRetailUser}
                  />
                </Grid>
              </FormSection>
            </CardContent>
          </Card>
        );
      })}
    </Stack>
  );
};
